interface Node {
  tagName: string;
  type: string;
  children?: Node[];
  value?: string;
}

export const sectionPlugin = () => (tree: Node) => {
  const oldChildren = tree.children;
  const newChildren = [];

  if (!oldChildren) {
    return tree;
  }

  for (const child of oldChildren) {
    const itemType = child.tagName;
    const itemChildren = child.children;
    const lastNewChildrenItem = newChildren[newChildren.length - 1];

    if (itemType === 'h2' && itemChildren) {
      newChildren.push({
        tagName: 'section',
        type: 'element',
        children: [
          {
            tagName: 'header',
            type: 'element',
            children: itemChildren,
          },
        ],
      });
    } else if (lastNewChildrenItem?.children && lastNewChildrenItem?.tagName === 'section') {
      lastNewChildrenItem.children.push(child);
    } else {
      newChildren.push(child);
    }
  }

  const result = { ...tree, children: newChildren };

  return result;
};
