import { notification, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { CareUnit } from 'api/careUnitsApi';
import RootStoreContext from 'context/RootStoreContext';
import { parseFloatStringToNumber } from 'utils/appUtils';

import CareUnitDetailsForm from '../components/CareUnitsDetailsForm';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

@observer
export class CareUnitBasicDetails extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  onSubmit = async (data: CareUnit) => {
    try {
      const {
        match: {
          params: { careProviderId },
        },
        intl,
      } = this.props;
      const { careUnitStore } = this.context;
      const cleanedData = {
        ...data,
        longitude: parseFloatStringToNumber(data.longitude),
        latitude: parseFloatStringToNumber(data.latitude),
      };

      await careUnitStore.updateCareUnit(careProviderId, cleanedData);

      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'care-units.care-unit-updated-message' }),
      });
      /* eslint-disable no-empty */
    } catch {}
  };

  render() {
    const { careUnitStore, userPermissionsStore } = this.context;

    if (!careUnitStore.careUnit) {
      return null;
    }

    return (
      <div>
        <Typography.Title level={2}>
          <FormattedMessage id="care-units.basic-details" />
        </Typography.Title>
        <CareUnitDetailsForm
          initialValues={careUnitStore.careUnit}
          onSubmit={this.onSubmit}
          isSaving={careUnitStore.isSaving() || careUnitStore.isLoading()}
          isDisabled={
            !userPermissionsStore.canEditCurrentPartner &&
            !userPermissionsStore.canEditCareUnit(
              this.props.match.params.careProviderId,
              careUnitStore.careUnit.id
            ) &&
            !careUnitStore.canBeEditedByMe
          }
        />
      </div>
    );
  }
}

export default injectIntl(CareUnitBasicDetails);
