import { CloseOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import styles from './FullScreenVisualization.module.css';
import { NodeData } from '../../stores/ConditionVisualizationStore';
import Diagram from '../Diagram';
import Exits from '../Exits';

/**
 * @notExported
 */
interface FullScreenVisualizationProps {
  isExpanded: boolean;
  handleExpand: () => void;
  handleClearSelectionClick: () => void;
  handleExitClick: (exit: NodeData) => void;
  handleDependencyClick: (id: string) => void;
}

const FullScreenVisualization: FunctionComponent<FullScreenVisualizationProps> = ({
  isExpanded,
  handleExpand,
  handleClearSelectionClick,
  handleExitClick,
  handleDependencyClick,
}) => {
  const { conditionVisualizationStore, conditionStore } = useContext(RootStoreContext);
  const diagramRef = useRef<HTMLDivElement>(null);

  const exitsWidth = 350;
  const isLoading = conditionStore.isLoading() || conditionVisualizationStore.isLoading;
  const isVisualizationDataAvailable = conditionVisualizationStore.questionsAndGotos?.length > 0;

  return (
    <Observer>
      {() => (
        <div
          ref={diagramRef}
          className={classNames(styles.diagramFullScreen, { [styles.isExpanded]: isExpanded })}
        >
          {isExpanded && diagramRef.current && isVisualizationDataAvailable && (
            <>
              <div className={styles.diagramTopRight}>
                {conditionVisualizationStore.selectedNodeId && (
                  <Button
                    shape="round"
                    onClick={handleClearSelectionClick}
                    icon={<CloseOutlined />}
                    loading={isLoading}
                    disabled={!isVisualizationDataAvailable}
                    className={styles.clearSelectionBtn}
                  >
                    <span>
                      <FormattedMessage id="condition.visualization.clear-selection" />
                    </span>
                  </Button>
                )}
                <div className={styles.expandBtn} onClick={handleExpand}>
                  <ShrinkOutlined className={styles.expandBtnIcon} />
                </div>
              </div>
              <div className={styles.contentWrapper}>
                <Exits
                  exits={conditionVisualizationStore.exits}
                  height={diagramRef.current.offsetHeight}
                  width={exitsWidth}
                  onExitClick={handleExitClick}
                  onDependencyClick={handleDependencyClick}
                  selectedNodeId={conditionVisualizationStore.selectedNodeId}
                  conditionId={conditionStore.conditionId}
                />
                <Diagram
                  width={diagramRef.current.offsetWidth - exitsWidth}
                  height={diagramRef.current.offsetHeight}
                  conditionId={conditionStore.conditionId}
                  selectedNodeId={conditionVisualizationStore.selectedNodeId}
                  focusedElementId={conditionVisualizationStore.focusedElementId}
                  showMiniMap
                />
              </div>
            </>
          )}
        </div>
      )}
    </Observer>
  );
};

export default FullScreenVisualization;
