import { useEffect } from 'react';

import { SelfMountedInterface } from './remoteComponent.types';

// Unmount component. It is not part of "useEffect" which renders component because if props would change
// then we would unmount component and mount it back which is not what we want.
// Instead we want just to unmount it only if it was actually unmounted. And React.Strict might brake it, but who cares
export const useUnmountComponent = (Component: SelfMountedInterface<unknown> | null) => {
  useEffect(
    () => () => {
      if (Component) {
        Component.unmount();
      }
    },
    [Component]
  );
};
