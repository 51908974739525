export const BASIC_DETAILS_FORM_FIELDS = {
  NAME: 'name',
  EXTERNAL_ORG_ID: 'externalOrgId',
  VISITING_ADDRESS: 'visitingAddress',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  LOCATION: 'location',
  PHONE_NUMBER: 'phoneNumber',
  DEFAULT_ORIGIN_ID: 'defaultOriginId',
};
