import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { CURRENT_ENV_TYPE, ENV } from '../../constants/env';

const VERSION = process.env.REACT_APP_SERVICE_VERSION || process.env.SERVICE_VERSION;

export const AppVersion = () => {
  return (
    <>
      <FormattedMessage id="triage24-version" />
      <br />
      Manage {VERSION}
      {CURRENT_ENV_TYPE === ENV.PROD ? '' : `: ${CURRENT_ENV_TYPE}`}
    </>
  );
};
