/* eslint-disable react/prop-types */
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useState } from 'react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { styled } from 'styled-components';

const Styled = {
  Collapse: styled(Collapse)`
    margin-bottom: 8px;
  `,
  CollapsePanel: styled(Collapse.Panel)`
    .ant-collapse-item {
      background-color: white;
      cursor: pointer;
    }

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    path {
      fill: var(--color-primary);
    }

    path:last-child {
      fill: var(--color-secondary);
    }
  `,
  Header: styled.div`
    flex-grow: 1;
  `,
  IconContainer: styled.div`
    margin-left: 16px;
  `,
};

export const MarkdownSection: NormalComponents['section'] = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const headerChild = React.isValidElement(children[0]) ? children[0] : <header />;
  const contentChildren = children.slice(1);
  return (
    <Styled.Collapse
      activeKey={isActive ? 'section' : ''}
      onChange={() => setIsActive(prev => !prev)}
    >
      <Styled.CollapsePanel
        extra={
          <Styled.IconContainer>
            {isActive ? <MinusCircleTwoTone /> : <PlusCircleTwoTone />}
          </Styled.IconContainer>
        }
        header={
          <Styled.Header data-testid="section-header-text">
            {headerChild.props.children}
          </Styled.Header>
        }
        key="section"
        showArrow={false}
      >
        {contentChildren}
      </Styled.CollapsePanel>
    </Styled.Collapse>
  );
};
