import axios from 'axios';

import { ROLES } from 'constants/roles';

const ROLES_URL = '/rest/admin/v1/clinic/roles/';

export const ADMINISTRATIVE_ROLES_URI = '/rest/admin/v1/clinic/roles/administrative';

export const RESOURCE_TYPES_URI = '/rest/admin/v1/practitioners/resourceTypes';

export const fetchRoles = () => axios.get<ROLES[]>(ROLES_URL);

export const fetchAdministrativeRoles = () => axios.get<ROLES[]>(ADMINISTRATIVE_ROLES_URI);

export const fetchResourceTypes = () => axios.get<ROLES[]>(RESOURCE_TYPES_URI);
