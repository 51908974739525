import { PlusCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './PlusFloatingButton.module.css';

interface Props {
  onClick: () => void;
  className?: string;
  testId?: string;
  disabled?: boolean;
}

const PlusFloatingButton: FunctionComponent<Props> = ({
  onClick,
  className,
  testId,
  disabled = false,
}) => (
  <Button
    type="link"
    onClick={onClick}
    className={classNames(styles.container, className)}
    data-testid={testId}
    icon={<PlusCircleFilled className={styles.plusIcon} />}
    disabled={disabled}
  ></Button>
);

export default PlusFloatingButton;
