import { TranslationOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { useAtom, PrimitiveAtom, WritableAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { tabContentStateAtom } from './LanguageTabs.state';
import {
  LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT,
  LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT,
} from '../fields/LanguageIcon';

const Styled = {
  Container: styled.div`
    position: relative;

    &.highlighted-language-tab {
      button[role='tab'] {
        transition: all 0.3s;
      }

      div[role='tab'][aria-selected='true'] {
        border-radius: 3px;
        background-color: #e6f4ff;
        padding: 0 6px;
      }
    }
  `,
  TabsLabelPrefix: styled.div`
    margin-right: 24px;

    svg {
      font-size: 16px;
      line-height: 24px;
      margin-right: 4px;
    }
  `,
};

type AtomValueTuple<T> = [PrimitiveAtom<T> | WritableAtom<T, any, any>, T];
export const HydrateAtoms = ({
  initialValues,
  children,
}: {
  initialValues: AtomValueTuple<any>[];
  children: JSX.Element | null;
}) => {
  useHydrateAtoms(initialValues);

  return children;
};

const HIGHLIGHTED_LANGUAGE_TAB_CLASS = 'highlighted-language-tab';

export const LanguageTabs = ({
  languages,
  defaultLanguage,
  disabled,
  className,
  onChange,
  children,
}: {
  languages: string[] | readonly string[];
  defaultLanguage?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (language: string) => void;
  children: ({ selectedLanguage }: { selectedLanguage: string }) => React.ReactNode;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const prevSelectedLanguage = useRef<string | undefined>(defaultLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageTabs = useMemo(
    () =>
      languages.map(lang => ({
        label:
          defaultLanguage === lang ? (
            <span>
              <FormattedMessage id={`general.language-${lang}`} /> (
              <span style={{ textTransform: 'lowercase' }}>
                <FormattedMessage id={`general.default`} />
              </span>
              )
            </span>
          ) : (
            <FormattedMessage id={`general.language-${lang}`} />
          ),
        key: lang,
        children: children({ selectedLanguage: lang }),
        disabled,
      })),
    [languages, disabled, defaultLanguage, children]
  );
  useEffect(() => {
    if (selectedLanguage && selectedLanguage !== prevSelectedLanguage.current) {
      prevSelectedLanguage.current = selectedLanguage;
      onChange?.(selectedLanguage);
    }
  }, [selectedLanguage, onChange]);

  const [, setTabContentState] = useAtom(tabContentStateAtom);

  const switchToDefaultLanguage = useCallback(() => {
    setSelectedLanguage(defaultLanguage);
  }, [defaultLanguage]);
  useEffect(
    function updateSwitchToDefaultLanguage() {
      setTabContentState(prev => ({
        ...prev,
        switchToDefaultLanguage: { fn: switchToDefaultLanguage },
      }));
    },
    [switchToDefaultLanguage, setTabContentState]
  );
  useEffect(
    function updateSetSelectedLanguage() {
      setTabContentState(prev => ({
        ...prev,
        setSelectedLanguage: { fn: setSelectedLanguage },
      }));
    },
    [setSelectedLanguage, setTabContentState]
  );

  useEffect(
    function updateIsOutsideDefaultLanguage() {
      setTabContentState(prev => ({
        ...prev,
        isOutsideDefaultLanguage: selectedLanguage !== defaultLanguage,
      }));
    },
    [defaultLanguage, selectedLanguage, setTabContentState]
  );

  useEffect(() => {
    const handleLocalizedFieldTranslationIconMouseEnter = () => {
      containerRef.current?.classList.add(HIGHLIGHTED_LANGUAGE_TAB_CLASS);
    };
    document.addEventListener(
      LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT,
      handleLocalizedFieldTranslationIconMouseEnter,
      { capture: true }
    );

    const handleLocalizedFieldTranslationIconMouseLeave = () => {
      containerRef.current?.classList.remove(HIGHLIGHTED_LANGUAGE_TAB_CLASS);
    };
    document.addEventListener(
      LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT,
      handleLocalizedFieldTranslationIconMouseLeave,
      { capture: true }
    );

    return () => {
      document.removeEventListener(
        LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT,
        handleLocalizedFieldTranslationIconMouseEnter,
        { capture: true }
      );
      document.removeEventListener(
        LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT,
        handleLocalizedFieldTranslationIconMouseLeave,
        { capture: true }
      );
    };
  }, []);

  return (
    <Styled.Container ref={containerRef}>
      <HydrateAtoms
        initialValues={[
          [
            tabContentStateAtom,
            {
              isInsideLanguageTabs: true,
              isOutsideDefaultLanguage: false,
              switchToDefaultLanguage,
            },
          ],
        ]}
      >
        <Tabs
          activeKey={selectedLanguage}
          defaultActiveKey={defaultLanguage}
          size="small"
          onChange={setSelectedLanguage}
          className={className}
          tabBarGutter={16}
          tabBarExtraContent={{
            left: (
              <Styled.TabsLabelPrefix>
                <TranslationOutlined className="tw-mr-1 tw-text-base" />
                <FormattedMessage id="general.languages" />:
              </Styled.TabsLabelPrefix>
            ),
          }}
          items={languageTabs}
        />
      </HydrateAtoms>
    </Styled.Container>
  );
};
