import { DeleteOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { FieldArrayRenderProps } from 'formik';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';

import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { addUUID } from 'utils/uuidUtils';

import Header from './Header';
import styles from './QuestionContentList.module.css';
import { QuestionFormData } from '../../QuestionForm';
import QuestionContentItem from '../QuestionContentItem/QuestionContentItem';

/**
 * @notExported
 */
interface QuestionContentListProps {
  isSaving: boolean;
  isDisabled?: boolean;
  formikArrayHelpers: FieldArrayRenderProps;
  activeLanguage: string;
}

const QuestionContentList: FunctionComponent<QuestionContentListProps> = ({
  isSaving,
  isDisabled,
  formikArrayHelpers,
  activeLanguage,
}) => {
  const isFormDisabled = isSaving || isDisabled;
  const values: QuestionFormData = formikArrayHelpers.form.values;

  const content = useMemo(() => values.content || [], [values.content]);

  const handleNewItem = useCallback(() => {
    const newItem = addUUID({
      type: CODE24_MODEL_TYPES.RESPONSE,
    });
    formikArrayHelpers.push(newItem);
  }, [formikArrayHelpers]);

  const handleItemTypeChange = useCallback(
    (type: CODE24_MODEL_TYPES.RESPONSE | CODE24_MODEL_TYPES.LAST_RESPONSE, index: number) => {
      const item = content[index];
      formikArrayHelpers.replace(index, { uuid: item.uuid, type });
    },
    [formikArrayHelpers, content]
  );

  const dndProps = {
    backend: HTML5Backend,
  };
  return (
    <DndProvider {...dndProps}>
      {!!content.length && (
        <Collapse
          accordion
          items={content.map((elem, index) => ({
            key: elem.uuid,
            collapsible: 'header',
            label: (
              <Header
                key={elem.uuid}
                data={elem}
                index={index}
                isDisabled={isFormDisabled}
                formikArrayHelpers={formikArrayHelpers}
              />
            ),
            children: (
              <QuestionContentItem
                key={elem.uuid}
                data={elem}
                index={index}
                isDisabled={isFormDisabled}
                formikArrayHelpers={formikArrayHelpers}
                isSaving={isSaving}
                activeLanguage={activeLanguage}
                onTypeChange={handleItemTypeChange}
              />
            ),
            extra: (
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => formikArrayHelpers.remove(index)}
                disabled={isFormDisabled}
              />
            ),
          }))}
        />
      )}
      <Button
        type="link"
        onClick={handleNewItem}
        disabled={isFormDisabled}
        className={styles.button}
      >
        +{' '}
        <FormattedMessage
          id={content.length ? 'general.add-more' : 'condition-edit.add-response-btn'}
        />
      </Button>
    </DndProvider>
  );
};

export default QuestionContentList;
