// Use when you want to access context in constructor, as
// usual static contextType approach fails there.

import React, { ComponentType } from 'react';

import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';
import { Subtract } from 'types/types';

export interface RootStoreProps {
  rootStore: RootStore;
}

const withRootStoreProp = <P extends RootStoreProps>(WrappedComponent: ComponentType<P>) => {
  const WrappedComponentToReturn = (props: Subtract<P, RootStoreProps>) => (
    <RootStoreContext.Consumer>
      {context => <WrappedComponent {...(props as P)} rootStore={context} />}
    </RootStoreContext.Consumer>
  );
  WrappedComponentToReturn.displayName = 'withRootStoreProp';
  return WrappedComponentToReturn;
};

export default withRootStoreProp;
