import { useFormikContext } from 'formik';
import React from 'react';

import { RowRegularDay } from './RowRegularDay';
import { OpeningHoursRegular } from '../openingHours.types';

interface Props {
  disabled: boolean;
}

export const FormRegularContent = ({ disabled }: Props) => {
  const { values } = useFormikContext<OpeningHoursRegular>();
  return (
    <table>
      <tbody>
        {values.detailedRegularHours.map((day, index) => (
          <RowRegularDay key={index} name={`detailedRegularHours[${index}]`} disabled={disabled} />
        ))}
      </tbody>
    </table>
  );
};
