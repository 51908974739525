import { notification } from 'antd';
import { observable, action, computed, runInAction, IObservableArray } from 'mobx';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import { Phrase, getPhrases, deletePhrase, updatePhrase, createPhrase } from '../api/phrasesApi';

export default class PhrasesStore {
  phrases: IObservableArray<Phrase> = observable.array([]);
  @observable.shallow activePhrase?: Phrase;
  @observable isLoading = false;

  constructor(
    private rootStore: RootStore,
    private careProviderId: string,
    private careUnitId: string
  ) {}

  @action
  fetchPhrases = async () => {
    try {
      this.isLoading = true;
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await getPhrases(partnerId, this.careProviderId, this.careUnitId);

      runInAction(() => {
        this.phrases.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @computed
  get categories() {
    return this.phrases
      .reduce((accumulator: string[], current: Phrase) => {
        if (current.category && !accumulator.includes(current.category)) {
          accumulator.push(current.category);
        }

        return accumulator;
      }, [])
      .sort((a, b) => sortWithLocale(a, b));
  }

  @action
  handleAdd = () => {
    this.activePhrase = {
      id: '',
      careUnitId: this.careUnitId,
      category: '',
      header: '',
      text: '',
    };
  };

  @action
  handleEdit = async (phrase: Phrase) => {
    this.activePhrase = phrase;
  };

  @action
  handleDelete = async (phrase: Phrase, successMessage: string, errorMessage: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      await deletePhrase(phrase.id, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.phrases.replace(this.phrases.filter(({ id }) => id !== phrase.id));
      });
      notification.success({
        placement: 'top',
        message: successMessage,
      });
    } catch {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: errorMessage,
      });
    }
  };

  @action
  handleCancel = () => {
    this.activePhrase = undefined;
  };

  @action
  handleUpdate = async (phrase: Phrase, successMessage: string, errorMessage: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await updatePhrase(phrase, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.activePhrase = undefined;
        this.phrases.replace(this.phrases.map(item => (item.id === phrase.id ? data : item)));
      });
      notification.success({ placement: 'top', message: successMessage });
    } catch {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: errorMessage,
      });
    }
  };

  @action
  handleCreate = async (phrase: Phrase, successMessage: string, errorMessage: string) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    try {
      const { data } = await createPhrase(phrase, partnerId, this.careProviderId, this.careUnitId);
      runInAction(() => {
        this.activePhrase = undefined;
        this.phrases.push(data);
      });
      notification.success({ placement: 'top', message: successMessage });
    } catch {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: errorMessage,
      });
    }
  };

  handleSubmit =
    ({
      createdMessage,
      updatedMessage,
      errorMessage,
    }: {
      createdMessage: string;
      updatedMessage: string;
      errorMessage: string;
    }) =>
    (phrase: Phrase) => {
      if (phrase.id) {
        this.handleUpdate(phrase, updatedMessage, errorMessage);
      } else {
        this.handleCreate(phrase, createdMessage, errorMessage);
      }
    };
}
