import {
  DeleteOutlined,
  TeamOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Typography, Row, Col, Button, Form as AntdForm, Space, Spin, notification } from 'antd';
import { FieldArray, FormikProvider } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import differenceWith from 'lodash/differenceWith';
import flow from 'lodash/flow';
import get from 'lodash/get';
import React, { Fragment, useCallback } from 'react';
import { FormattedMessage, WrappedComponentProps, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { Practitioner, ExternalId } from 'api/practitionerApi';
import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { DEFAULT_PHONE_COUNTRY_CODES, IDENTITIES } from 'constants/practitioner';
import RootStoreContext from 'context/RootStoreContext';
import { InputOption } from 'types/types';
import {
  addCountryCodeToPhoneNumber,
  removeNonDigitsFromPhoneNumber,
  removeStartingZeroFromPhoneNumber,
} from 'utils/phoneNumberUtils';
import { useShortDateFormat } from 'utils/useDateFormat';

import styles from './PractitionerData.module.css';
import { useIdentityOptions } from './useIdentityOptions';
import { usePractitionerFormik } from './usePractitionerFormik';
import { usePractitionerValidateExternalId } from './usePractitionerValidateExternalId';
import { checkEmailEquality } from './utils';

const { Title } = Typography;
const { Item: FormItem } = AntdForm;

interface Props extends Omit<WrappedComponentProps, 'intl'> {
  externalIdValidationFunction: (externalId: ExternalId) => Promise<Practitioner | undefined>;
  initialValues?: Practitioner;
  onSubmit: (data: Practitioner) => void;
  isFullyEditable: boolean;
  hasRoleAtUserCareUnit: boolean;
  isLoading: boolean;
  countryCallingCodes: string[];
}

const getExternalIdPlaceholder = (externalIdType: IDENTITIES) => {
  switch (externalIdType) {
    case IDENTITIES.SWEDISH_PERSONAL_IDENTITY_NUMBER:
      return 'general.swedish-bank-id-pattern';
    case IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER:
      return 'general.norwegian-bank-id-pattern';
    case IDENTITIES.DANISH_PERSONAL_IDENTITY_NUMBER:
      return 'general.danish-bank-id-pattern';
    case IDENTITIES.EMAIL:
      return 'general.email-as-personal-id-pattern';
    case IDENTITIES.INTERNAL_IDP:
      return 'general.email-as-personal-id-pattern';
    default:
      return 'general.hsaid-pattern';
  }
};

const PractitionerData = (props: Props) => {
  const { id: userId } = useParams<{ id: string }>();
  const intl = useIntl();

  const location = useLocation();
  const isEditMode = !!props.initialValues?.id;
  const { practitionerStore } = React.useContext(RootStoreContext);

  const handleSubmit = (data: Practitioner & { countryCode: string }) => {
    const { countryCode } = data;
    const dataToSubmit = {
      ...data,
      externalIds: data.externalIds.map(({ externalId, ...others }) => ({
        ...others,
        externalId: externalId.trim(),
      })),
      countryCode: undefined,
    };

    if (dataToSubmit.mobileNumber) {
      dataToSubmit.mobileNumber = flow([
        removeNonDigitsFromPhoneNumber,
        removeStartingZeroFromPhoneNumber,
        mobileNumber =>
          mobileNumber ? addCountryCodeToPhoneNumber(mobileNumber, countryCode) : null,
      ])(dataToSubmit.mobileNumber);
    }
    props.onSubmit(dataToSubmit);
  };

  const [validateExternalId, nowValidating] = usePractitionerValidateExternalId(
    location.pathname,
    props?.initialValues?.id
  );

  const getPhoneNumber = (number: string | null | undefined) => {
    if (!number) {
      return { phoneNumber: '', countryCode: '+46' };
    }

    const countryCode = props.countryCallingCodes?.find(countryCode =>
      number.startsWith(countryCode)
    );
    const phoneNumber = countryCode ? number.slice(countryCode.length) : number;

    return {
      phoneNumber,
      countryCode: countryCode || DEFAULT_PHONE_COUNTRY_CODES[props.initialValues!.externalIdType],
    };
  };

  const identityOptions = useIdentityOptions();

  const { phoneNumber: mobileNumber, countryCode } = getPhoneNumber(
    props.initialValues?.mobileNumber
  );

  const initialValues = {
    id: '',
    givenName: '',
    middleAndSurname: '',
    title: '',
    email: '',
    externalIds: [{ externalIdType: IDENTITIES.HSA_ID, externalId: '' }],
    ...props.initialValues,
    mobileNumber,
    countryCode,
  } as Practitioner & { countryCode: string };

  const formik = usePractitionerFormik({ initialValues, onSubmit: handleSubmit });
  const { format } = useShortDateFormat();

  const breadcrumbs: AppBreadcrumbItem[] = [
    {
      text: <FormattedMessage id="main-navigation.practitioners" />,
      link: '/roles',
      icon: <TeamOutlined />,
    },
    {
      text: props.initialValues?.id ? (
        <FormattedMessage id="roles.edit.header" />
      ) : (
        <FormattedMessage id="roles.add.header" />
      ),
    },
  ];

  const titleId = !props.initialValues?.id ? 'roles.add.header' : 'roles.edit.header';

  const { hasRoleAtUserCareUnit, isFullyEditable, isLoading, countryCallingCodes } = props;

  const takenIdentityOptionsValues = formik.values.externalIds.map(
    ({ externalIdType }) => externalIdType
  );

  const handlePasswordReset = useCallback(async () => {
    try {
      if (!userId) {
        return;
      }
      await practitionerStore.resetPassword(userId);
      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'account.email-sent-info' }),
      });
    } catch (error) {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: intl.formatMessage({ id: 'general.error' }),
      });
    }
  }, [notification, intl, practitionerStore, userId]);

  const handleInvitationResend = async () => {
    try {
      if (!userId) {
        return;
      }
      await practitionerStore.resendInvite(userId);
      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'account.email-sent-info' }),
      });
    } catch (error) {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: intl.formatMessage({ id: 'general.error' }),
      });
    }
  };

  const getLink = () => {
    const isInternalIDP = props?.initialValues?.externalIdType === IDENTITIES.INTERNAL_IDP;
    if (!userId || !isInternalIDP) {
      return;
    }
    return props.initialValues?.activated ? (
      <Button
        data-testid="password-reset"
        className={styles.textDecorate}
        type="link"
        disabled={isLoading}
        onClick={handlePasswordReset}
      >
        <FormattedMessage id="account.reset-password" />
      </Button>
    ) : (
      <Button
        data-testid="send-invite"
        className={styles.textDecorate}
        type="link"
        disabled={isLoading}
        onClick={handleInvitationResend}
      >
        <FormattedMessage id="account.resend-invitation" />
      </Button>
    );
  };

  const getAccountCreationInfo = () => {
    if (!props?.initialValues?.createdAt) {
      return;
    }
    return (
      <span data-testid="account-info">
        <FormattedMessage id="account.account-created" />
        &nbsp;
        {props?.initialValues?.createdAt && format(new Date(props?.initialValues?.createdAt))}
        ,&nbsp;
        {props.initialValues?.activated ? (
          <FormattedMessage id="account.activated" />
        ) : (
          <FormattedMessage id="account.not-activated" />
        )}
      </span>
    );
  };

  const availableIdentityOptions = identityOptions.filter(
    ({ value }) => !takenIdentityOptionsValues.includes(value as IDENTITIES)
  );

  return (
    <Fragment>
      <PageHeader content={titleId} breadcrumbs={breadcrumbs} />
      <Title level={3}>
        <FormattedMessage id="practitioner-data-form.header" />
      </Title>
      <FormikProvider value={formik}>
        <Form layout="vertical" data-testid="practitioner-form">
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <FieldArray
              name="externalIds"
              render={arrayHelpers => (
                <Fragment>
                  {formik.values.externalIds.map((externalId, index) => {
                    const identitySelectOptions = differenceWith(
                      identityOptions,
                      takenIdentityOptionsValues.filter(
                        value => value !== externalId.externalIdType
                      ),
                      (value1: InputOption, value2: IDENTITIES) => value1.value === value2
                    );
                    const touched = get(formik.touched, `externalIds[${index}].externalId`);
                    const externalIdError =
                      touched && get(formik.errors, `externalIds[${index}].externalId`);

                    return (
                      <FormItem
                        key={index}
                        label={
                          index === 0 && (
                            <FormattedMessage id="practitioner-data-form.practitioner-id-type-label" />
                          )
                        }
                        required
                        noStyle
                      >
                        <Row gutter={16}>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item
                              extra={
                                index === 0 && formik.values.externalIds.length > 1 ? (
                                  <FormattedMessage id="practitioner-data-form.primary-external-id-info" />
                                ) : undefined
                              }
                              name={`externalIds.${index}.externalIdType`}
                            >
                              <Select
                                name={`externalIds.${index}.externalIdType`}
                                data-testid="practitioner-externalIdType-field"
                                disabled={isLoading}
                                options={identitySelectOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item
                              name={`externalIds.${index}.externalId`}
                              // This has to be set manually, because formik-antd does not
                              // handle FieldArray validation errors properly
                              validateStatus={externalIdError ? 'error' : undefined}
                              help={externalIdError}
                            >
                              <Input
                                placeholder={intl.formatMessage({
                                  id: getExternalIdPlaceholder(externalId.externalIdType),
                                })}
                                data-testid="practitioner-externalId-field"
                                name={`externalIds.${index}.externalId`}
                                // Unfortunately asynchronous validatation of field using other field value
                                // simply cannot be done using regular Yup validation scheme
                                validate={validateExternalId(externalId.externalIdType)}
                                suffix={
                                  nowValidating.includes(externalId.externalIdType) ? (
                                    <Spin
                                      size="small"
                                      indicator={
                                        <LoadingOutlined
                                          spin
                                          data-id-type={externalId.externalIdType}
                                          data-testid="field-is-validating-now"
                                        />
                                      }
                                    />
                                  ) : touched ? (
                                    externalIdError ? (
                                      <CloseCircleOutlined
                                        style={{ color: 'red' }}
                                        data-id-type={externalId.externalIdType}
                                        data-testid="field-is-invalid"
                                      />
                                    ) : (
                                      <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                        data-id-type={externalId.externalIdType}
                                        data-testid="field-is-valid"
                                      />
                                    )
                                  ) : (
                                    <span />
                                  )
                                }
                                disabled={
                                  isLoading ||
                                  (isEditMode &&
                                    externalId.externalIdType === IDENTITIES.INTERNAL_IDP)
                                }
                              />
                            </Form.Item>
                            {index === formik.values.externalIds.length - 1 &&
                              availableIdentityOptions.length > 0 &&
                              formik.values.externalIds.some(
                                item => item.externalIdType !== IDENTITIES.INTERNAL_IDP
                              ) && (
                                <div className={styles.addButtonContainer}>
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        externalIdType: availableIdentityOptions[0].value,
                                        externalId: '',
                                      });
                                    }}
                                    className={styles.addButton}
                                    disabled={isLoading}
                                  >
                                    + <FormattedMessage id="general.add-more" />
                                  </Button>
                                </div>
                              )}
                          </Col>
                          {index > 0 && (
                            <Col span={1}>
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                className={styles.deleteButton}
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={isLoading}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row gutter={16} className={styles.accountActivationInfo}>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            {getAccountCreationInfo()}
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            {getLink()}
                          </Col>
                        </Row>
                      </FormItem>
                    );
                  })}
                </Fragment>
              )}
            />
          )}
          <Row gutter={16}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
              <Form.Item
                label={
                  <FormattedMessage id="practitioner-data-form.practitioner-first-name-label" />
                }
                name="givenName"
                required={isFullyEditable}
                hasFeedback
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-first-name-placeholder',
                  })}
                  name="givenName"
                  data-testid="givenName"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
              <Form.Item
                label={
                  <FormattedMessage id="practitioner-data-form.practitioner-last-name-label" />
                }
                name="middleAndSurname"
                required={isFullyEditable}
                hasFeedback
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-last-name-placeholder',
                  })}
                  name="middleAndSurname"
                  data-testid="middleAndSurname"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<FormattedMessage id="practitioner-data-form.practitioner-title-label" />}
                name="title"
                required={isFullyEditable}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-title-placeholder',
                  })}
                  name="title"
                  data-testid="practitioner-title"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  label={<FormattedMessage id="practitioner-data-form.practitioner-phone-label" />}
                  name="mobileNumber"
                >
                  <Space.Compact>
                    <Select
                      name="countryCode"
                      className={styles.countryCode}
                      options={countryCallingCodes?.map(value => ({
                        label: value,
                        value,
                      }))}
                      disabled={!isFullyEditable || isLoading}
                    />
                    <div className={styles.phoneNumber}>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'practitioner-data-form.practitioner-phone-placeholder',
                        })}
                        name="mobileNumber"
                        disabled={!isFullyEditable || isLoading}
                      />
                    </div>
                  </Space.Compact>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  label={<FormattedMessage id="practitioner-data-form.practitioner-email-label" />}
                  name="email"
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'practitioner-data-form.practitioner-email-placeholder',
                    })}
                    validate={checkEmailEquality(
                      formik.values,
                      intl.formatMessage({ id: 'general.email-validation-error' }),
                      isEditMode
                    )}
                    data-testid="practitioner-email-field"
                    name="email"
                    disabled={
                      !isFullyEditable ||
                      isLoading ||
                      //fully editable and provider is internalIDP disables email field.
                      (formik.values.externalIdType === IDENTITIES.INTERNAL_IDP && isEditMode)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <Row gutter={16}>
              <Col span={12} className={styles.submitButtonContainer}>
                <Button
                  shape="round"
                  type="default"
                  disabled={isLoading || !formik.dirty}
                  htmlType="reset"
                >
                  <FormattedMessage id="general.cancel" />
                </Button>
                <Button
                  data-testid="practitioner-submit"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  className={styles.submitButton}
                  disabled={isLoading || !formik.isValid}
                  loading={nowValidating.length > 0}
                  // it's complicated
                  // when you click on a button, first thing which is happening
                  // is "blur" event on the input field. This is causing validation
                  // the problem that "onClick" handler is actually called only
                  // when you do mouse up. So, click is registered in about 100ms after actual click
                  // (See, physically you have only mouseDown and mouseUp events. Click event is determine when mouseUp happens
                  // so browser can determine if it was a click or double click or miss click)
                  // in most cases within this time there is an async validation happening in the background
                  // which disables button in a moment when actual "click" is fired
                  // to setting "onMouseDown" event to prevent default action
                  // we skip "blur" event and validation is not happening in this moment
                  // so button is still enabled and click is registered
                  onMouseDown={e => e.preventDefault()}
                >
                  <FormattedMessage id="general.save" />
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </FormikProvider>
    </Fragment>
  );
};

export default PractitionerData;
