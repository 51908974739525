import { Tag } from 'antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { PARTNER_STATUS, MERGE_REQUEST_STATUS } from 'modules/PartnerStatus/api/partnerStatusApi';

import styles from './PartnerStatusTag.module.css';

export const PARTNER_STATUS_TAG_MAP: Record<string, { color: string; translationId: string }> = {
  [PARTNER_STATUS.CHANGES]: {
    color: 'gold',
    translationId: 'partner-status.tag.changes',
  },
  [PARTNER_STATUS.PENDING]: {
    color: 'red',
    translationId: 'partner-status.tag.pending',
  },
  [PARTNER_STATUS.UP_TO_DATE]: {
    color: 'green',
    translationId: 'partner-status.tag.deployed',
  },
  [MERGE_REQUEST_STATUS.MERGED]: {
    color: 'green',
    translationId: 'partner-status.tag.deployed',
  },
  [MERGE_REQUEST_STATUS.DEPLOYED]: {
    color: 'green',
    translationId: 'partner-status.tag.deployed',
  },
  [MERGE_REQUEST_STATUS.PENDING_DEPLOYMENT]: {
    color: 'red',
    translationId: 'partner-status.tag.pending',
  },
  [MERGE_REQUEST_STATUS.PENDING]: {
    color: 'red',
    translationId: 'partner-status.tag.pending',
  },
  [MERGE_REQUEST_STATUS.ERROR]: {
    color: 'red',
    translationId: 'partner-status.tag.error',
  },
  [MERGE_REQUEST_STATUS.UNKNOWN]: {
    color: 'red',
    translationId: 'general.unknown',
  },
};

interface Props {
  status: PARTNER_STATUS | MERGE_REQUEST_STATUS;
}

const PartnerStatusTag: FunctionComponent<Props> = ({ status }) => {
  const isKnownStatus = !!PARTNER_STATUS_TAG_MAP[status];
  return (
    <Tag color={isKnownStatus ? PARTNER_STATUS_TAG_MAP[status].color : ''} className={styles.tag}>
      {isKnownStatus ? (
        <FormattedMessage id={PARTNER_STATUS_TAG_MAP[status].translationId} />
      ) : (
        status
      )}
    </Tag>
  );
};

export default PartnerStatusTag;
