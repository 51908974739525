import { Breadcrumb } from 'antd';
import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.css';

// antd already exports a type BreadcrumbItem
export interface AppBreadcrumbItem {
  link?: string;
  text: ReactNode;
  icon?: JSX.Element;
}

interface Props {
  data: AppBreadcrumbItem[];
}

const Breadcrumbs: FunctionComponent<Props> = ({ data }) => (
  <Breadcrumb
    items={data.map(({ link, text, icon }, index) => {
      const content = <span>{text}</span>;
      return {
        key: index,
        title: (
          <>
            {icon && <icon.type {...icon.props} className={styles.breadcrumbIcon} />}
            {link ? <Link to={link}>{content}</Link> : content}
          </>
        ),
      };
    })}
  />
);

export default Breadcrumbs;
