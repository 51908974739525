import { useMemo } from 'react';

import { useLang } from 'context/IntlContext';

/**
 * Recommended way to format a date. Example:
 *
 * @example
 * ```tsx
 * const date = new Date();
 * const formattedDate = useDateFormat().format(date);
 *
 * return <div>{formattedDate}</div>;
 * ```
 */
export const useDateFormat = (opts?: Intl.DateTimeFormatOptions) => {
  const { lang } = useLang();
  const locale = `${lang}-SE`;

  return useMemo(() => {
    return new Intl.DateTimeFormat(locale, opts);
  }, [locale, opts]);
};

const LONG: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const DATE_ONLY_SHORT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

/**
 * Date time
 * Nov 11, 2021, 17:23
 */
export const useLongDateTimeFormat = () => useDateFormat(LONG);

/**
 * Date only (short)
 * 2021-11-11
 */
export const useShortDateFormat = () => useDateFormat(DATE_ONLY_SHORT);
