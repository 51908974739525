import { NotificationInstance } from 'antd/es/notification/interface';
import { FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { IntlShape } from 'react-intl';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import {
  validateStatementContentAndReturnErrors,
  ExpressionErrors,
} from 'modules/Content24/Condition/utils/validationUtils';

import { QuestionFormData } from '../QuestionForm';

/**
 * @notExported
 */
interface handleErrorsProps {
  formData: QuestionFormData;
  formikActions: FormikHelpers<QuestionFormData>;
  intl: IntlShape;
  notification: NotificationInstance;
}

// async validation of question content
export const handleErrors = async ({
  formData,
  formikActions,
  intl,
  notification,
}: handleErrorsProps) => {
  const errors: ExpressionErrors = formData.content
    ? await validateStatementContentAndReturnErrors(formData.content)
    : {};

  const hasErrors = !isEmpty(errors);
  if (hasErrors) {
    Object.keys(errors).forEach(key => {
      const { translationKey, characters } = errors[key];
      formikActions.setFieldError(key, intl.formatMessage({ id: translationKey }, { characters }));
    });
    formikActions.setSubmitting(false);

    notification.error({
      placement: 'top',
      duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
      message: intl.formatMessage({ id: 'general.errors.errors-in-form' }),
    });
  }
  return { hasErrors };
};
