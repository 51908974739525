import { Switch, Typography } from 'antd';
import { useField } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './OpeningHours.module.css';

interface Props {
  name: string;
  disabled?: boolean;
}

export const Toggle = ({ name, disabled }: Props) => {
  const [field, , helpers] = useField(`${name}.closedAllDay`);
  const [, , { setTouched: setTouchedCloseTime }] = useField(`${name}.closedAllDay`);

  return (
    <>
      {!disabled && (
        <Switch
          className={style.switch}
          value={!field.value}
          onChange={async isOpen => {
            await helpers.setValue(!isOpen);
            await setTouchedCloseTime(false);
          }}
        />
      )}
      <Typography.Text type={disabled ? 'secondary' : undefined} className={style.openClosedTitle}>
        {field.value ? (
          <FormattedMessage id="opening-hours.closed" />
        ) : (
          <FormattedMessage id="opening-hours.open" />
        )}
      </Typography.Text>
    </>
  );
};
