import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PractitionersList from 'modules/PractitionersList';
import Users from 'modules/Users/index';
import { getValueOfFeatureFlag } from 'utils/appUtils';

import RolesEdit from './components/RolesEdit';

const Roles = () => {
  const FF_ENABLE_NEW_ROLES_UI_DESIGN = getValueOfFeatureFlag('FF_ENABLE_NEW_ROLES_UI_DESIGN');
  return (
    <Switch>
      <Route
        exact
        path="/roles"
        component={FF_ENABLE_NEW_ROLES_UI_DESIGN ? Users : PractitionersList}
      />
      <Route path="/roles/add" component={RolesEdit} />
      <Route path="/roles/:id/edit" component={RolesEdit} />
    </Switch>
  );
};

export default Roles;
