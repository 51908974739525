import axios from 'axios';

import { PARTNER_ROLES } from 'constants/roles';
import { TranslatedText } from 'types/types';

import { CareUnitBase } from './careUnitsApi';
import { Hostname } from './originsApi';

export interface PartnerUserRoleDTO {
  practitionerId: string;
  practitionerGivenName?: string;
  practitionerMiddleAndSurname?: string;
  role: PARTNER_ROLES;
}

export interface PartnerUserRole {
  practitionerId: string;
  practitionerName: string;
  role: PARTNER_ROLES;
}

export interface ChildOrigin {
  id: string;
  archived: boolean;
  name?: string;
  origins?: ChildOrigin[];
  parentId?: string;
  hostnames: Hostname[];
}

export interface PartnerCareProvider {
  id: string;
  name: string;
  careUnits?: CareUnitBase[];
}

export interface Partner {
  id: string;
  origins: ChildOrigin[];
  careProviders?: PartnerCareProvider[];
  role: PARTNER_ROLES;
}

export interface PartnerImage {
  fileName: string;
  source: string;
  metadata: {
    title: TranslatedText;
    description: TranslatedText;
  };
}

export const PARTNERS_URI = '/rest/admin/partners';

export const fetchPartners = () => axios.get<Partner[]>(PARTNERS_URI);

export const fetchPartnerUsers = (originId: string) =>
  axios.get<PartnerUserRoleDTO[]>(`${PARTNERS_URI}/${originId}/roles`);

export const updatePartnerUserRole = (data: PartnerUserRoleDTO, originId: string) =>
  axios.post(`${PARTNERS_URI}/${originId}/roles`, data);

export const deletePartnerUserRole = (practitionerId: string, originId: string) =>
  axios.delete(`${PARTNERS_URI}/${originId}/roles/${practitionerId}`);

export const fetchPartnerImages = (partnerId: string) =>
  axios.get<PartnerImage[]>(`${PARTNERS_URI}/${partnerId}/medicalImages`);

export const fetchPartnerImageData = (partnerId: string, imageId: string) =>
  axios.get<Blob>(`${PARTNERS_URI}/${partnerId}/medicalImages/${imageId}/data`, {
    responseType: 'blob',
  });
