import { Text } from '@vx/text';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { ShapeProps } from './shapeModel';
import styles from './shapes.module.css';

/**
 * @notExported
 */
interface RectangleProps extends ShapeProps {
  type?: 'question' | 'answerOption';
  icon?: ReactNode;
  iconSize?: number;
}

const Rectangle: FunctionComponent<RectangleProps> = ({
  width = 100,
  height = 30,
  type = 'question',
  title,
  text,
  onClick,
  isDimmed,
  isDependencyOfSelectedNode,
  isNotCondition,
  isYesCondition,
  className,
  min,
  max,
  icon,
  iconSize = 25,
  ...restProps
}) => {
  const padding = 2;
  const totalWidth = width + padding;
  const totalHeight = height + padding;
  const textPadding = 15;
  const opacity = isDimmed ? 0.2 : 1;
  const isHighlightedNotConditionResponse =
    isNotCondition && isDependencyOfSelectedNode && type === 'answerOption';
  const isHighlightedYesConditionResponse =
    isYesCondition && isDependencyOfSelectedNode && type === 'answerOption';

  return (
    <svg
      width={totalWidth}
      height={totalHeight}
      onClick={onClick}
      className={className}
      {...restProps}
    >
      <rect
        className={classNames(styles[type], {
          [styles.notCondition]: isHighlightedNotConditionResponse,
          [styles.notAndYesCondition]:
            isHighlightedYesConditionResponse && isHighlightedNotConditionResponse,
        })}
        x={0 + padding / 2}
        y={0 + padding / 2}
        width={width}
        height={height}
        strokeWidth={1}
        rx={5}
        ry={5}
        opacity={opacity}
      />
      {title && (
        <Text
          width={width - textPadding * 2}
          x={width / 2}
          y={15}
          verticalAnchor="start"
          textAnchor="middle"
          opacity={opacity}
          className={styles.title}
        >
          {title}
        </Text>
      )}
      {text && (
        <Text
          width={width - textPadding * 2}
          x={textPadding}
          y={40}
          verticalAnchor="start"
          opacity={opacity}
          className={styles.text}
          lineHeight={18}
        >
          {text + (min || max ? ` (Min: ${min} / Max: ${max})` : '')}
        </Text>
      )}
      {icon && (
        <foreignObject
          x={width - iconSize - padding * 2}
          y={padding * 2}
          height={iconSize}
          width={iconSize}
        >
          {icon}
        </foreignObject>
      )}
    </svg>
  );
};

export default Rectangle;
