import { CareUnit } from 'api/careUnitsApi';

export const getPartnerIdByCareUnitIdGivenAllCareUnits = (
  allCareUnits: CareUnit[],
  careUnitId: string
) => allCareUnits.find(careUnit => careUnit.id === careUnitId)?.careProvider?.partner?.id;

export const displayCareUnitWithProviderName = (careUnit: CareUnit) => {
  if (careUnit?.careProvider?.name) {
    return `${careUnit.name}, ${careUnit.careProvider.name}`;
  }
  return careUnit.name;
};
