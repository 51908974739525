import { toJS } from 'mobx';
import { IntlShape } from 'react-intl';

import { InputOption } from 'types/types';

import { NodeDependency } from './stores/ConditionVisualizationStore';

export function getLinkId(sourceNodeId: string, targetNodeId: string) {
  return `${sourceNodeId}-linksTo-${targetNodeId}`;
}

export function getResponseNodeId(questionId: string, responseId: string) {
  return `questionId-${questionId}-responseId-${responseId}`;
}

export function getText(text: string | InputOption | InputOption[], intl: IntlShape) {
  if (!text) {
    return '';
  }

  if (typeof text === 'string') {
    return text;
  }

  const rawTextObj = toJS(text);

  if (Array.isArray(rawTextObj)) {
    if (rawTextObj.length < 1) {
      return '';
    }

    return rawTextObj
      .map(({ label, value }) => {
        return `${intl.formatMessage({
          id: `condition-edit.${label}-label`,
        })}: ${value}`;
      })
      .join(' | ');
  }

  return `${intl.formatMessage({ id: `condition-edit.${rawTextObj.label}-label` })}: ${
    rawTextObj.value
  }`;
}

export function getNodePositionForAxis(value: number) {
  return -value / 2;
}

export const parseExitDependencies = (dependencies: NodeDependency[] = []) =>
  dependencies.reduce(
    (acc, curr) => (acc.some(el => el.questionId === curr.questionId) ? acc : [...acc, curr]),
    [] as NodeDependency[]
  );
