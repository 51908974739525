import { Button, Divider } from 'antd';
import { noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LANGS } from 'constants/enums';
import { CURRENT_ENV_LANGUAGE } from 'constants/env';
import { LOGIN_METHODS } from 'constants/login';
import RootStoreContext from 'context/RootStoreContext';

import { ListWithSeparator } from '../../../../components/ListWithSeparator/ListWithSeparator';
import HsaIdLogin from '../HsaIdLogin';
import { LoginPageHeader } from '../NewLoginDesign/LoginPageParts';
import NorwegianLoginMethods from '../NorwegianLoginMethods';
import SwedishSecureLogin from '../SwedishSecureBankIdLogin/SwedishSecureBankIdLogin';

const useSelectLoginMethod = () => {
  const { authStore } = useContext(RootStoreContext);
  return (loginMethod: LOGIN_METHODS) => {
    authStore.initiateSSOLogin(loginMethod);
  };
};

const OpenIdLoginButton = () => {
  const selectLoginMethod = useSelectLoginMethod();
  return (
    <Button
      type="primary"
      block
      size="large"
      data-login-method="openid-connect"
      data-testid="external-login-openid"
      onClick={() => {
        selectLoginMethod(LOGIN_METHODS.OPENIDCONNECT);
      }}
    >
      <FormattedMessage id="login.login" />
    </Button>
  );
};

const SamlLoginButton = () => {
  const selectLoginMethod = useSelectLoginMethod();
  return (
    <Button
      type="primary"
      block
      size="large"
      data-login-method="saml"
      data-testid="external-login-openid"
      onClick={() => {
        selectLoginMethod(LOGIN_METHODS.SAML);
      }}
    >
      <FormattedMessage id="login.login" />
    </Button>
  );
};

const LOGIN_METHOD_BUTTONS = {
  [LOGIN_METHODS.OPENIDCONNECT]: OpenIdLoginButton,
  [LOGIN_METHODS.SAML]: SamlLoginButton,
  [LOGIN_METHODS.HSA_ID]: HsaIdLogin,
  [LOGIN_METHODS.SWEDISH_BANK_ID]: SwedishSecureLogin,
  [LOGIN_METHODS.NORWEGIAN_BANK_ID]: NorwegianLoginMethods,
};

export const LoginMethodsFactory = (envLanguage: typeof CURRENT_ENV_LANGUAGE) =>
  function LoginMethods() {
    const { authStore } = useContext(RootStoreContext);
    const [loading, setLoading] = useState(true);
    const [loginMethods, setLoginMethods] = useState<{ type: LOGIN_METHODS }[]>([]);

    const [activeLoginMethod, setActiveLoginMethod] = useState<LOGIN_METHODS | null>(null);

    useEffect(() => {
      const controller = new AbortController();
      (async () => {
        const loginMethods = await authStore.getLoginMethods(controller.signal);
        if (loginMethods?.length > 0) {
          setLoginMethods(loginMethods);
        } else {
          if (envLanguage === LANGS.SV) {
            setLoginMethods([
              { type: LOGIN_METHODS.HSA_ID },
              { type: LOGIN_METHODS.SWEDISH_BANK_ID },
            ]);
          }

          if (envLanguage === LANGS.NO) {
            setLoginMethods([{ type: LOGIN_METHODS.NORWEGIAN_BANK_ID }]);
          }
        }
        setLoading(false);
      })();

      return () => {
        controller.abort();
      };
    }, []);

    if (loading) return null;

    if (activeLoginMethod) {
      const LoginButton = LOGIN_METHOD_BUTTONS[activeLoginMethod];
      return <LoginButton onBack={() => setActiveLoginMethod(null)} isActive onClick={noop} />;
    }

    return (
      <>
        <LoginPageHeader
          subtitle={
            loginMethods.length > 0 && <FormattedMessage id="login.which-method-you-want-to-use" />
          }
        />
        <ListWithSeparator
          Separator={() => (
            <Divider>
              <FormattedMessage id="general.or" />
            </Divider>
          )}
        >
          {loginMethods.map(({ type }) => {
            const LoginButton = LOGIN_METHOD_BUTTONS[type];
            return (
              <LoginButton
                isActive={activeLoginMethod === type}
                onClick={() => {
                  setActiveLoginMethod(type);
                }}
                onBack={() => {
                  setActiveLoginMethod(null);
                }}
                key={type}
              />
            );
          })}
        </ListWithSeparator>
      </>
    );
  };

export default LoginMethodsFactory(CURRENT_ENV_LANGUAGE);
