import { Divider, Row, Col } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Select } from 'formik-antd';
import flatMap from 'lodash/flatMap';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import { NO_LEADING_TRAILING_SPACE_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import {
  CODE24_CATEGORIES,
  CODE24_MODEL_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import { Goto } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
  validateStatementBuildTimeIfWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';
import { filterNotDisabledConditionsWithoutCategoryGroup } from 'modules/Content24/utils';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface GotoFormProps {
  onCancel: () => void;
  onSubmit: (data: Goto) => void;
  activeLanguage: LANGS;
  data?: Goto;
  isDisabled?: boolean;
}

const GotoForm: FunctionComponent<GotoFormProps> = ({
  data,
  onCancel,
  onSubmit,
  isDisabled,
  activeLanguage,
}) => {
  const intl = useIntl();
  const { conditionStore, conditionsListStore, content24Store } = useContext(RootStoreContext);
  const defaultValues: Goto = {
    id: '',
    type: CODE24_MODEL_TYPES.GOTO,
    condition: '',
    target: '',
    buildTimeIf: '',
    properties: [],
  };
  const initialValues = getInitialFormValues<Goto, Goto>(defaultValues, data);

  const validationSchema = Yup.object().shape({
    condition: Yup.string()
      .required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      )
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      ),
    buildTimeIf: Yup.string()
      .test(
        'noLeadingTrailingSpace',
        intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
        function (this: Yup.TestContext, value: string) {
          return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
        }
      )
      .test(
        'isValidBuildTimeIf',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementBuildTimeIfWithDebounce
      )
      .nullable(),
    target: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
    properties: Yup.array().of(Yup.string()).nullable(),
  });

  const handleSubmit = useCallback(
    (dataToSubmit: Goto) => {
      onSubmit(getDataFromFinalFormValues<Goto>(dataToSubmit));
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Observer>
          {() => {
            const isFormDisabled = conditionStore.isLoading() || isDisabled;
            const targets = filterNotDisabledConditionsWithoutCategoryGroup(
              conditionsListStore.conditions,
              flatMap(
                [
                  CODE24_CATEGORIES.LIBRARIES,
                  CODE24_CATEGORIES.ASSESSMENT,
                  CODE24_CATEGORIES.DIAGNOSIS,
                ],
                category => content24Store.categoryGroups.get(category) || []
              )
            )
              .map(condition => ({
                label: condition.description[activeLanguage] || condition.conditionId,
                value: condition.conditionId,
              }))
              .sort((a, b) => sortWithLocale(a, b, 'label', activeLanguage));
            return (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="condition"
                      required
                      hasFeedback
                      label={<FormattedMessage id="condition-edit.if-label" />}
                    >
                      <Input.TextArea
                        name="condition"
                        disabled={isFormDisabled}
                        rows={1}
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="target"
                      required
                      label={<FormattedMessage id="condition-edit.goto-label" />}
                    >
                      <Select
                        name="target"
                        disabled={isFormDisabled}
                        showSearch
                        optionFilterProp="label"
                        options={targets}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="buildTimeIf"
                      label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                    >
                      <Input name="buildTimeIf" disabled={isFormDisabled} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="properties"
                      label={<FormattedMessage id="condition-edit.properties-label" />}
                    >
                      <Select
                        disabled={isFormDisabled}
                        name="properties"
                        mode="tags"
                        tokenSeparators={[',', ' ']}
                        notFoundContent={null}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <FormActionButtons
                  isSaving={conditionStore.isLoading()}
                  isDisabled={isDisabled}
                  isValid={isValid && dirty}
                  onCancel={onCancel}
                  showCancelConfirm={dirty}
                  cancelDeclineText={
                    <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                  }
                />
              </Form>
            );
          }}
        </Observer>
      )}
    </Formik>
  );
};

export default GotoForm;
