import { notification } from 'antd';
import { observable, action, runInAction, IObservableArray } from 'mobx';
import { IntlShape } from 'react-intl';

import { fetchOriginCustomization } from 'api/customizationsApi';
import { fetchOrigin, updateOrigin, createOrigin, Origin, OriginSave } from 'api/originsApi';
import { LANGS } from 'constants/enums';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RulesStore from 'modules/Origins/Rules/stores/RulesStore';
import {
  getShutdownInfo,
  shutdownRequest,
  ShutdownDTO,
} from 'modules/Origins/Shutdown/api/shutdownApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export interface CurrentOrigin extends Origin {
  primaryAlias?: string;
}

export default class OriginStore extends StateManager {
  rulesStore: RulesStore;
  constructor(public rootStore: RootStore) {
    super();
    // This has to exist under OriginStore to be available for origin-level components
    // such as OriginsSideMenu.
    this.rulesStore = new RulesStore(rootStore);
  }

  @observable.shallow currentOrigin?: CurrentOrigin;
  availableOriginLanguages: IObservableArray<LANGS> = observable.array([]);

  @observable.shallow shutdownInfo?: ShutdownDTO;

  updateCurrentOrigin = async (originId: string, origin: OriginSave) => {
    const { partnersStore } = this.rootStore;

    this.setSaving();

    try {
      const { data: updatedOrigin } = await updateOrigin(partnersStore.partnerId, originId, origin);

      // update partners, as origins changed
      await partnersStore.fetchPartners();

      this.setCurrentOriginData(updatedOrigin);
      this.setLoaded();
      // To be refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.request?.status === 400) {
        notification.error({
          message: JSON.parse(error.request.response)?.message,
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          placement: 'top',
        });
      }

      this.manageException(error);
    }
  };

  createOrigin = async (origin: OriginSave, intl: IntlShape) => {
    this.setSaving();

    try {
      const { data: newOrigin } = await createOrigin(
        this.rootStore.partnersStore.partnerId,
        origin
      );

      // Update origin details, as a new child origin appeared
      // You can only create sub origin, so parentOriginId will always be here.

      await this.fetchOrigin(origin.parentOriginId!);

      this.setCurrentOriginData(newOrigin);
      this.setLoaded();

      return newOrigin;
      // To be refactored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.request?.status === 400) {
        notification.error({
          message: intl.formatMessage(
            { id: 'origins.errors.already-in-use' },
            { id: `${origin.parentOriginId}-${origin.id}` }
          ),
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          placement: 'top',
        });
      }

      this.manageException(error);
      return Promise.reject();
    }
  };

  fetchOrigin = async (originId: string) => {
    this.setLoading();

    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      const [originData, originAvailableLanguagesData] = await Promise.all([
        fetchOrigin(partnerId, originId),
        fetchOriginCustomization(partnerId, originId, 'AVAILABLE_LANGUAGES'),
        this.rulesStore.fetchRules(originId),
      ]);

      this.setCurrentOriginData(originData.data);

      runInAction(() => {
        this.availableOriginLanguages.replace(
          originAvailableLanguagesData.data.value || ([] as LANGS[])
        );
      });
      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchShutdownInformation = async () => {
    this.setLoading();
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await getShutdownInfo(partnerId, partnerId);

      runInAction(() => {
        this.shutdownInfo = data;
      });

      this.setLoaded();
    } catch (e) {
      this.setLoaded();
      console.log('fetchShutdownInformation::', e);
    }
  };

  shutdownPatientApp = async (payload: ShutdownDTO, originId: string) => {
    this.setLoading();
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await shutdownRequest(partnerId, originId, payload);

      runInAction(() => {
        this.shutdownInfo = data;
      });

      this.setLoaded();
    } catch (e) {
      this.setLoaded();
      console.log('shutdownPatientApp:', e);
    }
  };

  @action setCurrentOriginData(data: Origin) {
    this.currentOrigin = data;
  }
}
