import { Divider, Row, Col } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Select, Checkbox } from 'formik-antd';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { SearchTerm } from 'modules/Content24/Condition/models/Code24Model';
import { getInitialFormValues } from 'modules/Content24/Condition/utils/forms';

/**
 * @notExported
 */
interface SearchTermFormProps {
  onCancel: () => void;
  onSubmit: (data: SearchTerm) => void;
  activeLanguage: string;
  data?: SearchTerm;
  isDisabled?: boolean;
}

const SearchTermForm: FunctionComponent<SearchTermFormProps> = ({
  data,
  onCancel,
  onSubmit,
  isDisabled,
  activeLanguage,
}) => {
  const intl = useIntl();
  const { conditionStore } = useContext(RootStoreContext);
  const defaultValues: SearchTerm = {
    id: '',
    type: CODE24_MODEL_TYPES.SEARCH_TERM,
    term: { [activeLanguage]: '' },
    properties: [],
    shouldDisplay: false,
  };
  const initialValues = getInitialFormValues<SearchTerm, SearchTerm>(defaultValues, data);

  const validationSchema = Yup.object().shape({
    term: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
    properties: Yup.array().of(Yup.string()),
  });

  const isFormDisabled = conditionStore.isLoading() || isDisabled;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isValid, dirty }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={`term.${activeLanguage}`}
                required
                hasFeedback
                label={<FormattedMessage id="condition-edit.search-term-label" />}
              >
                <Input name={`term.${activeLanguage}`} disabled={isFormDisabled} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="properties"
                label={<FormattedMessage id="condition-edit.properties-label" />}
              >
                <Select
                  disabled={isFormDisabled}
                  name="properties"
                  mode="tags"
                  tokenSeparators={[',', ' ']}
                  notFoundContent={null}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="shouldDisplay" label=" ">
                <Checkbox name="shouldDisplay" disabled={isFormDisabled}>
                  <FormattedMessage id="condition.display" />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <FormActionButtons
            isSaving={conditionStore.isLoading()}
            isDisabled={isDisabled}
            isValid={isValid && dirty}
            onCancel={onCancel}
            showCancelConfirm={dirty}
            cancelDeclineText={<FormattedMessage id="condition-edit.statement-cancel-confirm" />}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SearchTermForm;
