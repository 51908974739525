import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, SortOrder, FilterValue } from 'antd/lib/table/interface';
import format from 'date-fns/format';
import intersection from 'lodash/intersection';
import { action, computed, IObservableArray, observable, runInAction, toJS } from 'mobx';
import { IntlShape } from 'react-intl';

import { DEFAULT_DATE_FORMAT, TIME_FORMAT_CLEAN } from 'constants/dateFormat';
import {
  createStatisticsReport,
  fetchStatistics,
  fetchStatisticsReports,
  Report,
  Statistic,
} from 'modules/Content24/api/reportsApi';
import { EXIT_ATTRIBUTES } from 'modules/Content24/Condition/constants/code24types';
import { REPORT_STATUSES, TRANSLATION_KEYS } from 'modules/Content24/constants/reports';
import { intlNotification } from 'state/notification';
import RootStore from 'stores/RootStore';
import { HasUUID } from 'types/types';
import { downloadAsCSVFile } from 'utils/appUtils';
import { capitalizeFirst, sortWithLocale } from 'utils/textUtils';
import { batchAddUUID, batchRemoveUUID } from 'utils/uuidUtils';

interface StatisticWithId extends HasUUID, Statistic {}

export interface TranslatedStatistic
  extends Omit<
    StatisticWithId,
    EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION | EXIT_ATTRIBUTES.CAPABILITY | 'exitGroup' | 'condition'
  > {
  [EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION]?: string;
  [EXIT_ATTRIBUTES.CAPABILITY]?: string;
}

export interface Filter {
  capabilities: string[];
  resources: string[];
  sources: string[];
  typesOfConsultation: string[];
  appointmentPriorities: number[];
  urgencies: string[];
  levelsOfCare: string[];
}

class StatisticsStore {
  statistics: IObservableArray<StatisticWithId> = observable.array([]);
  reports: IObservableArray<Report> = observable.array([]);
  @observable activeReportId?: string;
  @observable isLoadingReports = false;
  @observable isLoadingStatistics = false;
  @observable filter: Filter = {
    capabilities: [],
    resources: [],
    sources: [],
    typesOfConsultation: [],
    appointmentPriorities: [],
    levelsOfCare: [],
    urgencies: [],
  };
  @observable sorter: SorterResult<TranslatedStatistic> & {
    field: undefined | keyof TranslatedStatistic;
  } = {
    field: undefined,
    order: null,
  };

  constructor(
    private rootStore: RootStore,
    private intl: IntlShape
  ) {}

  dataPresentationOrder = [
    'source',
    'id',
    EXIT_ATTRIBUTES.URGENCY,
    EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY,
    EXIT_ATTRIBUTES.LEVEL_OF_CARE,
    EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION,
    EXIT_ATTRIBUTES.RESOURCE,
    EXIT_ATTRIBUTES.CAPABILITY,
    'count',
  ];

  @computed
  get filteredStatistics() {
    const {
      capabilities,
      resources,
      sources,
      typesOfConsultation,
      appointmentPriorities,
      levelsOfCare,
      urgencies,
    } = toJS(this.filter);

    return this.statistics.filter(item => {
      const resource = item[EXIT_ATTRIBUTES.RESOURCE];
      const typeOfConsultation = item[EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION];
      const capability = item[EXIT_ATTRIBUTES.CAPABILITY];
      const appointmentPriority = item[EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY];
      const urgency = item[EXIT_ATTRIBUTES.URGENCY];
      const levelOfCare = item[EXIT_ATTRIBUTES.LEVEL_OF_CARE];

      if (
        capabilities.length &&
        (!capability || intersection(capabilities, capability).length === 0)
      ) {
        return false;
      }

      if (resources.length && (!resource || !resources.includes(resource))) {
        return false;
      }

      if (sources.length && (!item.source || !sources.includes(item.source))) {
        return false;
      }

      if (
        typesOfConsultation.length &&
        (!typeOfConsultation || intersection(typesOfConsultation, typeOfConsultation).length === 0)
      ) {
        return false;
      }

      if (urgencies.length && (!urgency || !urgencies.includes(urgency))) {
        return false;
      }

      if (levelsOfCare.length && (!levelOfCare || !levelsOfCare.includes(levelOfCare))) {
        return false;
      }

      if (
        appointmentPriorities.length &&
        (!appointmentPriority || !appointmentPriorities.includes(appointmentPriority))
      ) {
        return false;
      }

      return true;
    });
  }

  @computed
  get translatedStatistics(): TranslatedStatistic[] {
    const { conditionsListStore, content24Store, partnersStore } = this.rootStore;
    const availableExitAttributes: EXIT_ATTRIBUTES[] =
      partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_EXIT_ATTRIBUTES') || [];
    const capabilityTranslations = content24Store.exitAttributesTranslations.capability;
    const resourceTranslations = content24Store.exitAttributesTranslations.resource;
    const urgencyTranslations = content24Store.exitAttributesTranslations.urgency;
    const levelOfCareTranslations = content24Store.exitAttributesTranslations.levelOfCare;
    const typeOfConsultationTranslations =
      content24Store.exitAttributesTranslations.typeOfConsultation;
    const { locale } = this.intl;
    const hasUrgency = availableExitAttributes.includes(EXIT_ATTRIBUTES.URGENCY);
    const hasPriority = availableExitAttributes.includes(EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY);
    const hasLevelOfCare = availableExitAttributes.includes(EXIT_ATTRIBUTES.LEVEL_OF_CARE);
    const hasTypeOfConsultation = availableExitAttributes.includes(
      EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION
    );
    const hasResource = availableExitAttributes.includes(EXIT_ATTRIBUTES.RESOURCE);
    const hasCapability = availableExitAttributes.includes(EXIT_ATTRIBUTES.CAPABILITY);

    return this.filteredStatistics.map(
      ({
        uuid,
        id,
        count,
        source,
        capability,
        resource,
        typeOfConsultation,
        appointmentPriority,
        levelOfCare,
        urgency,
      }) => {
        const translatedStatistic: TranslatedStatistic = {
          uuid,
          source: capitalizeFirst(
            conditionsListStore.conditions.find(({ conditionId }) => conditionId === source)
              ?.description[this.intl.locale] || source
          ),
          id,
          count,
        };

        if (hasUrgency) {
          translatedStatistic[EXIT_ATTRIBUTES.URGENCY] = urgency
            ? urgencyTranslations[urgency]?.[locale] || urgency
            : undefined;
        }

        if (hasPriority) {
          translatedStatistic[EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY] =
            appointmentPriority !== undefined ? appointmentPriority : undefined;
        }

        if (hasLevelOfCare) {
          translatedStatistic[EXIT_ATTRIBUTES.LEVEL_OF_CARE] = levelOfCare
            ? levelOfCareTranslations[levelOfCare]?.[locale] || levelOfCare
            : undefined;
        }

        if (hasTypeOfConsultation) {
          translatedStatistic[EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION] = typeOfConsultation
            ? typeOfConsultation
                .map(value => typeOfConsultationTranslations[value]?.[locale] || value)
                .join(', ')
            : undefined;
        }

        if (hasResource) {
          translatedStatistic[EXIT_ATTRIBUTES.RESOURCE] = resource
            ? resourceTranslations[resource]?.[locale] || resource
            : undefined;
        }

        if (hasCapability) {
          translatedStatistic[EXIT_ATTRIBUTES.CAPABILITY] = capability
            ? capability.map(value => capabilityTranslations[value]?.[locale] || value).join(', ')
            : undefined;
        }

        return translatedStatistic;
      }
    );
  }

  @computed
  get sortedStatistics() {
    if (this.sorter.field && !Array.isArray(this.sorter.field) && this.sorter.order) {
      const sorter =
        this.sorter.field === 'count' || this.sorter.field === EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY
          ? this.sortByNumber(this.sorter.field, this.sorter.order)
          : this.sortByLocale(this.sorter.field, this.sorter.order);
      return this.translatedStatistics.slice().sort(sorter);
    }

    return this.translatedStatistics;
  }

  @computed
  get isLoading() {
    const { conditionsListStore, content24Store } = this.rootStore;
    return (
      this.isLoadingReports ||
      this.isLoadingStatistics ||
      conditionsListStore.isLoading ||
      content24Store.isLoading()
    );
  }

  @computed
  get reportOptions() {
    return this.reports.reverse().map(({ reportId, status, createdAt }) => ({
      value: reportId,
      label: `${format(
        new Date(createdAt),
        `${DEFAULT_DATE_FORMAT} ${TIME_FORMAT_CLEAN}`
      )} ${this.intl.formatMessage({
        id: TRANSLATION_KEYS[status],
      })}`,
      disabled: status !== REPORT_STATUSES.COMPLETE,
    }));
  }

  @action
  initialize = async () => {
    await this.fetchReports();

    if (this.activeReportId) {
      this.fetchStatistics(this.activeReportId);
    } else {
      runInAction(() => {
        this.statistics.replace([]);
      });
    }
  };

  @action
  fetchReports = async () => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.isLoadingReports = true;

      const { data } = await fetchStatisticsReports(partnerId);

      runInAction(() => {
        this.reports.replace(data);
        this.handleSetActiveReport();
      });
    } finally {
      runInAction(() => {
        this.isLoadingReports = false;
      });
    }
  };

  @action
  fetchStatistics = async (reportId: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.isLoadingStatistics = true;

      const { data } = await fetchStatistics(partnerId, reportId);

      runInAction(() => {
        this.statistics.replace(batchAddUUID(data));
      });
    } finally {
      runInAction(() => {
        this.isLoadingStatistics = false;
      });
    }
  };

  @action
  handleChangeVersion = (reportId: string) => {
    this.activeReportId = reportId;
    this.fetchStatistics(this.activeReportId);
  };

  @action
  handleAddNewReport = async () => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.isLoadingReports = true;

      const { data } = await createStatisticsReport(partnerId);

      runInAction(() => {
        this.reports.push(data);
        this.handleSetActiveReport();
      });

      intlNotification.success(
        {
          frmMessage: {
            id: 'condition.new-report-initiated',
          },
        },
        {
          placement: 'top',
        }
      );

      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoadingReports = false;
      });
    }
  };

  @action
  handleSetActiveReport = () => {
    this.activeReportId = this.reports
      .slice()
      .reverse()
      .find(({ status }) => status === REPORT_STATUSES.COMPLETE)?.reportId;
  };

  @action
  handleApplyFilter = (filter: Filter) => {
    this.filter = { ...filter };
  };

  @action
  handleSortChange = (
    _: TablePaginationConfig,
    __: Record<string, FilterValue | null>,
    sorter: SorterResult<TranslatedStatistic> | SorterResult<TranslatedStatistic>[]
  ) => {
    if (Array.isArray(sorter)) {
      return;
    }

    const { order, field } = sorter;
    // No better way to type field, as AntDesign uses React Key type by default
    this.sorter = { order, field: field as keyof TranslatedStatistic | undefined };
  };

  handleDownloadClick = () => {
    const { partnersStore } = this.rootStore;
    const { formatMessage } = this.intl;
    const report = this.reports.find(({ reportId }) => reportId === this.activeReportId);

    if (!report) {
      return;
    }

    const availableExitAttributes: EXIT_ATTRIBUTES[] =
      partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_EXIT_ATTRIBUTES') || [];
    const headerGeneral = [
      {
        key: 'source',
        value: formatMessage({ id: 'general.condition' }),
      },
      {
        key: 'id',
        value: formatMessage({ id: 'statistics.exit-id' }),
      },
      {
        key: 'count',
        value: formatMessage({ id: 'statistics.received-exits' }),
      },
    ];
    // This includes:
    // condition-edit.urgency-label
    // condition-edit.appointmentPriority-label
    // condition-edit.levelOfCare-label
    // condition-edit.typeOfConsultation-label
    // condition-edit.resource-label
    // condition-edit.capability-label
    const headerExitAttributes = availableExitAttributes
      .filter(key => this.dataPresentationOrder.includes(key))
      .map(key => ({
        key,
        value: formatMessage({ id: `condition-edit.${key}-label` }),
      }));
    const header = headerGeneral.concat(headerExitAttributes);

    const fileName = `${format(new Date(report.createdAt), 'yyyy-MM-dd-HH-mm')}`;
    const statistics = batchRemoveUUID(toJS(this.sortedStatistics)).map(statistic =>
      this.sortWithDataPresentationOrder(
        Object.entries(statistic).map(record => ({ key: record[0], value: record[1] })),
        'key'
      )
    );

    statistics.unshift(this.sortWithDataPresentationOrder(header, 'key'));

    downloadAsCSVFile(
      statistics.map(item => item.map(subitem => subitem.value)),
      fileName
    );
  };

  sortByLocale = (key: keyof TranslatedStatistic, order: SortOrder) => {
    return (a: TranslatedStatistic, b: TranslatedStatistic) =>
      sortWithLocale(
        order === 'ascend' ? a : b,
        order === 'ascend' ? b : a,
        String(key),
        this.intl.locale
      );
  };

  sortByNumber = (key: keyof TranslatedStatistic, order: SortOrder) => {
    return (a: TranslatedStatistic, b: TranslatedStatistic) => {
      const firstItem = order === 'ascend' ? a : b;
      const secondItem = order === 'ascend' ? b : a;
      return (
        (key in firstItem && firstItem[key] ? (firstItem[key] as number) : 0) -
        ('count' in secondItem && secondItem[key] ? (secondItem[key] as number) : 0)
      );
    };
  };

  sortWithDataPresentationOrder(data: Record<string, any>[], sortKey: string) {
    return data.slice().sort((a, b) => {
      return (
        this.dataPresentationOrder.indexOf(a[sortKey]) -
        this.dataPresentationOrder.indexOf(b[sortKey])
      );
    });
  }
}

export default StatisticsStore;
