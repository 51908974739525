import React from 'react';

import TechProblems from 'assets/svg/techProblem.svg';

import styles from './ShutdownError.module.css';

export const SystemShutDownError = ({ error, imageAlt }: { error: string; imageAlt: string }) => (
  <div className={styles.container}>
    <div className={styles.modal}>
      <img src={TechProblems} width={90} alt={imageAlt} />
      <span className={styles.content} data-testid="preview-text">
        {error &&
          error
            .split(/\\n/)
            .map((paragraph, index) => <span key={`paragraph${index}`}> {paragraph} </span>)}
      </span>
    </div>
  </div>
);
