import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import './Toastify.css';
import styles from './FlashMessage.module.css';

const FlashMessage: React.FunctionComponent = () => (
  <div data-testid="flash-message">
    <ToastContainer
      position={toast.POSITION.TOP_RIGHT}
      newestOnTop
      toastClassName={styles.toast}
      hideProgressBar
      draggable={false}
      autoClose={false}
      closeOnClick={false}
      pauseOnHover
    />
  </div>
);

export default FlashMessage;
