import { Switch, Button, Modal, Row, Col } from 'antd';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { TranslatedText } from 'types/types';

import { getShutdownInfo, PartnerShutdownExplanationDTO } from './api/shutdownApi';
import PhonePreview from './components/PhonePreview';
import TabLayoutView from './components/TabLayoutView';
import styles from './Shutdown.module.css';

const getPayload = (availableOriginLanguages: LANGS, isShutdown: boolean) => {
  return {
    isShutdown,
    shutdownExplanations: Object.keys(availableOriginLanguages).map(languageKey => ({
      locale: languageKey,
      explanation: availableOriginLanguages[languageKey],
    })),
  };
};

const getInitialValues = (
  availableLanguagues: LANGS[] = [],
  shutdownExplanations: PartnerShutdownExplanationDTO[] = []
) => {
  const translationsTextMemo = shutdownExplanations?.reduce((memo, value) => {
    memo[value.locale as LANGS] = value.explanation;
    return memo;
  }, {});
  return availableLanguagues?.reduce((acc, lang) => {
    acc[lang as LANGS] = translationsTextMemo[lang as LANGS] || '';
    return acc;
  }, {});
};

const Shutdown = () => {
  const intl = useIntl();
  const { originId } = useParams<{ originId: string }>();
  const { originStore, partnersStore } = useContext(RootStoreContext);
  const { availableOriginLanguages } = originStore;
  const [viewModal, setModalVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isShutdown, setIsShutdown] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [translations, setTranslations] = useState<any>(
    getInitialValues(availableOriginLanguages as LANGS[])
  );

  useEffect(() => {
    if (!translations[currentTab]) {
      setEditable(true);
    }

    if (!originId) {
      return;
    }
    getShutdownInfo(partnersStore.partnerId, originId).then(response => {
      const { data } = response;
      const translationText = getInitialValues(
        availableOriginLanguages,
        response.data.shutdownExplanations
      );
      setIsShutdown(data.isShutdown);
      setCurrentTab(toJS(availableOriginLanguages)[0] ?? 'sv');
      setTranslations(translationText);
    });
  }, []);

  const onChange = (checked: boolean) => {
    setIsShutdown(checked);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const confirmShutdown = () => {
    handleClose();
    if (!originId) {
      return;
    }
    originStore.shutdownPatientApp(getPayload(translations as LANGS, isShutdown), originId);
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();

    setEditable(false);
    if (isShutdown) {
      setModalVisible(true);
    } else {
      if (!originId) {
        return;
      }
      originStore.shutdownPatientApp(getPayload(translations as LANGS, isShutdown), originId);
    }
  };

  const onEditClick = () => {
    setEditable(true);
  };

  const handleTranslationTxtChange =
    (lang: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setTranslations({ ...translations, [lang]: value });
    };

  const handleTabChange = (value: string) => {
    setCurrentTab(value as LANGS);
    setEditable(!translations[value as LANGS]);
  };

  return (
    <div>
      <PageHeader content="origins.shutdown-heading" canEdit={false} />
      <FormattedMessage id="origins.shutdown" /> <Switch checked={isShutdown} onChange={onChange} />
      <Modal
        open={viewModal}
        title={intl.formatMessage({ id: 'general.confirm' })}
        onOk={confirmShutdown}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            <FormattedMessage id="general.cancel" />
          </Button>,
          <Button key="submit" type="primary" onClick={confirmShutdown}>
            <FormattedMessage id="general.yes" />
          </Button>,
        ]}
      >
        <FormattedMessage id="origins.shutdown-confirmation" />
      </Modal>
      <Row className={styles.tab}>
        <Col span="18" flex="auto">
          <Observer>
            {() => (
              <TabLayoutView
                availableLanguages={availableOriginLanguages}
                editable={editable}
                onEditClick={onEditClick}
                loading={originStore.isLoading()}
                texts={translations as TranslatedText}
                onTabChange={handleTabChange}
                onTextChange={handleTranslationTxtChange}
                onSubmit={submitForm}
                disabled={
                  isShutdown &&
                  Object.values(translations as TranslatedText).every(txt => !txt?.trim())
                }
              />
            )}
          </Observer>
        </Col>
        <Col span="6">
          <PhonePreview previewText={translations[currentTab]} />
        </Col>
      </Row>
    </div>
  );
};

export default Shutdown;
