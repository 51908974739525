import React from 'react';

import OpeningHours from './OpeningHours';
import { useCurrentCareUnitId } from './openingHours.hooks';
import { MODE } from './openingHours.types';

const OpeningHoursCareUnit = () => {
  const careUnitId = useCurrentCareUnitId();

  return <OpeningHours id={careUnitId} mode={MODE.CARE_UNIT} />;
};

export default OpeningHoursCareUnit;
