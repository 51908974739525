import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Input, Form } from 'formik-antd';
import React, { FunctionComponent, useContext, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import {
  ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX,
  NOT_ONLY_NUMBERS_REGEX,
  NO_LEADING_TRAILING_SPACE_REGEX,
} from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import {
  CODE24_MODEL_TYPES,
  EXIT_ATTRIBUTES,
} from 'modules/Content24/Condition/constants/code24types';
import { Formula } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
  validateStatementBuildTimeIfWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';

import PatientRiskMessage from '../../PatientRiskMessage';

/**
 * @notExported
 */
interface FormulaFormProps {
  onCancel: () => void;
  onSubmit: (data: Formula) => void;
  data?: Formula;
  isDisabled?: boolean;
}

const FormulaForm: FunctionComponent<FormulaFormProps> = ({
  data,
  onCancel,
  onSubmit,
  isDisabled,
}) => {
  const defaultValues: Formula = {
    id: '',
    type: CODE24_MODEL_TYPES.FORMULA,
    formulaId: '',
    condition: '',
    buildTimeIf: '',
  };
  const intl = useIntl();
  const { conditionStore } = useContext(RootStoreContext);
  const initialValues = getInitialFormValues<Formula, Formula>(defaultValues, data);

  const validationSchema = Yup.object().shape({
    formulaId: Yup.string()
      .matches(
        ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX,
        intl.formatMessage({
          id: 'general.errors.alphanumeric-characters-dashes-hyphens-validation',
        })
      )
      .matches(
        NOT_ONLY_NUMBERS_REGEX,
        intl.formatMessage({
          id: 'general.errors.not_only_numbers_validation',
        })
      )
      .required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      ),
    condition: Yup.string()
      .required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      )
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      ),
    buildTimeIf: Yup.string()
      .test(
        'noLeadingTrailingSpace',
        intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
        function (this: Yup.TestContext, value: string) {
          return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
        }
      )
      .test(
        'isValidBuildTimeIf',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementBuildTimeIfWithDebounce
      )
      .nullable(),
  });

  const isFormDisabled = conditionStore.isLoading() || isDisabled;

  const handleSubmit = useCallback(
    (dataToSubmit: Formula) => {
      onSubmit(getDataFromFinalFormValues<Formula>(dataToSubmit));
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty, values, submitForm }) => {
        const isNewExit = !values.id;
        const isSubmitConfirmed = !isNewExit && initialValues.condition !== values.condition;

        return (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="formulaId"
                  required
                  label={<FormattedMessage id="condition-edit.formula-label" />}
                >
                  <Input name="formulaId" disabled={isFormDisabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="condition"
                  required
                  label={<FormattedMessage id="condition-edit.condition-label" />}
                >
                  <Input.TextArea name="condition" disabled={isFormDisabled} rows={1} autoSize />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="buildTimeIf"
                  label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                >
                  <Input name="buildTimeIf" disabled={isFormDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <FormActionButtons
              isSaving={conditionStore.isLoading()}
              isDisabled={isDisabled}
              isValid={isValid && dirty}
              onCancel={onCancel}
              showCancelConfirm={dirty}
              cancelDeclineText={<FormattedMessage id="condition-edit.statement-cancel-confirm" />}
              showSubmitConfirm={isSubmitConfirmed}
              submitQuestionText={
                <PatientRiskMessage
                  values={values}
                  initialValues={initialValues}
                  keys={[EXIT_ATTRIBUTES.URGENCY, 'condition']}
                />
              }
              onSubmit={submitForm}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormulaForm;
