import React, { useEffect } from 'react';

import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';

import { UserFilter } from '../types';

const initialValues: UserFilter = {
  searchString: '',
  practitionerRoles: [],
  administrativeRoles: [],
  careProviderIds: [],
  careUnitIds: [],
};

const loadHSAIDsFromLocalStorage = () => {
  try {
    const savedFilters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_QUERY) || '{}');
    return savedFilters ?? initialValues;
  } catch (error) {
    return initialValues;
  }
};

export const useFilterStorage = () => {
  const [selectedFilters, setSelectedFilter] = React.useState(loadHSAIDsFromLocalStorage());

  const setFilters = React.useCallback((filterProps: UserFilter) => {
    setSelectedFilter(filterProps);
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_QUERY, JSON.stringify(filterProps));
  }, []);

  const clearFilters = React.useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_QUERY);
    setSelectedFilter(initialValues);
  }, []);

  return {
    setFilters,
    clearFilters,
    selectedFilters,
  };
};
