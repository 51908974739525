import { Marked } from 'marked';

export type ImageBlockToken = {
  type: 'imageBlock';
  raw: string;
  alt: string;
  href: string;
};

/**
 * Custom Extension for Marked.js to Handle Standalone Block-Level Images
 *
 * This extension modifies the default behavior of Marked.js to treat images as
 * block-level tokens, preventing them from being wrapped in paragraph (`<p>`) tags.
 * By default, Marked.js processes images as inline elements, which causes them
 * to be enclosed within paragraph tags when used alone on a line.
 */
const imageBlockExtension = {
  name: 'imageBlock',
  level: 'block' as const,
  start(src: string) {
    return src.match(/^!\[.*?\]\(.*?\)/)?.index;
  }, // Match image pattern at the start of a line
  tokenizer(src: string) {
    const rule = /^!\[([^\]]*?)\]\((.*?)\)/; // Regex to match an image in markdown
    const match = rule.exec(src);

    if (match) {
      return {
        type: 'imageBlock', // Custom type to identify this block
        raw: match[0],
        alt: match[1],
        href: match[2],
      };
    }
    return undefined;
  },
  renderer(token: ImageBlockToken) {
    return `<img src="${token.href}" alt="${token.alt}">\n`;
  },
};
export const getMarked = () => new Marked().use({ extensions: [imageBlockExtension] });

export const marked = getMarked();
