import { atom, useAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { ScopeProvider } from 'jotai-scope';
import React from 'react';

export const tabContentStateAtom = atom({
  isInsideLanguageTabs: false,
  isOutsideDefaultLanguage: false,
  highlightedLanguage: null as null | string,
  setSelectedLanguage: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fn: (language: string) => {
      /* noop */
    },
  },
  switchToDefaultLanguage: {
    fn: () => {
      /* noop */
    },
  },
});

const isOutsideDefaultLanguageAtom = focusAtom(tabContentStateAtom, optic =>
  optic.prop('isOutsideDefaultLanguage')
);
export const useIsOutsideDefaultLanguage = () => useAtom(isOutsideDefaultLanguageAtom);

const isInsideLanguageTabsAtom = focusAtom(tabContentStateAtom, optic =>
  optic.prop('isInsideLanguageTabs')
);
export const useIsInsideLanguageTabs = () => useAtom(isInsideLanguageTabsAtom)[0];

const switchToDefaultLanguageAtom = focusAtom(tabContentStateAtom, optic =>
  optic.prop('switchToDefaultLanguage')
);
export const useSwitchToDefaultLanguage = () => useAtomValue(switchToDefaultLanguageAtom)?.fn;

const setSelectedLanguageAtom = focusAtom(tabContentStateAtom, optic =>
  optic.prop('setSelectedLanguage')
);
export const useSetSelectedLanguage = () => useAtomValue(setSelectedLanguageAtom)?.fn;

const highlightedLanguageAtom = focusAtom(tabContentStateAtom, optic =>
  optic.prop('highlightedLanguage')
);
export const useHighlightedLanguage = () => useAtom(highlightedLanguageAtom);

export const LanguageTabStateScopeProvider = ({ children }: { children: React.ReactNode }) => (
  <ScopeProvider atoms={[tabContentStateAtom]}>{children}</ScopeProvider>
);

export const withLanguageTabState = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent = (props: P) => (
    <LanguageTabStateScopeProvider>
      <Component {...props} />
    </LanguageTabStateScopeProvider>
  );
  WrappedComponent.displayName = `withLanguageTabState(${Component.displayName || Component.name})`;

  return WrappedComponent;
};
