import { CalendarOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import React from 'react';

const DateDisplay = ({ date }: { date: string }) => (
  <Flex gap="small" align="center">
    <CalendarOutlined />
    <Typography.Text>{date}</Typography.Text>
  </Flex>
);

export default DateDisplay;
