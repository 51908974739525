import axios from 'axios';

export interface NewsItem {
  subject: string;
  body: string;
  link: string;
  id: string;
}

interface NewsItemOrder {
  newsId: string;
  rank: number;
}

export type PartnerId = string | undefined;

export const NEWS_ITEMS_URI = '/rest/admin/v1/partners';

export const fetchNewsItems = (originId: string, partnerId: PartnerId) =>
  axios.get<NewsItem[]>(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems`);

export const createNewsItem = async (data: NewsItem, originId: string, partnerId: PartnerId) =>
  axios.post(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems`, data);

export const updateNewsItems = (
  data: NewsItem,
  originId: string,
  newsId: string,
  partnerId: PartnerId
) => axios.put(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/${newsId}`, data);

export const deleteNewsItem = (newsId: string, originId: string, partnerId: PartnerId) =>
  axios.delete(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/${newsId}`);

export const reorderNewsItems = (data: NewsItemOrder[], originId: string, partnerId: PartnerId) =>
  axios.put(`${NEWS_ITEMS_URI}/${partnerId}/origins/${originId}/newsitems/rankings`, data);
