import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';
import { LANGS } from 'constants/enums';

export enum OriginRuleConditionOperators {
  ContainsAny = 'ContainsAny',
  Contains = 'Contains',
  Equals = 'Equals',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  In = 'In',
  IsEmpty = 'IsEmpty',
  IsNull = 'IsNull',
  LessThanOrEqual = 'LessThanOrEqual',
}

export enum OriginRuleOutcomeOperators {
  APPEND = 'Append',
  SET = 'Set',
  CLEAR = 'Clear',
  REMOVE = 'Remove',
}

export interface OriginRuleCondition {
  field: string;
  not: boolean;
  operator: OriginRuleConditionOperators;
  value: any;
}

export interface OriginRuleOutcome {
  field: string;
  operator: OriginRuleOutcomeOperators;
  value: any;
  method?: string;
  methodProperties?: {
    type: string;
    preText: {
      [index in LANGS]: string;
    };
  };
}

export interface OriginRule {
  id: string;
  description?: string;
  category?: string;
  priority: number;
  conditional: OriginRuleCondition[];
  outcome: OriginRuleOutcome[];
}

export const fetchOriginRules = (partnerId: string, originId: string) =>
  axios.get<OriginRule[]>(`${PARTNERS_URI}/${partnerId}/origins/${originId}/rules`);

export const updateRule = (partnerId: string, originId: string, rule: OriginRule) =>
  axios.put<OriginRule>(`${PARTNERS_URI}/${partnerId}/origins/${originId}/rules/${rule.id}`, rule);

export const deleteRule = (partnerId: string, originId: string, ruleId: string) =>
  axios.delete(`${PARTNERS_URI}/${partnerId}/origins/${originId}/rules/${ruleId}`);
