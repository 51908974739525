import { Row, Col, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import noop from 'lodash/noop';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './DiagnosisCodes.module.css';

/**
 * @notExported
 */
interface DiagnosisCodesProps {
  diagnosisCodes: {
    [key: string]: string[];
  };
}

const DiagnosisCodes = ({ diagnosisCodes }: DiagnosisCodesProps) => (
  <Formik initialValues={{}} onSubmit={noop}>
    <div className={styles.container}>
      <div className="ant-form-vertical">
        <Typography.Title level={4}>
          <FormattedMessage id="condition-edit.diagnosis-code" />
        </Typography.Title>
        {Object.keys(diagnosisCodes).map(code => (
          <Form.Item name="diagnosisCode" key={code}>
            <Row key={code} gutter={16}>
              <Col span="8">
                <Select
                  name="diagnosisCodeType"
                  className={styles.codeNames}
                  disabled
                  showSearch
                  optionFilterProp="label"
                  options={[
                    {
                      label: code,
                      value: code,
                    },
                  ]}
                  defaultValue={code}
                />
              </Col>
              <Col span="8">
                {Object.values(diagnosisCodes[code]) && (
                  <Select
                    name="diagnosisCode"
                    mode="multiple"
                    disabled
                    showSearch
                    optionFilterProp="label"
                    options={Object.values(diagnosisCodes[code]).map(key => ({
                      label: key,
                      value: key,
                    }))}
                    defaultValue={Object.values(diagnosisCodes[code])}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
        ))}
      </div>
    </div>
  </Formik>
);

export default DiagnosisCodes;
