import { useMemo } from 'react';

import RootStore from 'stores/RootStore';
import { usePrivateFederationBaseProps } from 'utils/remoteModules/remoteModuleUtils';

export const usePathwaysProps = <TAdditionalProps extends Record<string, unknown>>(
  rootStore: RootStore,
  additionalProps: TAdditionalProps = {} as TAdditionalProps
) => {
  const baseProps = usePrivateFederationBaseProps(rootStore);

  return useMemo(
    () => ({
      ...baseProps,
      ...(additionalProps as { [key in keyof TAdditionalProps]: TAdditionalProps[key] }),
    }),
    [additionalProps, baseProps]
  );
};
