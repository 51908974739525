import uuid4 from 'uuid/v4';

import { HasUUID } from 'types/types';

/**
 * Generates a UUID
 */
export function generateUUID() {
  return uuid4();
}

/**
 * Adds a `uuid` property with unique identifier to the passed object
 * @param {object} elem
 */
// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addUUID(elem: any): any & HasUUID {
  if (typeof elem === 'object' && elem !== null && !Array.isArray(elem)) {
    return { ...elem, uuid: elem.uuid || generateUUID() };
  }

  if (Array.isArray(elem)) {
    throw new TypeError('invalid type [array] passed to addUUID, expected [object]');
  } else {
    throw new TypeError(`invalid type [${typeof elem}] passed to addUUID, expected [object]`);
  }
}

/**
 * Removes `uuid` property from the passed object
 * @param {object} elem
 */
// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUUID(elem: any) {
  if (typeof elem !== 'object' || elem === null || Array.isArray(elem)) {
    return elem;
  }

  const elementCopy = { ...elem };
  delete elementCopy.uuid;
  return elementCopy;
}

/**
 * Adds a `uuid` property with unique identifier to all objects in the passed array
 * @param {object[]} data
 */
// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function batchAddUUID(data: any[]): (any & HasUUID)[] {
  return data.map(elem => addUUID(elem));
}

/**
 * Removes `uuid` property from all objects in the array
 * @param {object[]} data
 */
// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function batchRemoveUUID(data: any[]) {
  if (!Array.isArray(data)) {
    return data;
  }
  return data.map(elem => removeUUID(elem));
}
