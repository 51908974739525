import { TestContext } from 'yup';

import { validateCondition, validateBuildTimeIf } from 'modules/Content24/Condition/api/code24api';
import { asyncDebounce } from 'utils/appUtils';

import { ExitFormData } from '../components/BlockForms/ExitForm/ExitForm';
import { EXIT_ATTRIBUTES, EXIT_ATTRIBUTES_KEYS } from '../constants/code24types';
import {
  REGEX_PATIENT_FIELD_VALIDATION,
  REGEX_PATIENT_4_FIELD_VALIDATION,
  REGEX_PATIENT_4_FIELD_VALIDATION_PIPE,
  REGEX_PIPE_ALTERNATIVES,
  REGEX_BRACKETS_EXPRESSION_PATTERN,
  isTemplate,
  isValidHyperlink,
} from '../constants/regex';

export interface ExpressionError {
  translationKey: string;
  characters: string;
}

export interface ExpressionErrors {
  [key: string]: ExpressionError;
}

function getValidationErrorParams(errorsAt: number[]) {
  return {
    translationKey: 'condition-edit.errors.errors-at-character',
    // common people use 1-based counting
    characters: errorsAt.map(num => (num += 1)).join(', '),
  };
}

export function validateAtLeastOneExitAttributeSelected(formFields: ExitFormData) {
  return (
    formFields === null ||
    EXIT_ATTRIBUTES_KEYS.some((field: EXIT_ATTRIBUTES) => {
      const selectedAttributes = formFields[field];
      if (Array.isArray(selectedAttributes)) {
        return selectedAttributes.length > 0;
      }
      return (
        selectedAttributes !== null && selectedAttributes !== '' && selectedAttributes !== undefined
      );
    })
  );
}

async function validateStatementBuildTimeIf(testContext: TestContext, value: string) {
  if (!value) {
    return false;
  }

  try {
    const { data } = await validateBuildTimeIf(value);

    if (data.valid) {
      return true;
    }

    const error = testContext.createError({
      params: getValidationErrorParams(data.errorsAt),
    });

    return error;
  } catch {
    return false;
  }
}

const debouncedValidateStatementBuildTimeIf = asyncDebounce(validateStatementBuildTimeIf, 500);

async function validateStatementCondition(testContext: TestContext, value: string) {
  if (!value) {
    return false;
  }

  try {
    const { data } = await validateCondition(value);

    if (data.valid) {
      return true;
    }

    const error = testContext.createError({
      params: getValidationErrorParams(data.errorsAt),
    });

    return error;
  } catch {
    return false;
  }
}

const debouncedValidateStatementCondition = asyncDebounce(validateStatementCondition, 500);

const debouncedValidateStatementTextCondition = asyncDebounce(validateStatementCondition, 500);

const debouncedValidateStatementVariable = asyncDebounce(validateStatementCondition, 500);

export async function validateStatementBuildTimeIfWithDebounce(this: TestContext, value: string) {
  return debouncedValidateStatementBuildTimeIf(this, value);
}

// These three basically do the same, but they have to be separated, because if not,
// they conflict with each other if they are used in the same form, like QuestionForm,
// ExitForm or ExportDataForm.

export async function validateStatementConditionWithDebounce(this: TestContext, value: string) {
  return debouncedValidateStatementCondition(this, value);
}

export async function validateStatementTextConditionWithDebounce(this: TestContext, value: string) {
  return debouncedValidateStatementTextCondition(this, value);
}

export async function validateStatementVariableWithDebounce(this: TestContext, value: string) {
  return debouncedValidateStatementVariable(this, value);
}

export async function validateStatementContentAndReturnErrors(
  content: Array<{ condition?: string; buildTimeIf?: string | null }>
): Promise<ExpressionErrors> {
  if (!content || !content.length) {
    return {};
  }

  const errors = {};

  for (let i = 0; i < content.length; i++) {
    if (!content[i]) {
      continue;
    }

    const { condition, buildTimeIf } = content[i];

    if (condition) {
      const { data } = await validateCondition(condition);

      if (!data.valid) {
        errors[`content[${i}].condition`] = getValidationErrorParams(data.errorsAt);
      }
    }

    if (buildTimeIf) {
      const { data } = await validateBuildTimeIf(buildTimeIf);

      if (!data.valid) {
        errors[`content[${i}].buildTimeIf`] = getValidationErrorParams(data.errorsAt);
      }
    }
  }

  return errors;
}

function* matchAll(str: string, regExp: RegExp) {
  if (!regExp.global) {
    throw new TypeError('Flag /g must be set!');
  }
  const localCopy = new RegExp(regExp, regExp.flags);
  let match;
  while ((match = localCopy.exec(str))) {
    yield match;
  }
}

export const validatePatientField = (value: string) => {
  // number of "[" should be the same as "]"
  if ((value.match(/\[/g) || []).length !== (value.match(/\]/g) || []).length) {
    return false;
  }

  // this would return array of items with following formats:
  // [anythingHere]
  // [anythingHere](anything here)
  const squareBracketsExpressions = [
    ...matchAll(value, new RegExp(REGEX_BRACKETS_EXPRESSION_PATTERN, 'g')),
  ];

  // if normal text without brackets or hyuperlinks => valid
  if (squareBracketsExpressions.length === 0) {
    return true;
  }

  // nested square brackets are never valid!
  if (hasNestedSquareBrackets(value)) {
    return false;
  }

  // check each square brackets expression separately
  return squareBracketsExpressions.every(expr => {
    const testedSample = expr[0];

    // check if sample is valid md hyperlink syntax [nameOfTheLink](linkItself)
    if (isValidHyperlink(testedSample)) {
      return true;
    }
    // apply validation rules to template sample [template here]
    if (isTemplate(testedSample)) {
      // if it doesn't have | then check regex like before
      if (!value.includes('|')) {
        //check if expression has 1 or 3 slashes only
        if (testedSample.match(/\//g)?.length !== 1 && testedSample.match(/\//g)?.length !== 3) {
          return false;
        }
        return (
          REGEX_PATIENT_FIELD_VALIDATION.test(testedSample) ||
          REGEX_PATIENT_4_FIELD_VALIDATION.test(testedSample)
        );
      } else {
        // check each part of | separately
        const alternatives = testedSample.split('|');
        if (alternatives.length !== 2 && alternatives.length !== 4) {
          return false;
        }
        return alternatives.slice(0, 1).every(expr => {
          if (expr.length === 0) return false;
          return REGEX_PIPE_ALTERNATIVES.test(expr);
        });
      }
    }
    return false;
  });
};

export const validatePatient4Regex = (value: string) =>
  REGEX_PATIENT_4_FIELD_VALIDATION.test(value) || REGEX_PATIENT_4_FIELD_VALIDATION_PIPE.test(value);

function hasNestedSquareBrackets(inputString: string) {
  if (!inputString) {
    return false;
  }
  const stack = [];

  for (const char of inputString) {
    if (char === '[') {
      if (stack[stack.length - 1] === '[') {
        // found opening bracket. fail
        return true;
      }
      stack.push('[');
    } else if (char === ']' && stack.length !== 0) {
      stack.pop();
    }
  }

  return stack.length !== 0; // If stack is empty, we do not have nested square brackets
}
