import React from 'react';

/**
 * Automatically adds *Separator* between each child
 *
 * @param children - The children to render
 * @param Separator - The separator to render between each child
 */
export const ListWithSeparator = ({
  children,
  Separator,
}: {
  children: React.ReactNode[];
  Separator: React.ElementType;
}) => {
  const els = React.Children.toArray(children).reduce((acc: React.ReactNode[], child, index) => {
    if (index === 0) {
      return [child] as React.ReactNode[];
    }
    return [...acc, <Separator key={index} />, child] as React.ReactNode[];
  }, [] as React.ReactNode[]);

  return <>{els}</>;
};
