import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';
import { Report } from 'modules/Content24/api/reportsApi';

interface Code24Test {
  id: string;
  name: string;
}

export interface Code24TestReport {
  id: string;
  success: boolean;
}

// Please note: API still requires originId, but Content24 is now used in Partner scope. Using partnerId for both params is
// a "hacky" way to handle that.

export const fetchCode24Tests = (partnerId: string) =>
  axios.get<Code24Test[]>(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/test`);

export const createCode24Test = (partnerId: string, interviewId: string) =>
  axios.post<Code24Test>(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/test`, {
    interviewId,
  });

export const deleteCode24Test = (partnerId: string, testId: string) =>
  axios.delete(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/test/${testId}`);

export const fetchUnitTestReports = (partnerId: string) =>
  axios.get<Report[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/unit_test/queue`
  );

export const fetchLastCompletedUnitTestReport = (partnerId: string) =>
  axios.get<Code24TestReport[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/unit_test/latest`
  );

export const createCode24TestReport = (partnerId: string) =>
  axios.post<Code24Test>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/unit_test/queue`
  );
