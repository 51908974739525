import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';
import { LANGS } from 'constants/enums';
import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';

export enum SELF_CARE_STATUS {
  SYSTEM_DEFAULT = 'SYSTEM_DEFAULT',
  LOCAL = 'LOCAL',
  SYSTEM_DEFAULT_AND_LOCAL = 'SYSTEM_DEFAULT_AND_LOCAL',
  REPLACED_SYSTEM_DEFAULT = 'REPLACED_SYSTEM_DEFAULT',
}

export enum SELF_CARE_CONTENT_STATUS {
  EDITABLE = 'EDITABLE',
  ONLY_REPLACE = 'ONLY_REPLACE',
}

export interface SelfCareInfo {
  id: string;
  languageToStatus: { [key in LANGS]?: SELF_CARE_STATUS };
  languageToContentPolicy: { [key in LANGS]?: SELF_CARE_CONTENT_STATUS };
  languages: LANGS[];
  status: SELF_CARE_STATUS;
  contentTypes: RICH_TEXT_CONTENT_TYPES[];
}

export const fetchSelfCareInfoList = (partnerId: string) =>
  axios.get<SelfCareInfo[]>(`${PARTNERS_URI}/${partnerId}/code24/text/selfcare`);

export const fetchSelfCareInfo = (partnerId: string, selfcareAdviceId: string) =>
  axios.get<SelfCareInfo>(`${PARTNERS_URI}/${partnerId}/code24/text/selfcare/${selfcareAdviceId}`, {
    ignoreErrorStatuses: [400],
  });

export const fetchSelfCareFile = (
  partnerId: string,
  selfcareId: string,
  lang: LANGS,
  contentType: RICH_TEXT_CONTENT_TYPES
) =>
  axios.get<string>(`${PARTNERS_URI}/${partnerId}/code24/text/selfcare/${selfcareId}/${lang}`, {
    ignoreErrorStatuses: [404],
    headers: {
      Accept: contentType,
    },
  });

export const saveSelfCareFile = (
  partnerId: string,
  selfcareId: string,
  lang: LANGS,
  contentType: RICH_TEXT_CONTENT_TYPES,
  content: string
) =>
  axios.post(`${PARTNERS_URI}/${partnerId}/code24/text/selfcare/${selfcareId}/${lang}`, content, {
    headers: { 'Content-Type': contentType },
  });

export const deleteSelfCareFile = (partnerId: string, selfcareId: string, lang: LANGS) =>
  axios.delete(`${PARTNERS_URI}/${partnerId}/code24/text/selfcare/${selfcareId}/${lang}`);
