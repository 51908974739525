import { Tabs } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import NorwegianDevLogin from '../NorwegianDevLogin';
import SwedishDevLogin from '../SwedishDevLogin/SwedishDevLogin';

const DevLogin = () => (
  <Tabs
    defaultActiveKey="1"
    items={[
      { key: '1', label: <FormattedMessage id="general.hsa-id" />, children: <SwedishDevLogin /> },
      {
        key: '2',
        label: <FormattedMessage id="general.norwegian-personal-id" />,
        children: <NorwegianDevLogin />,
      },
    ]}
  />
);

export default DevLogin;
