import { IntlShape } from 'react-intl';

import { SelectableRoleIT } from 'modules/Roles/api/rolesApi';

export const sortRoles = (data: SelectableRoleIT[], intl: IntlShape) => {
  return data?.sort(function (a, b) {
    return intl.formatMessage({ id: `roles.roleid.${a.id}` }) >
      intl.formatMessage({ id: `roles.roleid.${b.id}` })
      ? 1
      : -1;
  });
};
