import { notification } from 'antd';
import { observable, runInAction, action, computed, toJS } from 'mobx';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { ConditionValidationError } from 'modules/Content24/api/reportsApi';
import {
  fetchAvailableSectionStatements,
  AvailableSectionStatements,
} from 'modules/Content24/Condition/api/code24api';
import {
  Condition,
  fetchOriginCondition,
  fetchDefaultCondition,
  createOriginStatement,
  updateOriginStatement,
  deleteOriginStatement,
  createOriginSection,
  validateOriginCondition,
  createOriginCondition,
  NewPartnerCondition,
} from 'modules/Content24/Condition/api/partnerCode24api';
import { Section, Statement } from 'modules/Content24/Condition/models/Code24Model';
import { intlNotification } from 'state/notification';
import StateManager from 'stores/abstractStores/StateManager';
import RootStore from 'stores/RootStore';
import { Optional } from 'types/types';

import { CODE24_MODEL_TYPES } from '../Condition/constants/code24types';
import { UPDATE_ACTIONS } from '../constants/conditions';
import { updateStatementInOriginCondition } from '../utils/inPlaceConditionUpdate';

export default class ConditionStore extends StateManager {
  @observable
  conditionId?: string;

  // undefined if not initiated, null otherwise (error when fetching etc.)
  @observable
  originCondition: Optional<Condition> = undefined;

  // undefined if not initiated, null otherwise (error when fetching etc.)
  @observable
  defaultCondition: Optional<Condition> = undefined;

  @observable
  availableSectionStatements?: AvailableSectionStatements;

  @observable
  activeLibraryCondition?: Condition;

  @observable
  isLoadingActiveLibraryCondition = false;

  @observable
  isLoadingValidationStatus = false;

  @observable
  conditionValidationStatus: Record<string, string[]> = {};

  constructor(private rootStore: RootStore) {
    super();
  }

  @computed({ keepAlive: true })
  get condition(): Condition & { sections: Section[] } {
    const originCondition = toJS(this.originCondition);
    const defaultCondition = toJS(this.defaultCondition);
    const condition: Condition = originCondition ||
      defaultCondition || {
        type: '',
        metadata: {
          id: '',
          type: CODE24_MODEL_TYPES.METADATA,
          conditionId: '',
          level1id: '',
          title: {},
          status: '',
          priority: 0,
          reviewers: '',
        },
        defaultValues: {
          icdCode: '',
          referralQuestion: '',
          referralServiceRequested: '',
          type: '',
        },
      };

    return { ...condition, sections: condition.sections || [] };
  }

  @computed
  get conditionCategory() {
    const group = this.condition.metadata.level1id;
    const category = group ? this.rootStore.content24Store.groupCategories.get(group) : undefined;
    return category;
  }

  @computed({ keepAlive: true })
  get conditionStatementsAsOneList(): (Statement | Section)[] {
    return this.condition.sections.reduce(
      (accumulator: Statement[], currentSection: Section) =>
        accumulator.concat(currentSection.content?.slice() || []),
      []
    );
  }

  @action
  async handleAddStatement(statement: Statement, parentSection: Section, successMessage: string) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    const conditionId = this.condition.metadata.conditionId;
    const isIntroStatement = statement.type === CODE24_MODEL_TYPES.INTRO;
    const sectionContentLength = parentSection.content.length;
    let after;

    if (!!sectionContentLength && !isIntroStatement) {
      after = parentSection.content[sectionContentLength - 1].id;
    }

    // Statement should go as first in section if there are no other statements or if statement is of type Intro.
    // In case of search terms, no after param should be provided, if the first item is created, as there's no real search terms section.
    if (
      (!sectionContentLength && parentSection.type !== CODE24_MODEL_TYPES.SEARCH_TERMS) ||
      isIntroStatement
    ) {
      after = parentSection.id;
    }

    try {
      this.setLoading();

      const { data } = await createOriginStatement(partnerId, conditionId, {
        after,
        model: statement,
      });
      const result = updateStatementInOriginCondition(
        data,
        UPDATE_ACTIONS.ADD,
        toJS(this.originCondition)
      );

      runInAction(() => {
        if (result) {
          this.originCondition = result;
        }
      });

      // Refresh validation status, as some errors are reported only after whole condition is
      // checked on BE side, not during saving the updates.
      this.fetchConditionValidationStatus();

      notification.success({ placement: 'top', message: successMessage });
    } finally {
      this.setLoaded();
    }
  }

  @action
  async handleUpdateStatement(statement: Statement, successMessage: string) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const conditionId = this.condition.metadata.conditionId;

    try {
      this.setLoading();

      // we're updating statement of origin-specific condition
      const { data } = await updateOriginStatement(partnerId, conditionId, {
        model: statement,
      });
      // some statements (like search term, goto) change id with each update, we have to pass the original id to make an update
      const result = updateStatementInOriginCondition(
        data,
        UPDATE_ACTIONS.EDIT,
        toJS(this.originCondition),
        statement.id
      );

      runInAction(() => {
        if (result) {
          this.originCondition = result;
        }
      });

      // Refresh validation status, as some errors are reported only after whole condition is
      // checked on BE side, not during saving the updates.
      this.fetchConditionValidationStatus();

      notification.success({ placement: 'top', message: successMessage });
    } finally {
      this.setLoaded();
    }
  }

  async handleDeleteStatement(statement: Statement, successMessage: string) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const conditionId = this.condition.metadata.conditionId;

    try {
      this.setLoading();

      // we're removing statement from origin-specific condition
      const { data } = await deleteOriginStatement(partnerId, conditionId, statement.id);
      const result = updateStatementInOriginCondition(
        data,
        UPDATE_ACTIONS.DELETE,
        toJS(this.originCondition)
      );

      runInAction(() => {
        if (result) {
          this.originCondition = result;
        }
      });

      // Refresh validation status, as some errors are reported only after whole condition is
      // checked on BE side, not during saving the updates.
      this.fetchConditionValidationStatus();

      notification.success({ placement: 'top', message: successMessage });
    } finally {
      this.setLoaded();
    }
  }

  @action
  handleMoveStatement = async (statement: Statement, after: string) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const conditionId = this.condition.metadata.conditionId;

    try {
      this.setLoading();

      // we're updating statement of origin-specific condition
      const { data } = await updateOriginStatement(partnerId, conditionId, {
        after,
        model: statement,
      });
      const result = updateStatementInOriginCondition(
        data,
        UPDATE_ACTIONS.MOVE,
        toJS(this.originCondition)
      );

      runInAction(() => {
        if (result) {
          this.originCondition = result;
        }
      });

      // Refresh validation status, as some errors are reported only after whole condition is
      // checked on BE side, not during saving the updates.
      this.fetchConditionValidationStatus();
    } finally {
      this.setLoaded();
    }
  };

  @action
  async handleAddSection(section: Section) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    const conditionId = this.condition.metadata.conditionId;
    const preceedingSection =
      this.condition.sections && this.condition.sections[this.condition.sections.length - 1];
    const sectionUpdate = preceedingSection
      ? { model: section, after: preceedingSection.id }
      : { model: section };

    try {
      this.setLoading();

      // we're creating new section in origin-specific condition
      const { data } = await createOriginSection(partnerId, conditionId, sectionUpdate);
      const result = updateStatementInOriginCondition(
        data,
        UPDATE_ACTIONS.ADD,
        toJS(this.originCondition)
      );

      runInAction(() => {
        if (result) {
          this.originCondition = result;
        }
      });

      intlNotification.success(
        {
          frmMessage: {
            id: 'condition-edit.statement-created',
          },
        },
        {
          placement: 'top',
        }
      );
    } finally {
      this.setLoaded();
    }
  }

  @action
  handleConditionConversion = async (
    condition: NewPartnerCondition,
    notificationMessage: { successMessage: string; errorMessage: string }
  ) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    this.setLoading();

    try {
      const { data } = await createOriginCondition(partnerId, condition);

      runInAction(() => {
        this.originCondition = data;
        this.defaultCondition = undefined;
      });

      notification.success({
        placement: 'top',
        message: notificationMessage.successMessage,
      });
      // Refresh the list, so that if user goes back from condition, he would see
      // updated condition status
      this.rootStore.conditionsListStore.updateAllConditions();
    } catch (error) {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: notificationMessage.errorMessage,
      });
      throw error;
    } finally {
      this.setLoaded();
    }
  };

  @action
  initialize = (id: string) => {
    this.conditionId = id;

    // This will happen only if the condition was set before the store is initiated
    // (see setNewlyCreatedCondition method below)
    if (this.condition.metadata.conditionId === id) {
      return;
    }

    return this.refreshCondition();
  };

  // This method serves one purpose only - to set the condition from "outside", i.e. ConditionListStore.
  // The reason is to spare requests for fetching condition data and avoid issues caused by pod being not
  // synchronized
  @action
  setNewlyCreatedOriginCondition = (condition: Condition) => {
    this.originCondition = condition;
  };

  @action
  refreshCondition = async () => {
    this.setLoading();

    await this.fetchCondition();

    // Check validation only if it is an origin condition.
    // Default conditions should not be validated.
    if (this.originCondition) {
      this.fetchConditionValidationStatus();
    }

    this.setLoaded();
  };

  @action
  private fetchCondition = async () => {
    const isMedicalContentVersionSet = !!this.rootStore.content24Store.code24MedconVersion;

    runInAction(() => {
      this.originCondition = undefined;
      this.defaultCondition = undefined;
    });

    await this.fetchOriginCondition();

    if (!this.originCondition && isMedicalContentVersionSet) {
      await this.fetchDefaultCondition();
    }
  };

  @action
  private fetchConditionValidationStatus = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const { conditionId } = this;

    if (!conditionId) {
      return;
    }

    this.isLoadingValidationStatus = true;

    const { data } = await validateOriginCondition(partnerId, conditionId);

    runInAction(() => {
      this.conditionValidationStatus = data.reduce(
        (accumulator: Record<string, string[]>, current: ConditionValidationError) => ({
          ...accumulator,
          [current.modelId]: (accumulator[current.modelId] || []).concat(current.message),
        }),
        {}
      );
      this.isLoadingValidationStatus = false;
    });
  };

  private fetchOriginCondition = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const conditionId = this.conditionId;

    if (!conditionId) {
      return;
    }

    try {
      const { data } = await fetchOriginCondition(partnerId, conditionId);

      runInAction(() => {
        this.originCondition = data;
      });
    } catch {
      runInAction(() => {
        this.originCondition = null;
      });
    }
  };

  private fetchDefaultCondition = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    const conditionId = this.conditionId;

    if (!conditionId) {
      return;
    }

    try {
      const { data } = await fetchDefaultCondition(partnerId, conditionId);

      runInAction(() => {
        this.defaultCondition = data;
      });
    } catch {
      runInAction(() => {
        this.defaultCondition = null;
      });
    }
  };

  fetchAvailableSectionStatements = async () => {
    if (this.availableSectionStatements) {
      return;
    }

    try {
      const { data } = await fetchAvailableSectionStatements();

      runInAction(() => {
        this.availableSectionStatements = data;
      });
      /* eslint-disable no-empty */
    } catch {}
  };

  @action
  handleActiveLibraryConditionChange = async (conditionId: string) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    try {
      this.isLoadingActiveLibraryCondition = true;

      const { data } = await fetchOriginCondition(partnerId, conditionId);

      runInAction(() => {
        this.activeLibraryCondition = data;
      });
    } catch {
      try {
        const { data } = await fetchDefaultCondition(partnerId, conditionId);

        runInAction(() => {
          this.activeLibraryCondition = data;
        });
      } finally {
        runInAction(() => {
          this.isLoadingActiveLibraryCondition = false;
        });
      }
    } finally {
      runInAction(() => {
        this.isLoadingActiveLibraryCondition = false;
      });
    }
  };

  @action
  clearActiveLibraryCondition = () => {
    this.activeLibraryCondition = undefined;
  };

  @action
  clearCondition = () => {
    this.conditionId = undefined;
    this.originCondition = undefined;
    this.defaultCondition = undefined;
    this.conditionValidationStatus = {};
    this.isLoadingValidationStatus = false;
  };
}
