import { TranslationOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';

const Styled = {
  Container: styled.span`
    margin-left: 8px;

    svg {
      font-size: 16px;
      margin-right: 4px;
    }

    span {
      font-size: 12px;
    }
  `,
};

export const LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT =
  'LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT';
export const LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT =
  'LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT';

export const LanguageIcon = ({ language }: { language: string }) => {
  const onTranslationIconMouseEnter = useCallback<React.MouseEventHandler<HTMLSpanElement>>(
    function (e) {
      e.target.dispatchEvent(new Event(LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_ENTER_EVENT));
    },
    []
  );

  const onTranslationIconMouseLeave = useCallback<React.MouseEventHandler<HTMLSpanElement>>(
    function (e) {
      e.target.dispatchEvent(new Event(LOCALIZED_FIELD_TRANSLATION_ICON_MOUSE_LEAVE_EVENT));
    },
    []
  );

  return (
    <Styled.Container
      onMouseEnter={onTranslationIconMouseEnter}
      onMouseLeave={onTranslationIconMouseLeave}
    >
      <Typography.Text type="secondary">
        <TranslationOutlined />
        <span>{language.charAt(0).toUpperCase() + language.slice(1)}</span>
      </Typography.Text>
    </Styled.Container>
  );
};
