import { Modal, Divider, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { ConditionSearchTerms } from 'modules/Content24/Condition/api/partnerCode24api';
import { sortWithLocale } from 'utils/textUtils';

import styles from './AddEditToplistItem.module.css';
import { ToplistItem } from '../../api/toplistApi';

interface Props {
  initialValues?: ToplistItem;
  isSaving: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (data: ToplistItem) => void;
  onDelete: (uuid: string) => void;
  isDisabled?: boolean;
  availableLanguages: string[];
  conditionSearchTerms: ConditionSearchTerms[];
}

const AddEditToplistItem: FunctionComponent<Props> = ({
  isSaving,
  isLoading,
  onCancel,
  initialValues,
  onSubmit,
  isDisabled,
  availableLanguages,
  conditionSearchTerms,
}) => {
  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });

  const validationSchema = Yup.object().shape({
    conditionId: Yup.string().required(requiredErrorMessage),
    searchTermId: Yup.string().required(requiredErrorMessage),
  });

  const { originStore } = useContext(RootStoreContext);

  const [activeLanguage, setLanguage] = useState<LANGS>(
    originStore.currentOrigin?.defaultLanguage || (intl.locale as LANGS)
  );

  const conditionOptions = useMemo(
    () =>
      conditionSearchTerms
        .map(({ id, description }) => ({
          value: id,
          label: description[activeLanguage] || id,
        }))
        .sort((a, b) => sortWithLocale(a, b, 'label')),
    [conditionSearchTerms, activeLanguage]
  );

  const formLayouts = {
    sideBySide: {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
      },
    },
  };

  const isFormDisabled = isSaving || isLoading || isDisabled;

  if (!initialValues) {
    return null;
  }

  return (
    <Modal
      open
      destroyOnClose
      title={
        <ModalTitleWithLanguageSwitcher
          title={
            initialValues && initialValues.searchTermId ? (
              <FormattedMessage id="general.edit" />
            ) : (
              <FormattedMessage id="origin.toplist.add-item" />
            )
          }
          activeLanguage={activeLanguage}
          onLanguageChange={setLanguage}
          availableLanguages={originStore.availableOriginLanguages}
        />
      }
      footer={null}
      closable={false}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isValid, dirty, setFieldValue, values }) => {
          const searchTermOptions = (
            conditionSearchTerms.find(({ id }) => id === values.conditionId)?.searchTerms || []
          )
            .map(({ id, term }) => ({
              value: id,
              label: term[activeLanguage] || id,
            }))
            .sort((a, b) => sortWithLocale(a, b, 'label'));

          return (
            <Form layout="vertical">
              <Form.Item
                name="conditionId"
                required
                label={<FormattedMessage id="general.condition" />}
              >
                <Select
                  name="conditionId"
                  disabled={isFormDisabled || isLoading}
                  onChange={() => {
                    setFieldValue('searchTermId', undefined);
                  }}
                  showSearch
                  optionFilterProp="label"
                  options={conditionOptions}
                  loading={isLoading}
                />
              </Form.Item>
              <Form.Item
                name="searchTermId"
                required
                label={<FormattedMessage id="origin.toplist.searchTermId" />}
              >
                <Select
                  name="searchTermId"
                  disabled={isFormDisabled || isLoading}
                  options={searchTermOptions}
                  loading={isLoading}
                />
              </Form.Item>
              <Typography.Title level={4}>
                <FormattedMessage id="origin.toplist.description" />
              </Typography.Title>
              {availableLanguages.map(lang => (
                <Form.Item
                  key={lang}
                  name={`description.${lang}`}
                  label={<FormattedMessage id={`general.language-${lang}`} />}
                  className={styles.horizontalItem}
                  {...formLayouts.sideBySide}
                >
                  <Input
                    name={`description.${lang}`}
                    disabled={isFormDisabled || !values.conditionId || !values.searchTermId}
                  />
                </Form.Item>
              ))}
              <Divider />
              <FormActionButtons
                showCancelConfirm={dirty}
                isSaving={isSaving}
                isDisabled={isDisabled}
                isValid={isValid && dirty}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEditToplistItem;
