import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import { REPORT_STATUSES } from 'modules/Content24/constants/reports';
import StateManager from 'stores/abstractStores/StateManager';
import RootStore from 'stores/RootStore';

import {
  createCode24Test,
  deleteCode24Test,
  fetchUnitTestReports,
  fetchCode24Tests,
  createCode24TestReport,
  fetchLastCompletedUnitTestReport,
} from '../api/code24TestsApi';

export interface Code24Test {
  id: string;
  name: string;
  success?: boolean;
}

export type PollingReport = { isRunningReport: boolean; completedReportDate: string };

export const POLLING_INTERVAL = 2000;

export default class Code24TestsStore extends StateManager {
  @observable results: IObservableArray<Code24Test> = observable.array([]);
  @observable isReportPending = false;
  @observable searchQuery = '';
  @observable lastReportDate = '';
  reactionDisposer: IReactionDisposer;
  pollingStatus?: Promise<PollingReport>;
  pollingInterval?: NodeJS.Timeout;

  constructor(private rootStore: RootStore) {
    super();

    this.reactionDisposer = reaction(
      () => !!this.isReportPending,
      isPending => this.togglePolling(isPending)
    );
  }

  togglePolling(isActive: boolean) {
    if (isActive && !this.pollingInterval) {
      this.startPolling();
    } else if (!isActive && this.pollingInterval) {
      this.stopPolling();
    }
  }

  startPolling() {
    this.pollingInterval = setInterval(async () => {
      if (!this.pollingStatus) {
        this.pollingStatus = this.fetchReportStatus();
        const reportStatus = await this.pollingStatus;
        if (!reportStatus.isRunningReport) {
          this.stopPolling();
          this.fetchCode24Tests();
        }
        this.pollingStatus = undefined;
      }
    }, POLLING_INTERVAL);
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = undefined;
    }
  }

  dispose() {
    this.stopPolling();
    this.reactionDisposer();
  }

  @computed
  get filteredResults() {
    return this.results.filter(({ id }) => id.toLowerCase().includes(this.searchQuery));
  }

  async fetchReportStatus() {
    const reportStatus = { isRunningReport: false, completedReportDate: '' };

    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      const { data: reportsData } = await fetchUnitTestReports(partnerId);
      const reversedReportsData = reportsData.reverse();

      if (reversedReportsData.length && reversedReportsData[0].status === REPORT_STATUSES.RUNNING) {
        reportStatus.isRunningReport = true;
        return reportStatus;
      }

      const completedReport = reversedReportsData.find(
        report => report.status === REPORT_STATUSES.COMPLETE
      );

      if (completedReport) {
        reportStatus.completedReportDate = completedReport.createdAt;
      }

      return reportStatus;
    } catch {
      return reportStatus;
    }
  }

  @action
  fetchCode24Tests = async () => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.setLoading();

      const { data: testsData } = await fetchCode24Tests(partnerId);
      const reportStatus = await this.fetchReportStatus();

      runInAction(() => {
        this.isReportPending = reportStatus.isRunningReport;
      });

      if (!reportStatus.isRunningReport && reportStatus.completedReportDate) {
        const { data: reportData } = await fetchLastCompletedUnitTestReport(partnerId);

        runInAction(() => {
          this.lastReportDate = reportStatus.completedReportDate;
          this.results.replace(
            testsData
              .map<Code24Test>(result => {
                const unitTestReport = reportData.find(item => item.id === result.id) || {};

                return { ...result, ...unitTestReport };
              })
              .sort((x, y) => Number(y.success) - Number(x.success))
          );
        });
      } else {
        runInAction(() => {
          this.results.replace(testsData);
          this.lastReportDate = '';
        });
      }
      /* eslint-disable no-empty */
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  @action
  createCode24TestsReport = async () => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.setLoading();

      await createCode24TestReport(partnerId);

      runInAction(() => {
        this.lastReportDate = '';
        this.isReportPending = true;
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  @action
  handleDeleteCode24Test = async (testId: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.setLoading();

      await deleteCode24Test(partnerId, testId);

      runInAction(() => {
        this.results.replace(this.results.filter(({ id }) => id !== testId));
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  @action
  handleCreateCode24Test = async (interviewId: string) => {
    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      this.setLoading();

      const response = await createCode24Test(partnerId, interviewId);
      runInAction(() => {
        this.results.push(response.data);
      });
      return response;
    } catch {
      return false;
    } finally {
      this.setLoaded();
    }
  };

  @action
  handleSearch = (query: string) => {
    this.searchQuery = query;
  };
}
