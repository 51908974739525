import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import style from './BoxContent.module.css';
import { useBoolean } from '../../hooks/useBoolean';

interface Props {
  title: string | React.ReactNode;
  tooltip?: string;
  topRightContent?: React.ReactNode;
  topContent?: React.ReactNode;
  children?: React.ReactNode;
  isPending?: boolean;
  isCollapsible?: boolean;
}

const BoxContent = ({
  title,
  tooltip,
  topContent,
  topRightContent,
  children,
  isPending,
  isCollapsible,
}: Props) => {
  const { value: isExpanded, toggle } = useBoolean(true);
  const collapsableDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!collapsableDiv.current) return;
    collapsableDiv.current.style.maxHeight = isExpanded
      ? `${collapsableDiv.current.scrollHeight}px`
      : '0px';
  }, [isExpanded, children]);

  return (
    <div className={classNames(style.container, { [style.pending]: isPending })}>
      <div className={style.top}>
        <Flex justify="space-between" align="center">
          <div>
            <Typography.Text>{title}</Typography.Text>
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircleOutlined />
              </Tooltip>
            )}
          </div>
          <Flex>
            {topRightContent}
            {isCollapsible && (
              <DownOutlined
                onClick={toggle}
                className={classNames(style.chevron, { [style.rotated]: isExpanded })}
              />
            )}
          </Flex>
        </Flex>
        <div>{topContent}</div>
      </div>
      <div
        ref={collapsableDiv}
        className={classNames(style.collapseWrapper, { [style.animatedHeight]: isCollapsible })}
      >
        <div className={style.bottom}>{children}</div>
      </div>
    </div>
  );
};

export default BoxContent;
