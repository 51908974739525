import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { DEV_LOGIN_CREDENTIALS } from 'constants/env';

import CollectNorwegianBankId from '../CollectNorwegianBankId';
import DevLogin from '../DevLogin';
import LoginMethods from '../LoginMethods';
import SelectPartner from '../SelectPartner';
import SSOAuthCollect from '../SSOAuthCollect';

export const LoginRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}/dev`}
        component={DEV_LOGIN_CREDENTIALS.length ? DevLogin : () => <Redirect to={path} />}
      />
      <Route exact path={`${path}/collect/norwegian-bank-id`} component={CollectNorwegianBankId} />
      <Route exact path={`${path}/select-partner`} component={SelectPartner} />
      <Route exact path={`${path}/practitioner-auth/collect`} component={SSOAuthCollect} />
      <Route exact path={path} component={LoginMethods} />
      <Route path={path} render={() => <Redirect to={path} />} />
    </Switch>
  );
};
