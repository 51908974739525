import { observable, computed, action, runInAction, ObservableSet, toJS } from 'mobx';

const HSAID_STORAGE_KEY = 'HSAIDs';

export default class HSAIDstore {
  @observable ids = new ObservableSet<string>();

  constructor() {
    this.loadHSAIDsFromLocalStorage();
  }

  @computed
  get HSAIDs() {
    return Array.from(this.ids);
  }

  @action
  setHSAID = (id: string) => {
    if (!this.ids.has(id)) {
      this.ids.add(id);
      this.saveHSAIDsToLocalStorage();
    }
  };

  @action
  removeHSAID = (id: string) => {
    if (this.ids.has(id)) {
      runInAction(() => {
        this.ids.delete(id);
        this.saveHSAIDsToLocalStorage();
      });
    }
  };

  loadHSAIDsFromLocalStorage() {
    const storedIds = localStorage.getItem(HSAID_STORAGE_KEY);
    if (storedIds) {
      this.ids.replace(JSON.parse(storedIds));
    }
  }

  saveHSAIDsToLocalStorage() {
    localStorage.setItem(HSAID_STORAGE_KEY, JSON.stringify(Array.from(toJS(this.ids))));
  }
}
