import { Divider, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import {
  DISABLED_CONDITIONS,
  DISABLED_EXIT_ATTRIBUTES,
  EXIT_ATTRIBUTES,
} from 'modules/Content24/Condition/constants/code24types';
import { sortWithLocale } from 'utils/textUtils';

import { Filter } from '../../stores/StatisticsStore';

/**
 * @notExported
 */
interface ApplyFilterModalProps {
  isVisible: boolean;
  initialValues: Filter;
  onSubmit: (sample: Filter) => any;
  onClose: () => any;
}

const ApplyFilterModal: FunctionComponent<ApplyFilterModalProps> = ({
  isVisible,
  initialValues,
  onSubmit,
  onClose,
}) => {
  const { locale } = useIntl();
  const { content24Store, partnersStore, conditionsListStore } = useContext(RootStoreContext);

  return (
    <Modal
      open={isVisible}
      destroyOnClose
      title={<FormattedMessage id="statistics.apply-filter" />}
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={toJS(initialValues)}
        onSubmit={onSubmit}
        validateOnChange
        render={({ isValid, dirty }) => (
          <Observer>
            {() => {
              const {
                capability,
                typeOfConsultation,
                resource,
                appointmentPriority,
                urgency,
                levelOfCare,
              } = toJS(content24Store.exitAttributes);
              const availableExitAttributes =
                partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_EXIT_ATTRIBUTES') || [];
              const isResourceEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.RESOURCE);
              const isCapabilityEnabled = availableExitAttributes.includes(
                EXIT_ATTRIBUTES.CAPABILITY
              );
              const isTypeOfConsultationEnabled = availableExitAttributes.includes(
                EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION
              );
              const isAppointmentPriorityEnabled = availableExitAttributes.includes(
                EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY
              );
              const isUrgencyEnabled = availableExitAttributes.includes(EXIT_ATTRIBUTES.URGENCY);
              const isLevelOfCareEnabled = availableExitAttributes.includes(
                EXIT_ATTRIBUTES.LEVEL_OF_CARE
              );
              const conditions = conditionsListStore.conditions
                .filter(
                  ({ group, conditionId }) =>
                    group !== 'libraries' && !DISABLED_CONDITIONS.includes(conditionId)
                )
                .map(({ description, conditionId }) => ({
                  value: conditionId,
                  label: description[locale] || conditionId,
                }))
                .sort((a, b) => sortWithLocale(a, b, 'label', locale));
              const resources = isResourceEnabled
                ? resource
                    .sort((a, b) => sortWithLocale(a, b, `display.${locale}`))
                    .map(({ id, display }) => ({ value: id, label: display[locale] || id }))
                : [];
              const consultationTypes = isTypeOfConsultationEnabled
                ? typeOfConsultation
                    .filter(({ id }) => !DISABLED_EXIT_ATTRIBUTES.includes(id))
                    .sort((a, b) => sortWithLocale(a, b, `display.${locale}`))
                    .map(({ id, display }) => ({ value: id, label: display[locale] || id }))
                : [];
              const appointmentPriorities = isAppointmentPriorityEnabled
                ? appointmentPriority.map(({ id, display }) => ({
                    value: id,
                    label: display[locale] || id,
                  }))
                : [];
              const capabilities = isCapabilityEnabled
                ? capability
                    .sort((a, b) => sortWithLocale(a, b, `display.${locale}`))
                    .map(({ id, display }) => ({ value: id, label: display[locale] || id }))
                : [];
              const urgencies = isUrgencyEnabled
                ? urgency
                    .sort((a, b) => sortWithLocale(a, b, `display.${locale}`))
                    .map(({ id, display }) => ({ value: id, label: display[locale] || id }))
                : [];
              const levelsOfCare = isLevelOfCareEnabled
                ? levelOfCare
                    .sort((a, b) => sortWithLocale(a, b, `display.${locale}`))
                    .map(({ id, display }) => ({ value: id, label: display[locale] || id }))
                : [];
              const isExitAttributeLoading = content24Store.isLoading();

              return (
                <Form layout="vertical">
                  {isCapabilityEnabled && (
                    <Form.Item
                      name="capabilities"
                      label={<FormattedMessage id="condition-edit.capability-label" />}
                    >
                      <Select
                        name="capabilities"
                        options={capabilities}
                        mode="multiple"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                      />
                    </Form.Item>
                  )}
                  {isResourceEnabled && (
                    <Form.Item
                      name="resources"
                      label={<FormattedMessage id="condition-edit.resource-label" />}
                    >
                      <Select
                        name="resources"
                        options={resources}
                        mode="multiple"
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                      />
                    </Form.Item>
                  )}
                  <Form.Item name="sources" label={<FormattedMessage id="general.condition" />}>
                    <Select
                      name="sources"
                      options={conditions}
                      mode="multiple"
                      placeholder={<FormattedMessage id="statistics.all-conditions" />}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      loading={conditionsListStore.isLoading}
                    />
                  </Form.Item>
                  {isTypeOfConsultationEnabled && (
                    <Form.Item
                      name="typesOfConsultation"
                      label={<FormattedMessage id="condition-edit.typeOfConsultation-label" />}
                    >
                      <Select
                        name="typesOfConsultation"
                        mode="multiple"
                        options={consultationTypes}
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                        allowClear
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  )}
                  {isUrgencyEnabled && (
                    <Form.Item
                      name="urgencies"
                      label={<FormattedMessage id="condition-edit.urgency-label" />}
                    >
                      <Select
                        name="urgencies"
                        mode="multiple"
                        options={urgencies}
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                        allowClear
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  )}
                  {isLevelOfCareEnabled && (
                    <Form.Item
                      name="levelsOfCare"
                      label={<FormattedMessage id="condition-edit.levelOfCare-label" />}
                    >
                      <Select
                        name="levelsOfCare"
                        mode="multiple"
                        options={levelsOfCare}
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                        allowClear
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  )}
                  {isAppointmentPriorityEnabled && (
                    <Form.Item
                      name="appointmentPriorities"
                      label={<FormattedMessage id="condition-edit.appointmentPriority-label" />}
                    >
                      <Select
                        name="appointmentPriorities"
                        mode="multiple"
                        options={appointmentPriorities}
                        disabled={isExitAttributeLoading}
                        loading={isExitAttributeLoading}
                        allowClear
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  )}
                  <Divider />
                  <FormActionButtons
                    isValid={isValid && dirty}
                    onCancel={onClose}
                    showCancelConfirm={dirty}
                    isDisabled={isExitAttributeLoading}
                  />
                </Form>
              );
            }}
          </Observer>
        )}
      />
    </Modal>
  );
};

export default ApplyFilterModal;
