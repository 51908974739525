import { routes } from '@platform24/admin-ui-utils';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Spinner } from 'components/Spinner';
import RootStoreContext from 'context/RootStoreContext';
import ArticlePage from 'modules/Articles/ArticlePage/components/ArticlePage';
import ArticlesList from 'modules/Articles/ArticlesList';
import PartnerStatus from 'modules/PartnerStatus';

import Code24TestsList from './Code24Tests';
import { ConditionContent } from './Condition/ConditionContent';
import ConditionsList from './ConditionsList';
import styles from './Content24.module.css';
import EditSelfcare from './Selfcare/EditSelfcare/EditSelfcare';
import SelfcareList from './Selfcare/SelfcareList/SelfcareList';
import StatisticsList from './Statistics';

const Content24 = () => {
  const { content24Store, conditionsListStore } = useContext(RootStoreContext);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function init() {
      // the order is important here, as the first store holds most important configuration data
      await content24Store.initialize();
      await conditionsListStore.initialize();

      setIsReady(true);
    }

    init();
  }, []);

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <PartnerStatus />
      <div className={styles.container}>
        <Switch>
          <Route exact path={routes.content24.questionnares.fullPath} component={ConditionsList} />
          <Route path="/content24/questionnaires/:id" component={ConditionContent} />
          <Route exact path={routes.content24.selfCare.list.fullPath} component={SelfcareList} />
          <Route path={routes.content24.selfCare.view.fullPath} component={EditSelfcare} />
          <Route path="/content24/statistics" component={StatisticsList} />
          <Route path="/content24/test" component={Code24TestsList} />
          <Route exact path={routes.content24.articles.list.fullPath} component={ArticlesList} />
          <Route path={routes.content24.articles.view.fullPath} component={ArticlePage} />
          <Redirect from="/content24" to={routes.content24.selfCare.list.fullPath} />
        </Switch>
      </div>
    </Fragment>
  );
};

export default Content24;
