import { useSetAtom } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import RootStoreContext from 'context/RootStoreContext';
import { partnerStatusAtoms } from 'state/partnerStatus/partnerStatus.atoms';

export const PartnerStatusAdapter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { partnerStatusStore } = rootStore;

  const { partnerStatus, isLoading, isTriggeringPipeline, partnerGitStatus, isGitStatusLoaded } =
    partnerStatusStore;

  const setPartnerStatusState = useSetAtom(partnerStatusAtoms.root);

  useEffect(() => {
    setPartnerStatusState({
      partnerStatus,
      isLoading,
      isTriggeringPipeline,
      partnerGitStatus,
      isGitStatusLoaded,
    });
  }, [
    isGitStatusLoaded,
    isLoading,
    isTriggeringPipeline,
    partnerGitStatus,
    partnerStatus,
    setPartnerStatusState,
  ]);

  return null;
});
