import { Button } from 'antd';
import { useSetAtom } from 'jotai';
import React from 'react';

import { jotaiStore } from 'config/jotaiStore';

import { ImageModal } from './ImageModal';
import { ImageModalState, imageModalStateAtom } from './ImageModal.state';

export const ImageModalButton = ({
  disabled,
  onSelect,
  mode,
  imageType,
  onDelete,
  data,
  label,
}: ImageModalState & { disabled: boolean; label: string | React.ReactElement }) => {
  const showImageSelectModal = useSetAtom(imageModalStateAtom);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() =>
          showImageSelectModal({
            mode,
            onSelect,
            imageType,
            onDelete,
            data,
          })
        }
      >
        {label}
      </Button>
      <ImageModal />
    </>
  );
};

export const showImageModal = (modalState: ImageModalState) => {
  jotaiStore.set(imageModalStateAtom, modalState);
};
