import { Input, Modal, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  isVisible: boolean;
  url?: string;
  onCancel: () => any;
  onSubmit: (url: string) => any;
}

const LinkModal: FunctionComponent<Props> = ({ isVisible, onCancel, url, onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    (values: Store) => {
      onSubmit(values.url);
    },
    [onSubmit]
  );

  useEffect(() => {
    form.setFieldsValue({
      url: url,
    });
  }, [form, url]);

  return (
    <Modal
      forceRender
      open={isVisible}
      destroyOnClose
      title={<FormattedMessage id="general.save" />}
      okText={<FormattedMessage id="general.save" />}
      okButtonProps={{
        shape: 'round',
        htmlType: 'submit',
      }}
      cancelButtonProps={{
        shape: 'round',
      }}
      cancelText={<FormattedMessage id="general.cancel" />}
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(values => {
          form.resetFields();
          handleSubmit(values);
        });
      }}
    >
      <Form form={form} onFinish={handleSubmit} layout="inline" initialValues={{ url }}>
        <Form.Item label={<FormattedMessage id="general.value" />} name="url">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LinkModal;
