import { useSetAtom } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import RootStoreContext from 'context/RootStoreContext';
import { content24Atoms } from 'state/content24';

export const Content24Adapter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { content24Store } = rootStore;
  const {
    questionCategories,
    availableLanguages,
    hiddenConditions,
    healthDataCodes,
    serviceRequestCodes,
    isLoadingHealthDataCodes,
    isLoadingServiceRequestCodes,
    maxOriginConditions,
    isDiagnosisCodesShown,
  } = content24Store;

  const setContent24RootAtomValue = useSetAtom(content24Atoms.root);

  useEffect(() => {
    setContent24RootAtomValue({
      questionCategories: questionCategories.toJS(),
      availableLanguages: availableLanguages.toJS(),
      hiddenConditions: hiddenConditions.toJS(),
      healthDataCodes: healthDataCodes.toJS(),
      serviceRequestCodes: serviceRequestCodes.toJS(),
      isLoadingHealthDataCodes: isLoadingHealthDataCodes,
      isLoadingServiceRequestCodes: isLoadingServiceRequestCodes,
      maxOriginConditions: maxOriginConditions,
      isDiagnosisCodesShown: isDiagnosisCodesShown,
    });
  }, [
    setContent24RootAtomValue,
    questionCategories,
    availableLanguages,
    hiddenConditions,
    healthDataCodes,
    serviceRequestCodes,
    isLoadingHealthDataCodes,
    isLoadingServiceRequestCodes,
    maxOriginConditions,
    isDiagnosisCodesShown,
  ]);

  return null;
});
