import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';

import {
  ExpressionValidationResult,
  Rule,
  RuleDraft,
  RulesAttribute,
  RulesPackage,
  RulesPackageMetaDraft,
} from '../types';

export const fetchRulesPackagesList = async (partnerId: string) =>
  axios.get<RulesPackage[]>(`${PARTNERS_URI}/${partnerId}/rules/v2/package`);

export const fetchRulesPackage = async (partnerId: string, packageId: string) =>
  axios.get<RulesPackage>(`${PARTNERS_URI}/${partnerId}/rules/v2/package/${packageId}`);

export const fetchRulesAttributes = async (partnerId: string) =>
  axios.get<RulesAttribute[]>(`${PARTNERS_URI}/${partnerId}/rules/v2/attribute`);

export const updateRule = async (
  partnerId: string,
  packageId: string,
  ruleId: string,
  data: Rule | RuleDraft
) =>
  axios.put<Rule>(
    `${PARTNERS_URI}/${partnerId}/rules/v2/package/${packageId}/rule/${ruleId}`,
    data
  );

export const createRule = async (partnerId: string, packageId: string, data: Rule | RuleDraft) =>
  axios.post<Rule>(`${PARTNERS_URI}/${partnerId}/rules/v2/package/${packageId}/rule`, data);

export const deleteRule = async (partnerId: string, packageId: string, ruleId: string) =>
  axios.delete(`${PARTNERS_URI}/${partnerId}/rules/v2/package/${packageId}/rule/${ruleId}`);

export const updateRulesPackage = async (
  partnerId: string,
  packageId: string,
  data: Partial<RulesPackage>
) => axios.put<RulesPackage>(`${PARTNERS_URI}/${partnerId}/rules/v2/package/${packageId}`, data);

export const createRulesPackage = async (
  partnerId: string,
  { id, title, description }: RulesPackageMetaDraft,
  copyOfId?: string
) =>
  axios.post<RulesPackage>(
    `${PARTNERS_URI}/${partnerId}/rules/v2/package`,
    { id, title, description },
    { params: copyOfId ? { copyOf: copyOfId } : undefined }
  );

export const validateCondition = (partnerId: string, expression: string) =>
  axios.get<ExpressionValidationResult>(
    `${PARTNERS_URI}/${partnerId}/rules/v2/expression/validate`,
    { params: { expression } }
  );
