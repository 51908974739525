import { Origin } from 'api/originsApi';
import { LANGS } from 'constants/enums';

export default class OriginModel implements Origin {
  id = '';
  name = '';
  clinicName = '';
  patientUrl = '';
  hostname = '';
  urlSuffix = '';
  practitionerUrl = '';
  email = '';
  bankIdDisplayName = '';
  smsDisplayName = '';
  paymentMethod = '';
  originGroup = '';
  careProviderId = '';
  careProviderName = '';
  payor = '';
  defaultLanguage = LANGS.SV;
  parentOriginId = '';
  countryCode = '';
  aliases = [{ alias: '', primary: false }];
  hostnames = [{ hostname: '', primary: false }];

  constructor(data?: Partial<Origin>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
