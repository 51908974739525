import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { Fragment } from 'react';

import styles from './ErrorTooltip.module.css';

interface ErrorTooltipProps {
  errors: string[];
}

const ErrorTooltip = ({ errors }: ErrorTooltipProps) => (
  <Tooltip
    placement="right"
    title={
      <Fragment>
        {errors.length > 1 ? (
          <ul className={styles.errorList}>
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        ) : (
          errors[0]
        )}
      </Fragment>
    }
  >
    <ExclamationCircleFilled className={classNames([styles.validationErrorIcon, styles.rowIcon])} />
  </Tooltip>
);

export default ErrorTooltip;
