import { Row, Col, Input, Tag, Tooltip, AutoComplete, Button } from 'antd';
import capitalize from 'lodash/capitalize';
import React, { useState, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';

import { CustomTagInputProps, isList } from 'modules/Rules/types';
import { getInputMetaProps } from 'modules/Rules/utils/utils';

import styles from '../RuleForm.module.css';

export const CustomTagInput = ({
  onBlur,
  value = [],
  isDisabled,
  outcomeMeta,
  onAdd,
  onRemove,
  allowedValues = [],
}: CustomTagInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState('');

  const { formatMessage } = useIntl();

  const onAutocompleteSelectHandler = (data: string) => {
    onAdd(data);
    setAutocompleteValue('');
  };
  const onAutocompleteChangeHandler = (data: string) => {
    setAutocompleteValue(data);
  };

  const onTagRemoveHandler = (index: number) => {
    onRemove(index);
  };

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const onInputConfirmHandler = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    if (inputValue) {
      onAdd(inputValue);
      setInputValue('');
    }
  };

  const inputMeta = getInputMetaProps(outcomeMeta);

  return (
    <>
      <Row gutter={[16, 16]}>
        {allowedValues.length > 0 && isList(outcomeMeta) ? (
          <Col span={24}>
            <AutoComplete
              // TODO: double keyboard enter press in autocomplete form trigger form submit. probably should be fixed
              allowClear
              filterOption
              value={autocompleteValue}
              disabled={isDisabled}
              options={allowedValues}
              onSelect={onAutocompleteSelectHandler}
              onChange={onAutocompleteChangeHandler}
              placeholder={formatMessage({ id: 'rules.placeholders.autocomplete' })}
              notFoundContent={formatMessage({ id: 'rules.placeholders.autocomplete.empty' })}
            />
          </Col>
        ) : (
          <>
            <Col flex="auto">
              <Input
                {...inputMeta}
                allowClear
                disabled={isDisabled}
                value={inputValue}
                onChange={onInputChangeHandler}
                onPressEnter={onInputConfirmHandler}
                onBlur={onBlur}
                placeholder={formatMessage({ id: 'rules.placeholders.tags' })}
              />
            </Col>
            <Col flex="0 0 auto">
              <Button
                type={'primary'}
                onClick={onInputConfirmHandler}
                disabled={isDisabled || !inputValue}
                shape="round"
              >
                {formatMessage({ id: 'rules.placeholders.tags.addValue' })}
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <Col>
          {value?.map((tag, index) => {
            const tagToDisplay = capitalize(String(tag));
            const isLongTag = tagToDisplay.length > 20;
            const tagElem = (
              <Tag
                className={styles.tag}
                key={`${tagToDisplay}-${index}`}
                onClose={() => !isDisabled && onTagRemoveHandler(index)}
                closable
                aria-disabled={isDisabled}
              >
                {isLongTag ? `${tagToDisplay.slice(0, 20)}...` : tagToDisplay}
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tagToDisplay} key={tagToDisplay}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
        </Col>
      </Row>
    </>
  );
};
