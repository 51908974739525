import { Spin, Typography } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Overlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  `,
};

export const EnduringProgressLoader = ({
  className,
  label,
  withOverlay = true,
}: {
  className?: string;
  label?: React.ReactNode;
  withOverlay?: boolean;
}) => {
  const loader = (
    <Styled.Container className={className}>
      <Spin percent={'auto'} size="large" />

      {label && <Typography.Text strong>{label}</Typography.Text>}
    </Styled.Container>
  );

  return withOverlay ? <Styled.Overlay>{loader}</Styled.Overlay> : loader;
};
