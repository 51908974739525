import { atom } from 'jotai';

import { ImageData } from './ImageModal';

export interface ImageModalState {
  data?: ImageData;
  mode?: 'select' | 'edit';
  imageType?: 'thumbnail' | 'content';
  onSelect?: (data: Pick<ImageData, 'src' | 'imageName'>) => void;
  onDelete?: (src: string) => void;
}

export const imageModalStateAtom = atom<ImageModalState>({});
