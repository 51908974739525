import { Row, Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import uuid4 from 'uuid/v4';
import * as Yup from 'yup';

import { CareUnit } from 'api/careUnitsApi';
import FormActionButtons from 'components/FormActionButtons';
import { BASIC_DETAILS_FORM_FIELDS } from 'constants/careUnits';
import { LETTERS_NUMBERS_SPACES_DASHES_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { validateLatitude, validateLongitude } from 'utils/geolocationUtils';

import styles from './CareUnitDetailsForm.module.css';

interface Props extends WrappedComponentProps {
  initialValues?: CareUnit;
  onSubmit: (data: CareUnit) => any;
  isSaving: boolean;
  isDisabled?: boolean;
  onCancel?: () => any;
}

@observer
class CareUnitDetailsForm extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  static defaultProps = {
    initialValues: {
      id: '',
      name: '',
      externalOrgId: '',
      externalOrgIdType: 'HSA_ID',
      visitingAddress: '',
      latitude: 0,
      longitude: 0,
      bookingSystem: '',
      location: '',
      phoneNumber: '',
      careProviderName: '',
      careProviderId: '',
      defaultOriginId: '',
      regionId: '',
      uuid: uuid4(),
    },
  };

  get reservedExternalOrgIds(): string[] {
    const { partnersStore, careUnitStore } = this.context;
    const currentCareUnitId = careUnitStore.careUnit?.id;

    return partnersStore.currentPartnerCareUnits
      .filter(careUnit => careUnit.id !== currentCareUnitId)
      .map(cu => {
        return cu.externalOrgId;
      });
  }

  validationSchema = Yup.object().shape({
    [BASIC_DETAILS_FORM_FIELDS.NAME]: Yup.string().matches(
      LETTERS_NUMBERS_SPACES_DASHES_REGEX,
      () => (
        <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
      )
    ),
    [BASIC_DETAILS_FORM_FIELDS.EXTERNAL_ORG_ID]: Yup.string()
      .required(() => <FormattedMessage id="general.errors.required" />)
      .test({
        name: 'Unique HSAID validator',
        message: () => <FormattedMessage id="general.errors.already-in-use" />,
        test: value => !this.reservedExternalOrgIds.includes(value),
      }),
    [BASIC_DETAILS_FORM_FIELDS.LATITUDE]: Yup.string().test({
      name: 'Latitude validator',
      message: () => <FormattedMessage id="basic-details-form.wrong-value-error" />,
      test: value => !value || validateLatitude(value),
    }),
    [BASIC_DETAILS_FORM_FIELDS.LONGITUDE]: Yup.string().test({
      name: 'Longitude validator',
      message: () => <FormattedMessage id="basic-details-form.wrong-value-error" />,
      test: value => !value || validateLongitude(value),
    }),
  });

  render() {
    const { isSaving, isDisabled, initialValues, onSubmit, onCancel } = this.props;
    const isFormDisabled = isSaving || isDisabled;
    const isEditMode = !!initialValues?.id;

    const initialValuesWithDefaults = {
      ...initialValues,
      latitude: initialValues?.latitude || '',
      longitude: initialValues?.longitude || '',
      phoneNumber: initialValues?.phoneNumber || '',
      location: initialValues?.location || '',
      defaultOriginId: initialValues?.defaultOriginId || '',
    } as CareUnit;

    return (
      <Formik
        initialValues={initialValuesWithDefaults!}
        enableReinitialize
        validationSchema={this.validationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<CareUnit>) => (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.NAME}
                  label={<FormattedMessage id="basic-details-form.name-label" />}
                >
                  <Input
                    name={BASIC_DETAILS_FORM_FIELDS.NAME}
                    disabled={isFormDisabled || isEditMode}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.EXTERNAL_ORG_ID}
                  label={<FormattedMessage id="basic-details-form.hsaid-label" />}
                  required
                  hasFeedback
                >
                  <Input
                    data-testid="externalOrgId"
                    name={BASIC_DETAILS_FORM_FIELDS.EXTERNAL_ORG_ID}
                    disabled={isFormDisabled || isEditMode}
                    aria-disabled={isFormDisabled || isEditMode}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.DEFAULT_ORIGIN_ID}
                  label={<FormattedMessage id="basic-details-form.default-origin-label" />}
                >
                  <Input
                    name={BASIC_DETAILS_FORM_FIELDS.DEFAULT_ORIGIN_ID}
                    disabled={isFormDisabled || isEditMode}
                    data-testid="defaultOriginId"
                    aria-disabled={isFormDisabled || isEditMode}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.VISITING_ADDRESS}
                  label={<FormattedMessage id="basic-details-form.address-label" />}
                >
                  <Input
                    name={BASIC_DETAILS_FORM_FIELDS.VISITING_ADDRESS}
                    disabled={isFormDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.LOCATION}
                  label={<FormattedMessage id="basic-details-form.location-label" />}
                >
                  <Input name={BASIC_DETAILS_FORM_FIELDS.LOCATION} disabled={isFormDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.PHONE_NUMBER}
                  label={<FormattedMessage id="basic-details-form.phone-number-label" />}
                >
                  <Input name={BASIC_DETAILS_FORM_FIELDS.PHONE_NUMBER} disabled={isFormDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.LATITUDE}
                  label={<FormattedMessage id="basic-details-form.latitude-label" />}
                >
                  <Input
                    type="number"
                    name={BASIC_DETAILS_FORM_FIELDS.LATITUDE}
                    disabled={isFormDisabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={6}>
                <Form.Item
                  name={BASIC_DETAILS_FORM_FIELDS.LONGITUDE}
                  label={<FormattedMessage id="basic-details-form.longitude-label" />}
                >
                  <Input
                    type="number"
                    name={BASIC_DETAILS_FORM_FIELDS.LONGITUDE}
                    disabled={isFormDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.submit}>
              {!initialValues?.id && onCancel && (
                <FormActionButtons
                  isSaving={isSaving}
                  isValid={props.isValid}
                  isDisabled={isDisabled}
                  onCancel={onCancel}
                />
              )}
              {initialValues?.id && !isDisabled && (
                <SubmitButton
                  shape="round"
                  key="submit"
                  loading={isSaving}
                  disabled={isSaving || !(props.isValid && props.dirty)}
                >
                  <FormattedMessage id="general.save" />
                </SubmitButton>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default injectIntl(CareUnitDetailsForm);
