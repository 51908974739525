import { Skeleton } from 'antd';
import React from 'react';

import withRootStoreProp from 'components/HOC/withRootStoreProp';
import RootStore from 'stores/RootStore';
import { usePrivateFederationBaseProps } from 'utils/remoteModules/remoteModuleUtils';

import RemoteComponent from '../../utils/remoteModules/remoteComponent';

const ArticlesList = ({ rootStore }: { rootStore: RootStore }) => {
  const props = usePrivateFederationBaseProps(rootStore);

  return (
    <React.Suspense fallback={<Skeleton />}>
      <RemoteComponent suspense module="pathwaysManage/ArticlesList" props={props} />
    </React.Suspense>
  );
};

export default withRootStoreProp(ArticlesList);
