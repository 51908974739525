import classNames from 'classnames';
import { FieldArrayRenderProps } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FormattedMessage } from 'react-intl';

import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { QuestionResponse } from 'modules/Content24/Condition/models/Code24Model';

import { QuestionFormData } from '../../QuestionForm';
import itemStyles from '../QuestionContentItem/QuestionContentItem.module.css';

/**
 * @notExported
 */
enum DND_ITEM_TYPES {
  RESPONSE = 'response',
}

/**
 * @notExported
 */
interface DraggedItem {
  type: DND_ITEM_TYPES;
  dragIndex: number;
  data: QuestionResponse;
}

interface HeaderProps {
  data: QuestionResponse;
  index: number;
  formikArrayHelpers: FieldArrayRenderProps;
  isDisabled?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({
  data,
  index,
  isDisabled,
  formikArrayHelpers,
}) => {
  const values: QuestionFormData = formikArrayHelpers.form.values;
  const content = values.content || [];
  const isLastItemInList = index === content.length - 1;
  const isDraggable =
    !(isLastItemInList && data.type === CODE24_MODEL_TYPES.LAST_RESPONSE) && !isDisabled;

  const handleMoveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      formikArrayHelpers.move(dragIndex, hoverIndex);
    },
    [formikArrayHelpers]
  );

  const [{ isDragging }, drag] = useDrag({
    item: { type: DND_ITEM_TYPES.RESPONSE, dragIndex: index, data: data },
    canDrag: () => isDraggable,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DND_ITEM_TYPES.RESPONSE,
    canDrop: (item: DraggedItem) => item.dragIndex !== index && isDraggable,
    drop: (item: DraggedItem) => handleMoveItem(item.dragIndex, index),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drag}
      className={classNames(itemStyles.header, {
        [itemStyles.isDragged]: isDragging,
        [itemStyles.isOver]: isOver && canDrop,
      })}
    >
      <div ref={drop} className={itemStyles.container}>
        <FormattedMessage id={`condition.type-${data.type}`} />{' '}
        {'responseId' in data && data.responseId}
      </div>
    </div>
  );
};
export default Header;
