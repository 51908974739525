import React from 'react';

import { Content24Adapter } from './content24/content24.MobxAdapter';
import { OriginAdapter } from './origin/origin.MobxAdapter';
import { PartnersStoreAdapter } from './partners/partners.MobxAdapter';
import { PartnerStatusAdapter } from './partnerStatus/partnerStatus.MobxAdapter';
import { UserPermissionsAdapter } from './userPermissions/userPermissions.MobxAdapter';

export const MobxToJotaiAdapters = () => (
  <>
    <PartnersStoreAdapter />
    <PartnerStatusAdapter />
    <UserPermissionsAdapter />
    <Content24Adapter />
    <OriginAdapter />
  </>
);
