import axios from 'axios';

import { IDENTITIES } from 'constants/practitioner';

export interface ExternalId {
  externalIdType: IDENTITIES;
  externalId: string;
}

export interface RolePayload {
  role: string;
}

export interface User {
  id: string;
  primaryExternalId: string;
  primaryExternalIdType: IDENTITIES;
  givenName: string;
  middleAndSurname: string;
  externalIds: ExternalId[];
  email?: string;
  mobileNumber?: string;
}

export interface SelectableNameIT {
  id: string;
  name: string;
}

export interface ClinicRole {
  id: string;
  capability?: string;
  careUnit?: {
    id: string;
    name: string;
  };
  roleType?: string;
}
export interface SelectableRoleIT {
  id: string;
  selectable: boolean;
}

const BASE_URL = '/rest/admin/v2/permissions';

export const fetchSelectablePartners = () => axios.get(`${BASE_URL}/selectable-partners`);

export const fetchSelectableScopes = () => axios.get(`${BASE_URL}/selectable-scopes`);

export const fetchSelectableCareProviders = (partnerId: string) =>
  axios.get(`${BASE_URL}/partners/${partnerId}/selectable-careproviders`);

export const fetchSelectableCareUnits = ({
  partnerId,
  careProviderId,
}: {
  partnerId: string;
  careProviderId: string;
}) =>
  axios.get(
    `${BASE_URL}/partners/${partnerId}/careproviders/${careProviderId}/selectable-careunits`
  );

export const fetchCareUnitSelectableRoles = ({
  partnerId,
  careProviderId,
  careUnitId,
  practitionerId,
}: {
  partnerId: string;
  careProviderId: string;
  careUnitId: string;
  practitionerId: string;
}) =>
  axios.get(
    `${BASE_URL}/partners/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/selectable-roles?practitionerId=${practitionerId}`
  );

export const fetchSelectableOrigins = (partnerId: string) =>
  axios.get(`${BASE_URL}/partners/${partnerId}/selectable-origins`);

export const fetchOriginSelectableRoles = ({
  partnerId,
  originId,
  practitionerId,
}: {
  partnerId: string;
  originId: string;
  practitionerId: string;
}) =>
  axios.get(
    `${BASE_URL}/partners/${partnerId}/origins/${originId}/selectable-roles?practitionerId=${practitionerId}`
  );

export const createOriginRoles = (
  {
    partnerId,
    originId,
    practitionerId,
  }: {
    partnerId: string;
    originId: string;
    practitionerId: string;
  },
  payload: RolePayload
) =>
  axios.post(
    `${BASE_URL}/partners/${partnerId}/origins/${originId}/practitioners/${practitionerId}/roles`,
    payload
  );

export const createCareUnitRoles = (
  {
    partnerId,
    careProviderId,
    careUnitId,
    practitionerId,
  }: {
    partnerId: string;
    careUnitId: string;
    careProviderId: string;
    practitionerId: string;
  },
  payload: RolePayload
) =>
  axios.post(
    `${BASE_URL}/partners/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/practitioners/${practitionerId}/roles`,
    payload
  );

export const deleteManageRoles = (practitionerId: string, roleIds: number[]) =>
  axios.patch(`${BASE_URL}/practitioners/${practitionerId}/delete-roles`, {
    roleIds,
  });

export const fetchAccessForMenuItems = () => axios.get(`${BASE_URL}/me/roles`);
