import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Input, Form } from 'formik-antd';
import React, { FunctionComponent, useContext, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';
import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';

import { TextContent } from '../api/textContentApi';

interface Props extends WrappedComponentProps {
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (data: TextContent) => void;
  initialValues?: TextContent;
  isDisabled?: boolean;
}

const EditTextContent: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  onSubmit,
  initialValues,
  isDisabled,
  intl,
}) => {
  const isFormDisabled = isSaving || isDisabled;

  const { originStore } = useContext(RootStoreContext);

  const [activeLanguage, setLanguage] = useState(
    originStore.currentOrigin?.defaultLanguage || (intl.locale as LANGS)
  );

  if (!initialValues) {
    return null;
  }

  return (
    <Modal
      open
      destroyOnClose
      title={
        <ModalTitleWithLanguageSwitcher
          title={<FormattedMessage id="origin.text-content.header" />}
          activeLanguage={activeLanguage}
          availableLanguages={originStore.availableOriginLanguages}
          onLanguageChange={setLanguage}
        />
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ dirty, isValid }) => (
          <Form layout="vertical">
            <Form.Item name={`translations.${activeLanguage}`}>
              <Input.TextArea
                name={`translations.${activeLanguage}`}
                rows={3}
                disabled={isFormDisabled}
              />
            </Form.Item>
            <Divider />
            <FormActionButtons
              isSaving={isSaving}
              isDisabled={isDisabled}
              showCancelConfirm={dirty}
              isValid={isValid && dirty}
              onCancel={onCancel}
            />
          </Form>
        )}
      />
    </Modal>
  );
};

export default injectIntl(EditTextContent);
