import React from 'react';

const Info = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="20.4"
    viewBox="0 0 470.000000 366.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,366.000000) scale(0.100000,-0.100000)"
      fill="#888"
      stroke="none"
    >
      <path
        d="M1945 3171 c-11 -5 -41 -10 -67 -10 -26 -1 -50 -5 -53 -11 -3 -5 -17
-10 -31 -10 -23 0 -33 -3 -81 -28 -13 -7 -30 -12 -38 -12 -8 0 -15 -4 -15 -9
0 -5 -9 -13 -20 -16 -11 -3 -26 -12 -32 -19 -18 -17 -176 -96 -194 -96 -8 0
-14 -4 -14 -10 0 -5 -11 -10 -25 -10 -14 0 -33 -4 -43 -9 -37 -20 -161 -47
-284 -62 -97 -12 -242 -3 -338 21 -32 8 -47 12 -123 27 -15 3 -27 9 -27 14 0
5 -13 9 -29 9 -16 0 -31 5 -33 12 -2 6 -10 8 -16 4 -17 -11 -17 -2467 0 -2484
7 -7 22 -12 34 -12 13 0 25 -4 28 -9 3 -5 18 -11 33 -14 16 -3 60 -13 98 -22
88 -22 446 -27 513 -7 23 6 60 17 82 22 85 21 125 34 135 42 5 4 19 8 32 8 13
0 23 5 23 10 0 6 8 10 18 10 17 0 131 56 142 70 3 4 25 17 50 31 25 13 47 27
48 32 2 4 12 7 22 7 10 0 21 4 24 9 3 5 20 12 38 15 18 4 56 12 83 18 88 19
315 10 315 -13 0 -5 14 -9 30 -9 17 0 30 -4 30 -10 0 -5 6 -10 13 -10 8 0 28
-7 45 -16 30 -14 69 -12 82 5 7 10 106 42 165 54 92 18 315 8 315 -14 0 -5 14
-9 30 -9 17 0 30 -4 30 -10 0 -5 7 -10 15 -10 17 0 51 -18 72 -37 7 -6 22 -15
33 -18 11 -3 20 -11 20 -16 0 -5 9 -9 20 -9 11 0 20 -4 20 -10 0 -5 9 -10 20
-10 11 0 20 -4 20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 9 -10 19 -10
11 0 23 -4 26 -10 3 -5 17 -10 31 -10 13 0 24 -4 24 -10 0 -5 14 -10 30 -10
17 0 30 -4 30 -9 0 -5 15 -11 33 -14 17 -3 52 -11 77 -17 135 -34 449 -34 580
0 25 6 60 14 78 17 18 3 35 9 38 14 3 5 15 9 28 9 12 0 27 5 34 12 15 15 18
2488 3 2488 -6 0 -13 -4 -16 -10 -3 -5 -15 -10 -26 -10 -10 0 -27 -4 -37 -9
-37 -20 -161 -47 -284 -62 -82 -10 -114 -10 -215 4 -178 24 -270 44 -279 58
-3 5 -14 9 -25 9 -10 0 -27 4 -37 9 -74 37 -106 51 -118 51 -8 0 -14 4 -14 9
0 5 -8 11 -17 14 -20 6 -75 38 -83 48 -9 12 -89 49 -105 49 -8 0 -15 4 -15 9
0 5 -17 12 -37 15 -143 24 -184 29 -233 29 -80 -1 -179 -14 -186 -24 -3 -5
-16 -9 -30 -9 -23 0 -33 -3 -81 -28 -28 -15 -60 -15 -68 -2 -3 6 -15 10 -26
10 -10 0 -19 5 -19 10 0 6 -15 10 -34 10 -19 0 -37 4 -40 9 -10 16 -233 34
-261 22z m240 -128 c22 -5 57 -18 78 -27 l37 -18 0 -1120 0 -1120 -27 6 c-16
4 -46 12 -68 18 -44 13 -328 15 -387 3 -21 -4 -38 -11 -38 -16 0 -5 -13 -9
-30 -9 -16 0 -30 -4 -30 -10 0 -5 -7 -10 -15 -10 -14 0 -53 -18 -65 -30 -10
-10 -65 -41 -82 -47 -10 -3 -18 -9 -18 -14 0 -5 -9 -9 -20 -9 -11 0 -20 -4
-20 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -7 -10 -15 -10 -8 0
-25 -5 -38 -12 -49 -25 -58 -28 -82 -28 -14 0 -25 -4 -25 -10 0 -5 -17 -10
-38 -10 -21 0 -42 -4 -47 -9 -17 -16 -104 -23 -265 -23 -176 -1 -330 20 -357
48 -10 10 -13 234 -13 1128 l0 1116 30 0 c17 0 30 -4 30 -9 0 -5 26 -12 58
-16 85 -12 440 -10 497 3 142 31 222 56 304 96 30 14 61 26 68 26 7 0 13 5 13
10 0 6 6 10 13 10 7 0 22 8 33 18 24 21 137 77 184 90 19 6 49 15 65 20 36 13
254 9 310 -5z m665 0 c46 -11 141 -44 150 -53 3 -3 15 -11 28 -17 12 -7 32
-19 45 -25 12 -7 24 -15 27 -18 6 -6 131 -67 170 -83 94 -38 104 -40 235 -69
58 -13 435 -15 513 -3 29 4 55 12 58 16 3 5 12 9 21 9 43 0 42 21 43 -1125 0
-1211 5 -1128 -67 -1138 -21 -3 -67 -12 -103 -19 -81 -16 -324 -16 -410 1 -36
6 -82 14 -102 18 -21 3 -38 9 -38 14 0 5 -11 9 -25 9 -14 0 -33 4 -43 9 -16 8
-31 15 -99 43 -18 7 -33 16 -33 20 0 5 -9 8 -20 8 -11 0 -20 5 -20 10 0 6 -9
10 -20 10 -11 0 -20 4 -20 9 0 5 -13 14 -30 21 -16 7 -30 15 -30 19 0 3 -15
12 -32 19 -18 7 -41 18 -50 23 -10 5 -27 9 -38 9 -11 0 -20 4 -20 9 0 20 -214
39 -329 29 -87 -7 -126 -15 -159 -31 -19 -9 -25 -8 -32 3 -6 9 -10 462 -10
1111 0 1231 -7 1123 75 1146 22 6 56 16 75 22 42 11 227 8 290 -6z"
      />
      <path
        d="M3265 2507 c-22 -7 -44 -15 -50 -19 -54 -41 -75 -62 -75 -74 0 -8 -3
-14 -8 -14 -11 0 -32 -75 -32 -115 0 -71 71 -168 141 -192 45 -16 112 -16 158
-1 73 25 141 121 141 197 0 68 -45 160 -89 183 -14 7 -28 16 -31 19 -6 7 -95
30 -107 28 -4 -1 -26 -6 -48 -12z"
      />
      <path
        d="M2900 1870 l0 -50 86 0 c61 0 90 -4 100 -14 12 -13 14 -66 12 -328
l-3 -313 -85 -3 c-46 -2 -90 -8 -97 -14 -7 -6 -13 -25 -13 -43 0 -70 -30 -65
416 -65 l404 0 0 60 0 60 -90 0 -90 0 0 380 0 380 -320 0 -320 0 0 -50z"
      />
    </g>
  </svg>
);

export default Info;
