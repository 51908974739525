import { action, observable, runInAction } from 'mobx';

import {
  fetchPractitioner,
  Practitioner,
  updatePractitioner,
  createPractitioner,
  ExtendedRole,
  resendPractitionerInvite,
  resetPractitionerPassword,
} from 'api/practitionerApi';
import { HasUUID } from 'types/types';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export interface PractitionerStoreData extends Omit<Practitioner, 'extendedRoles'> {
  extendedRoles: ExtendedRole & HasUUID[];
}

export default class PractitionerStore extends StateManager {
  rootStore: RootStore;
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable data: PractitionerStoreData | { [key: string]: any } = {}; // TODO make it nullable

  constructor(rootStore: RootStore) {
    super();
    this.rootStore = rootStore;
  }

  fetch = async (id: string) => {
    if (!id) {
      return;
    }

    this.setLoading();

    try {
      const { data } = await fetchPractitioner(id);

      runInAction(() => {
        this.data = data;
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  resendInvite = async (id: string) => {
    if (!id) {
      return;
    }

    this.setLoading();
    try {
      await resendPractitionerInvite(id);
    } catch (e) {
      this.manageException(e);
    } finally {
      this.setLoaded();
    }
  };

  resetPassword = async (id: string) => {
    if (!id) {
      return;
    }

    this.setLoading();
    try {
      await resetPractitionerPassword(id);
    } catch (e) {
      this.manageException(e);
    } finally {
      this.setLoaded();
    }
  };

  save = action((practitioner: Practitioner) => {
    const practitionerData = !this.data.id ? practitioner : { ...this.data, ...practitioner };
    const { externalId, externalIdType } = practitionerData.externalIds[0];

    practitionerData.externalId = externalId;
    practitionerData.externalIdType = externalIdType;

    // @ts-ignore FIXME: sending extendedRoles and careUnit drives BE crazy and it results in altering their values, which is not wanted here. This should be fixed in a better way when moving to the new user roles in M24
    delete practitionerData.extendedRoles;
    // @ts-ignore FIXME: sending extendedRoles and careUnit drives BE crazy and it results in altering their values, which is not wanted here. This should be fixed in a better way when moving to the new user roles in M24
    delete practitionerData.careUnit;

    if (practitionerData.id) {
      return this.update(practitionerData);
    }
    return this.create(practitionerData);
  });

  update = async (practitioner: Practitioner) => {
    this.setSaving();

    try {
      const { data } = await updatePractitioner(practitioner);

      runInAction(() => {
        this.data = data;
      });

      this.rootStore.practitionersStore.upsert(data);

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }

    return this.data;
  };

  create = async (practitioner: Practitioner) => {
    this.setSaving();

    try {
      const { data } = await createPractitioner(practitioner);

      runInAction(() => {
        this.data = data;
      });

      this.rootStore.practitionersStore.upsert(data);

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }

    return this.data;
  };

  refetchPractitioner = async (practitionerId: string) => {
    this.setLoading();
    try {
      const { data } = await fetchPractitioner(practitionerId);

      runInAction(() => {
        this.data = data;
      });

      if (this.rootStore.userDataStore.id === this.data.id) {
        this.rootStore.userPermissionsStore.updateRoles(this.data.roles);
      }
      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @action
  clear = () => {
    this.data = {};
  };
}
