import { TestContext } from 'yup';

import { validateCondition } from '../api/rules';

export async function validateRuleCondition(
  testContext: TestContext,
  condition: string,
  partnerId: string
) {
  if (!condition) {
    return false;
  }

  try {
    const {
      data: { valid, message },
    } = await validateCondition(partnerId, condition);

    if (valid) {
      return true;
    }

    const error = testContext.createError({
      params: { message },
    });

    return error;
  } catch {
    return false;
  }
}
