import {
  CaretRightOutlined,
  EnterOutlined,
  LogoutOutlined,
  NodeExpandOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Input, List, Tag, Typography } from 'antd';
import classNames from 'classnames';
import { Observer } from 'mobx-react';
import React, { ChangeEvent, FunctionComponent, useCallback, useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';

import styles from './Exits.module.css';
import { ExitNodeData, NodeDependency } from '../../stores/ConditionVisualizationStore';
import { getResponseNodeId, getText } from '../../utils';

/**
 * @notExported
 */
interface ExitsProps {
  onExitClick: (exit: ExitNodeData) => void;
  onDependencyClick: (id: string) => void;
  exits: ExitNodeData[];
  height?: number;
  width?: number;
  selectedNodeId: string | undefined;
  conditionId?: string;
}

const Exits: FunctionComponent<ExitsProps> = ({
  onExitClick,
  onDependencyClick,
  height,
  width,
  exits,
  selectedNodeId,
  conditionId,
}) => {
  const intl = useIntl();
  const { conditionVisualizationStore } = useContext(RootStoreContext);
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value || '');
  }, []);

  const handleExitBtnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, exit: ExitNodeData) => {
      event.stopPropagation();
      onExitClick(exit);
    },
    [onExitClick]
  );

  const handleDependencyClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: string, exit: ExitNodeData) => {
      event.stopPropagation();
      conditionVisualizationStore.setNodeNavigation(false);

      const exitIsNotSelected = !selectedNodeId || (selectedNodeId && selectedNodeId !== exit.id);

      if (exitIsNotSelected) {
        onExitClick(exit);
      }

      onDependencyClick(id);
    },
    [conditionVisualizationStore, onDependencyClick, onExitClick, selectedNodeId]
  );

  const matchesSearch = useCallback(
    (exit: ExitNodeData) => {
      if (!searchText) {
        return true;
      }

      return (
        (exit.exitModel.condition || '').toLowerCase().includes(searchText.toLowerCase()) ||
        getText(exit.text, intl).toLowerCase().includes(searchText.toLowerCase())
      );
    },
    [intl, searchText]
  );

  const breakingExits = exits.filter(e => e.breaking && matchesSearch(e));
  const nonBreakingExits = exits.filter(e => !e.breaking && matchesSearch(e));

  const groupDependencies = useCallback((dependencies: NodeDependency[] | undefined) => {
    if (!dependencies) {
      return [];
    }

    const groupedDependencies = dependencies.reduce(
      (accumulator: { [key: string]: NodeDependency[] }, dep: NodeDependency) => {
        const group = accumulator[dep.questionId];
        if (group) {
          accumulator[dep.questionId].push(dep);
        } else {
          accumulator[dep.questionId] = [dep];
        }
        return accumulator;
      },
      {}
    );

    return Object.keys(groupedDependencies).map(key => ({
      questionId: key,
      responses: groupedDependencies[key],
    }));
  }, []);

  const renderExit = useCallback(
    (exit: ExitNodeData) => {
      const hasDependencies = exit.dependencies && exit.dependencies.length > 0;
      const exitTypeClass = exit.breaking ? styles.breakingExit : styles.exit;
      const isFromExternalCondition = exit.source !== conditionId;

      return {
        key: exit.id + exit.source,
        collapsible: !hasDependencies ? 'disabled' : 'header',
        showArrow: hasDependencies,
        className: classNames(styles.exitItem, {
          [styles.exitItemFromExternalCondition]: isFromExternalCondition,
        }),
        label: (
          <div className={styles.exitItemHeader}>
            <LogoutOutlined className={classNames(styles.exitIcon, exitTypeClass)} />
            <div className={styles.exitItemTitle}>
              <p>{exit.exitModel.condition}</p>
              <p>{getText(exit.text, intl)}</p>
            </div>
            {hasDependencies && (
              <Button
                shape="circle"
                size="small"
                icon={<NodeExpandOutlined />}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleExitBtnClick(event, exit)
                }
                className={styles.dependenciesButton}
              />
            )}
          </div>
        ),
        children: hasDependencies ? (
          <List
            itemLayout="horizontal"
            dataSource={groupDependencies(exit.dependencies)}
            renderItem={({ questionId, responses }) => (
              <List.Item
                className={classNames(styles.dependencyItem, exitTypeClass)}
                key={questionId}
                onClick={event => handleDependencyClick(event, questionId, exit)}
              >
                <List.Item.Meta
                  avatar={<QuestionCircleOutlined className={styles.questionIcon} />}
                  title={<div className={styles.dependencyTitle}>{questionId}</div>}
                  description={responses.map(resp => (
                    <div
                      className={styles.dependencyDescription}
                      key={getResponseNodeId(questionId, resp.responseId)}
                    >
                      <EnterOutlined className={styles.responseIcon} />
                      {resp.not ? 'NOT' : ''} {resp.responseId}
                    </div>
                  ))}
                />
              </List.Item>
            )}
          />
        ) : null,
      };
    },
    [conditionId, groupDependencies, handleDependencyClick, handleExitBtnClick, intl]
  );

  return (
    <Observer>
      {() => {
        const exitItems: CollapseProps['items'] = [];
        if (breakingExits.length > 0) {
          exitItems.push({
            key: 'breaking-exit',
            collapsible: 'header',
            className: styles.topLevelCollapse,
            label: (
              <Tag className={styles.breakingExitColors}>
                <FormattedMessage id="condition.visualization.legend.breaking-exit" />
              </Tag>
            ),
            children: (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={breakingExits.map(renderExit) as CollapseProps['items']}
              />
            ),
          });
        }

        if (nonBreakingExits.length > 0) {
          exitItems.push({
            key: 'exit',
            label: (
              <Tag className={styles.exitColors}>
                <FormattedMessage id="condition.visualization.legend.exit" />
              </Tag>
            ),
            collapsible: 'header',
            className: styles.topLevelCollapse,
            children: (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={nonBreakingExits.map(renderExit) as CollapseProps['items']}
              />
            ),
          });
        }

        return (
          <div className={styles.wrapper} style={{ height, width }}>
            <Typography.Title level={4}>
              <FormattedMessage id="condition.visualization.exits.title" />
            </Typography.Title>
            <Input
              placeholder={intl.formatMessage({
                id: 'condition.visualization.exits.serach',
              })}
              onChange={handleSearchChange}
            />
            <div className={styles.exitList}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                defaultActiveKey={['breaking-exit', 'exit']}
                items={exitItems}
              />
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default Exits;
