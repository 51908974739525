import { InputOption } from 'types/types';

export function findOptionInSelectOptions(options: InputOption[], value: string | number) {
  return options.find(opt => opt.value === value);
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getOptionLabelByKey(options: InputOption[], value: any, key: string) {
  const found = findOptionInSelectOptions(options, value);
  return found ? found[key] : value;
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getOptionLabel(options: InputOption[], value: any) {
  return getOptionLabelByKey(options, value, 'label');
}

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSelectOptions = (options: any[]): InputOption<any>[] => {
  return options.map(opt => ({
    label: opt.toString(),
    value: opt,
  }));
};
