import { UrlPrefix, initEventAnalytics } from '@platform24/analytics';
import axios from 'axios';
import { useEffect } from 'react';

import { rootStore } from 'config/appConfig';

import { sendV2Event } from './utils';

// note that if error is on the page itself, it would crash before than any of
// 'useEffect' hooks would be called
// and analytics won't be set
// therefore we setup analytics globally
initEventAnalytics({
  //     sendAnalyticsEvent(event, partnersStore.currentPartner.id, userDataStore.id);
  //  const { partnersStore, userDataStore } = this.rootStore;
  fetchingClient: (url: string, data: unknown) =>
    axios.post(url, data, {
      headers: {
        'X-Origin': rootStore.partnersStore?.currentPartner?.id || 'unknown', // analytics for whatever reason requires this header
      },
    }),
  prefix: UrlPrefix.REST,
});

export const InitAnalytics = () => {
  useEffect(() => {
    sendV2Event({
      name: 'initial-screen-dimensions',
      scope: 'client-info',
      properties: {
        screenHeight: screen.height,
        screenWidth: screen.width,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        zoom: window.devicePixelRatio,
      },
    });
  }, []);

  return null;
};
