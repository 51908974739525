import { Button, Popover } from 'antd';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { useSwitchToDefaultLanguage } from '../LanguageTabs/LanguageTabs.state';

const Styled = {
  SwitchToDefaultLanguageButton: styled(Button)`
    padding-left: 2px;

    span {
      text-decoration: underline;
      color: #127373 !important;
    }

    &:hover {
      span {
        text-decoration: none;
      }
    }
  `,
  PopoverContent: styled.div`
    max-width: 280px;
  `,
};

/**
 * Wrapper that adds a tooltip about unlocalized fields being disabled
 * outside the default language tab, along with a button to switch to
 * the default language tab.
 *
 * If not enabled it simply returns the provided children.
 */
export const UnlocalizedFieldOutsideDefaultLangContainer = ({
  active = true,
  children,
}: {
  active?: boolean;
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}) => {
  const switchToDefaultLanguageTab = useSwitchToDefaultLanguage();

  return active ? (
    <Popover
      content={
        <Styled.PopoverContent>
          <div>
            <FormattedMessage id="languages.uneditable-field-outside-default" />
          </div>

          {switchToDefaultLanguageTab ? (
            <Styled.SwitchToDefaultLanguageButton type="link" onClick={switchToDefaultLanguageTab}>
              <FormattedMessage id="languages.switch-to-default" />
            </Styled.SwitchToDefaultLanguageButton>
          ) : null}
        </Styled.PopoverContent>
      }
    >
      <div>{children}</div>
    </Popover>
  ) : (
    children
  );
};
