export const MAX_CHILDREN_IN_ROW = 5;

export const MARGIN = { top: 30, left: 30, right: 30, bottom: 70 };
export const SECTION = {
  header: {
    height: 40,
  },
  bodyPadding: {
    top: 30,
    bottom: 30,
    right: 30,
    left: 30,
  },
};
export const NODE_WIDTH = 400;
export const NODE_HEIGHT = 100;
export const GUTTER = { x: 20, y: 50 };
export const BASE_NODE_X = 0;
export const TOPBAR_HEIGHT = 30;
