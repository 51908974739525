import { Formik, FormikProps } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Origin, OriginSave } from 'api/originsApi';
import FormActionButtons from 'components/FormActionButtons';
import { BASIC_DETAILS_FORM_FIELDS as FORM_FIELDS } from 'constants/origins';
import {
  validateSmsSender,
  validateNoSpaces,
  validateNoUppercase,
  validateAlphanumericAndHyphens,
} from 'utils/validationUtils';

import BasicDetails from './components/BasicDetails';
import styles from './OriginsDetailsForm.module.css';
import { LETTERS_NUMBERS_SPACES_DASHES_REGEX } from '../../../../constants/regex';

interface Props {
  initialValues: Origin;
  isSaving: boolean;
  onSubmit: (data: OriginSave) => void;
  onCancel?: () => void;
  isDisabled: boolean;
}

const idValidator = {
  name: 'Id is valid',
  message: () => <FormattedMessage id="origin.basic-details-form.id-error" />,
  test: (value: string) =>
    validateNoSpaces(value) && validateAlphanumericAndHyphens(value) && validateNoUppercase(value),
};

const OriginsDetailsForm: FunctionComponent<Props> = ({
  initialValues,
  onSubmit,
  isDisabled,
  isSaving,
  onCancel,
}) => {
  const { parentOriginId } = useParams<{ originId?: string; parentOriginId?: string }>();

  const validationSchema = Yup.object().shape({
    [FORM_FIELDS.ID]: Yup.string()
      .required(() => <FormattedMessage id="general.errors.required" />)
      .test(idValidator),
    [FORM_FIELDS.NAME]: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () => (
      <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
    )),
    [FORM_FIELDS.CLINIC_NAME]: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () => (
      <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
    )),
    [FORM_FIELDS.BANK_ID_DISPLAY_NAME]: Yup.string().matches(
      LETTERS_NUMBERS_SPACES_DASHES_REGEX,
      () => (
        <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
      )
    ),
    [FORM_FIELDS.SMS_DISPLAY_NAME]: Yup.string().test({
      name: 'SMS sender',
      message: () => <FormattedMessage id="origin.basic-details-form.sms-sender-error" />,
      test: value => validateSmsSender(value),
    }),
    [FORM_FIELDS.EMAIL]: Yup.string().email(() => (
      <FormattedMessage id="general.email-validation-error" />
    )),
  });

  const handleSubmit = (data: Origin) => {
    onSubmit(data);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      // Origin details can change (e.g. from root to sub origin) after component is mounted,
      // so the form should react to this change.
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<Origin>) => (
        <Form className="ant-form-vertical">
          <BasicDetails
            isSaving={isSaving}
            parentOriginId={parentOriginId || ''}
            isDisabled={isDisabled}
            isNew={!initialValues.id}
          />
          <div className={styles.submit}>
            {!initialValues.id && onCancel && (
              <FormActionButtons isSaving={isSaving} isValid={props.isValid} onCancel={onCancel} />
            )}
            {initialValues.id && (
              <SubmitButton
                shape="round"
                key="submit"
                loading={isSaving}
                disabled={isSaving || isDisabled || !props.isValid}
              >
                <FormattedMessage id="general.save" />
              </SubmitButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OriginsDetailsForm;
