import { NormalComponents } from 'react-markdown/lib/complex-types';

import { Image } from 'components/Image';

// eslint-disable-next-line react/prop-types
export const MarkdownImage: NormalComponents['img'] = ({ src, alt }) => {
  if (src && alt) {
    return <Image src={src} alt={alt} />;
  }
  return null;
};
