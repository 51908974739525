import { MutableRefObject, useCallback, useRef } from 'react';

export function useRenderNode(): [
  MutableRefObject<HTMLDivElement | null>,
  (ref: HTMLDivElement | null) => void,
] {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }

    nodeRef.current = ref;
  }, []);

  return [nodeRef, setRef];
}
