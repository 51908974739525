import React, { useCallback, useEffect, useRef, useState } from 'react';

import { createRootIn16 } from './createRootIn16';
import { debug } from './debug';
import { CreateRootShape, isSelfMounted, SelfMountedRenderProps } from './remoteComponent.types';
import { useOnModuleLoaded } from './useOnModuleLoaded';
import { useRenderNode } from './useRenderNode';
import { useUnmountComponent } from './useUnmountComponent';
import { resolveModuleSuspenseV2, resolveModuleV2 } from '../resolveModule/resolveModule';

export interface RemoteComponentProps {
  children?: React.ReactNode;
  /**
   * Module name. Can include version ("microfrontend/federation@2")
   */
  module: string;
  /**
   * CSS class name, would be applied to wrapping div
   */
  className?: string;
  /**
   * styles, would be applied to wrapping div
   */
  style?: React.CSSProperties;
  /**
   * Props which would be passed to mounted component
   */
  props?: Record<string, unknown>;
  /**
   * Component which would be shown during loading
   */
  fallback?: React.ComponentType<unknown>;

  /**
   * error fallback
   * @deprecated please use suspense instead and handle crash manually. suspense API would bypass error
   *
   * @example
   * <ErrorBoundary fallback={<div>ERROR!</div>}>
   *    <React.Suspense fallback={<div>LOADING!</div>}>
   *      <RemoteComponent suspense module="foo/bar" />
   *    </React.Suspense>
   * </ErrorBoundary>
   */
  errorFallback?: React.ComponentType<unknown>;

  /**
   * Callback which would be called when module is loading
   */
  onLoad?: () => void;
  /**
   * will use React.Suspense API for loading
   */
  suspense?: boolean;
  /**
   * adapter for react 18. You can just do
   *
   * @example
   * import { createRoot } from 'react-dom/client';
   * <RemoteComponent createRoot={createRoot} ... />
   */
  createRoot?: CreateRootShape;
}

const EmptyComponent = () => null;

export const RemoteComponentNormal: React.FC<RemoteComponentProps> = ({
  className,
  style,
  module: moduleId,
  props,
  fallback: Fallback,
  children,
  onLoad,
  createRoot = createRootIn16,
  errorFallback,
}) => {
  const [ref, setNodeRef] = useRenderNode();
  const [error, setError] = useState<unknown>();
  const [Component, onModuleLoaded] = useOnModuleLoaded(ref, createRoot);
  const loaded = useRef(false);

  React.useEffect(() => {
    resolveModuleV2(moduleId)
      .then(mod => {
        // if ref.current is null -- we already unmounted component
        if (ref.current) {
          onModuleLoaded(mod);
        } else {
          debug(`RemoteComponent unmounted before it loaded module ${moduleId}`);
        }
      })
      .catch((e: unknown) => {
        setError(e);
      });
  }, [moduleId, ref, onModuleLoaded]);

  const bypassError = useCallback((e: unknown) => {
    setError(e);
  }, []);

  const FB = Fallback ?? EmptyComponent;
  const FailedFallback = (errorFallback ?? Fallback ?? EmptyComponent) as React.FC<{
    error?: unknown;
  }>;

  React.useEffect(() => {
    debug(
      `Rendering ${moduleId}. Is div and renderer ready? ${!!ref.current}. Is component ready: ${!!Component}, Is self mounted: ${isSelfMounted(
        Component
      )}`
    );
    // safety check
    if (ref.current && Component) {
      const data: SelfMountedRenderProps<unknown> = {
        children,
        props,
        Fallback: Fallback ?? EmptyComponent,
        bypassError,
      };

      Component.render(data);
      if (!loaded.current) {
        onLoad?.();
        loaded.current = true;
      }
    }
  }, [Component, Fallback, bypassError, children, moduleId, onLoad, props, ref]);

  useUnmountComponent(Component);

  const shouldShowOverlay = !Component || !!error;

  return (
    <>
      {shouldShowOverlay && (
        <div className={className} style={style}>
          {error ? <FailedFallback error={error} /> : <FB />}
        </div>
      )}
      <div className={className} style={style} ref={setNodeRef} />
    </>
  );
};

const RemoteComponentSuspense: React.FC<RemoteComponentProps> = ({
  className,
  style,
  module: moduleId,
  props,
  fallback: Fallback,
  children,
  onLoad,
  createRoot = createRootIn16,
}) => {
  const [ref, setNodeRef] = useRenderNode();
  const [error, setError] = useState<unknown>();

  const loaded = useRef(false);

  const [Component, onModuleLoaded] = useOnModuleLoaded(ref, createRoot);

  const LoadedComponent = resolveModuleSuspenseV2(moduleId);

  useEffect(() => {
    onModuleLoaded(LoadedComponent);
  }, [onModuleLoaded, LoadedComponent]);

  const bypassError = useCallback((e: unknown) => {
    setError(e);
  }, []);

  React.useEffect(() => {
    debug(
      `Rendering ${moduleId}. Is div and renderer ready? ${!!ref.current}. Is component ready: ${!!Component}, Is self mounted: ${isSelfMounted(
        Component
      )}`
    );
    if (ref.current && Component) {
      const data: SelfMountedRenderProps<unknown> = {
        children,
        props,
        Fallback: Fallback ?? EmptyComponent,
        bypassError,
      };
      Component.render(data);

      if (!loaded.current) {
        onLoad?.();
        loaded.current = true;
      }
    }
  }, [onLoad, Fallback, children, props, ref, moduleId, Component, bypassError]);

  useUnmountComponent(Component);

  if (error) {
    throw error;
  }

  return (
    <>
      <div className={className} style={style} ref={setNodeRef} />
    </>
  );
};

export default function RemoteComponent(props: RemoteComponentProps) {
  if (props.suspense) {
    return <RemoteComponentSuspense {...props} />;
  }

  return <RemoteComponentNormal {...props} />;
}
