// We can use the TimePicker component from antd with date-fns as the date library.
// source: https://ant.design/docs/react/use-custom-date-library

import { RangePickerTimeProps } from 'antd/lib/time-picker';
import React from 'react';

import DatePicker from './DatePicker';
import { useAntDLocale } from '../config/antdConfigProvider';

export type TimePickerProps = Omit<RangePickerTimeProps<Date>, 'picker'>;

const RangeTimePicker = React.forwardRef<never, TimePickerProps>((props, ref) => {
  const locale = useAntDLocale();

  return (
    <DatePicker.RangePicker
      {...props}
      locale={locale.DatePicker}
      picker="time"
      mode={undefined}
      ref={ref}
    />
  );
});

RangeTimePicker.displayName = 'TimePicker';

export default RangeTimePicker;
