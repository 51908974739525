import { Tag } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './StatusTag.module.css';

/**
 * @notExported
 */
interface StatusTagProps {
  status: 'modified' | 'localCopy';
  className?: string;
}

const StatusTag = ({ status, className = '' }: StatusTagProps) => {
  if (status === 'localCopy') {
    return (
      <Tag color="green" className={classNames(styles.tag, className)}>
        <FormattedMessage id="condition-list.local-copy" />
      </Tag>
    );
  }

  return (
    <Tag color="gold" className={classNames(styles.tag, className)}>
      <FormattedMessage id="general.modified" />
    </Tag>
  );
};

export default StatusTag;
