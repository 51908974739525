import escape from 'lodash/escape';
import get from 'lodash/get';
import { Converter } from 'showdown';

export function splitKebabCase(str: string): string {
  return str.split('-').join(' ');
}

export function maxLength(str: string, maxLength = 60): string {
  if (str.length <= 50) {
    return str;
  }

  return str.substring(0, maxLength) + '...';
}

export function capitalizeFirst(str?: string): string {
  if (!str) {
    return '';
  } else if (str.length === 0) {
    return str;
  }

  const firstLetter = str[0].toUpperCase();
  return firstLetter + str.substring(1);
}

// This utility converts Markdown markup to inline HTML.
// To prevent potential XSS, markdown is escaped to avoid certain characters.
export function markdownToInlineHtml(markdown: string) {
  const converter = new Converter();
  return converter.makeHtml(escape(markdown));
}

export function sortWithLocale(
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  a: string | { [key: string]: any },
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  b: string | { [key: string]: any },
  propertyPath?: string,
  locale?: string
) {
  const aValue = propertyPath ? get(a, propertyPath) : a;
  const bValue = propertyPath ? get(b, propertyPath) : b;

  // empty goes first

  if (!aValue) {
    return -1;
  }

  if (!bValue) {
    return 1;
  }

  return aValue?.toString().toLowerCase().localeCompare(bValue?.toString().toLowerCase(), locale);
}

export function getPermissionStringWildCardCombinations(basePath: string) {
  const parts = basePath.split('/');

  if (parts.length < 2) {
    return [basePath];
  }

  const result = [];
  const slots = Math.floor(parts.length / 2);

  for (let i = 0; i < 1 << slots; i++) {
    const variant = parts.slice();

    for (let j = 0; j < slots; j += 1) {
      variant[j * 2 + 1] = i & (1 << j) ? parts[j * 2 + 1] : '*';
    }

    result.push(variant.join('/'));
  }

  return result;
}
