export function removeNonDigitsFromPhoneNumber(phoneNumber: string) {
  if (!/^\d*$/.test(phoneNumber)) {
    return phoneNumber.replace(/\D+/g, '');
  }
  return phoneNumber;
}

export function removeStartingZeroFromPhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith('0')) {
    return phoneNumber.slice(1);
  }
  return phoneNumber;
}

export function addCountryCodeToPhoneNumber(phoneNumber: string, countryCode: string) {
  return `${countryCode}${phoneNumber}`;
}
