import {
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './BlockIcon.module.css';
import { CODE24_MODEL_TYPES } from '../../constants/code24types';
import { ConditionListItem } from '../../stores/ConditionViewStore';

/**
 * @notExported
 */
interface BlockIconProps {
  block: ConditionListItem;
}

const BlockIcon: FunctionComponent<BlockIconProps> = ({ block }) => {
  switch (block.type) {
    case CODE24_MODEL_TYPES.PRE_TRIAGE:
    case CODE24_MODEL_TYPES.POST_TRIAGE:
      return <HomeOutlined className={styles.blockIcon} />;
    case CODE24_MODEL_TYPES.INCLUDE:
    case CODE24_MODEL_TYPES.FORMULA:
    case CODE24_MODEL_TYPES.SEARCH_TERM:
    case CODE24_MODEL_TYPES.GOTO:
    case CODE24_MODEL_TYPES.DEFAULT_VALUES:
    case CODE24_MODEL_TYPES.CALL:
    case CODE24_MODEL_TYPES.CHECK:
    case CODE24_MODEL_TYPES.EXPORT_DATA:
    case CODE24_MODEL_TYPES.IMPORT_DATA:
      return <MenuFoldOutlined className={classNames(styles.blockIcon, styles.commonIcon)} />;
    case CODE24_MODEL_TYPES.QUESTION:
      return (
        <QuestionCircleOutlined className={classNames(styles.blockIcon, styles.questionIcon)} />
      );
    case CODE24_MODEL_TYPES.EXIT:
      return (
        <Fragment>
          {!('breaking' in block) && <LogoutOutlined className={styles.blockIcon} />}
          {'breaking' in block && block.breaking && (
            <Tooltip title={<FormattedMessage id="condition-edit.breaking-exit-label" />}>
              <LogoutOutlined className={classNames(styles.blockIcon, styles.breakingExitIcon)} />
            </Tooltip>
          )}
        </Fragment>
      );
    default:
      return null;
  }
};

export default BlockIcon;
