import React from 'react';

const MD = () => (
  <svg width="30" height="19" viewBox="0 0 30 19" fill="none">
    <g clipPath="url(#clip0_22274_24607)">
      <path
        fill="#888"
        d="M0 19V0.231135C0.0319621 0.18403 0.067691 0.13957 0.106831 0.0981829C0.133508 0.0779836 0.164398 0.0640012 0.197224 0.0572718C0.364229 0.0270983 0.533055 0.00796604 0.702598 0C10.3171 0 19.9302 0.00340655 29.5419 0.0102247C29.6404 0.0217275 29.7371 0.045789 29.8295 0.081816C29.856 0.107404 29.8807 0.134737 29.9035 0.163632C29.9265 0.187671 29.9428 0.217291 29.9507 0.249543C29.9712 0.519539 30 0.789536 30 1.06771C29.9918 6.9681 29.9815 12.8699 29.9692 18.773C29.9692 18.8323 29.9137 18.8895 29.8541 19.0082L0 19ZM0.986103 18.1266H29.092C29.092 15.2303 29.1022 12.3994 29.092 9.55216C29.0817 6.70492 29.1495 3.82496 29.0468 0.961355H0.986103V18.1266Z"
      />
    </g>
    <path
      fill="#888"
      d="M6.23158 15H4V4H7.48343L9.56728 12.6493L11.6356 4H15.0801V15H12.8485V7.5597C12.8485 7.34577 12.8511 7.04726 12.8563 6.66418C12.8615 6.27612 12.8641 5.97761 12.8641 5.76866L10.6947 15H8.36984L6.21602 5.76866C6.21602 5.97761 6.21862 6.27612 6.2238 6.66418C6.22898 7.04726 6.23158 7.34577 6.23158 7.5597V15Z"
    />
    <path
      fill="#888"
      d="M22.2414 15H17.3039V4H22.2414C22.9515 4.00995 23.5425 4.08955 24.0142 4.23881C24.8177 4.49254 25.4682 4.95771 25.9659 5.63433C26.365 6.18159 26.6371 6.77363 26.7823 7.41045C26.9274 8.04726 27 8.65423 27 9.23134C27 10.694 26.6942 11.9328 26.0825 12.9478C25.2531 14.3159 23.9727 15 22.2414 15ZM24.022 6.80597C23.6539 6.20896 22.9256 5.91045 21.8371 5.91045H19.6288V13.0896H21.8371C22.9671 13.0896 23.755 12.5547 24.2008 11.4851C24.4444 10.898 24.5663 10.199 24.5663 9.38806C24.5663 8.26866 24.3848 7.40796 24.022 6.80597Z"
    />
    <defs>
      <clipPath id="clip0_22274_24607">
        <rect width="30" height="19" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default MD;
