import { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import RootStoreContext from 'context/RootStoreContext';

import { ImageData } from './RichTextEditor/components/ImageModal';

type ImageProps = ImageData & {
  className?: string;
};

const Styled = {
  Image: styled.img`
    max-height: 100%;
    max-width: 100%;
  `,
};

export const isBlobImageSrc = (src: string) => src.startsWith('blob:');

export const Image = ({ src, alt, className }: Omit<ImageProps, 'imageName'>) => {
  const {
    partnersStore: { imageStore },
  } = useContext(RootStoreContext);
  const [imageSrc, setImageSrc] = useState('');
  const isBlob = isBlobImageSrc(src);

  useEffect(
    function fetchImageDataIfUsingAlreadyUploaded() {
      if (isBlob) {
        return;
      }

      let isCancelled = false;
      const fetcher = async (imageId: string) => {
        const fetchedImg = await imageStore.getImage(imageId);
        if (!isCancelled) {
          const imgSrc = URL.createObjectURL(fetchedImg);
          setImageSrc(imgSrc);
        }
      };

      if (src) {
        void fetcher(src);
      }

      return () => {
        isCancelled = true;
      };
    },
    // Should not react to imageStore changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [src, isBlob]
  );

  useEffect(
    () => () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    },
    [imageSrc]
  );

  if (isBlob) {
    return <Styled.Image src={src} alt={alt} />;
  }

  return <Styled.Image className={className} src={imageSrc} alt={alt} />;
};
