import { Alert } from 'antd';
import { isAxiosError } from 'axios';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorType, logError } from '../../../Analytics/utils';

interface Props {
  error: unknown;
}

export const ErrorBox = ({ error }: Props) => {
  useEffect(() => {
    if (isAxiosError(error)) {
      logError(error, { type: ErrorType.ERROR_BOX });
    }
  }, [error]);

  if (isAxiosError(error)) {
    if (error.response?.status === 404) {
      return (
        <Alert showIcon type="info" message={<FormattedMessage id="opening-hours.none-set" />} />
      );
    }
    return (
      <Alert
        showIcon
        type="error"
        message="Error"
        description={
          <>
            <p>
              <FormattedMessage id="general.error" />
            </p>
            {error.response?.data.message && <p>{error.response?.data.message}</p>}
          </>
        }
      />
    );
  }
  return (
    <Alert
      showIcon
      type="error"
      message="Error"
      description={<FormattedMessage id="general.error" />}
    />
  );
};
