import { Col } from 'antd';
import { FormikProps } from 'formik';
import { Input, Form, InputNumber } from 'formik-antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import StepField from '../../components/StepField';
import { QuestionFormData } from '../../QuestionForm';
import { getPrecisionForMaxMinValues, getStepForMaxMinValues } from '../../utils/utils';

/**
 * @notExported
 */
interface NumberOrRangeQuestionTypeProps {
  activeLanguage: string;
  isFormDisabled: boolean;
  isRangeQuestionType: boolean;
  formikProps: FormikProps<QuestionFormData>;
  isDisabled: boolean;
  isLoading: boolean;
}

const handleStepChange = (value: number, formikProps: FormikProps<QuestionFormData>) => {
  const { max, min } = formikProps.values;

  if (value > 1) {
    if (max && max < value) {
      formikProps.setFieldValue('max', max * 10);
    } else if (max && max > value) {
      formikProps.setFieldValue('max', max / 10);
    }

    if (min && min < value) {
      formikProps.setFieldValue('min', min * 10);
    } else if (min && min > value) {
      formikProps.setFieldValue('min', min / 10);
    }
  }
};

const NumberOrRangeQuestionType = ({
  activeLanguage,
  isFormDisabled,
  isRangeQuestionType,
  formikProps,
  isDisabled,
  isLoading,
}: NumberOrRangeQuestionTypeProps) => {
  const { values } = formikProps;
  const isUnitFieldDisabled =
    values.min === undefined ||
    !Number.isInteger(values.min) ||
    values.max === undefined ||
    !Number.isInteger(values.max);

  return (
    <Fragment>
      <Col span={2}>
        <Form.Item
          name="min"
          label={<FormattedMessage id="condition-edit.min-label" />}
          required={isRangeQuestionType}
          hasFeedback
        >
          <InputNumber
            name="min"
            step={getStepForMaxMinValues(formikProps.values.step)}
            precision={getPrecisionForMaxMinValues(formikProps.values.step)}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          name="max"
          label={<FormattedMessage id="condition-edit.max-label" />}
          required={isRangeQuestionType}
          hasFeedback
        >
          <InputNumber
            name="max"
            step={getStepForMaxMinValues(formikProps.values.step)}
            precision={getPrecisionForMaxMinValues(formikProps.values.step)}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <StepField
          isSaving={isLoading}
          isDisabled={isDisabled}
          onChange={(value: number) => handleStepChange(value, formikProps)}
        />
      </Col>
      <Col span={2}>
        <Form.Item
          name={`unit.${activeLanguage}`}
          label={<FormattedMessage id="condition-edit.unit-label" />}
        >
          <Input name={`unit.${activeLanguage}`} disabled={isFormDisabled || isUnitFieldDisabled} />
        </Form.Item>
      </Col>
      {isRangeQuestionType && (
        <Fragment>
          <Col span={4}>
            <Form.Item
              name={`minLabel.${activeLanguage}`}
              label={<FormattedMessage id="condition-edit.min-label-label" />}
            >
              <Input name={`minLabel.${activeLanguage}`} disabled={isFormDisabled} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={`maxLabel.${activeLanguage}`}
              label={<FormattedMessage id="condition-edit.max-label-label" />}
            >
              <Input name={`maxLabel.${activeLanguage}`} disabled={isFormDisabled} />
            </Form.Item>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NumberOrRangeQuestionType;
