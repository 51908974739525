import toPairs from 'lodash/toPairs';

// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeParamsString(params: Record<any, any>) {
  if (!params) return '';
  return `?${toPairs(params)
    .filter(x => x[1] !== undefined && x[1] !== null)
    .map(([name, value]) => `${name}=${value}`)
    .join('&')}`;
}
