import { App, message as antdMessage, notification as antNotification } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';
import type { NotificationInstance } from 'antd/es/notification/interface';

let message: MessageInstance = antdMessage;
let notification: NotificationInstance = antNotification;

function MessageGlobal() {
  const staticFunction = App.useApp();
  message = staticFunction.message;
  notification = staticFunction.notification;
  return null;
}

export { message, notification };
export default MessageGlobal;
