export const articlesQueryKeys = {
  getFullArticle: ({
    partnerId,
    id,
    type,
  }: {
    partnerId?: string | null;
    id: string;
    type: 'article' | 'selfcare';
  }) => ['articles', partnerId, type, id, 'full'],
};
