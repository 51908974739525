import React from 'react';

import { LabelledField, LabelledFieldProps } from './LabelledField';
import { LanguageIcon } from './LanguageIcon';

export const LOCALIZED_FIELD_DATA_TYPE = 'localized-labelled-field';

/**
 * A field that is labelled with the language it is for.
 * It will create a Form.Item with the name with the provided language as the last item:
 * `[...name, language]`
 */
export const LabelledLocalizedField = ({
  language,
  children,
  name,
  ...props
}: LabelledFieldProps & { language: string; label: string }) => (
  <LabelledField
    afterLabel={<LanguageIcon language={language} />}
    data-type={LOCALIZED_FIELD_DATA_TYPE}
    name={
      name !== undefined
        ? Array.isArray(name)
          ? [...name, language]
          : [name, language]
        : undefined
    }
    {...props}
  >
    {children}
  </LabelledField>
);
