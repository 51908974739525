import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';

export interface PartnerShutdownExplanationDTO {
  locale: string;
  explanation: string;
}

export interface ShutdownDTO {
  isShutdown: boolean;
  shutdownExplanations: PartnerShutdownExplanationDTO[];
}

export const shutdownRequest = async (partnerId: string, originId: string, data: ShutdownDTO) =>
  axios.post<ShutdownDTO>(
    `${PARTNERS_URI}/${partnerId}/origins/${originId}/front-door/shutdown`,
    data
  );

export const getShutdownInfo = async (partnerId: string, originId: string) =>
  axios.get<ShutdownDTO>(`${PARTNERS_URI}/${partnerId}/origins/${originId}/front-door/shutdown`);
