import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import {
  ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX,
  ALPHANUMERIC_CHARS_REGEX,
  NOT_ONLY_NUMBERS_REGEX,
  NO_LEADING_TRAILING_SPACE_REGEX,
} from 'constants/regex';
import {
  QUESTION_TYPES,
  CODE24_MODEL_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
  validateStatementBuildTimeIfWithDebounce,
  validatePatientField,
  validatePatient4Regex,
  validateStatementTextConditionWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';
import { TranslatedText } from 'types/types';

/**
 * @notExported
 */
interface getValidationSchemaProps {
  activeLanguage: string;
  intl: IntlShape;
}

export const getValidationSchema = ({ activeLanguage, intl }: getValidationSchemaProps) => {
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const wrongFormatErrorMessage = intl.formatMessage({
    id: 'general.errors.wrong-format',
  });
  return Yup.object().shape({
    type: Yup.string().required(),
    questionId: Yup.string()
      .matches(
        ALPHANUMERIC_CHARS_REGEX,
        intl.formatMessage({
          id: 'general.errors.alphanumeric-characters-validation',
        })
      )
      .matches(
        NOT_ONLY_NUMBERS_REGEX,
        intl.formatMessage({
          id: 'general.errors.not_only_numbers_validation',
        })
      )
      .required(requiredErrorMessage),
    condition: Yup.string()
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      )
      .nullable(),
    buildTimeIf: Yup.string()
      .test(
        'noLeadingTrailingSpace',
        intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
        function (this: Yup.TestContext, value: string) {
          return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
        }
      )
      .test(
        'isValidBuildTimeIf',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementBuildTimeIfWithDebounce
      )
      .nullable(),
    textCondition: Yup.string()
      .test(
        'isTextConditionRequired',
        requiredErrorMessage,
        function (this: Yup.TestContext, value: string) {
          const isRequired =
            !!this.parent.patient && validatePatient4Regex(this.parent.patient[activeLanguage]);

          return !isRequired || !!value;
        }
      )
      .test(
        'isValidTextCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementTextConditionWithDebounce
      ),
    patient: Yup.mixed().test(
      'isPatientValid',
      wrongFormatErrorMessage,
      function (this: Yup.TestContext, value: TranslatedText) {
        if (!value || !value[activeLanguage]) {
          return this.createError({
            path: `patient.${activeLanguage}`,
            message: requiredErrorMessage,
          });
        }

        return (
          validatePatientField(value[activeLanguage]) ||
          this.createError({
            path: `patient.${activeLanguage}`,
            message: wrongFormatErrorMessage,
          })
        );
      }
    ),
    max: Yup.mixed().test(
      'isMaxValid',
      wrongFormatErrorMessage,
      function (this: Yup.TestContext, value?: number | null) {
        if (this.parent['questionType'] === QUESTION_TYPES.RANGE && !value) {
          return this.createError({
            path: 'max',
            message: requiredErrorMessage,
          });
        }

        if (
          this.parent['questionType'] === QUESTION_TYPES.NUMBER &&
          (value || 0) < this.parent['min']
        ) {
          return this.createError({
            path: 'max',
            message: intl.formatMessage({
              id: 'condition-edit.max-less-than-min-error',
            }),
          });
        }

        return true;
      }
    ),
    step: Yup.mixed().when('questionType', {
      is: (val: QUESTION_TYPES) => val === QUESTION_TYPES.RANGE,
      then: Yup.number().required(requiredErrorMessage),
    }),
    min: Yup.mixed().when('questionType', {
      is: (val: QUESTION_TYPES) => val === QUESTION_TYPES.RANGE,
      then: Yup.number().required(requiredErrorMessage),
    }),
    content: Yup.array().when('questionType', {
      is: (val: QUESTION_TYPES) => val === QUESTION_TYPES.MULTIPLE || val === QUESTION_TYPES.SINGLE,
      then: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string().required(requiredErrorMessage),
            responseId: Yup.string().when('type', {
              is: val =>
                [CODE24_MODEL_TYPES.RESPONSE, CODE24_MODEL_TYPES.LAST_RESPONSE].includes(val),
              then: Yup.string()
                .required(requiredErrorMessage)
                .matches(
                  ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX,
                  intl.formatMessage({
                    id: 'general.errors.alphanumeric-characters-dashes-hyphens-validation',
                  })
                )
                .matches(
                  NOT_ONLY_NUMBERS_REGEX,
                  intl.formatMessage({
                    id: 'general.errors.not_only_numbers_validation',
                  })
                ),
              otherwise: Yup.string().nullable(),
            }),
            patient: Yup.object().when('type', {
              is: val =>
                [CODE24_MODEL_TYPES.RESPONSE, CODE24_MODEL_TYPES.LAST_RESPONSE].includes(val),
              then: Yup.object().shape({
                [activeLanguage]: Yup.string().required(requiredErrorMessage),
              }),
              otherwise: Yup.object().nullable(),
            }),
            buildTimeIf: Yup.string()
              .test(
                'noLeadingTrailingSpace',
                intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
                function (this: Yup.TestContext, value: string) {
                  return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
                }
              )
              .nullable(), // for performance reasons async validation is done when submitting
            condition: Yup.string().nullable(), // for performance reasons async validation is done o when submitting
          })
        )
        .required(),
      otherwise: Yup.array().nullable(),
    }),
  });
};
