import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LETTERS_REGEX, NO_LEADING_TRAILING_SPACE_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { Call } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
  validateStatementBuildTimeIfWithDebounce,
} from 'modules/Content24/Condition/utils/validationUtils';
import { getConditionsFilteredByGroupAsSelectOptions } from 'modules/Content24/utils';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface CallFormProps {
  onCancel: () => void;
  onSubmit: (data: Call) => void;
  activeLanguage: string;
  data?: Call;
  isDisabled?: boolean;
}

const CallForm: FunctionComponent<CallFormProps> = ({
  data,
  onCancel,
  onSubmit,
  activeLanguage,
  isDisabled,
}) => {
  const intl = useIntl();
  const { conditionStore, conditionsListStore } = useContext(RootStoreContext);

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    target: Yup.string().required(requiredErrorMessage),
    // we want to display error message only once, not for each item separately
    parameters: Yup.array()
      .test(
        'isValidParametersArray',
        intl.formatMessage({
          id: 'general.errors.letters-allowed',
        }),
        parameters => !parameters.some((parameter: string) => !LETTERS_REGEX.test(parameter))
      )
      .required(requiredErrorMessage),
    condition: Yup.string()
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      )
      .nullable(),
    buildTimeIf: Yup.string()
      .test(
        'noLeadingTrailingSpace',
        intl.formatMessage({ id: 'general.errors.leading_trailing_space_not_allowed' }),
        function (this: Yup.TestContext, value: string) {
          return !NO_LEADING_TRAILING_SPACE_REGEX.test(value);
        }
      )
      .test(
        'isValidBuildTimeIf',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementBuildTimeIfWithDebounce
      )
      .nullable(),
  });

  const defaultValues: Call = {
    id: '',
    type: CODE24_MODEL_TYPES.CALL,
    target: '',
    parameters: [],
    condition: '',
    buildTimeIf: '',
  };
  const initialValues = getInitialFormValues<Call, Call>(defaultValues, data);

  const handleSubmit = useCallback(
    (dataToSubmit: Call) => {
      onSubmit(getDataFromFinalFormValues<Call>(dataToSubmit));
    },
    [onSubmit]
  );
  return (
    <Observer>
      {() => {
        const libraryConditionsSelectOptions = getConditionsFilteredByGroupAsSelectOptions(
          conditionsListStore.conditions,
          'libraries'
        );
        const isSaving = conditionStore.isLoading();
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, setFieldValue, values, dirty }) => (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="target"
                      required
                      label={<FormattedMessage id="condition-edit.call-library-label" />}
                    >
                      <Select
                        name="target"
                        disabled={isDisabled}
                        onChange={() => {
                          setFieldValue('parameters', []);
                        }}
                        options={libraryConditionsSelectOptions
                          .map(({ label, value }) => ({
                            value,
                            label: label[activeLanguage] || value,
                          }))
                          .sort((a, b) => sortWithLocale(a, b, 'label', activeLanguage))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="parameters"
                      label={<FormattedMessage id="condition-edit.parameters-label" />}
                      required
                    >
                      <Select
                        disabled={isDisabled || !values.target}
                        name="parameters"
                        mode="tags"
                        tokenSeparators={[',', ' ']}
                        notFoundContent={null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="condition"
                      label={<FormattedMessage id="condition-edit.condition-label" />}
                    >
                      <Input.TextArea name="condition" disabled={isDisabled} rows={1} autoSize />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="buildTimeIf"
                      label={<FormattedMessage id="condition-edit.build-time-if-label" />}
                    >
                      <Input name="buildTimeIf" disabled={isDisabled} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <FormActionButtons
                  isSaving={isSaving}
                  isDisabled={isDisabled}
                  isValid={isValid && dirty}
                  onCancel={onCancel}
                  showCancelConfirm={dirty}
                  cancelDeclineText={
                    <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                  }
                />
              </Form>
            )}
          </Formik>
        );
      }}
    </Observer>
  );
};

export default CallForm;
