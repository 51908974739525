import { EditOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Flex, Modal, Typography } from 'antd';
import { Field, FieldProps, useField, useFormikContext } from 'formik';
import { ResetButton, SubmitButton } from 'formik-antd';
import { concat, differenceBy, uniqBy } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useBoolean } from '../../../../hooks/useBoolean';
import { OpeningHoursContext } from '../openingHours.context';
import {
  useCurrentCareProviderId,
  useInheritanceStatusQuery,
  useOpeningHoursQuery,
} from '../openingHours.hooks';
import {
  CareProviderInheritanceStatus,
  CareUnitIdAndName,
  MODE,
  OpeningHoursRegular,
  OpeningHoursSpecial,
} from '../openingHours.types';
import { groupSpecialHours, isSpecialHours } from '../openingHours.utils';

interface Props {
  isEditingEnabled: boolean;
  onIsEditingToggleRequest: (isEditingEnabled: boolean) => void;
  onInheritFromParent?: () => void;
  name: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
  isSubmitting: boolean;
}

function getAllCareUnits(
  inheritanceStatus: CareProviderInheritanceStatus | undefined
): CareUnitIdAndName[] {
  if (!inheritanceStatus) return [];
  return uniqBy(
    concat(
      inheritanceStatus.careUnitsWithInheritedRegularHours,
      inheritanceStatus.careUnitsWithInheritedSpecialHours,
      inheritanceStatus.careUnitsWithoutInheritance
    ),
    'id'
  );
}

function getAffectedCareUnits(
  inheritanceStatus: CareProviderInheritanceStatus | undefined,
  isSpecial: boolean
): CareUnitIdAndName[] {
  if (!inheritanceStatus) return [];
  return isSpecial
    ? inheritanceStatus.careUnitsWithInheritedSpecialHours
    : inheritanceStatus.careUnitsWithInheritedRegularHours;
}

function getUnaffectedCareUnits(
  inheritanceStatus: CareProviderInheritanceStatus | undefined,
  isSpecial: boolean
): CareUnitIdAndName[] {
  return differenceBy(
    getAllCareUnits(inheritanceStatus),
    getAffectedCareUnits(inheritanceStatus, isSpecial),
    'id'
  );
}

const ModalCareProviderInheritanceStatus = ({
  isOpen,
  onClose,
  onOk,
  isSubmitting,
}: ModalProps) => {
  const intl = useIntl();
  const careProviderId = useCurrentCareProviderId();
  const {
    data: inheritanceStatus,
    isLoading,
    isSuccess,
  } = useInheritanceStatusQuery(careProviderId ?? '');
  const { values } = useFormikContext<OpeningHoursSpecial | OpeningHoursRegular>();
  const isSpecial = isSpecialHours(values);
  const careUnitsAffected = useMemo(
    () => getAffectedCareUnits(inheritanceStatus, isSpecial),
    [isSpecial, inheritanceStatus]
  );
  const careUnitsUnaffected = useMemo(
    () => getUnaffectedCareUnits(inheritanceStatus, isSpecial),
    [isSpecial, inheritanceStatus]
  );

  return (
    <Modal
      data-testid="modal-inheritance-status"
      open={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="opening-hours.care-units-status" />}
      onOk={onOk}
      onCancel={onClose}
      cancelButtonProps={{ disabled: isSubmitting }}
      okButtonProps={{ loading: isSubmitting }}
      closable={!isSubmitting}
      okText={intl.formatMessage({ id: 'general.ok' })}
      cancelText={intl.formatMessage({ id: 'general.cancel' })}
      loading={isLoading}
    >
      {isSuccess && (
        <>
          <p>
            <FormattedMessage id="opening-hours.affected-care-units" />
          </p>
          <ul>{careUnitsAffected?.map(({ name }, i) => <li key={i}>{name}</li>)}</ul>
          <p>
            <FormattedMessage id="opening-hours.not-affected-title" />
          </p>
          <ul>{careUnitsUnaffected?.map(({ name }, i) => <li key={i}>{name}</li>)}</ul>
        </>
      )}
    </Modal>
  );
};

const ModalCareUnitInheritanceConfirmation = ({
  isOpen,
  onClose,
  onOk,
  isSubmitting,
}: ModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      data-testid="modal-inheritance-confirmation"
      open={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="opening-hours.change-to-care-provider-hours" />}
      onOk={onOk}
      onCancel={onClose}
      cancelButtonProps={{ disabled: isSubmitting }}
      okButtonProps={{ loading: isSubmitting }}
      closable={!isSubmitting}
      okText={intl.formatMessage({ id: 'opening-hours.revert-hours' })}
      cancelText={intl.formatMessage({ id: 'general.cancel' })}
    >
      <Typography.Text>
        <FormattedMessage id="opening-hours.revert-to-care-provider" />
      </Typography.Text>
    </Modal>
  );
};

export function FormControlButtons({
  isEditingEnabled,
  onIsEditingToggleRequest,
  onInheritFromParent,
  name,
}: Props) {
  const { id, mode } = useContext(OpeningHoursContext);
  const { value: isModalOpen, setTrue: showModal, setFalse: hideModal } = useBoolean(false);
  const [, , { setValue: setInheritanceFieldValue }] = useField(name);
  const { submitForm, isSubmitting, values } = useFormikContext<
    OpeningHoursSpecial | OpeningHoursRegular
  >();
  const { data } = useOpeningHoursQuery(mode, id);
  const dataToUse = data?.candidate || data?.release;
  const isSpecial = isSpecialHours(values);
  const isInheritedFromCareProvider = isSpecial
    ? dataToUse?.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent
    : dataToUse?.detailedRegularHoursWrapper.regularHoursInheritedByParent;
  const canShowEditButton: boolean =
    !isSpecial ||
    !!data?.candidate?.detailedSpecialHourPeriodsWrapper ||
    !!groupSpecialHours(values).futureDays;

  const onEnableEditingClick = useCallback(async () => {
    onIsEditingToggleRequest(true);
  }, [onIsEditingToggleRequest]);

  const submitAndHideModal = useCallback(async () => {
    await submitForm();
    onIsEditingToggleRequest(false);
    hideModal();
  }, [submitForm, onIsEditingToggleRequest, hideModal]);

  const submitInheritFromCareProvider = useCallback(async () => {
    await setInheritanceFieldValue(true);
    await submitAndHideModal();
  }, [submitAndHideModal, setInheritanceFieldValue]);

  const testIdPrefix = isSpecialHours(values) ? 'form-special' : 'form-regular';

  return (
    <Flex wrap gap="small">
      <Field>
        {({ form: { isValid, dirty, submitCount, isSubmitting } }: FieldProps) => (
          <>
            {!isInheritedFromCareProvider && onInheritFromParent && (
              <Button
                onClick={showModal}
                disabled={isSubmitting}
                icon={<UndoOutlined />}
                data-testid={`${testIdPrefix}-inherit-from-care-provider-button`}
              >
                <FormattedMessage id="opening-hours.change-to-care-provider-hours" />
              </Button>
            )}
            {isEditingEnabled ? (
              <>
                <ResetButton
                  data-testid={`${testIdPrefix}-reset-button`}
                  disabled={isSubmitting}
                  onClick={() => onIsEditingToggleRequest(false)}
                >
                  <FormattedMessage id="opening-hours.cancel-editing" />
                </ResetButton>
                <SubmitButton
                  data-testid={`${testIdPrefix}-submit-button`}
                  icon={<SaveOutlined />}
                  disabled={(submitCount === 0 && !isValid) || !dirty}
                  onClick={e => {
                    if (mode === MODE.CARE_PROVIDER) {
                      e.preventDefault();
                      showModal();
                    }
                  }}
                >
                  <FormattedMessage id="general.save" />
                </SubmitButton>
              </>
            ) : canShowEditButton ? (
              <>
                <Button
                  data-testid={`${testIdPrefix}-edit-button`}
                  icon={<EditOutlined />}
                  onClick={onEnableEditingClick}
                  loading={!isModalOpen && isSubmitting}
                >
                  <FormattedMessage id="opening-hours.edit-hours" />
                </Button>
              </>
            ) : null}
          </>
        )}
      </Field>
      {mode === MODE.CARE_UNIT ? (
        <ModalCareUnitInheritanceConfirmation
          isOpen={isModalOpen}
          onClose={hideModal}
          onOk={submitInheritFromCareProvider}
          isSubmitting={isSubmitting}
        />
      ) : (
        <ModalCareProviderInheritanceStatus
          isOpen={isModalOpen}
          onClose={hideModal}
          onOk={submitAndHideModal}
          isSubmitting={isSubmitting}
        />
      )}
    </Flex>
  );
}
