import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';

export enum MERGE_REQUEST_STATUS {
  PENDING = 'PENDING',
  MERGED = 'MERGED',
  PENDING_DEPLOYMENT = 'PENDING_DEPLOYMENT',
  DEPLOYED = 'DEPLOYED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export enum PARTNER_STATUS {
  PENDING = 'PENDING',
  UP_TO_DATE = 'UP_TO_DATE',
  CHANGES = 'CHANGES',
}

export enum COMMIT_STATUS {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
  MANUAL = 'MANUAL',
  CREATED = 'CREATED',
  UNKNOWN = 'UNKNOWN',
}

export interface ConfigChange {
  when: string;
  author: string;
  email: string;
  message: string;
  status?: COMMIT_STATUS;
}

export interface PartnerStatusDTO {
  status: PARTNER_STATUS;
  mergeRequestId?: number;
  changes: ConfigChange[];
}

export interface PartnerGitStatus {
  status: PARTNER_STATUS;
  changesCount: number;
  latestCommitAuthor: string | null;
  latestCommitWhen: string | null;
}

export interface MergeRequest {
  id: number;
  when: string;
  status: MERGE_REQUEST_STATUS;
  description?: string;
  changes: ConfigChange[];
}

// The backend could take the whole MergeRequest object (except 'changes'), but the only editable property is 'description',
// so no need to send more data in the payload.
export interface UpdateMergeRequestDTO {
  description: string;
}

export const fetchPartnerStatus = (partnerId: string) =>
  axios.get<PartnerStatusDTO>(`${PARTNERS_URI}/${partnerId}/status`);

export const fetchPartnerGitStatus = (partnerId: string) =>
  axios.get<PartnerGitStatus>(`${PARTNERS_URI}/${partnerId}/status/light`);

export const fetchCurrentMergeRequest = (partnerId: string) =>
  axios.get<MergeRequest>(`${PARTNERS_URI}/${partnerId}/status/merge-request/current`);

export const fetchMergeRequestById = (partnerId: string, mergeRequestId: number) =>
  axios.get<MergeRequest>(`${PARTNERS_URI}/${partnerId}/status/merge-request/${mergeRequestId}`);

export const fetchMergeRequests = (partnerId: string) =>
  axios.get<MergeRequest[]>(`${PARTNERS_URI}/${partnerId}/status/merge-request`);

export const createMergeRequest = (partnerId: string, data: UpdateMergeRequestDTO) =>
  axios.post<MergeRequest>(`${PARTNERS_URI}/${partnerId}/status/merge-request`, data);

export const updateMergeRequest = (
  partnerId: string,
  mergeRequestId: number,
  data: UpdateMergeRequestDTO
) =>
  axios.put<MergeRequest>(
    `${PARTNERS_URI}/${partnerId}/status/merge-request/${mergeRequestId}`,
    data
  );

export const cancelMergeRequest = (partnerId: string, mergeRequestId: number) =>
  axios.delete(`${PARTNERS_URI}/${partnerId}/status/merge-request/${mergeRequestId}`);

export const triggerPipeline = (partnerId: string) =>
  axios.post<ConfigChange>(`${PARTNERS_URI}/${partnerId}/status/pipeline`);
