import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { LANGS } from 'constants/enums';
import { RESOURCE_TYPES } from 'constants/permissions';
import { QuestionCategory } from 'modules/Content24/Condition/api/code24api';
import {
  ExitCategories,
  HealthDataCode,
  ServiceRequestCode,
} from 'modules/Content24/Condition/api/partnerCode24api';
import { partnersAtoms } from 'state/partners/partners.atoms';

import { content24Selectors } from './content24.selectors';

interface Content24State {
  exitsCategories?: ExitCategories;
  questionCategories: QuestionCategory[];
  availableLanguages: LANGS[];
  hiddenConditions: string[];
  healthDataCodes: HealthDataCode[];
  serviceRequestCodes: ServiceRequestCode[];
  isLoadingHealthDataCodes: boolean;
  isLoadingServiceRequestCodes: boolean;
  maxOriginConditions: number;
  isDiagnosisCodesShown: boolean;
  code24MedconVersion?: number;
}

const root = atom<Content24State>({
  questionCategories: [],
  availableLanguages: [],
  hiddenConditions: [],
  healthDataCodes: [],
  serviceRequestCodes: [],
  isLoadingHealthDataCodes: false,
  isLoadingServiceRequestCodes: false,
  maxOriginConditions: 0,
  isDiagnosisCodesShown: false,
});

const availableLanguages = focusAtom(root, o => o.prop('availableLanguages'));
const questionCategories = focusAtom(root, o => o.prop('questionCategories'));
const hiddenConditions = focusAtom(root, o => o.prop('hiddenConditions'));
const healthDataCodes = focusAtom(root, o => o.prop('healthDataCodes'));
const serviceRequestCodes = focusAtom(root, o => o.prop('serviceRequestCodes'));
const isLoadingHealthDataCodes = focusAtom(root, o => o.prop('isLoadingHealthDataCodes'));
const isLoadingServiceRequestCodes = focusAtom(root, o => o.prop('isLoadingServiceRequestCodes'));
const maxOriginConditions = focusAtom(root, o => o.prop('maxOriginConditions'));
const isDiagnosisCodesShown = focusAtom(root, o => o.prop('isDiagnosisCodesShown'));
const code24MedconVersion = focusAtom(root, o => o.prop('code24MedconVersion'));

// --- computed
const canEditSelfcareAdvice = atom(get => {
  const isReadOnlyModeEnabled = get(partnersAtoms.isReadOnlyModeEnabled);

  return (
    content24Selectors.getCanEditContent24Resource(RESOURCE_TYPES.SELFCARE) &&
    !isReadOnlyModeEnabled
  );
});

const canAddContent24 = atom(get => {
  const isReadOnlyModeEnabled = get(partnersAtoms.isReadOnlyModeEnabled);

  return (
    content24Selectors.getCanEditContent24Resource(RESOURCE_TYPES.CODE24) && !isReadOnlyModeEnabled
  );
});

const canEditContent24 = atom(get => {
  const isReadOnlyModeEnabled = get(partnersAtoms.isReadOnlyModeEnabled);

  return (
    content24Selectors.getCanEditContent24Resource(RESOURCE_TYPES.HOOKS) && !isReadOnlyModeEnabled
  );
});

const healthDataCodeTypeMap = atom(get => {
  return new Map(get(healthDataCodes).map(({ id, type }) => [id, type]));
});

export const content24Atoms = {
  root,
  availableLanguages,
  questionCategories,
  hiddenConditions,
  healthDataCodes,
  serviceRequestCodes,
  isLoadingHealthDataCodes,
  isLoadingServiceRequestCodes,
  maxOriginConditions,
  isDiagnosisCodesShown,
  code24MedconVersion,
  canEditSelfcareAdvice,
  canAddContent24,
  canEditContent24,
  healthDataCodeTypeMap,
};
