import { CheckCircleFilled, CloseCircleFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Alert, Button, Input, notification, Table, Tag } from 'antd';
import classNames from 'classnames';
import { action, observable, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, ChangeEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { Customization, CUSTOMIZATION_TYPES } from 'api/customizationsApi';
import EditCustomization from 'components/CustomizationsCommon/EditCustomization';
import withOnUnloadGuard, { OnUnloadGuardProps } from 'components/HOC/withOnUnloadGuard';
import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { TEST_IDS } from 'constants/testIds';
import RootStoreContext from 'context/RootStoreContext';
import { DataError } from 'errors/DataError';

import styles from './Customizations.module.css';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ originId: string }>,
    OnUnloadGuardProps {}

@observer
class Customizations extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable editedCustomization: Customization | null = null;
  @observable searchTerm = '';

  async componentDidMount() {
    try {
      const {
        match: {
          params: { originId },
        },
      } = this.props;
      const { customizationsStore } = this.context;

      await customizationsStore.fetchOriginCustomizations(originId);
      /* eslint-disable no-empty */
    } catch {}
  }

  handleSave = async (data: Customization) => {
    const {
      intl,
      match: {
        params: { originId },
      },
    } = this.props;
    const { customizationsStore } = this.context;

    try {
      if (this.editedCustomization === null) {
        throw new DataError();
      }

      const updatedCustomization = {
        ...this.editedCustomization,
        value: data.value,
        roles: data.roles,
      };

      await customizationsStore.updateOriginCustomization(updatedCustomization, originId);

      runInAction(() => {
        this.editedCustomization = null;
      });

      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'origin.customizations.customization-updated' }),
      });
      /* eslint-disable no-empty */
    } catch {}
  };

  @action
  handleCancel = () => {
    this.editedCustomization = null;
  };

  @action
  handleEdit = (key: string) => {
    this.editedCustomization =
      this.customizationsList.find(customization => customization.key === key) || null;
  };

  @action
  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = event.target.value.toLowerCase();
  };

  @computed
  get customizationsList() {
    const { customizationsStore } = this.context;
    const searchTerm = this.searchTerm.toLowerCase();
    const customizations = customizationsStore.originCustomizationsSorted.slice();

    if (searchTerm) {
      return customizations.filter(
        item =>
          item.header.toLowerCase().includes(searchTerm) ||
          item.description?.toLowerCase().includes(searchTerm) ||
          item.key.toLowerCase().includes(searchTerm)
      );
    }
    return customizations;
  }

  renderValue = (_: string, { type, value }: Customization) => {
    switch (type) {
      case CUSTOMIZATION_TYPES.BOOLEAN:
        return value === true ? (
          <span>
            <CheckCircleFilled className={classNames(styles.booleanIcon, styles.trueIcon)} />
            <FormattedMessage id="general.true" />
          </span>
        ) : (
          <span>
            <CloseCircleFilled className={classNames(styles.booleanIcon, styles.falseIcon)} />
            <FormattedMessage id="general.false" />
          </span>
        );
      case CUSTOMIZATION_TYPES.TEXTLIST:
        return <p>{value ? `'${value.join("', '")}'` : ''}</p>;
      default:
        return <p>{value}</p>;
    }
  };

  get columns() {
    return [
      {
        title: <FormattedMessage id="origin.customizations.setting" />,
        dataIndex: 'header',
      },
      {
        title: <FormattedMessage id="general.value" />,
        dataIndex: 'value',
        render: this.renderValue,
      },
      {
        title: <FormattedMessage id="general.tags" />,
        dataIndex: 'tags',
        filters: this.context.customizationsStore.originCustomizationsTagFilters,
        onFilter: (value: any, record: Customization) => !!record.tags.includes(value as string),
        render: (_: string, record: Customization) => (
          <div className={styles.tags}>
            {record.tags.map((tag, idx) => (
              <Tag className={styles.tag} key={`${tag}-${idx}`}>
                {tag}
              </Tag>
            ))}
          </div>
        ),
      },
      {
        title: <FormattedMessage id="general.description" />,
        dataIndex: 'description',
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: EDIT_ACTIONS_FIELD,
        render: (_: string, customization: Customization) => (
          <Button
            type="link"
            icon={
              (this.context.userPermissionsStore.canEditCurrentPartner ||
                this.context.userPermissionsStore.canEditOrigin(
                  this.props.match.params.originId
                )) &&
              customization.editable ? (
                <EditOutlined />
              ) : (
                <EyeOutlined />
              )
            }
            onClick={() => this.handleEdit(customization.key)}
            data-testid={TEST_IDS.EDIT_BTN}
          />
        ),
      },
    ];
  }

  render() {
    const { intl } = this.props;
    const { customizationsStore, userPermissionsStore } = this.context;

    return (
      <div data-testid="customizations">
        {(userPermissionsStore.canEditCurrentPartner ||
          userPermissionsStore.canEditOrigin(this.props.match.params.originId)) && (
          <Alert
            message={intl.formatMessage({ id: 'origin.customizations.slow-saving-alert' })}
            type="warning"
            className={styles.notification}
            showIcon
          />
        )}
        <PageHeader
          content="origin.customizations.header"
          canEdit={false}
          headerActions={
            <Input.Search
              placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
              onChange={this.handleSearchChange}
              className={styles.search}
            />
          }
        >
          <p className={styles.subheader}>
            <FormattedMessage id="origin.customizations.subheader" />
          </p>
        </PageHeader>
        <Table<Customization>
          dataSource={this.customizationsList}
          columns={this.columns}
          pagination={false}
          className={styles.table}
          loading={customizationsStore.isLoading() || customizationsStore.isSaving()}
          data-testid="customizations-list"
          rowKey={'key'}
        />
        <EditCustomization
          data={this.editedCustomization}
          isSaving={customizationsStore.isSaving()}
          isDisabled={
            (!userPermissionsStore.canEditCurrentPartner &&
              !userPermissionsStore.canEditOrigin(this.props.match.params.originId)) ||
            !this.editedCustomization?.editable
          }
          isVisible={this.editedCustomization !== null}
          onSubmit={this.handleSave}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

export default injectIntl(withOnUnloadGuard(Customizations));
