import { Practitioner } from 'api/practitionerApi';
import { IDENTITIES } from 'constants/practitioner';

export const checkEmailEquality = (values: Practitioner, errorMessage: string, isEdit = false) => {
  return () => {
    if (values?.externalIds?.length) {
      const hasError = values.externalIds.some(externalId => {
        return (
          // checks only when a practitioner is being created for Internal IDP type. This is because during creation externalId must be equal email
          // but when editing externalId field returns some random numbers which are not the same as the email. ie. No need for the check during edit.
          (externalId.externalIdType === IDENTITIES.INTERNAL_IDP &&
            values.email !== externalId.externalId &&
            !isEdit) ||
          // for email type equality should alway be check. ie. during creation and edit
          (externalId.externalIdType === IDENTITIES.EMAIL && values.email !== externalId.externalId)
        );
      });
      if (hasError) return errorMessage;
    }
    return undefined;
  };
};
