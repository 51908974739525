import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import {
  COMMIT_STATUS,
  PartnerGitStatus,
  PartnerStatusDTO,
  PARTNER_STATUS,
} from 'modules/PartnerStatus/api/partnerStatusApi';

interface PartnerStatusState {
  partnerStatus: PartnerStatusDTO | null;
  isLoading: boolean;
  isTriggeringPipeline: boolean;
  partnerGitStatus: PartnerGitStatus;
  isGitStatusLoaded: boolean;
}

const root = atom<PartnerStatusState>({
  partnerStatus: null,
  isLoading: false,
  isTriggeringPipeline: false,
  partnerGitStatus: {
    status: PARTNER_STATUS.PENDING,
    changesCount: 0,
    latestCommitAuthor: null,
    latestCommitWhen: null,
  },
  isGitStatusLoaded: false,
});

const partnerStatus = focusAtom(root, o => o.prop('partnerStatus'));
const isLoading = focusAtom(root, o => o.prop('isLoading'));
const isTriggeringPipeline = focusAtom(root, o => o.prop('isTriggeringPipeline'));
const partnerGitStatus = focusAtom(root, o => o.prop('partnerGitStatus'));
const isGitStatusLoaded = focusAtom(root, o => o.prop('isGitStatusLoaded'));

// --- computed
const isMergeRequestPending = atom(get => get(partnerGitStatus).status === PARTNER_STATUS.PENDING);
const latestSuccessfulDeploymentDate = atom(
  get => get(partnerStatus)?.changes.find(({ status }) => status === COMMIT_STATUS.SUCCESS)?.when
);
const isLatestPipelinePending = atom(get => {
  const status = get(partnerStatus)?.changes[0]?.status;
  return (
    status && [COMMIT_STATUS.CREATED, COMMIT_STATUS.PENDING, COMMIT_STATUS.RUNNING].includes(status)
  );
});

export const partnerStatusAtoms = {
  root,
  partnerStatus,
  isLoading,
  isTriggeringPipeline,
  partnerGitStatus,
  isGitStatusLoaded,
  isMergeRequestPending,
  latestSuccessfulDeploymentDate,
  isLatestPipelinePending,
};
