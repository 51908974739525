import {
  EditOutlined,
  HomeOutlined,
  InboxOutlined,
  PlusCircleOutlined,
  CaretDownFilled,
  CaretRightFilled,
} from '@ant-design/icons';
import { Button, Table, Input, Tooltip } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import { Component, ContextType, Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import { TEST_IDS } from 'constants/testIds';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import styles from './OriginsList.module.css';
import OriginsListStore, { OriginListItem } from './stores/OriginsListStore';

interface Props extends WrappedComponentProps, RouteComponentProps<{ parentOriginId?: string }> {}

@observer
class OriginsList extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  originsListStore: OriginsListStore;

  columns = [
    {
      title: <FormattedMessage id="origin.basic-details-form.origin-id-label" />,
      dataIndex: FIELDS.ID,
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: OriginListItem, b: OriginListItem) => (a.id < b.id ? -1 : 1),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 500,
      render: (text: string, record: OriginListItem) =>
        record.archived ? (
          <Tooltip title={<FormattedMessage id="origins.origin-archived" />}>
            <span>
              <InboxOutlined className={styles.homeIcon} />
              {this.renderText(text)}
            </span>
          </Tooltip>
        ) : (
          <Fragment>
            <HomeOutlined className={styles.homeIcon} />
            {this.renderText(text)}
          </Fragment>
        ),
    },
    {
      title: <FormattedMessage id="origin.basic-details-form.name-label" />,
      dataIndex: FIELDS.NAME,
      sorter: (a: OriginListItem, b: OriginListItem) => sortWithLocale(a, b, 'name'),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (text: string) => this.renderText(text),
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 120,
      render: (_: string, record: OriginListItem) => (
        <Fragment>
          {record.canView && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => this.props.history.push(`/origins/${record.id}/basic-details`)}
              data-testid={TEST_IDS.EDIT_BTN}
            />
          )}
          {record.children && this.context.userPermissionsStore.isSuperAdmin && (
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(`/origins/${record.id}/add`)}
              data-testid="add-origin-btn"
            />
          )}
        </Fragment>
      ),
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.originsListStore = context.originsListStore;
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const expanded = parse(search).expanded;

    if (expanded) {
      this.originsListStore.replaceExpandedKeys(Array.isArray(expanded) ? expanded : [expanded]);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      location: { search },
    } = this.props;

    if (prevProps.location.search !== search) {
      const expanded = parse(search).expanded;

      if (expanded) {
        this.originsListStore.replaceExpandedKeys(Array.isArray(expanded) ? expanded : [expanded]);
      } else {
        this.originsListStore.replaceExpandedKeys([]);
      }
    }
  }

  handleExpand = (isExpanded: boolean, record: OriginListItem) => {
    const id = record.id;
    const { nestedExpandedRowKeys } = this.originsListStore;
    const expandedQueryParam = { expanded: nestedExpandedRowKeys };

    if (isExpanded && !nestedExpandedRowKeys.includes(id)) {
      expandedQueryParam.expanded.push(id);
    }

    if (!isExpanded && nestedExpandedRowKeys.includes(id)) {
      expandedQueryParam.expanded = expandedQueryParam.expanded.filter(
        expandedId => expandedId !== id
      );
    }

    this.props.history.push(`/origins?${stringify(expandedQueryParam)}`);
  };

  renderText = (text: string) =>
    this.originsListStore.searchTerm ? (
      <Highlighter
        highlightClassName={styles.highlightedText}
        searchWords={[this.originsListStore.searchTerm]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    );

  render() {
    const { originStore, partnersStore, userOriginPermissionListStore } = this.context;

    return (
      <Fragment>
        <PartnerStatus />
        <div className={styles.container}>
          <PageHeader
            content="main-navigation.patient-apps"
            breadcrumbs={[
              {
                icon: <HomeOutlined />,
                text: <FormattedMessage id="main-navigation.patient-apps" />,
                link: '/origins',
              },
            ]}
            headerActions={
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={this.originsListStore.handleSearchChange}
                className={styles.search}
                allowClear
              />
            }
          />
          <Table<OriginListItem>
            columns={this.columns}
            dataSource={userOriginPermissionListStore.originsWithPermissionsAttached}
            pagination={false}
            data-testid="origins-list"
            className={styles.table}
            rowKey={FIELDS.ID}
            loading={partnersStore.isLoading() || originStore.isLoading()}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                record.children.length ? (
                  <Button
                    type="link"
                    icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                    onClick={e => onExpand(record, e)}
                    data-testid="expand-origin-btn"
                    data-origin-id={record.id}
                  />
                ) : (
                  <span className={styles.spacer}></span>
                ),
              onExpand: this.handleExpand,
              expandedRowKeys: this.originsListStore.nestedExpandedRowKeys,
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(OriginsList);
