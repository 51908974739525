import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { eventTrackingService } from 'config/appConfig';
import Login from 'modules/Login';

import PrivateRoute from './PrivateRoute';
import PrivateRoutes from './PrivateRoutes';

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    eventTrackingService.sendEvent('routeChanged');
  }, [location]);

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/" component={PrivateRoutes} />
    </Switch>
  );
};

export default Routes;
