import { Button } from 'antd';
import React, { Component } from 'react';

interface Props {
  onToggle: (style: string) => any;
  style: string;
  active: boolean;
  label?: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
}

class StyleButton extends Component<Props> {
  onToggle = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    const { icon, label, isDisabled } = this.props;
    return (
      <Button
        type={this.props.active ? 'primary' : undefined}
        onMouseDown={this.onToggle}
        icon={icon && <icon.type {...icon.props} />}
        disabled={isDisabled}
      >
        {label}
      </Button>
    );
  }
}

export default StyleButton;
