import { keyBy } from 'lodash';
import { IntlFormatters } from 'react-intl';

import { InputOption } from 'types/types';

import {
  INPUTS_TYPES_MAP,
  isKeyValueLikeData,
  isObject,
  isPrimitive,
  Outcome,
  Rule,
  RuleDraft,
  RulesAttribute,
  RULES_ATTRIBUTE_TYPE,
} from '../types';

export const prepareRuleDataToRender = (rule: RuleDraft | Rule): RuleDraft | Rule => {
  const result: RuleDraft = { ...rule };
  result.outcomes = [
    ...result.outcomes.map(outcome => {
      // we have got data from BE in following shape {key: val}
      // but both of key and value should be editable in a form
      // in order to render input for that values in standard formik approach we have to
      // convert objects to arrays of values with following format [key: keyValue, value: valueValue]
      outcome.value = convertObjectLikeDataToArrayLike(outcome.value);
      return outcome;
    }),
  ];
  // we have to set it to null if it's empty as an initial value
  // since we have custom logic on a fieled change when value is set to null
  // if field is empty, which could make some issues (required by BE) ==>
  // if we get this initial value as empty string, then type smth to field
  // and then remove all typed content ==>
  // in this case form becomes dirty since value is changed from
  // empty string from default value to null
  // after user have removed all typed content
  if (!result.when) {
    result.when = null;
  }

  return result;
};

export const prepareRuleDataToSubmit = (values: RuleDraft) => {
  const result: RuleDraft = { ...values };
  result.outcomes = [
    ...result.outcomes.map(outcome => {
      const convertedOutcome = { ...outcome };
      // converting arrays to objects to be able to submit it
      // [key: keyValue, value: valueValue] --> {[keyValue]: valueValue}
      convertedOutcome.value = convertArrayLikeDataToObjectLike(convertedOutcome.value);
      return convertedOutcome;
    }),
  ];
  return result;
};

type ObjToArrConverterReturnType<T> = T extends Record<string, any> ? Record<string, any>[] : T;

export const convertObjectLikeDataToArrayLike = (
  data: any
): ObjToArrConverterReturnType<typeof data> => {
  if (isObject(data)) {
    return Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }));
  }

  return data;
};

export const convertArrayLikeDataToObjectLike = <T>(
  data: T
): Record<string, unknown> | typeof data => {
  if (Array.isArray(data)) {
    const arrayResult: unknown[] = [];
    const objResult = {};
    data.forEach(dataItem => {
      if (isKeyValueLikeData(dataItem)) {
        const { key, value } = dataItem;
        objResult[key] = value;
      }
    });
    return Object.entries(objResult).length ? objResult : arrayResult.length ? arrayResult : data;
  }
  return data;
};

export const filterOutUsedValues = (
  outcomeValue: Outcome['value'],
  allowedValues: InputOption<any, string>[]
) => {
  const alreadyUsed = keyBy(outcomeValue, 'key');
  return allowedValues.filter(({ value }) => !(value in alreadyUsed));
};

export const getBaseValueType = (outcomeMeta: RulesAttribute | null) => {
  const isComplexBaseType = !isPrimitive(outcomeMeta);
  return isComplexBaseType ? outcomeMeta?.type.valueType?.baseType : outcomeMeta.type.baseType;
};

export const getConstraints = (outcomeMeta: RulesAttribute | null) => {
  if (outcomeMeta === null) {
    return {};
  }
  const {
    constraints,
    type: { constraints: typeConstraints },
  } = outcomeMeta;

  const allowedValues = constraints?.allowedValues?.length
    ? constraints?.allowedValues
    : typeConstraints.allowedValues?.length
      ? typeConstraints.allowedValues
      : null;

  return {
    min: constraints?.min ?? typeConstraints.min ?? null,
    max: constraints?.max ?? typeConstraints.max ?? null,
    allowedValues,
  };
};

export const getInputMetaProps = (outcomeMeta: RulesAttribute | null) => {
  const valueType = getBaseValueType(outcomeMeta);
  return INPUTS_TYPES_MAP[valueType ?? RULES_ATTRIBUTE_TYPE.STRING];
};

export const getBooleanControlValues = (formatMessage: IntlFormatters['formatMessage']) => [
  { label: formatMessage({ id: 'general.true' }), value: true },
  { label: formatMessage({ id: 'general.false' }), value: false },
];
