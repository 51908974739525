import React from 'react';

const Manufacturer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 251" height="33.466" width="22.4">
    <path
      d="M.5 250.5v-120l25-50 25 50 25-50 25 50 25-50 25 50V.5h50v250z"
      fillRule="evenodd"
      fill="#888"
    />
  </svg>
);

export default Manufacturer;
