import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';

import { ONLY_ALLOW_USER_HANDLING } from 'constants/env';

/**
 * If the value is a string, it parses it to a floating point number
 * @param {string | number} val
 */
export function parseFloatStringToNumber(val: string | number): number {
  if (isNumber(val) || !val) {
    return val as number;
  }
  return parseFloat(val.replace(/,/, '.'));
}

/**
 * Removes excessive spaces from the string.
 * @param {string} str
 */
export function sanitizeSpaces(str: string): string {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/\s+/g, ' ').trim();
}

/**
 * Concurrently resolve all promises.
 * Instead of rejecting the whole batch when any promise fails, this will complete all promises regardless of how many rejects
 * @param promises
 */
export function settleAll<T>(promises: Array<Promise<T>>): Promise<(T | Error)[]> {
  return Promise.all(promises.map(p => p.catch(e => e)));
}

/**
 * Return a string made of the first letters of each word
 * @param str
 */
export function getFirstLetters(str: string) {
  return str.split(' ').reduce((returnValue, word) => returnValue + word[0], '');
}

export function convertToCSVString(data: (string | number | undefined)[][]) {
  return data
    .map(record =>
      record.map(item => (item?.toString().includes(',') ? `"${item}"` : item)).join(',')
    )
    .join('\r\n');
}

export function downloadAsCSVFile(data: (string | number | undefined)[][], filename: string) {
  const blob = new Blob([convertToCSVString(data)], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

/**
 * Debounce function prepared to work properly with asynchronous calls.
 * Unlike normal lodash debounce, it does not return undefined, but resolves
 * only after async callback is done.
 *
 * @param debouncedFunction function which calls should be debounced
 * @param time time to react in miliseconds
 */
// To be refactored
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, time?: number) {
  const debounced = debounce(
    (resolve, reject, args: Parameters<F>) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    },
    time,
    { trailing: true }
  );
  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;
}

export const isEducationEnv = () => ONLY_ALLOW_USER_HANDLING;

export const GET_FEATURE_FLAG = () => window.FEATURE_FLAGS || {};

/**
 * Allows to specify feature flags in .env.local file.
 *
 * To specify feature flag (feature-flag, FF), add it to .env.local file:
 * REACT_APP_FF={"FF_feature-flag": true}
 *
 * Note, must be a valid JSON object.
 */
let devFeatureFlags: Record<string, unknown> = {};

if (process.env?.REACT_APP_FF) {
  try {
    devFeatureFlags = JSON.parse(process.env?.REACT_APP_FF);
  } catch (e) {
    console.error('Error parsing REACT_APP_FF', e);
  }
}

export const getValueOfFeatureFlag = (flag: string) =>
  devFeatureFlags[flag] ?? GET_FEATURE_FLAG()[flag];
