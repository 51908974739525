import { Empty, Typography } from 'antd';
import { useIntl } from 'react-intl';

const { Text } = Typography;

export const DefaultEmpty = () => {
  const intl = useIntl();
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      data-testid="default-empty-state"
      description={<Text>{intl.formatMessage({ id: 'No data' })}</Text>}
    />
  );
};
