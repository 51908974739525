import { notification } from 'antd';
import { keyBy } from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { ChangeEvent } from 'react';
import { IntlShape } from 'react-intl';

import { ACCESS_LEVEL } from 'api/permissionsApi';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { RESOURCE_TYPES } from 'constants/permissions';
import RootStore from 'stores/RootStore';
import { Optional } from 'types/types';

import {
  createRule,
  deleteRule,
  fetchRulesAttributes,
  fetchRulesPackage,
  updateRule,
  updateRulesPackage,
} from '../api/rules';
import {
  Rule,
  RuleDraft,
  RulesAttribute,
  RulesPackage,
  RulesPackageMeta,
  SEND_RULES_DATA_MODE,
} from '../types';

export default class RulesPackageStore {
  @observable rulesPackage: Optional<RulesPackage> = null;
  @observable searchTerm = '';
  rulesAttributes: Record<RulesAttribute['id'], RulesAttribute> = {};

  @observable
  isLoading = false;

  constructor(private rootStore: RootStore) {}

  initialize = async (rulesPackageId: string, intl: IntlShape) => {
    this.fetchRulesAttributes(intl);

    // This will happen only if the rules package was set before the store is initiated
    // (see setNewlyCreatedRulesPackage method below)
    if (this.rulesPackage?.id === rulesPackageId) {
      return;
    } else {
      this.fetchRulesPackage(rulesPackageId);
    }
  };

  // This method serves one purpose only - to set the rule package from "outside", i.e. RulesPackagesListStore.
  // The reason is to spare requests for fetching condition data and avoid issues caused by pod being not
  // synchronized
  @action
  setNewlyCreatedRulesPackage = (rulesPackage: RulesPackage) => {
    this.rulesPackage = rulesPackage;
  };

  @action
  private async fetchRulesPackage(rulesPackageId: string) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { data: rulesPackage } = await fetchRulesPackage(partnerId, rulesPackageId);
      runInAction(() => {
        this.rulesPackage = rulesPackage;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  private async fetchRulesAttributes(intl: IntlShape) {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { data: rulesAttributes } = await fetchRulesAttributes(partnerId);

      if (!rulesAttributes.length) {
        notification.error({
          message: intl.formatMessage({ id: 'rules.errors.attributes-missed' }),
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          placement: 'top',
        });
        return;
      }

      this.rulesAttributes = keyBy(rulesAttributes as RulesAttribute[], attribute => attribute.id);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = e.target.value.toLowerCase();
  };

  @computed
  get rules() {
    const { rules = [] } = this.rulesPackage ?? {};

    if (!this.searchTerm) {
      return rules;
    }

    return rules.filter(
      ({ id, description = '' }) =>
        id.toLowerCase().includes(this.searchTerm) ||
        description.toLowerCase().includes(this.searchTerm)
    );
  }

  getRuleById = (id: Rule['id']) => {
    const rulesMap = keyBy(this.rules, 'id');
    return rulesMap[id];
  };

  getRuleIndexInList = (id: Rule['id']) => {
    return this.rules.findIndex(rule => rule.id === id);
  };

  @computed
  get rulesPackageMeta() {
    return {
      id: this.rulesPackage?.id ?? '',
      title: this.rulesPackage?.title ?? '',
      description: this.rulesPackage?.description ?? '',
      source: this.rulesPackage?.source ?? '',
    };
  }

  /*--------------------------
  ADDING
  --------------------------*/
  @computed
  get canSaveRule() {
    const {
      partnersStore: { isReadOnlyModeEnabled },
      userPermissionsStore: { getPermission },
    } = this.rootStore;

    return (
      getPermission({
        resourceType: RESOURCE_TYPES.RULES,
        accessLevel: ACCESS_LEVEL.WRITE,
      }) && !isReadOnlyModeEnabled
    );
  }

  @computed
  get areRulesEditable() {
    return Boolean(Object.keys(this.rulesAttributes).length);
  }

  @action
  handleCreateRule = async () => {
    console.warn('Implement me! ==> handleCreateRule');
    return Promise.resolve('123');
  };

  @action
  sendRulesPackageUpdate = async (payload: Partial<RulesPackage>) => {
    if (!this.rulesPackage) {
      return Promise.reject('There is no fetched Rules Package');
    }

    runInAction(() => {
      this.isLoading = true;
    });

    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    try {
      const { data } = await updateRulesPackage(partnerId, this.rulesPackage.id, payload);

      return data;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  updateRulesPackageMeta = async (rulesPackageMeta: RulesPackageMeta) => {
    const savedRulesPackageMeta = await this.sendRulesPackageUpdate(rulesPackageMeta);
    runInAction(() => {
      this.rulesPackage = { ...this.rulesPackage, ...savedRulesPackageMeta };
    });
    return savedRulesPackageMeta;
  };

  @action
  changeRuleIndex = async (fromIndex: number, toIndex: number, ruleId: string) => {
    if (!this.rulesPackage || !ruleId) {
      throw new Error('There is no fetched Rule');
    }

    runInAction(() => {
      this.isLoading = true;
    });

    const { rules = [] } = this.rulesPackage;

    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    // this is container for updated rules list which we set to store after work is done here
    // but now to that container we are saving backup in order to restore previous state of rules list in case
    // BE trows an error during upddate
    let rulesListToStore = [...rules];

    // for now BE requires to send whole rule object in order to have it updated
    // so prepare container for it here
    let ruleOriginalPayload: null | Rule = null;

    // mutating current list of rules to display result of drugging for user
    runInAction(() => {
      const [ruleToMove] = rules.splice(fromIndex, 1);

      if (ruleToMove) {
        // updating rule we just have drugged and set its data as a payload for update request
        const updatedRule = { ...ruleToMove, index: toIndex };
        ruleOriginalPayload = updatedRule;
        rules.splice(toIndex, 0, updatedRule);
      }
    });

    try {
      // if we have smth to save to BE
      if (ruleOriginalPayload) {
        await updateRule(partnerId, this.rulesPackage.id, ruleId, ruleOriginalPayload);
        // updating rules list which is going to be saved to store
        // rules - mutated on previous step rules list
        rulesListToStore = rules;
      }
    } catch {
      runInAction(() => {
        if (this.rulesPackage) {
          this.rulesPackage.rules.replace(rulesListToStore);
        }
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  sendRuleData = async (
    ruleData: Rule | RuleDraft,
    { mode = SEND_RULES_DATA_MODE.UPDATE }: { mode: SEND_RULES_DATA_MODE }
  ) => {
    if (!this.rulesPackage) {
      return Promise.reject('There is no fetched Rule');
    }

    runInAction(() => {
      this.isLoading = true;
    });

    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    const { rules = [] } = this.rulesPackage ?? {};

    let savedRule: Rule | null = null;
    try {
      if (mode === SEND_RULES_DATA_MODE.DELETE) {
        const { data } = await deleteRule(partnerId, this.rulesPackage.id, ruleData.id);
        savedRule = data;
      }

      if (mode === SEND_RULES_DATA_MODE.UPDATE) {
        const { data } = await updateRule(partnerId, this.rulesPackage.id, ruleData.id, ruleData);
        savedRule = data;
      }

      if (mode === SEND_RULES_DATA_MODE.CREATE) {
        const { data } = await createRule(partnerId, this.rulesPackage.id, ruleData);
        savedRule = data;
      }

      // mutating current list of rules to display result of drugging for user
      runInAction(() => {
        if (savedRule) {
          // mutating riules list to display changes
          if (mode === SEND_RULES_DATA_MODE.DELETE) {
            // we cant use savedRule.indexHere since we mutating local copy of rules list
            // and if you have 5 items [0, 1, 2, 3, 4] and delete item with index 2 you will get
            // following list as result [0, 1, 3, 4]. so now rule.index is not the same as
            // index in local list and you got a problem as soon as user start remove more items
            // from list
            rules.splice(this.getRuleIndexInList(savedRule.id), 1);
          } else {
            rules.splice(savedRule.index, 1, savedRule);
          }
        }
        this.isLoading = false;
      });

      // could be handy to have updated rule in form component
      // for for example reseting form with newely saved value
      return savedRule;
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
      return Promise.reject(error);
    }
  };

  @action
  clearRulesPackage = () => {
    this.rulesPackage = null;
    this.searchTerm = '';
    this.isLoading = false;
  };
}
