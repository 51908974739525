export enum CODE24_MODEL_TYPES {
  SEARCH_TERMS = 'SEARCH_TERMS',
  PRE_TRIAGE = 'PRE_TRIAGE',
  POST_TRIAGE = 'POST_TRIAGE',
  PRE_POST_TRIAGE = 'PRE_POST_TRIAGE',
  HEALTH_TEST = 'HEALTH_TEST',
  INCLUDE = 'INCLUDE',
  FORMULA = 'FORMULA',
  EXIT = 'EXIT',
  SEARCH_TERM = 'SEARCH_TERM',
  GOTO = 'GOTO',
  QUESTION = 'QUESTION',
  DEFAULT_VALUES = 'DEFAULT',
  CALL = 'CALL',
  CHECK = 'CHECK',
  GUIDANCE = 'GUIDANCE',
  RESPONSE = 'RESPONSE',
  LAST_RESPONSE = 'LAST_RESPONSE',
  LONG_RESPONSE = 'LONG_RESPONSE',
  METADATA = 'METADATA',
  INTRO = 'INTRO',
  EXPORT_DATA = 'EXPORT_DATA',
  IMPORT_DATA = 'IMPORT_DATA',
}

export enum QUESTION_TYPES {
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
  YESNO = 'YESNO',
  YESNOMAYBE = 'YESNOMAYBE',
  FILE = 'FILE',
  MEDICATION = 'MEDICATION',
  DATE = 'DATE',
  FREETEXT = 'FREETEXT',
  NUMBER = 'NUMBER',
  RANGE = 'RANGE',
}

export enum QUESTION_SHORT_RESPONSES {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
}

export type ConditionSectionModelType =
  | CODE24_MODEL_TYPES.SEARCH_TERMS
  | CODE24_MODEL_TYPES.PRE_TRIAGE
  | CODE24_MODEL_TYPES.POST_TRIAGE
  | CODE24_MODEL_TYPES.PRE_POST_TRIAGE
  | CODE24_MODEL_TYPES.HEALTH_TEST;

export type ConditionStatementModelType = Exclude<CODE24_MODEL_TYPES, ConditionSectionModelType>;

export const EDITABLE_CONDITION_SECTION_MODEL_TYPES: ConditionSectionModelType[] = [
  CODE24_MODEL_TYPES.PRE_TRIAGE,
  CODE24_MODEL_TYPES.POST_TRIAGE,
  CODE24_MODEL_TYPES.PRE_POST_TRIAGE,
  CODE24_MODEL_TYPES.HEALTH_TEST,
];

export enum CODE24_CATEGORIES {
  TRIAGE = 'triage',
  DIAGNOSIS = 'diagnosis',
  ASSESSMENT = 'assessment',
  LIBRARIES = 'libraries',
}

// This list can be also fetched from GET /rest/admin/code24/code24-templates if needed,
// but currently it would be a kind of overengineering to get it this way.
// See https://platform24.atlassian.net/browse/AX-14048 for details
export const TEMPLATED_CONDITION_CATEGORIES = [
  CODE24_CATEGORIES.DIAGNOSIS,
  CODE24_CATEGORIES.ASSESSMENT,
  CODE24_CATEGORIES.LIBRARIES,
];

export enum EXIT_ATTRIBUTES {
  LEVEL_OF_CARE = 'levelOfCare',
  URGENCY = 'urgency',
  RESOURCE = 'resource',
  CAPABILITY = 'capability',
  TYPE_OF_CONSULTATION = 'typeOfConsultation',
  URL = 'url',
  APPOINTMENT_PRIORITY = 'appointmentPriority',
}

export const EXIT_ATTRIBUTES_KEYS = Object.values(EXIT_ATTRIBUTES);

export const DISABLED_CONDITIONS = ['libraryPredefinedFormulas'];

export const DISABLED_STATEMENT_GROUPS = ['partner', 'test', 'tests'];

export const DISABLED_EXIT_ATTRIBUTES: (string | number)[] = [
  'call',
  'request',
  'prescriptions',
  'certificate',
  'clearActionTextPatient',
  'notLifeThreatening',
  'selfAssessed',
  'noUrgency',
  'noTriage',
  'redirectToDoktor24',
  'extraSelfCare',
];
