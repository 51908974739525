import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Button, Row, Col } from 'antd';
import React, { useState, ChangeEvent, useCallback, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './TextlistForm.module.css';

// This component follows guidelines for Antd 4.x customized form controls
// https://ant.design/components/form/#components-form-demo-customized-form-controls

interface Props {
  isSaving: boolean;
  isDisabled?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
}

const TextlistForm = forwardRef<HTMLDivElement, Props>(
  ({ value = [], onChange, isSaving, isDisabled }, ref) => {
    const isFormDisabled = isSaving || isDisabled;
    const [values, setValues] = useState<string[]>(value);

    const applyValuesChanges = useCallback(
      (values: string[]) => {
        setValues(values);
        if (onChange) {
          onChange(values.filter(val => val.trim() !== ''));
        }
      },
      [onChange]
    );

    const handleRemove = useCallback(
      (index: number) => {
        // at least one item must be set
        if (values.length === 1 || isFormDisabled) {
          return;
        }

        applyValuesChanges(values.filter((_, i) => i !== index));
      },
      [applyValuesChanges, isFormDisabled, values]
    );

    const handleAdd = useCallback(() => {
      applyValuesChanges(values.concat(''));
    }, [applyValuesChanges, values]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.currentTarget.value;
        const newValues = values.map((currentValue, i) => {
          if (i === index) {
            return value;
          }
          return currentValue;
        });

        applyValuesChanges(newValues);
      },
      [applyValuesChanges, values]
    );

    return (
      <div className={styles.form} ref={ref}>
        {values.map((_, i) => (
          <Row key={i} gutter={[16, 8]} className={styles.row}>
            <Col span={20}>
              <Input
                name={`values[${i}]`}
                value={values[i]}
                onChange={event => handleChange(event, i)}
                disabled={isFormDisabled}
              />
            </Col>
            <Col span={4}>
              {values.length > 1 ? <DeleteOutlined onClick={() => handleRemove(i)} /> : null}
            </Col>
          </Row>
        ))}
        <Row className={styles.row}>
          <Col>
            <Button type="dashed" onClick={handleAdd} disabled={isFormDisabled}>
              <PlusOutlined /> <FormattedMessage id="general.add-more" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
);
TextlistForm.displayName = 'TextlistForm';

export default TextlistForm;
