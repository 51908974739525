import { ContentState } from 'draft-js';
import React, { ReactNode } from 'react';

interface Props {
  entityKey: string;
  contentState: ContentState;
  children: ReactNode;
}

const Link = ({ contentState, entityKey, children }: Props) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

export default Link;
