import { Skeleton } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import styles from './CarePathways.module.css';
import { usePathwaysProps } from './carePathways.utils';
import withRootStoreProp from '../../components/HOC/withRootStoreProp';
import RootStore from '../../stores/RootStore';
import RemoteComponent from '../../utils/remoteModules/remoteComponent';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

const CarePathwayTemplatePage = ({ rootStore }: { rootStore: RootStore }) => {
  const { releaseStatus, templateName } = useParams<{
    templateName: string;
    releaseStatus: string;
  }>();

  const baseProps = useMemo(
    () => ({
      templateName: templateName,
      releaseStatus: releaseStatus,
    }),
    [templateName, releaseStatus]
  );

  const pathwayTemplateEditorProps = usePathwaysProps(rootStore, baseProps);

  return (
    <>
      <PartnerStatus />
      <div className={styles.container}>
        <React.Suspense fallback={<Skeleton />}>
          <RemoteComponent
            suspense
            module="pathwaysManage/PathwayTemplateEditor"
            props={pathwayTemplateEditorProps}
          />
        </React.Suspense>
      </div>
    </>
  );
};

export default withRootStoreProp(CarePathwayTemplatePage);
