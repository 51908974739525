import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import { PARTNERS_URI } from 'api/partnersApi';
import { LANGS } from 'constants/enums';
import { ConditionValidationError } from 'modules/Content24/api/reportsApi';
import { TranslatedText } from 'types/types';
import { makeParamsString } from 'utils/urlUtils';

import { CODE24_CATEGORIES, CODE24_MODEL_TYPES, EXIT_ATTRIBUTES } from '../constants/code24types';
import {
  Statement,
  Section,
  Question,
  Exit,
  Goto,
  SearchTerm,
  Metadata,
} from '../models/Code24Model';
import { stripIdAndOriginalStatement } from '../utils/api';

export interface Condition {
  type: string;
  origin?: string;
  defaultValues?: {
    referralQuestion: TranslatedText;
    referralServiceRequested: TranslatedText;
    type: string;
    icdCode?: string;
    codes?: { [key: string]: string[] };
  };
  metadata: Metadata;
  searchTerms?: SearchTerm[];
  sections?: Section[];
}

export interface NewPartnerCondition {
  id: string;
  conditionId: string;
  group: string;
  createPostTriage?: boolean;
  createPreTriage?: boolean;
  copyOf?: string;
  isHidden?: boolean;
}

export interface StatementUpdate {
  after?: string;
  disable?: string;
  replaces?: string;
  // "update" also means "create", so id is optional
  model: Omit<Statement, 'id'> & { id?: string };
}

export interface StatementUpdated {
  after?: string;
  disable?: string;
  replaces?: string;
  model?: Statement;
}

export interface StatementDisabled {
  disable: string;
}

export interface SectionUpdate {
  after?: string;
  model: Section;
}

export interface SectionUpdated {
  after?: string;
  model: Section;
}

export interface ConditionInfo {
  conditionId: string;
  group: string;
  description: TranslatedText;
  originId?: string;
}

export interface VariableDependency {
  id: string;
  value: string;
  type: CODE24_MODEL_TYPES.QUESTION;
  not: boolean;
}

export interface VisualizationItem {
  variableDependencies: VariableDependency[];
  source: string;
}

export interface QuestionVisualizationModel extends VisualizationItem {
  questionModel: Question;
}

export interface ExitVisalizationModel extends VisualizationItem {
  exitModel: Exit;
}

export interface GotoVisualizationModel {
  afterQuestionId: string;
  gotoConditionId: string;
  gotoModel: Goto;
  variableDependencies: VariableDependency[];
}

export interface ConditionVisualization {
  conditionId: string;
  questions: QuestionVisualizationModel[];
  gotos: GotoVisualizationModel[];
  exits: ExitVisalizationModel[];
  properties: string[];
  couldNotBuildNonFormulaCount: number;
}

export enum EXIT_CATEGORY_SOURCE {
  SYSTEM = 'SYSTEM',
}

export interface ExitCategory {
  id: string | number;
  source: EXIT_CATEGORY_SOURCE.SYSTEM | string;
  display: TranslatedText;
}

export interface ExitCategories {
  [EXIT_ATTRIBUTES.CAPABILITY]: ExitCategory[];
  [EXIT_ATTRIBUTES.LEVEL_OF_CARE]: ExitCategory[];
  offline: ExitCategory[];
  [EXIT_ATTRIBUTES.RESOURCE]: ExitCategory[];
  [EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION]: ExitCategory[];
  [EXIT_ATTRIBUTES.URGENCY]: ExitCategory[];
}

export interface ConditionSearchTerms {
  // conditionId
  id: string;
  // condition description
  description: TranslatedText;
  searchTerms: {
    id: string;
    term: TranslatedText;
  }[];
}

export enum HEALTH_DATA_CODE_TYPE {
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  CODE = 'CODE',
}

export interface HealthDataCode {
  id: string;
  type: HEALTH_DATA_CODE_TYPE;
  description: TranslatedText;
}

export interface ServiceRequestCode {
  code: string;
  type: string;
  description: TranslatedText;
  healthDataCodes: string[];
}

export enum PARTNER_SPECIFIC_ATTRIBUTE {
  EXIT = 'exit',
}

export interface PartnerAttribute {
  id: string;
  type: string;
  displayPractitioner: TranslatedText;
}

export interface PartnerAttributeWithValueId extends PartnerAttribute {
  value: string;
}

export type PartnerSpecificAttributes = {
  [PARTNER_SPECIFIC_ATTRIBUTE.EXIT]?: PartnerAttribute[];
};

// Please note: API still requires originId, but Content24 is now used in Partner scope. Using partnerId for both params is
// a "hacky" way to handle that.

export const createOriginCondition = (partnerId: string, condition: NewPartnerCondition) =>
  axios.post<Condition>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${encodeURIComponent(
      condition.conditionId
    )}${makeParamsString(
      omit(condition, ['conditionId', 'isHidden', 'id', 'group', 'templateName'])
    )}`
  );

export const createTemplatedOriginCondition = (
  partnerId: string,
  condition: NewPartnerCondition,
  templateName: CODE24_CATEGORIES
) =>
  axios.post<Condition>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${encodeURIComponent(
      condition.conditionId
    )}${makeParamsString({ templateName })}`
  );

export const updateOriginCondition = (partnerId: string, condition: Condition) =>
  axios.put<Condition>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${encodeURIComponent(
      condition.metadata.conditionId
    )}`,
    condition
  );

export const fetchOriginConditions = (partnerId: string) =>
  axios.get<ConditionInfo[]>(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition`);

export const fetchDefaultConditions = (partnerId: string) =>
  axios.get<ConditionInfo[]>(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/hooks/condition`);

export const fetchOriginCondition = async (partnerId: string, conditionId: string) =>
  axios.get<Condition>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}`,
    // Should not display general error message, as it's ok to have it not present
    { ignoreErrorStatuses: [404] }
  );

export const fetchDefaultCondition = async (partnerId: string, conditionId: string) =>
  axios.get<Condition>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/hooks/condition/${conditionId}`
  );

export const createOriginStatement = (
  partnerId: string,
  conditionId: string,
  statementUpdate: StatementUpdate
) => {
  const cleanStatementUpdate = cloneDeep(statementUpdate);

  delete cleanStatementUpdate.model.id;

  return axios.post<StatementUpdated>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/statement`,
    cleanStatementUpdate,
    // Should not display general error message, as bad request error is handled by the validation methods
    { ignoreErrorStatuses: [400] }
  );
};

export const deleteOriginStatement = (
  partnerId: string,
  conditionId: string,
  statementId: string
) =>
  axios.delete(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/statement/${statementId}`
  );

export const createOriginSection = (
  partnerId: string,
  conditionId: string,
  sectionUpdate: SectionUpdate
) =>
  axios.post<SectionUpdated>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/statement`,
    sectionUpdate
  );

export const updateOriginStatement = (
  partnerId: string,
  conditionId: string,
  statementUpdate: StatementUpdate
) => {
  const updatePayload = stripIdAndOriginalStatement(statementUpdate);

  return axios.put<StatementUpdated>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/statement/${statementUpdate.model.id}`,
    updatePayload,
    // Should not display general error message, as bad request error is handled by the validation methods
    { ignoreErrorStatuses: [400] }
  );
};

export const fetchConditionExitCategories = (partnerId: string) =>
  axios.get<ExitCategories>(`${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/exit`);

export const fetchConditionVisualization = async (partnerId: string, conditionId: string) =>
  axios.get<ConditionVisualization>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/visualization`
  );

export const fetchSearchTerms = async (partnerId: string) =>
  axios.get<ConditionSearchTerms[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/search-terms`,
    // Should not display general error message, as it's ok to have it not present
    { ignoreErrorStatuses: [404] }
  );

export const fetchOriginHealthDataCodes = async (partnerId: string) =>
  axios.get<HealthDataCode[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/health-data-code`
  );

export const validateOriginCondition = (partnerId: string, conditionId: string) =>
  axios.get<ConditionValidationError[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/condition/${conditionId}/validation`
  );

export const fetchOriginServiceRequestCodes = async (partnerId: string) =>
  axios.get<ServiceRequestCode[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/service-request-code`
  );

export const fetchPartnerSpecificAttributes = async (partnerId: string) =>
  axios.get<PartnerSpecificAttributes>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/partner-attributes`
  );

export const fetchCode24Text = async (
  partnerId: string,
  typeId: string,
  id: string,
  language: LANGS
) =>
  axios.get<string | number>(
    `${PARTNERS_URI}/${partnerId}/code24/text/${typeId}/${id}/${language}`,
    // Should not display general error message, as it's ok to have it not present (as API returns 400 for non-exisiting code24 text blocks)
    { ignoreErrorStatuses: [404], headers: { Accept: 'text/html' } }
  );

export const createCode24Text = async (
  partnerId: string,
  typeId: string,
  id: string,
  language: LANGS,
  value: string
) => {
  return axios.post<string>(
    `${PARTNERS_URI}/${partnerId}/code24/text/${typeId}/${id}/${language}`,
    value,
    {
      headers: {
        'Content-Type': 'text/html',
      },
    }
  );
};

export const updateCode24Text = async (
  partnerId: string,
  typeId: string,
  id: string,
  language: LANGS,
  value: string
) => {
  return axios.put<string>(
    `${PARTNERS_URI}/${partnerId}/code24/text/${typeId}/${id}/${language}`,
    value,
    {
      headers: {
        'Content-Type': 'text/html',
      },
    }
  );
};

export const deleteCode24Text = async (
  partnerId: string,
  typeId: string,
  id: string,
  language: LANGS
) => axios.delete(`${PARTNERS_URI}/${partnerId}/code24/text/${typeId}/${id}/${language}`);
