import { observable, computed, action, runInAction, IObservableArray } from 'mobx';
import uuid4 from 'uuid/v4';

import { ACCESS_LEVEL } from 'api/permissionsApi';
import { RESOURCE_TYPES } from 'constants/permissions';
import {
  createValidationReport,
  fetchLastCompletedValidationReport,
  fetchValidationReports,
} from 'modules/Content24/api/reportsApi';
import {
  createOriginCondition,
  NewPartnerCondition,
  fetchOriginConditions,
  updateOriginStatement,
  ConditionInfo,
  createTemplatedOriginCondition,
  fetchDefaultConditions,
} from 'modules/Content24/Condition/api/partnerCode24api';
import { REPORT_STATUSES } from 'modules/Content24/constants/reports';
import { intlNotification } from 'state/notification';
import RootStore from 'stores/RootStore';
import { TranslatedText } from 'types/types';

import { CODE24_CATEGORIES } from '../Condition/constants/code24types';
import { Metadata } from '../Condition/models/Code24Model';

export type ExtendedCondition = ConditionInfo & {
  description: TranslatedText;
  localizedGroup: TranslatedText;
  isLocalCopy?: boolean;
  isHidden?: boolean;
  isValid?: boolean;
};

export interface ConditionsValidationStatus {
  conditionsWithErrors: string[];
  validatedAt: string | null;
  isNewValidationInProgress: boolean;
}

export default class ConditionsListStore {
  noErrorReport = {
    conditionsWithErrors: [],
    validatedAt: null,
    isNewValidationInProgress: false,
  };
  @observable.shallow newCondition?: NewPartnerCondition;

  defaultConditions: IObservableArray<ConditionInfo> = observable([]);
  private originConditions: IObservableArray<ConditionInfo> = observable([]);
  @observable
  private isLoadingConditions = false;
  @observable
  private isSavingConditions = false;
  @observable
  isLoadingValidationStatus = false;
  @observable
  conditionsValidationStatus: ConditionsValidationStatus = this.noErrorReport;

  constructor(private rootStore: RootStore) {}

  initialize = async () =>
    Promise.all([this.fetchConditions(), this.fetchConditionsValidationStatus()]);

  refreshValidationStatus = () => {
    this.fetchConditionsValidationStatus();
  };

  @action
  private async fetchConditions() {
    const {
      partnersStore: { partnerId },
      content24Store: { code24MedconVersion },
      userPermissionsStore,
    } = this.rootStore;
    const canReadDefaultConditions = userPermissionsStore.getPermission(
      {
        // Currently there's no way to set partner scope permission in Manage24
        // Details: https://platform24.atlassian.net/browse/AX-30158
        originId: partnerId,
        resourceType: RESOURCE_TYPES.CODE24,
        accessLevel: ACCESS_LEVEL.READ,
      },
      true
    );

    if (!canReadDefaultConditions) {
      return;
    }

    try {
      this.isLoadingConditions = true;

      const isMedicalContentVersionSet = !!code24MedconVersion;
      const requests = await Promise.all([
        fetchOriginConditions(partnerId),
        isMedicalContentVersionSet
          ? fetchDefaultConditions(partnerId)
          : Promise.resolve({ data: [] as ConditionInfo[] }),
      ]);
      const originConditions = requests[0].data;
      const defaultConditions = requests[1].data
        // filter out conditions forked in Origin
        .filter(
          (condition: ConditionInfo) =>
            !originConditions.some(
              originCondition => condition.conditionId === originCondition.conditionId
            )
        );

      runInAction(() => {
        this.originConditions.replace(originConditions);
        this.defaultConditions.replace(defaultConditions);
      });
    } finally {
      runInAction(() => {
        this.isLoadingConditions = false;
      });
    }
  }

  @action
  private async fetchConditionsValidationStatus() {
    try {
      const {
        partnersStore: { partnerId },
        userPermissionsStore,
      } = this.rootStore;

      if (
        !userPermissionsStore.getPermission(
          {
            // Currently there's no way to set partner scope permission in Manage24
            // Details: https://platform24.atlassian.net/browse/AX-30158
            originId: partnerId,
            resourceType: RESOURCE_TYPES.HOOKS,
            accessLevel: ACCESS_LEVEL.READ,
          },
          true
        )
      ) {
        this.conditionsValidationStatus = this.noErrorReport;
        return;
      }

      this.isLoadingValidationStatus = true;

      const response = await fetchValidationReports(partnerId);
      const reports = response.data.reverse();
      const lastCompletedReport = reports.find(({ status }) => status === REPORT_STATUSES.COMPLETE);
      const lastRunningReport = reports.find(({ status }) =>
        [REPORT_STATUSES.RUNNING, REPORT_STATUSES.QUEUED].includes(status)
      );
      const report: ConditionsValidationStatus = { ...this.noErrorReport };

      report.isNewValidationInProgress = !!lastRunningReport;

      if (lastCompletedReport) {
        const { data } = await fetchLastCompletedValidationReport(
          partnerId,
          lastCompletedReport.reportId
        );

        report.validatedAt = lastCompletedReport.createdAt;

        if (data.length) {
          report.conditionsWithErrors = data.map(({ conditionId }) => conditionId);
        }
      }

      runInAction(() => {
        this.conditionsValidationStatus = report;
      });
    } finally {
      runInAction(() => {
        this.isLoadingValidationStatus = false;
      });
    }
  }

  @computed
  get isLoading() {
    return this.isLoadingConditions || this.isSavingConditions;
  }

  @computed
  get conditions(): ExtendedCondition[] {
    const {
      content24Store: { groups, hiddenConditions },
    } = this.rootStore;

    const originConditionsWithStatus = this.originConditions.map(condition => ({
      ...condition,
      isLocalCopy: true,
    }));

    const allConditions = this.defaultConditions.concat(originConditionsWithStatus);

    const allExtendedConditions: ExtendedCondition[] = allConditions.map(condition => {
      const { group, description } = condition;
      const localizedGroup = groups.find(({ id }) => id === group);
      const isHidden = hiddenConditions.includes(condition.conditionId);
      const isValid = !this.conditionsValidationStatus.conditionsWithErrors.some(
        id => id === condition.conditionId
      );

      return {
        ...condition,
        description: description || {},
        localizedGroup: localizedGroup || {},
        isHidden,
        isValid,
      };
    });

    return allExtendedConditions;
  }

  @computed
  get lowerCasedConditionIds(): string[] {
    return this.conditions.map(({ conditionId }) => conditionId?.toLowerCase());
  }

  @action
  handleAddCondition = () => {
    this.newCondition = {
      id: uuid4(),
      conditionId: '',
      group: '',
      createPostTriage: false,
      createPreTriage: false,
      isHidden: false,
    };
  };

  @action
  handleCancelAddCondition = () => {
    this.newCondition = undefined;
  };

  @action
  handleCreateCondition = async (condition: NewPartnerCondition) => {
    const {
      content24Store,
      partnersStore: { partnerId },
    } = this.rootStore;

    try {
      this.isSavingConditions = true;

      const { data: createdCondition } = await createOriginCondition(partnerId, condition);

      // As for now, condition group has to be added separately, by updating the newly created condition
      const { data: updatedMetadata } = await updateOriginStatement(
        partnerId,
        createdCondition.metadata.conditionId,
        {
          model: {
            ...createdCondition.metadata,
            level1id: condition.group,
            level1group: condition.group,
          } as Metadata,
        }
      );

      if (condition.isHidden) {
        await content24Store.updateHiddenConditionsCustomization(
          true,
          createdCondition.metadata.conditionId
        );
      }

      // Set condition data in ConditionStore, to avoid redundant fetch when user is redirected
      // to the Condition view
      this.rootStore.conditionStore.setNewlyCreatedOriginCondition({
        ...createdCondition,
        metadata: updatedMetadata.model as Metadata,
      });

      runInAction(() => {
        this.newCondition = undefined;
      });

      intlNotification.success(
        {
          frmMessage: {
            id: 'condition-add.condition-successfully-created',
          },
        },
        {
          placement: 'top',
        }
      );

      return createdCondition.metadata.conditionId;
    } finally {
      runInAction(() => {
        this.isSavingConditions = false;
      });
    }
  };

  @action
  handleCreateTemplatedCondition = async (
    condition: NewPartnerCondition,
    category: CODE24_CATEGORIES
  ) => {
    const {
      partnersStore: { partnerId },
      content24Store,
    } = this.rootStore;

    try {
      this.isSavingConditions = true;

      const { data: createdCondition } = await createTemplatedOriginCondition(
        partnerId,
        condition,
        category
      );

      if (condition.isHidden) {
        await content24Store.updateHiddenConditionsCustomization(
          true,
          createdCondition.metadata.conditionId
        );
      }

      runInAction(() => {
        this.newCondition = undefined;
      });

      // Set condition data in ConditionStore, to avoid redundant fetch when user is redirected
      // to the Condition view
      this.rootStore.conditionStore.setNewlyCreatedOriginCondition(createdCondition);

      intlNotification.success(
        {
          frmMessage: {
            id: 'condition-add.condition-successfully-created',
          },
        },
        {
          placement: 'top',
        }
      );

      return createdCondition.metadata.conditionId;
    } finally {
      runInAction(() => {
        this.isSavingConditions = false;
      });
    }
  };

  @action
  handleValidateConditions = async () => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    await createValidationReport(partnerId);

    intlNotification.success(
      {
        frmMessage: {
          id: 'condition.new-report-initiated',
        },
      },
      {
        placement: 'top',
      }
    );
  };

  @action
  updateAllConditions = () => {
    this.fetchConditions();
    this.fetchConditionsValidationStatus();
  };
}
