import { Card, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { usePartnerImageData } from 'api/partnersApi.hooks';
import { getPartnerId } from 'utils/tokenUtils';

import { Article } from '../../../types/article';
import { CardTitle } from '../CardTitle';
import { ThumbnailImageFrame } from '../ThumbnailImageFrame';

const Styled = {
  ContentContainer: styled.div`
    display: flex;
    gap: 24px;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
    gap: 4px;

    span {
      display: block;
      font-size: 16px !important;
    }
  `,
  TagsSection: styled.div`
    display: flex;
    gap: 4px;
  `,
  TagsContainer: styled.div`
    display: flex;
    gap: 4px;
  `,
};

export const PreviewMetadataCard = ({
  article,
  selectedLanguage,
}: {
  article: Article;
  selectedLanguage: string;
}) => {
  const { metadata } = article;

  const partnerId = getPartnerId();

  const imageDataQuery = usePartnerImageData({
    partnerId: partnerId!,
    imageId: metadata.thumbnail,
  });
  const isImageLoading = imageDataQuery.isLoading && imageDataQuery.fetchStatus !== 'idle';
  const imageBlobUrl = imageDataQuery.data ? URL.createObjectURL(imageDataQuery.data) : null;

  const title = metadata.title[selectedLanguage];
  const description = metadata.description?.[selectedLanguage];

  return (
    <Card size="small">
      <CardTitle tooltip={<FormattedMessage id="articles.metadata.tooltip" />}>
        <FormattedMessage id="articles.metadata.title" />
      </CardTitle>

      <Styled.ContentContainer>
        <ThumbnailImageFrame src={imageBlobUrl} loading={isImageLoading} />

        <Styled.TextContainer>
          {title && <Typography.Text strong>{title}</Typography.Text>}
          {description && <Typography.Text>{description}</Typography.Text>}
        </Styled.TextContainer>
      </Styled.ContentContainer>
    </Card>
  );
};
