import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

const PrivateRoute = ({ component: Component, render, ...rest }: RouteProps) => {
  const { authStore } = useContext(RootStoreContext);
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderComponent = (props: any) => {
    if (render) {
      return render(props);
    }

    if (Component) {
      return <Component {...props} />;
    }

    return null;
  };

  return (
    <Route
      {...rest}
      render={props =>
        authStore.isAuthenticated() ? (
          renderComponent(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
