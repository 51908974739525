import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import mergeWith from 'lodash/mergeWith';
import omitBy from 'lodash/omitBy';
import { toJS } from 'mobx';

/* We need these utilities to:
- Merge the initial C24 data that is delivered to the form with the initial form values matrix.
  So that if for example there is no condition field in the initial C24 data and that field value
  is added then removed (becoming empty string) in the form, Formik won't treat it as an update.
- Remove empty strings from the form values that are submitted, because in many cases empty string value
  (instead of null) in C24 data results in BE error.
*/

/**
 * Takes default form values and optional initial data and merges
 * them into initial form data (empty strings as empties)
 *
 * @param defaultFormValues object that represents form data shape
 * @param initialData optional initial data
 */
export function getInitialFormValues<P, K>(defaultFormValues: K, initialData?: P) {
  return mergeWith(
    toJS(initialData),
    defaultFormValues,
    (destinationProperty: any, sourceProperty: any) => {
      // return default schema value if initial data value is empty
      if (destinationProperty === undefined || destinationProperty == null) {
        return sourceProperty;
      }

      return destinationProperty;
    }
  ) as K;
}

/**
 * Takes final Formik form values and convert them to basic data by removing empties,
 * explicitly provided properties and casting strings to numbers when needed
 *
 * @param data Formik form data
 * @param propertiesToRemove array of property key names
 */
export function getDataFromFinalFormValues<P>(data: any, propertiesToRemove?: string[]): P {
  return stripEmptyFormValues(data, propertiesToRemove) as P;
}

export function stripEmptyFormValues(
  data: any,
  propertiesToRemove?: string[]
): any[] | Record<string, any> | number | string | undefined {
  // Convert array recursively, strip empty values
  if (Array.isArray(data)) {
    const result = data.reduce((accumulator: any[], value: any) => {
      const converted = stripEmptyFormValues(value, propertiesToRemove);

      if (converted !== undefined) {
        accumulator.push(converted);
      }

      return accumulator;
    }, []);

    if (result.length) {
      return result;
    }

    return undefined;
  }

  // Convert objects recursively, strip empty values and properties that needs to be removed
  if (isPlainObject(data)) {
    const result = omitBy(
      mapValues(data, (value: any) => stripEmptyFormValues(value, propertiesToRemove)),
      (propertyValue, propertyKey) =>
        propertyValue === undefined || propertiesToRemove?.includes(propertyKey)
    );

    if (Object.keys(result).length) {
      return result;
    }

    return undefined;
  }

  // Convert empty number to undefined
  if (data === null) {
    return undefined;
  }

  // Convert empty string to undefined
  if (data === '') {
    return undefined;
  }

  // return string
  return data;
}
