import { Select, Form } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { LANGS } from 'constants/enums';
import RootStoreContext from 'context/RootStoreContext';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface ServiceRequestFormFieldsProps {
  activeLanguage: LANGS;
  isDisabled?: boolean;
}

const ServiceRequestFormFields: FunctionComponent<ServiceRequestFormFieldsProps> = ({
  isDisabled,
  activeLanguage,
}) => {
  const { content24Store } = useContext(RootStoreContext);

  useEffect(() => {
    content24Store.fetchServiceRequestCodes();
  }, []);

  return (
    <Form.Item
      name="serviceRequests"
      label={<FormattedMessage id="condition-edit.service-requests-label" />}
    >
      <Observer>
        {() => {
          const serviceRequestOptions = content24Store.serviceRequestCodes
            .map(({ code, description }) => ({
              value: code,
              label: description[activeLanguage] || code,
            }))
            .sort((a, b) => sortWithLocale(a, b, 'label'));

          return (
            <Select
              disabled={isDisabled}
              name="serviceRequests"
              mode="multiple"
              options={serviceRequestOptions}
              loading={content24Store.isLoadingServiceRequestCodes}
            />
          );
        }}
      </Observer>
    </Form.Item>
  );
};

export default ServiceRequestFormFields;
