import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useRootStore } from 'context/RootStoreContext';

export const useCareUnitLabel = (careUnitId?: string | null) => {
  const { careProviderId } = useParams<{ careProviderId?: string }>();
  const { careProvidersListStore } = useRootStore();

  const careUnitName = useMemo(() => {
    const careProvider = careProvidersListStore.careProviders.find(cp => cp.id === careProviderId);
    return careProvider?.careUnits?.find(cu => cu.id === careUnitId)?.name ?? '';
  }, [careProviderId, careProvidersListStore.careProviders, careUnitId]);

  return careUnitName;
};
