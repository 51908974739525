import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './PatientRiskMessage.module.css';
import { EXIT_ATTRIBUTES } from '../../constants/code24types';

/**
 * @notExported
 */
interface PatientRiskMessageProps {
  values: Record<string, any>;
  initialValues: Record<string, any>;
  keys: string[];
}

const PatientRiskMessage = ({ values, initialValues, keys }: PatientRiskMessageProps) => {
  const messages = keys
    .map(key => {
      if (values[key] !== initialValues[key]) {
        switch (key) {
          case EXIT_ATTRIBUTES.URGENCY:
            return <FormattedMessage id="condition-edit.patient-risk.urgency" />;
          case 'condition':
            return <FormattedMessage id="condition-edit.patient-risk.condition" />;
          default:
            return false;
        }
      }

      return false;
    })
    .filter(Boolean);

  if (!messages.length) {
    return null;
  }

  return (
    <Fragment>
      <FormattedMessage id="condition-edit.patient-risk.disclaimer" />
      <ul className={styles.list}>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </Fragment>
  );
};

export default PatientRiskMessage;
