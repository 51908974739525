import axios from 'axios';

import { ROLES } from 'constants/roles';

export enum CUSTOMIZATION_TYPES {
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  TEXTLIST = 'TEXTLIST',
}

export interface CustomizationRoleValue {
  role: ROLES;
  value: any;
}

export interface Customization {
  defaultForOriginGroup: boolean;
  defaultForSystem: boolean;
  editable: boolean;
  header: string;
  key: string;
  type: CUSTOMIZATION_TYPES;
  tags: string[];
  value?: any;
  description?: string;
  allowedValues?: any[];
  roles?: CustomizationRoleValue[];
}

export const CUSTOMIZATIONS_URI = '/rest/admin/partners';

export const fetchOriginCustomizations = (partnerId: string, originId: string, byTag?: string) =>
  axios.get<Customization[]>(
    `${CUSTOMIZATIONS_URI}/${partnerId}/origins/${originId}/customizations`,
    { params: byTag ? { tag: byTag } : undefined }
  );

export const fetchOriginCustomization = (
  partnerId: string,
  originId: string,
  customizationId: string
) =>
  axios.get<Customization>(
    `${CUSTOMIZATIONS_URI}/${partnerId}/origins/${originId}/customizations/${customizationId}?isIncludeParent=true`
  );

export const updateOriginCustomization = (
  data: Customization,
  partnerId: string,
  originId: string
) =>
  axios.put<Customization>(
    `${CUSTOMIZATIONS_URI}/${partnerId}/origins/${originId}/customizations/${data.key}`,
    data
  );

export const fetchCareUnitCustomizations = (
  partnerId: string,
  careProviderId: string,
  careUnitId: string
) =>
  axios.get<Customization[]>(
    `${CUSTOMIZATIONS_URI}/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/customizations`
  );

export const updateCareUnitCustomization = (
  data: Customization,
  partnerId: string,
  careProviderId: string,
  careUnitId: string
) =>
  axios.put<Customization>(
    `${CUSTOMIZATIONS_URI}/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/customizations/${data.key}`,
    data
  );
