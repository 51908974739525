import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';
import { ConfigSourceType } from 'types/types';
import { makeParamsString } from 'utils/urlUtils';

type TextContentType = 'Static' | 'FreeMaker' | 'Localized Text';

type TextContentCategory =
  | 'customization'
  | 'patientWebApp'
  | 'action'
  | 'consent'
  | 'meetingMessage'
  | 'tutorial'
  | 'actionType'
  | 'triageFeedback'
  | 'actionWarning'
  | 'notification'
  | 'actionHeadlineByImageUrl'
  | 'irregularity'
  | 'policy';

export interface TextContent {
  key: string;
  description: string;
  type: TextContentType;
  category: TextContentCategory;
  source: ConfigSourceType;
  translations: {
    sv?: string | null;
    en?: string | null;
    no?: string | null;
    [key: string]: string | null | undefined;
  };
}

export const fetchTextContent = (partnerId: string, originId: string, query: string) =>
  axios.get<TextContent[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${originId}/translated-text${makeParamsString({ query })}`
  );

export const updateTextContent = (partnerId: string, originId: string, translation: TextContent) =>
  axios.put(
    `${PARTNERS_URI}/${partnerId}/origins/${originId}/translated-text/${translation.key}`,
    translation
  );
