import Cookie from 'js-cookie';

export const COOKIE_AUTH_KEY = 'auth';
export const COOKIE_PARTNER_KEY = 'partner';

export const setToken = (token: string) => {
  Cookie.set(COOKIE_AUTH_KEY, token, { secure: true });
};

export const getToken = () => {
  return Cookie.get(COOKIE_AUTH_KEY);
};

export const removeToken = () => {
  Cookie.remove(COOKIE_AUTH_KEY);
};

export const setPartnerId = (partnerId: string) => {
  Cookie.set(COOKIE_PARTNER_KEY, partnerId, { secure: true });
};

export const getPartnerId = () => {
  return Cookie.get(COOKIE_PARTNER_KEY);
};

export const removePartnerId = () => {
  Cookie.remove(COOKIE_PARTNER_KEY);
};
