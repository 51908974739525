import { Col, Row } from 'antd';
import { FieldArrayRenderProps } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { QuestionResponse } from 'modules/Content24/Condition/models/Code24Model';

import { QuestionFormData } from '../../QuestionForm';
import ResponseFormFields from '../ResponseFormFields';

/**
 * @notExported
 */
interface QuestionContentItemProps {
  data: QuestionResponse;
  index: number;
  isSaving: boolean;
  formikArrayHelpers: FieldArrayRenderProps;
  activeLanguage: string;
  onTypeChange?: (
    type: CODE24_MODEL_TYPES.RESPONSE | CODE24_MODEL_TYPES.LAST_RESPONSE,
    index: number
  ) => void;
  isDisabled?: boolean;
}

const QuestionContentItem: FunctionComponent<QuestionContentItemProps> = ({
  data,
  index,
  isSaving,
  isDisabled,
  formikArrayHelpers,
  activeLanguage,
  onTypeChange,
  ...props
}) => {
  const intl = useIntl();
  const isFormDisabled = isSaving || isDisabled;
  const values: QuestionFormData = formikArrayHelpers.form.values;
  const content = values.content || [];
  const isLongResponse = data.type === CODE24_MODEL_TYPES.LONG_RESPONSE;
  const isSelectDisabled = isFormDisabled || isLongResponse;
  const isLastItemInList = index === content.length - 1;
  const isLastResponseAlreadySet = content.some(
    item => item.type === CODE24_MODEL_TYPES.LAST_RESPONSE
  );

  const questionContentTypeOptions = [
    {
      label: `condition.type-${CODE24_MODEL_TYPES.RESPONSE}`,
      value: CODE24_MODEL_TYPES.RESPONSE,
    },
    {
      label: `condition.type-${CODE24_MODEL_TYPES.LAST_RESPONSE}`,
      value: CODE24_MODEL_TYPES.LAST_RESPONSE,
      disabled: isLastResponseAlreadySet || !isLastItemInList,
    },
  ];

  const renderForm = useCallback(
    (type: CODE24_MODEL_TYPES) => {
      switch (type) {
        case CODE24_MODEL_TYPES.RESPONSE:
        case CODE24_MODEL_TYPES.LAST_RESPONSE:
          return (
            <ResponseFormFields
              isSaving={isSaving}
              isDisabled={isDisabled}
              index={index}
              activeLanguage={activeLanguage}
            />
          );
        case CODE24_MODEL_TYPES.LONG_RESPONSE:
          return (
            <ResponseFormFields
              isSaving={isSaving}
              isDisabled
              index={index}
              activeLanguage={activeLanguage}
            />
          );
        default:
          return null;
      }
    },
    [isSaving, isDisabled, activeLanguage, index]
  );

  return (
    <div {...props} key={index}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label={<FormattedMessage id="general.type" />}
            name={`content.${index}.type`}
            required
            hasFeedback
          >
            <Select
              name={`content.${index}.type`}
              disabled={isSelectDisabled}
              onChange={type => onTypeChange && onTypeChange(type, index)}
              options={questionContentTypeOptions.map(({ label, value, disabled }) => ({
                value,
                label: intl.formatMessage({ id: label }),
                disabled: !!disabled,
              }))}
            />
          </Form.Item>
        </Col>
        {renderForm(data.type)}
      </Row>
    </div>
  );
};

export default QuestionContentItem;
