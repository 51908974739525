/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  MedicineBoxOutlined,
  CaretDownFilled,
  CaretRightFilled,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Typography, Dropdown, Checkbox, Tooltip } from 'antd';
import { ColumnsType, ColumnType } from 'antd/es/table';
import isEmpty from 'lodash/isEmpty';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment, HtmlHTMLAttributes } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as IntlComponentProps,
} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withPractitionerRolesOptions, {
  PractitionerRolesOptionsProps,
} from 'components/HOC/withPractitionerRolesOptions';
import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import { ROLE_MENUITEMS } from 'constants/permissions';
import { Config } from 'constants/practitioner';
import {
  DEFAULT_ADMINISTRATIVE_ROLE,
  DEFAULT_PRACTITIONER_RECEIVING_ROLE,
  ROLES,
  ROLES_DESCRIPTION_KEY,
} from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import AddPractitionerRoles from 'modules/Practitioner/PractitionerRoles/components/AddPractitionerRoles';
import EditPractitionerRole from 'modules/Practitioner/PractitionerRoles/components/EditPractitionerRole';
import { PractitionerRoleDefinition } from 'modules/Practitioner/PractitionerRoles/PractitionerRoles';
import {
  ClinicRoleRowType,
  RoleInCareUnit,
  StructuredRoleInCareUnit,
} from 'modules/Practitioner/stores/PractitionerRolesStore';
import {
  displayCareUnitWithProviderName,
  getPartnerIdByCareUnitIdGivenAllCareUnits,
} from 'utils/role.utils';

import styles from './ClinicPersonalRoles.module.css';

interface ColumnIT {
  title: JSX.Element;
  dataIndex: string;
  render: (value: boolean, record: RoleInCareUnit) => false | JSX.Element;
}

interface Props
  extends RootStoreProps,
    PractitionerRolesOptionsProps,
    IntlComponentProps,
    RouteComponentProps<{ id: string }> {}

@observer
class ClinicPersonalRoles extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable newRoleDefinition?: PractitionerRoleDefinition;
  @observable activePractitionerRole?: RoleInCareUnit;

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  async initialize() {
    const {
      match: { params },
    } = this.props;
    const { careUnitsStore, practitionerRolesStore, practitionerStore } = this.context;

    if (
      (isEmpty(practitionerStore.data) || params.id !== practitionerStore.data.id) &&
      !practitionerStore.isLoading()
    ) {
      await practitionerStore.fetch(params.id);
    }

    await careUnitsStore.fetchAllCareUnits();
    await practitionerRolesStore.initializeRoles(this.props.match.params.id);
  }

  get columns(): ColumnsType<StructuredRoleInCareUnit | RoleInCareUnit> {
    const mColumns = [
      {
        title: <FormattedMessage id="roles.role" />,
        dataIndex: 'careUnitName',
        render: (_: string, record: StructuredRoleInCareUnit | RoleInCareUnit) => {
          if (record.rowType === ClinicRoleRowType.careUnit && 'label' in record) {
            return (
              <>
                <MedicineBoxOutlined className={styles.icon} />
                {record.label}
              </>
            );
          }

          if (record.rowType === ClinicRoleRowType.rolesGroup && 'label' in record) {
            return <FormattedMessage id={record.label} />;
          }

          if (record.rowType === ClinicRoleRowType.role && 'role' in record) {
            return ROLES_DESCRIPTION_KEY[record.role]
              ? this.props.intl.formatMessage({
                  id: ROLES_DESCRIPTION_KEY[record.role],
                })
              : record.role;
          }

          return null;
        },
      },

      {
        title: <FormattedMessage id="practitioner-roles-form.primary-role-label" />,
        dataIndex: 'isPrimaryRole',
        render: (value: boolean, record: RoleInCareUnit) =>
          typeof value === 'boolean' && (
            <Checkbox
              data-testid={`primary-role-${record.roleId}`}
              onChange={this.handlePrimaryRole(record, value)}
              checked={!!value}
            />
          ),
      },
      {
        title: <FormattedMessage id="general.actions" />,
        width: 100,
        render: (_: boolean, record: RoleInCareUnit) => {
          if (record.rowType === ClinicRoleRowType.role && 'role' in record) {
            return this.renderActionButtons(record);
          }
          return null;
        },
      },
    ];

    const exemption: ColumnType<RoleInCareUnit> = {
      title: <FormattedMessage id="practitioner-roles-form.exemption-label" />,
      dataIndex: 'exemptFromAutomaticHandling',
      render: (_, record) =>
        record.rowType === ClinicRoleRowType.role &&
        'lockedFromAutoManagement' in record && (
          <Tooltip title={<FormattedMessage id="practitioner-roles-form.exemption-tooltip" />}>
            <Checkbox
              onChange={this.handleRoleExemption(record)}
              checked={!!record.lockedFromAutoManagement}
            />
          </Tooltip>
        ),
      align: 'center',
    };
    const adminCanLockRolesFromAutoManagement =
      this.context.partnersStore.partnerCustomizations.get(
        Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
      );
    if (adminCanLockRolesFromAutoManagement) {
      mColumns.splice(2, 0, exemption as ColumnIT);
    }
    return mColumns as ColumnsType<StructuredRoleInCareUnit | RoleInCareUnit>;
  }

  renderActionButtons = (listItem: RoleInCareUnit) => {
    const { userDataStore: userStore, userPermissionsStore } = this.context;
    const isDeletableCareUnit =
      userPermissionsStore.isSuperAdmin ||
      !!userStore.userCareUnitsAsSelectOptions.find(
        careUnit => careUnit.value === listItem.careUnitId
      ) ||
      userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CLINIC_USER_ADMIN);

    if (!isDeletableCareUnit) {
      return null;
    }

    return (
      <div>
        <Popconfirm
          title={<FormattedMessage id="general.sure-to-delete" />}
          cancelText={<FormattedMessage id="general.cancel" />}
          onConfirm={this.handleRoleDelete(listItem)}
        >
          <Button
            type="link"
            icon={<DeleteOutlined />}
            data-testid={`delete-btn-${listItem.roleId}`}
          />
        </Popconfirm>
      </div>
    );
  };

  @action
  handleSetActivePractitionerRole = (role: RoleInCareUnit) => {
    this.activePractitionerRole = role;
  };

  @action
  handleClearActivePractitionerRole = () => {
    this.activePractitionerRole = undefined;
  };

  @computed
  get rolesSelectOptions() {
    const { rolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return this.props.administrativeRolesOptions;
    } else {
      return this.props.resourceTypesOptions;
    }
  }

  @computed
  get careUnitSelectOptions() {
    const {
      userDataStore: userStore,
      rolesStore,
      careUnitsStore,
      userPermissionsStore,
    } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    // Practitioner can have only one patient receiving role in care unit.
    // Practitioner can have multiple administrative roles in the same CU, but it cannot be the same role.

    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return userPermissionsStore.isSuperAdmin
        ? careUnitsStore.allCareUnitsAsSelectOptions
        : userStore.userCareUnitsAsSelectOptions;
    } else {
      const patientReceivingRoles = [
        ...this.userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions,
      ];

      if (this.activePractitionerRole) {
        const { careUnitId, careUnitName } = this.activePractitionerRole;
        const insertIndex = patientReceivingRoles.findIndex(
          ({ label }) => label.toLowerCase().localeCompare(careUnitName.toLowerCase()) > 0
        );
        patientReceivingRoles.splice(insertIndex, 0, {
          value: careUnitId,
          label: careUnitName,
        });
      }

      return patientReceivingRoles;
    }
  }

  @computed
  get userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions() {
    const { userDataStore: userStore, practitionerRolesStore } = this.context;

    return userStore.userCareUnitsAsSelectOptions.filter(
      cu => !practitionerRolesStore.patientReceivingRoles.some(role => role.careUnitId === cu.value)
    );
  }

  @computed
  get roleBasedCareUnitSelectOptions() {
    return this.context.userDataStore.roleBasedCareUnits.map(careUnit => ({
      value: careUnit.id,
      label: displayCareUnitWithProviderName(careUnit),
    }));
  }

  @computed
  get canAddPatientReceivingRole() {
    return !!this.userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions.length;
  }

  @computed
  get notAvailableCareUnitRoles() {
    // Practitioner can have multiple roles in the same CU, but it cannot be the same role
    const { rolesStore, practitionerRolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role || !rolesStore.administrativeRoles.includes(role)) {
      return [];
    }

    return practitionerRolesStore.administrativeRoles;
  }

  @action
  handleAddNewRole = async (role: ROLES) => {
    this.newRoleDefinition = {
      role,
      careUnitIds: [],
    };

    await this.getSelectableCareUnitsForClinicUserAdmin();
  };

  getSelectableCareUnitsForClinicUserAdmin = async () => {
    // Check if user has clinic_user_admin scoped role and then fetch care units from the new endpoint
    // else use the old way for showing  care units.
    const { userPermissionsStore, userDataStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    if (isClinicUserAdminOrAdmin) {
      await userDataStore.fetchCareUnitsBasedOnUserRole(ROLE_MENUITEMS.CLINIC_USER_ADMIN);
    }
  };

  @action
  handleClearNewRoleDefinition = () => {
    this.newRoleDefinition = undefined;
  };

  handleAddNewPractitionerRoles = async (practitionerRole: PractitionerRoleDefinition) => {
    const { practitionerRolesStore, userPermissionsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );
    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.addNewRoleV2(this.props.intl)(
          this.props.match.params.id,
          practitionerRole
        )
      : await practitionerRolesStore.addNewRole(this.props.intl)(
          this.props.match.params.id,
          practitionerRole
        );
    this.handleClearNewRoleDefinition();
  };

  handleEditPractitionerRole = async (data: RoleInCareUnit) => {
    const { practitionerRolesStore, userPermissionsStore, careUnitsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    const partnerId = getPartnerIdByCareUnitIdGivenAllCareUnits(
      careUnitsStore.allCareUnits,
      data.careUnitId
    );
    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.updatePractitionerRoleV2(this.props.intl)(
          this.props.match.params.id,
          data,
          partnerId
        )
      : await practitionerRolesStore.updatePractitionerRole(this.props.intl)(
          this.props.match.params.id,
          data
        );

    this.handleClearActivePractitionerRole();
  };

  handlePrimaryRole = (role: RoleInCareUnit, currentValue: boolean) => async () => {
    const newRole = { ...role, isPrimaryRole: !currentValue };
    this.handleEditPractitionerRole(newRole);
  };

  handleRoleExemption = (role: RoleInCareUnit) => async () => {
    const newRole = { ...role, lockedFromAutoManagement: !role.lockedFromAutoManagement };
    this.handleEditPractitionerRole(newRole);
  };

  onRowHandler = (record: StructuredRoleInCareUnit | RoleInCareUnit) => {
    return {
      'data-testid': record['data-testid'] || (record as StructuredRoleInCareUnit)?.id,
    } as HtmlHTMLAttributes<{ 'data-testid': string }>;
  };

  handleRoleDelete = (role: RoleInCareUnit) => async () => {
    const { practitionerRolesStore, userPermissionsStore, careUnitsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    const partnerId = getPartnerIdByCareUnitIdGivenAllCareUnits(
      careUnitsStore.allCareUnits,
      role.careUnitId
    );

    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.archivePractitionerExtendedRoleV2(this.props.intl)(
          this.props.match.params.id,
          role.roleId,
          role.careUnitId,
          partnerId
        )
      : await practitionerRolesStore.archivePractitionerExtendedRole(this.props.intl)(
          this.props.match.params.id,
          role.roleId
        );
  };

  handleMenuClick = ({ key }: { key: string }) => this.handleAddNewRole(key as ROLES);

  render() {
    const {
      careUnitsStore,
      practitionerRolesStore,
      practitionerStore,
      partnersStore,
      rolesStore,
      userPermissionsStore,
    } = this.context;
    const isLoading =
      careUnitsStore.isLoading() ||
      practitionerStore.isLoading() ||
      practitionerStore.isSaving() ||
      rolesStore.isLoading();
    const showRoleLock = partnersStore.partnerCustomizations.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    );
    const isClinicUserAdminORSuperAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    return (
      <Fragment>
        <div className={styles.header}>
          <Typography.Title level={3}>
            <FormattedMessage id="roles.clinic24-roles" />
          </Typography.Title>

          <Dropdown
            menu={{
              items: [
                {
                  key: DEFAULT_PRACTITIONER_RECEIVING_ROLE,
                  label: (
                    <span data-testid="add-practitioner-role-menu">
                      <FormattedMessage id="add-roles-form.add-btn" /> -{' '}
                      <FormattedMessage id="practitioner-roles-form.practitioner-roles-header" />
                    </span>
                  ),
                  onClick: () => this.handleMenuClick({ key: DEFAULT_PRACTITIONER_RECEIVING_ROLE }),
                },
                {
                  key: DEFAULT_ADMINISTRATIVE_ROLE,
                  label: (
                    <span data-testid="add-administrative-role-menu">
                      <FormattedMessage id="add-roles-form.add-btn" /> -{' '}
                      <FormattedMessage id="practitioner-roles-form.administrative-roles-header" />
                    </span>
                  ),
                  onClick: () => this.handleMenuClick({ key: DEFAULT_ADMINISTRATIVE_ROLE }),
                },
              ],
            }}
          >
            <Button shape="round" type="primary" data-testid="add-practitioner-role-button">
              <FormattedMessage id="add-roles-form.add-btn" />
            </Button>
          </Dropdown>
        </div>

        <Table<StructuredRoleInCareUnit | RoleInCareUnit>
          columns={this.columns}
          rowKey="id"
          data-testid="practitioner-role-table"
          onRow={this.onRowHandler}
          dataSource={practitionerRolesStore.groupedByCareUnit}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              'children' in record && !!record.children?.length ? (
                <Button
                  type="link"
                  data-testid={`expandable-button-${record['data-testid'] || record.id}`}
                  icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={e => onExpand(record, e)}
                />
              ) : (
                <span className={styles.spacer} />
              ),
          }}
          pagination={false}
        />

        <AddPractitionerRoles
          initialValues={this.newRoleDefinition}
          isSaving={isLoading}
          onCancel={this.handleClearNewRoleDefinition}
          onSubmit={this.handleAddNewPractitionerRoles}
          rolesOptions={this.rolesSelectOptions}
          careUnitsOptions={
            isClinicUserAdminORSuperAdmin
              ? this.roleBasedCareUnitSelectOptions
              : this.careUnitSelectOptions
          }
          notAvailableCareUnitRoles={this.notAvailableCareUnitRoles}
          showRoleLock={showRoleLock}
        />

        <EditPractitionerRole
          role={this.activePractitionerRole}
          isSaving={isLoading}
          onCancel={this.handleClearActivePractitionerRole}
          onSubmit={this.handleEditPractitionerRole}
          rolesOptions={this.rolesSelectOptions}
          careUnitsOptions={
            isClinicUserAdminORSuperAdmin
              ? this.roleBasedCareUnitSelectOptions
              : this.careUnitSelectOptions
          }
          notAvailableCareUnitRoles={this.notAvailableCareUnitRoles}
          showRoleLock={showRoleLock}
        />
      </Fragment>
    );
  }
}

export default withPractitionerRolesOptions(
  withRootStoreProp(injectIntl(withRouter(ClinicPersonalRoles)))
);
