import { styled } from 'styled-components';

import { TagsSelect } from './TagsSelect';
import { LabelledLocalizedField } from '../fields/LabelledLocalizedField';

export const Styled = {
  ContentContainer: styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 24px;
  `,

  ThumbnailContainer: styled.div`
    display: flex;
    min-width: 240px;
  `,

  ThumbnailImageControlSection: styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 4px;
    padding-left: 16px;
    text-align: center;
  `,

  TextFieldsContainer: styled.div`
    flex-grow: 1;
  `,

  // -- Use this when adding tags back
  // TextFieldsContainer: styled.div`
  //   flex-grow: 1;
  //   display: grid;
  //   grid-template-rows: auto;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 24px;
  // `,

  TagsSelect: styled(TagsSelect)`
    margin-bottom: 0;
    margin-top: 1px;
  `,

  Description: styled(LabelledLocalizedField)`
    margin-bottom: 0;
  `,

  ThumbnailTitleContainer: styled.div`
    max-width: 236px;
  `,
};
