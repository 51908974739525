/* eslint-disable no-useless-escape */
const PUNCTUATIONS = "’'()[]{}<>:,‒–—―…!.«»-‐?‘’“”;/⁄␠·&@*\\•^¤¢$€£¥₩₪†‡°¡¿¬#№%‰‱¶′§~¨_|¦⁂☞∴‽※";

export const LETTERS = 'a-zA-Z';

export const NUMBERS = '0-9';

export const MARKS = PUNCTUATIONS.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

// NB. Occasionally one will also see an "é" ->\u00E9 \u00C9 or a German "ü" -> \u00DC \u00FC in Swedish names
export const SWEDISH_NORWEGIAN_CHARS =
  '\u00C4\u00C5\u00C6\u00D6\u00D8\u00E4\u00E5\u00E6\u00F6\u00F8\u00E9\u00C9\u00DC\u00FC\u0027';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LETTERS_REGEX = /^[a-zA-Z]+$/;

export const NUMBERS_REGEX = /^\d+$/;

export const ALPHANUMERIC_CHARS_REGEX = /^[a-zA-Z0-9]+$/;

export const ALPHANUMERIC_CHARS_SPACES_REGEX = /^[a-zA-Z0-9\s]*[a-zA-Z]*$/;

export const ALPHANUMERIC_CHARS_DASHES_HYPHENS_REGEX = /^[a-zA-Z0-9_-]+$/;

export const ALPHANUMERIC_CHARS_HYPHENS_REGEX = /^[a-zA-Z0-9-\s]*[a-zA-Z-]*$/;

export const ALPHANUMERIC_CHARS_HYPHENS_DOTS_REGEX = /^[\w\-.]+$/;

export const ALPHANUMERIC_LOWERCASE_DOT_DASHES_REGEX = /^[a-z0-9/.\-_\s]*$/;

export const NOT_ONLY_NUMBERS_REGEX = /\b.*[a-zA-Z]+.*\b/;

export const LETTERS_NUMBERS_SPACES_DASHES_REGEX = new RegExp(
  `^[${LETTERS}${NUMBERS}\\s-_${SWEDISH_NORWEGIAN_CHARS}]*$`
);
export const LETTERS_NUMBERS_SPACES_DASHES_AND_DOTS_REGEX = new RegExp(
  `^[${LETTERS}${NUMBERS}\\s-_${SWEDISH_NORWEGIAN_CHARS}\.]*$`
);

export const AT_LEAST_ONE_LETTER_REGEX = /(.*[a-z].*)|(.*[A-Z].*)/;

export const LETTERS_SPACES_REGEX = new RegExp(`^[${LETTERS}\\s${SWEDISH_NORWEGIAN_CHARS}]*$`);

export const LETTERS_SPACES_DASHES_REGEX = new RegExp(
  `^[${LETTERS}\\s-${SWEDISH_NORWEGIAN_CHARS}]*$`
);

export const LETTERS_NUMBERS_SPACES_DASHES_MARKS_REGEX = new RegExp(
  `^[${LETTERS}${NUMBERS}${MARKS}\\s-${SWEDISH_NORWEGIAN_CHARS}]*$`
);

export const NO_SPACES_REGEX = /^\S*$/;

export const SWEDISH_BANK_ID_REGEX = /\b[0-9]{4}[0-1]{1}[0-9]{1}[0-3]{1}[0-9]{1}[0-9]{4}\b/;

export const NO_LEADING_TRAILING_SPACE_REGEX = /^\s|\s$/;
