import { UnorderedListOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { EditorState } from 'draft-js';
import React from 'react';

import StyleButton from '../../StyleButton';

const LIST_TYPES = [
  { style: 'unordered-list-item', icon: <UnorderedListOutlined /> },
  { style: 'ordered-list-item', icon: <OrderedListOutlined /> },
];

interface Props {
  editorState: EditorState;
  onToggle: (style: string) => void;
  isDisabled?: boolean;
}

const ListStyleControls = (props: Props) => {
  const { editorState, isDisabled } = props;
  const selection = editorState.getSelection();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <Button.Group>
      {LIST_TYPES.map(type => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
          isDisabled={isDisabled}
        />
      ))}
    </Button.Group>
  );
};

export default ListStyleControls;
