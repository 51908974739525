import { BoldOutlined, ItalicOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { EditorState } from 'draft-js';
import React from 'react';

import StyleButton from '../../StyleButton';

const INLINE_STYLES = [
  { style: 'BOLD', icon: <BoldOutlined /> },
  { style: 'ITALIC', icon: <ItalicOutlined /> },
];

interface Props {
  onToggle: (style: string) => void;
  editorState: EditorState;
  isDisabled?: boolean;
}

const InlineStyleControls = (props: Props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <Button.Group>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
          isDisabled={props.isDisabled}
        />
      ))}
    </Button.Group>
  );
};

export default InlineStyleControls;
