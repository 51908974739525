import React, { useMemo } from 'react';

import { ErrorBox } from './components/ErrorBox';
import { FormRegular } from './components/FormRegular';
import { FormSpecial } from './components/FormSpecial';
import { PendingInfo } from './components/PendingInfo';
import { OpeningHoursContext } from './openingHours.context';
import { useOpeningHoursQuery } from './openingHours.hooks';
import { MODE } from './openingHours.types';
import { Spinner } from '../../../components/Spinner';

interface Props {
  id: string;
  mode: MODE;
}

const OpeningHours = ({ id, mode }: Props) => {
  const { data, isLoading, isError, isSuccess, error } = useOpeningHoursQuery(mode, id);

  const contextData = useMemo(() => ({ id, mode }), [id, mode]);

  if (isLoading && !data) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorBox error={error} />;
  }

  return isSuccess ? (
    <OpeningHoursContext.Provider value={contextData}>
      <PendingInfo />
      <FormRegular />
      <FormSpecial />
    </OpeningHoursContext.Provider>
  ) : null;
};

export default OpeningHours;
