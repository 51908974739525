import { BankIdDictionary } from './SwedishBankId.types';

/*
    1. Texts come from official documentation
       see https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/rp-anvaendarfall

    2. We want to have very strict types not allowing accessing non-existing texts
       and making sure that all keys translation are forced
    
    3. This code suppose to be portable (and being used in clinic)

    Because of this, texts are part of js files, not translations
*/

export const SwedishSecureLoginTextsSE: BankIdDictionary = {
  rfa1: 'Starta BankID-appen.',
  rfa2: 'Du har inte BankID-appen installerad. Kontakta din bank.',
  rfa3: 'Åtgärden avbruten. Försök igen',
  rfa4: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
  rfa5: 'Internt tekniskt fel. Försök igen.',
  rfa6: 'Åtgärden avbruten.',
  rfa8: 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
  rfa9: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
  rfa13: 'Försöker starta BankID-appen.',
  rfa14:
    'Söker efter BankID, det kan ta en liten stund … Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
  rfa15:
    'Söker efter BankID, det kan ta en liten stund … Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. Om du inte har något BankID kan du skaffa ett hos din bank.',
  rfa16:
    'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
  rfa17:
    'Misslyckades att läsa av QR-koden. Starta BankID-appen och läs av QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller https://install.bankid.com.',
  rfa18: 'Starta BankID-appen.',
  rfa19:
    'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
  rfa20:
    'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?',
  rfa21: 'Identifiering eller underskrift pågår.',
  rfa22: 'Okänt fel. Försök igen.',
  rfa23: 'Fotografera och läs av din ID-handling med BankID-appen.',
};

export const SwedishSecureLoginTextsEN: BankIdDictionary = {
  rfa1: 'Start your BankID app.',
  rfa2: 'The BankID app is not installed. Please contact your bank.',
  rfa3: 'Action cancelled. Please try again.',
  rfa4: 'An identification or signing for this personal number is already started. Please try again.',
  rfa5: 'Internal error. Please try again.',
  rfa6: 'Action cancelled.',
  rfa8: 'The BankID app is not responding. Please check that it’s started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.',
  rfa9: 'Enter your security code in the BankID app and select Identify or Sign.',
  rfa13: 'Trying to start your BankID app.',
  rfa14:
    'Searching for BankID, it may take a little while … If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank. If you have a BankID on another device you can start the BankID app on that device.',
  rfa15:
    'Searching for BankID:s, it may take a little while … If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank.',
  rfa16:
    'The BankID you are trying to use is blocked or too old. Please use another BankID or get a new one from your bank.',
  rfa17:
    "Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. If you don't have the BankID app, you need to install it and get a BankID from your bank. Install the app from your app store or https://install.bankid.com",
  rfa18: 'Start the BankID app.',
  rfa19:
    'Would you like to identify yourself or sign with a BankID on this computer, or with a Mobile BankID?',
  rfa20:
    'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?', // no english version
  rfa21: 'Identification or signing in progress.',
  rfa22: 'Unknown error. Please try again.',
  rfa23: 'Process your machine-readable travel document using the BankID app.',
};

export const SwedishSecureLoginTexts: Record<'sv' | 'en', BankIdDictionary> = {
  sv: SwedishSecureLoginTextsSE,
  en: SwedishSecureLoginTextsEN,
};
