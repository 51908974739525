import { observable, computed, IObservableArray, runInAction, action } from 'mobx';

import { fetchPractitioners, Practitioner } from 'api/practitionerApi';
import { sortWithLocale } from 'utils/textUtils';

import StateManager from './abstractStores/StateManager';

export default class PractitionersStore extends StateManager {
  @observable
  list: IObservableArray<Practitioner> = observable.array([]);

  getByExternalId(id: string) {
    return this.list.find(practitioner => practitioner.externalId === id);
  }

  fetchPractitioners = async () => {
    if (this.isLoaded()) {
      return;
    }

    this.setLoading();

    try {
      const { data: practitioners } = await fetchPractitioners();

      this.setLoaded();

      runInAction(() => {
        this.list.replace(practitioners);
      });
    } catch (e) {
      this.setError();
      throw e;
    }
  };

  @action
  upsert = (practitioner: Practitioner) => {
    if (!this.isLoaded()) {
      // Don't update the list if not loaded
      return;
    }

    const practitionerIndex = this.list.findIndex(it => it.id === practitioner.id);

    if (practitionerIndex === -1) {
      this.list.push(practitioner);
    } else {
      this.list[practitionerIndex] = practitioner;
    }
  };

  @computed
  get practitionersAsSelectOptions() {
    const allPractitioners = this.list.map(practitioner => ({
      value: practitioner.id,
      label: `${practitioner.givenName} ${practitioner.middleAndSurname}`.trim(),
    }));

    return allPractitioners.sort((a, b) => sortWithLocale(a, b, 'label'));
  }
}
