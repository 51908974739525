import { useIntl } from 'react-intl';

import { IDENTITIES } from 'constants/practitioner';

export const useIdentityOptions = () => {
  const intl = useIntl();
  return [
    {
      value: IDENTITIES.HSA_ID,
      label: intl.formatMessage({ id: 'general.hsa-id' }),
    },
    {
      value: IDENTITIES.SWEDISH_PERSONAL_IDENTITY_NUMBER,
      label: intl.formatMessage({ id: 'general.swedish-personal-id' }),
    },
    {
      value: IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER,
      label: intl.formatMessage({ id: 'general.norwegian-personal-id' }),
    },
    {
      value: IDENTITIES.DANISH_PERSONAL_IDENTITY_NUMBER,
      label: intl.formatMessage({ id: 'general.danish-personal-id' }),
    },
    {
      value: IDENTITIES.EMAIL,
      label: intl.formatMessage({ id: 'general.email-as-personal-id' }),
    },
    {
      value: IDENTITIES.EXTERNALLY_KNOWN_ID,
      label: intl.formatMessage({ id: 'general.externally-known-id' }),
    },
    {
      value: IDENTITIES.INTERNAL_IDP,
      label: intl.formatMessage({ id: 'general.internal-idp' }),
    },
  ];
};
