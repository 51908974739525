import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { getPartnerId } from 'utils/tokenUtils';

import { AnnouncementsByCareProviderDTO, CreateAnnouncementDTO } from './types';

export const useGetAnnouncementsForCareProvider = (careProviderId?: string) => {
  const partnerId = getPartnerId();

  return useQuery(
    ['announcements', 'care-provider', careProviderId],
    async () => {
      const response = await axios.get<AnnouncementsByCareProviderDTO>(
        `/rest/admin/partners/${partnerId}/careproviders/${careProviderId}/announcements/announcement/v1/by-care-provider/${careProviderId}`
      );
      return response;
    },
    {
      enabled: Boolean(careProviderId && partnerId),
    }
  );
};

/**
 * BE knows if it's a care provider or care unit announcement based on the presence of careProviderId / careUnitId
 */
export const useCreateAnnouncement = () => {
  const partnerId = getPartnerId();
  const queryClient = useQueryClient();

  return useMutation(async (data: CreateAnnouncementDTO) => {
    const response = await axios.post(
      `/rest/admin/partners/${partnerId}/careproviders/${data.careProviderId}/announcements/announcement/v1`,
      data
    );
    queryClient.invalidateQueries(['announcements']);

    return response;
  });
};

export const useDeleteAnnouncement = () => {
  const partnerId = getPartnerId();
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      announcementId,
      careProviderId,
    }: {
      announcementId: string;
      careProviderId: string;
    }) => {
      const response = await axios.delete(
        `/rest/admin/partners/${partnerId}/careproviders/${careProviderId}/announcements/announcement/v1/${announcementId}`
      );
      queryClient.invalidateQueries(['announcements']);

      return response;
    }
  );
};
