import React from 'react';

const UDI = () => (
  <svg width="30" height="19" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4830_15633)">
      <path
        d="M0 19V0.231135C0.0319621 0.18403 0.067691 0.13957 0.106831 0.0981829C0.133508 0.0779836 0.164398 0.0640012 0.197224 0.0572718C0.364229 0.0270983 0.533055 0.00796604 0.702598 0C10.3171 0 19.9302 0.00340655 29.5419 0.0102247C29.6404 0.0217275 29.7371 0.045789 29.8295 0.081816C29.856 0.107404 29.8807 0.134737 29.9035 0.163632C29.9265 0.187671 29.9428 0.217291 29.9507 0.249543C29.9712 0.519539 30 0.789536 30 1.06771C29.9918 6.9681 29.9815 12.8699 29.9692 18.773C29.9692 18.8323 29.9137 18.8895 29.8541 19.0082L0 19ZM0.986103 18.1266H29.092C29.092 15.2303 29.1022 12.3994 29.092 9.55216C29.0817 6.70492 29.1495 3.82496 29.0468 0.961355H0.986103V18.1266Z"
        fill="#242321"
      />
      <path
        d="M14.1299 14.2935V4.75562H16.3034C17.0204 4.75562 17.7415 4.73926 18.4523 4.75562C20.0958 4.81289 21.2216 5.6597 21.7783 7.16309C22.3402 8.66908 22.3504 10.3242 21.8071 11.8369C21.3058 13.3035 20.2211 14.1441 18.705 14.2566C17.1888 14.3691 15.7159 14.2935 14.1299 14.2935ZM16.0487 12.6571C16.9156 12.6571 17.7271 12.7103 18.5303 12.6387C18.8417 12.6199 19.1389 12.5029 19.3789 12.3047C19.619 12.1065 19.7895 11.8373 19.8657 11.5362C20.225 10.2943 20.2378 8.97832 19.9027 7.72967C19.8343 7.44624 19.6904 7.18645 19.4861 6.97765C19.2818 6.76886 19.0247 6.6188 18.7419 6.54332C17.8545 6.28355 16.9485 6.33878 16.0487 6.44105V12.6571Z"
        fill="#242321"
      />
      <path
        d="M4.32236 4.80267H6.25346C6.25346 4.80267 6.25346 5.5022 6.25346 5.82538C6.25346 7.21627 6.25346 8.60717 6.25346 9.99806C6.25346 10.3703 6.25347 10.7467 6.28223 11.1169C6.3165 11.4567 6.43856 11.7819 6.63653 12.0608C6.83449 12.3397 7.10162 12.5628 7.41189 12.7084C7.72216 12.854 8.06501 12.9172 8.40707 12.8917C8.74912 12.8662 9.07873 12.753 9.36379 12.563C9.86094 12.2358 10.0376 11.7449 10.0356 11.1578C10.0356 9.52147 10.0499 7.90149 10.0561 6.27333C10.0561 5.80697 10.0561 5.33858 10.0561 4.81086H12.0263V5.16471C12.0037 7.26741 11.9831 9.36807 11.9379 11.4708C11.9009 13.1358 11.1388 14.181 9.08234 14.4407C8.23388 14.5321 7.376 14.4795 6.54519 14.2853C5.42966 14.0398 4.7168 13.2748 4.47027 12.1376C4.39939 11.8754 4.35534 11.6068 4.33879 11.3358C4.31825 9.19625 4.32236 7.06082 4.32236 4.80267Z"
        fill="#242321"
      />
      <path
        d="M23.8904 4.79858H25.6859L25.6284 14.3241L23.8411 14.2955L23.8904 4.79858Z"
        fill="#242321"
      />
    </g>
    <defs>
      <clipPath id="clip0_4830_15633">
        <rect width="30" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UDI;
