import { LANGS } from './enums';

export const enum Config {
  ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT = 'ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT',
}

export enum ROLES_KEYS {
  RESOURCE_TYPES = 'resourceTypes',
  ADMINISTRATIVE_ROLES = 'administrativeRoles',
}

export enum IDENTITIES {
  HSA_ID = 'HSA_ID',
  NORWEGIAN_PERSONAL_IDENTITY_NUMBER = 'NORWEGIAN_PERSONAL_IDENTITY_NUMBER',
  SWEDISH_PERSONAL_IDENTITY_NUMBER = 'SWEDISH_PERSONAL_IDENTITY_NUMBER',
  DANISH_PERSONAL_IDENTITY_NUMBER = 'DANISH_PERSONAL_IDENTITY_NUMBER',
  EMAIL = 'EMAIL',
  EXTERNALLY_KNOWN_ID = 'EXTERNALLY_KNOWN_ID',
  INTERNAL_IDP = 'INTERNAL_IDP',
}

export const AVAILABLE_PHONE_COUNTRY_CODES = {
  [LANGS.SV]: '+46',
  [LANGS.NO]: '+47',
  [LANGS.DA]: '+45',
  [LANGS.NL]: '+31',
};

export const DEFAULT_PHONE_COUNTRY_CODES = {
  [IDENTITIES.HSA_ID]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.SV],
  [IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.NO],
  [IDENTITIES.SWEDISH_PERSONAL_IDENTITY_NUMBER]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.SV],
  [IDENTITIES.DANISH_PERSONAL_IDENTITY_NUMBER]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.DA],
  [IDENTITIES.EMAIL]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.SV],
  [IDENTITIES.EXTERNALLY_KNOWN_ID]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.SV],
  [IDENTITIES.INTERNAL_IDP]: AVAILABLE_PHONE_COUNTRY_CODES[LANGS.SV],
};

export const DANISH_PERSONAL_IDENTITY_NUMBER_LENGTH = 10;
export const SWEDISH_PERSONAL_IDENTITY_NUMBER_LENGTH = 12;
export const NORWEGIAN_PERSONAL_IDENTITY_NUMBER_LENGTH = 11;
