import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LETTERS_NUMBERS_SPACES_DASHES_MARKS_REGEX } from 'constants/regex';

import { NewsItem } from '../../api/newsItemsApi';

export const FIELDS = {
  SUBJECT: 'subject',
  BODY: 'body',
  LINK: 'link',
};

const SUBJECT_MAX_LENGTH = 40;
const BODY_MAX_LENGTH = 250;

type formActionType = 'add' | 'edit';

interface Props {
  initialValues?: NewsItem;
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (data: NewsItem, formType: formActionType) => void;
  onDelete: (id: string) => void;
  isDisabled?: boolean;
}

const AddEditAlert: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  initialValues,
  onSubmit,
  isDisabled,
}) => {
  const validationSchema = Yup.object().shape({
    subject: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_MARKS_REGEX, () => (
      <FormattedMessage id="general.errors.letters-spaces-numbers-allowed" />
    )),
    body: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_MARKS_REGEX, () => (
      <FormattedMessage id="general.errors.letters-spaces-numbers-allowed" />
    )),
    link: Yup.string(),
  });

  const formType: formActionType = initialValues && initialValues.id ? 'edit' : 'add';
  const isFormDisabled = isSaving || isDisabled;

  if (!initialValues) {
    return null;
  }

  return (
    <Modal
      open
      destroyOnClose
      title={
        formType === 'edit' ? (
          <FormattedMessage id="general.edit" />
        ) : (
          <FormattedMessage id="origin.alerts.add-item" />
        )
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => onSubmit(values, formType)}
      >
        {({ isValid, dirty }) => (
          <Form data-testid="edit-form">
            <Form.Item
              name={FIELDS.SUBJECT}
              label={
                <span>
                  <FormattedMessage id="origin.alerts.subject" />
                  <sup className="required">*</sup>
                </span>
              }
            >
              <Input.TextArea
                name={FIELDS.SUBJECT}
                autoSize={true}
                data-testid={FIELDS.SUBJECT}
                disabled={isFormDisabled}
                maxLength={SUBJECT_MAX_LENGTH}
              />
            </Form.Item>
            <Form.Item name={FIELDS.BODY} label={<FormattedMessage id="origin.alerts.body" />}>
              <Input.TextArea
                name={FIELDS.BODY}
                autoSize={true}
                disabled={isFormDisabled}
                maxLength={BODY_MAX_LENGTH}
              />
            </Form.Item>
            <Form.Item name={FIELDS.LINK} label={<FormattedMessage id="origin.alerts.link" />}>
              <Input.TextArea name={FIELDS.LINK} autoSize={true} disabled={isFormDisabled} />
            </Form.Item>
            <Divider />
            <FormActionButtons
              isSaving={isSaving}
              isDisabled={isDisabled}
              isValid={isValid && dirty}
              onCancel={onCancel}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddEditAlert;
