import axios from 'axios';

import {
  CareProviderInheritanceStatus,
  OpeningHoursResponse,
  OpeningHoursTypeForUpdate,
  OpeningHoursWrappers,
} from '../modules/CareUnits/OpeningHours/openingHours.types';

function fixInheritanceStatusesFromCareProvider(data: OpeningHoursResponse) {
  // This is a workaround for the API not returning the correct inheritance status for care providers
  // It's a lot simpler to have those set to false rather than adding additional validation handling
  // to the forms.
  data.release.detailedRegularHoursWrapper.regularHoursInheritedByParent = false;
  data.release.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent = false;
  if (data.candidate?.detailedRegularHoursWrapper) {
    data.candidate.detailedRegularHoursWrapper.regularHoursInheritedByParent = false;
  }
  if (data.candidate?.detailedSpecialHourPeriodsWrapper) {
    data.candidate.detailedSpecialHourPeriodsWrapper.specialHoursInheritedByParent = false;
  }
}

interface Ids {
  careUnitId?: string;
  careProviderId: string;
  partnerId: string;
}

function getPath({ careUnitId, careProviderId, partnerId }: Ids): string {
  const careUnitPath = careUnitId ? `careunits/${careUnitId}/` : '';
  return `/rest/admin/partners/${partnerId}/careproviders/${careProviderId}/${careUnitPath}opening-hours/v1`;
}

export const fetchOpeningHours = async (ids: Ids): Promise<OpeningHoursResponse> => {
  const response = await axios.get<OpeningHoursResponse>(getPath(ids));
  if (!ids.careUnitId) {
    fixInheritanceStatusesFromCareProvider(response.data);
  }
  return response.data;
};

export const setOpeningHours = async (ids: Ids, data: OpeningHoursTypeForUpdate) => {
  const response = await axios.put<OpeningHoursWrappers>(getPath(ids), data);
  return response.data;
};

export const fetchOpeningHoursInheritanceStatus = async (
  ids: Omit<Ids, 'careUnitId'>
): Promise<CareProviderInheritanceStatus> => {
  const response = await axios.get<CareProviderInheritanceStatus>(
    `${getPath(ids)}/inheritance-status`
  );
  return response.data;
};
