import { Popconfirm, Button } from 'antd';
import { SubmitButton } from 'formik-antd';
import React, { FunctionComponent, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TEST_IDS } from 'constants/testIds';
import { RequireAll } from 'types/types';

import styles from './FormActionButtons.module.css';

interface SubmitConfirmProps {
  onSubmit: () => void;
  showSubmitConfirm: boolean;
  submitQuestionText?: string | ReactNode;
  submitDeclineText?: string | ReactNode;
}

interface Props {
  isSaving?: boolean;
  isValid: boolean;
  isDisabled?: boolean;
  disabledContent?: React.ReactElement | null;
  showCancelConfirm?: boolean;
  onCancel?: () => void;
  onReset?: () => void;
  cancelDeclineText?: string | ReactNode;
  saveLabel?: ReactNode;
}

const FormActionButtons: FunctionComponent<RequireAll<SubmitConfirmProps, Props>> = ({
  isSaving,
  isDisabled,
  isValid,
  onReset,
  onCancel,
  disabledContent,
  showCancelConfirm = true,
  cancelDeclineText,
  showSubmitConfirm,
  submitDeclineText,
  submitQuestionText,
  onSubmit,
  saveLabel,
}) => (
  <div className={styles.wrapper}>
    <div>
      {onReset && (
        <Popconfirm
          title={<FormattedMessage id="general.sure-to-restore" />}
          cancelText={<FormattedMessage id="general.cancel" />}
          onConfirm={onReset}
        >
          <Button shape="round" loading={isSaving} disabled={isSaving}>
            <FormattedMessage id="general.restore-to-default" />
          </Button>
        </Popconfirm>
      )}
    </div>
    <div>
      {isDisabled &&
        (disabledContent === undefined ? (
          <Button shape="round" className={styles.btn} onClick={onCancel}>
            <FormattedMessage id="general.ok" />
          </Button>
        ) : (
          disabledContent
        ))}
      {!isDisabled && (
        <Fragment>
          {onCancel &&
            (showCancelConfirm ? (
              <Popconfirm
                title={<FormattedMessage id="general.sure-to-cancel" />}
                cancelText={cancelDeclineText || <FormattedMessage id="general.cancel" />}
                onConfirm={onCancel}
              >
                <Button shape="round" loading={isSaving} className={styles.btn} disabled={isSaving}>
                  <FormattedMessage id="general.cancel" />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                shape="round"
                loading={isSaving}
                className={styles.btn}
                disabled={isSaving}
                onClick={onCancel}
              >
                <FormattedMessage id="general.cancel" />
              </Button>
            ))}
          {showSubmitConfirm ? (
            <Popconfirm
              title={
                submitQuestionText || <FormattedMessage id="general.remove-rule-dialog-header" />
              }
              cancelText={submitDeclineText || <FormattedMessage id="general.cancel" />}
              onConfirm={onSubmit}
            >
              <Button
                shape="round"
                type="primary"
                loading={isSaving}
                disabled={isSaving || !isValid}
                data-testid={TEST_IDS.SUBMIT_BTN}
              >
                <FormattedMessage id="general.save" />
              </Button>
            </Popconfirm>
          ) : (
            <SubmitButton
              shape="round"
              key="submit"
              loading={isSaving}
              disabled={isSaving || !isValid}
              data-testid={TEST_IDS.SUBMIT_BTN}
            >
              {saveLabel ? saveLabel : <FormattedMessage id="general.save" />}
            </SubmitButton>
          )}
        </Fragment>
      )}
    </div>
  </div>
);

export default FormActionButtons;
