import React from 'react';

import { debug } from './debug';
import {
  CreateRootShape,
  SelfMountedConstructor,
  SelfMountedRenderProps,
} from './remoteComponent.types';

class ErrorBoundary extends React.Component<{
  onError: (e: unknown) => void;
  children: React.ReactNode;
}> {
  state = {
    error: undefined,
  };

  componentDidCatch(e: Error) {
    debug(`Error boundary of MFE. Is bypass provided? ${!!this.props.onError}. Error: ${e}`);
    console.error(e);
    this.setState({
      error: e,
    });
    this.props.onError(e);
  }

  public render() {
    if (this.state.error) {
      // render nothing in case of error. We expect error to be handled by "onError"
      return null;
    }

    return this.props.children;
  }
}

export function toSelfRendered<T>(
  Component: any,
  createRoot: CreateRootShape
): SelfMountedConstructor<T> {
  return {
    __selfMountedConstructor: true,
    create(div: HTMLDivElement) {
      const root = createRoot(div);
      return {
        __selfMounted: true,
        render({ Fallback, props, children, bypassError }: SelfMountedRenderProps<T>) {
          root.render(
            <ErrorBoundary onError={bypassError}>
              {/* check if mounted component dies with error */}
              <React.Suspense fallback={<Fallback />}>
                {/* allow child component to seamlessly load something using suspense API */}
                <Component {...(props as any)} {...{ children }} />
                {/* component itself */}
              </React.Suspense>
            </ErrorBoundary>
          );
        },
        unmount() {
          root.unmount();
        },
      };
    },
  };
}
