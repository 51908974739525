/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { App } from 'antd';
import { parse } from 'query-string';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';

interface QueryParams {
  responseCode?: string | undefined;
}

enum LOGIN_ERROR_CODES {
  UNAUTHORIZED = 'login.error.failed',
  FORBIDDEN = 'login.error.forbidden',
  NOT_FOUND = 'login.error.not_found',
  INTERNAL_ERROR = 'login.error.failed',
  NO_CARE_UNIT = 'login.error.forbidden',
}

export function useLoginErrorCode() {
  const { search } = useLocation();
  const { notification } = App.useApp();
  const intl = useIntl();

  useEffect(() => {
    const { responseCode }: QueryParams = parse(search);

    if (!!responseCode && responseCode in LOGIN_ERROR_CODES) {
      notification.error({
        message: intl.formatMessage({ id: LOGIN_ERROR_CODES[responseCode] }),
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        placement: 'top',
      });
    } else if (responseCode) {
      // default message when code isn't error available
      notification.error({
        message: intl.formatMessage({ id: LOGIN_ERROR_CODES.UNAUTHORIZED }),
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        placement: 'top',
      });
    }
  }, [search]);
}
