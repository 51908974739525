import { format, parseISO } from 'date-fns';

import { ROLES } from 'constants/roles';
import { getLanguage } from 'context/lang.utils';

import { UserSlice } from '../types';

interface TableRow {
  key: string;
  name: string;
  ids: string;
  careUnits: string;
  roles: string;
  latestLogin: string;
}

export const normalizeUsers = (data: UserSlice[]) => {
  const language = getLanguage();
  if (!data) {
    return [];
  }
  const results = data?.reduce((acc: TableRow[], user) => {
    const roles = [];
    for (const careUnit of user.careUnits) {
      for (const role of careUnit.roles) {
        if (role.id !== ROLES.ADMIN) {
          roles.push(role.id);
        }
      }
    }
    let latestLogin = '–';
    if (user.latestLogin) {
      latestLogin =
        language === 'sv'
          ? format(parseISO(user.latestLogin), 'd MMM yyyy, HH:mm')
          : format(parseISO(user.latestLogin), 'MMM d yyyy, h:mm a');
    }
    const row = {
      key: user.id,
      name: `${user.givenName} ${user.middleAndSureName}`,
      ids: user.externalIds ? Object.values(user.externalIds).join(', ') : '–',
      careUnits:
        user.careUnits?.length > 0
          ? user.careUnits?.map((careUnits: any) => careUnits.name)?.join(', ')
          : '–',
      roles: roles.length > 0 ? Array.from(new Set(roles)).join(', ') : '–',
      latestLogin,
    };
    acc.push(row);
    return acc;
  }, []);
  return results;
};
