import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import logo from 'assets/svg/manage24.svg';

import styles from './Home.module.css';

const Home = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <div className={styles.illustration} />
      <div className={styles.copy}>
        <img src={logo} alt={intl.formatMessage({ id: 'general.logo' })} className={styles.logo} />
        <h1 className={styles.header}>
          <FormattedMessage id="home.header" />
        </h1>
        <p className={styles.subheader}>
          <FormattedMessage id="home.subheader" />
        </p>
      </div>
    </Fragment>
  );
};

export default Home;
