import { Typography } from 'antd';
import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as CautionIcon } from 'assets/svg/caution.svg';
import { ReactComponent as CELogo } from 'assets/svg/CE_Logo.svg';
import { ReactComponent as DateOfManufacturer } from 'assets/svg/date-of-manufacture.svg';
import { ReactComponent as ManufacturerIcon } from 'assets/svg/manufacturer-icon.svg';
import { ReactComponent as ManualInfoIcon } from 'assets/svg/maunal-info.svg';
import { ReactComponent as VersionIcon } from 'assets/svg/version.svg';

import styles from './About.module.css';
import { AppVersion } from './AppVersion';
import MD from '../../components/IconsSvg/MD';
import UDI from '../../components/IconsSvg/UDI';

const About = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <Typography.Title level={3}>
          <FormattedMessage id="about.page-title" />
        </Typography.Title>
        <Typography.Text className={styles.description}>
          <FormattedMessage id="about.page-subtitle" />
        </Typography.Text>
      </div>

      <div className={styles.topSection} data-testid="triage-copy">
        <Typography.Title level={4}>
          <FormattedMessage id="about.triage24.title" />
        </Typography.Title>
        <Typography.Text className={styles.description2}>
          <FormattedMessage id="about.triage24.copy1" />
        </Typography.Text>
        <Typography.Text className={styles.description2}>
          <FormattedMessage id="about.triage24.copy2" />
        </Typography.Text>
      </div>

      <div className={styles.iconsSection}>
        <div className={styles.iconRow}>
          <div className={styles.customMargin} data-testid="ce-logo">
            <CELogo />
          </div>
          <div>
            <FormattedMessage id="home-copyright-ce-notice" />
          </div>
        </div>

        <div className={styles.iconRow}>
          <div className={styles.icon}>
            <MD />
          </div>
          <div>
            <FormattedMessage id="about.triage24.md-notice" />
          </div>
        </div>

        <div className={styles.iconRow}>
          <div className={styles.icon}>
            <UDI />
          </div>
          <div>7350127221004</div>
        </div>

        <div className={styles.iconRow}>
          <div className={styles.icon}>
            <VersionIcon className={styles.versionIcon} />
          </div>
          <div data-testid="app-version">
            <AppVersion />
          </div>
        </div>

        <div className={styles.iconRow}>
          <div className={styles.icon}>
            <ManualInfoIcon />
          </div>
          <div>
            <FormattedMessage id="about.info-notice" />
          </div>
        </div>

        <div className={styles.iconRow}>
          <div className={styles.icon} />
          <div>
            <span>
              <a
                data-testid="link_to_user_manuals"
                href={`https://platform24.com/${intl.locale}/manuals/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="about.link_to_user_manuals" />
              </a>
            </span>
          </div>
        </div>
        <div className={styles.iconRow} data-testid="caution">
          <div className={styles.icon}>
            <CautionIcon />
          </div>
          <div className={styles.textwrap}>
            <FormattedMessage id="about.caution" />
          </div>
        </div>
        <div className={styles.iconRow} data-testid="triage">
          <div className={styles.icon}>
            <DateOfManufacturer className={styles.manufacturerIcon} />
          </div>
          <div className={styles.alignSelfCenter}>Triage24: 2022-11-01</div>
        </div>
        <div className={classNames(styles.iconRow, styles.bottomRow)}>
          <div className={styles.icon}>
            <ManufacturerIcon className={styles.manufacturerIcon} />
          </div>
          <div>
            <strong>
              <FormattedMessage id="home-copyright-manufacturer-name" />
            </strong>
            <br />
            <FormattedMessage id="home-copyright-manufacturer-address-1" />
            {', '}
            <FormattedMessage id="home-copyright-manufacturer-address-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
