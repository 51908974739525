import { endpoints as articlesEndpoints } from '@platform24/api-toolkit-articles/lib/admin';
import { Zodios } from '@zodios/core';
import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

import { createAxiosInstance } from 'config/axiosInstance';
import { getPartnerId } from 'utils/tokenUtils';

export const createArticlesApi = (partnerId: string) =>
  new Zodios(`/rest/admin/partners/${partnerId}/articles`, articlesEndpoints, {
    axiosInstance: createAxiosInstance(),
    validate: 'none', // if we validate requests and responses are not valid, then zodios just doesn't return the values!
  });

const articlesApiAtom = atom<{
  partnerId: string;
  api: ReturnType<typeof createArticlesApi>;
} | null>(null);

export const useArticlesApi = () => {
  const partnerId = getPartnerId();
  const [articlesApi, setArticlesApi] = useAtom(articlesApiAtom);

  useEffect(() => {
    if (!partnerId) {
      setArticlesApi(null);
      return;
    }

    if (articlesApi && articlesApi.partnerId === partnerId) {
      return;
    }

    setArticlesApi({
      partnerId,
      api: createArticlesApi(partnerId),
    });
  }, [articlesApi, partnerId, setArticlesApi]);

  return articlesApi?.api;
};
