import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Popconfirm, Typography } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';
import removeMarkdown from 'remove-markdown';

import PlusFloatingButton from 'components/PlusFloatingButton';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';
import { markdownToInlineHtml, sortWithLocale } from 'utils/textUtils';

import { Phrase } from './api/phrasesApi';
import AddEditPhrase from './components/AddEditPhrase';
import styles from './Phrases.module.css';
import PhrasesStore from './stores/PhrasesStore';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

@observer
class Phrases extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  phrasesStore: PhrasesStore;

  @computed
  get columns() {
    return [
      {
        title: <FormattedMessage id="phrases.table-header" />,
        dataIndex: 'header',
        sorter: (a: Phrase, b: Phrase) => sortWithLocale(a, b, 'header'),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        width: 180,
      },
      {
        title: <FormattedMessage id="phrases.table-category" />,
        dataIndex: 'category',
        sorter: (a: Phrase, b: Phrase) => sortWithLocale(a, b, 'category'),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        width: 180,
      },
      {
        title: <FormattedMessage id="phrases.table-phrase" />,
        dataIndex: 'text',
        render: (text?: string) =>
          text ? (
            <TruncateMarkup lines={4}>
              <div
                className={styles.phraseContent}
                dangerouslySetInnerHTML={{ __html: markdownToInlineHtml(text) }}
              />
            </TruncateMarkup>
          ) : null,
        sorter: (a: Phrase, b: Phrase) =>
          sortWithLocale(removeMarkdown(a.text), removeMarkdown(b.text), undefined),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: 'actions',
        width: 100,
        render: (_: void, record: Phrase) => (
          <Fragment>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => this.phrasesStore.handleEdit(record)}
            />
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() =>
                this.phrasesStore.handleDelete(
                  record,
                  this.props.intl.formatMessage({ id: 'phrases.phrases-deleted-message' }),
                  this.props.intl.formatMessage({ id: 'general.error' })
                )
              }
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Fragment>
        ),
      },
    ];
  }

  constructor(props: Props, context: RootStore) {
    super(props);
    const { careProviderId, careUnitId } = this.props.match.params;
    this.phrasesStore = new PhrasesStore(context, careProviderId, careUnitId);
  }

  componentDidMount() {
    this.phrasesStore.fetchPhrases();
  }

  render() {
    const { handleAdd, handleSubmit, phrases, isLoading, handleCancel, activePhrase, categories } =
      this.phrasesStore;

    return (
      <Fragment>
        <div className={styles.headerWrapper}>
          <Typography.Title level={2}>
            <FormattedMessage id="phrases.header" />
          </Typography.Title>
          <p className={styles.subheader}>
            <FormattedMessage id="phrases.subheader" />
          </p>
        </div>
        <Table<Phrase>
          dataSource={toJS(phrases)}
          pagination={false}
          loading={isLoading}
          data-testid="phrases-list"
          className={styles.table}
          rowKey="id"
          columns={this.columns}
        />
        <AddEditPhrase
          initialValues={activePhrase}
          isSaving={isLoading}
          onSubmit={handleSubmit({
            createdMessage: this.props.intl.formatMessage({
              id: 'phrases.new-phrase-created-message',
            }),
            updatedMessage: this.props.intl.formatMessage({
              id: 'phrases.phrases-updated-message',
            }),
            errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
          })}
          onCancel={handleCancel}
          categories={categories}
        />
        <PlusFloatingButton onClick={handleAdd} />
      </Fragment>
    );
  }
}

export default injectIntl(Phrases);
