import { Input, notification, Table, Typography } from 'antd';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import {
  RulesPackageMeta,
  RulesPackageMetaDraft,
  RULES_PACKAGE_SOURCES,
} from 'modules/Rules/types';
import { capitalizeFirst, sortWithLocale } from 'utils/textUtils';

import { RulesPackageForm } from '../RulesPackageForm';
import styles from '../shared/SharedStyles.module.css';

/**
 * @notExported
 */
interface PackagesListComponentProps extends RouteComponentProps, WrappedComponentProps {}

@observer
class RulesPackagesListComponent extends Component<PackagesListComponentProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  title = this.props.intl.formatMessage({ id: 'rules.packages' });

  state = {
    isFormVisible: false,
  };

  componentDidMount() {
    this.context.rulesPackagesListStore.initialize();
  }

  private get breadcrumbs() {
    return [
      {
        iconName: 'pic-left',
        text: <FormattedMessage id="main-navigation.rules" />,
      },
      {
        text: this.title,
      },
    ];
  }

  @computed
  get columns() {
    const baseColumns: ColumnsType<RulesPackageMeta> = [
      {
        title: <FormattedMessage id="general.title" />,
        dataIndex: 'title',
        render: (_: string, rulesPackage: RulesPackageMeta) => (
          <Link to={`/rules/${rulesPackage.id}`}>
            {capitalizeFirst(rulesPackage.title || rulesPackage.id)}
          </Link>
        ),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: RulesPackageMeta, b: RulesPackageMeta) =>
          sortWithLocale(a.title || a.id, b.title || b.id),
      },
      {
        title: <FormattedMessage id="rules.id" />,
        dataIndex: 'id',
      },
      {
        title: <FormattedMessage id="general.description" />,
        dataIndex: 'description',
      },
      {
        title: <FormattedMessage id="rules.package-type" />,
        dataIndex: 'source',
        render: (source: string) => {
          return capitalizeFirst(
            this.props.intl.formatMessage({
              id:
                source === RULES_PACKAGE_SOURCES.SYSTEM
                  ? 'rules.source-of-package-system'
                  : 'rules.source-of-package-partner',
            })
          );
        },
      },
    ];

    return baseColumns;
  }

  showAddingPackageFormHandler = () => {
    this.setState({
      isFormVisible: true,
    });
  };

  hideAddingPackageFormHandler = async () => {
    this.setState({ isFormVisible: false });
  };

  savePackageHandler = async (data: RulesPackageMetaDraft, copyOf?: string) => {
    const { rulesPackagesListStore } = this.context;

    try {
      const { history } = this.props;
      const rulesPackageId = await rulesPackagesListStore.handleCreateRulesPackage(data, copyOf);

      if (rulesPackageId) {
        notification.success({
          placement: 'top',
          message: this.props.intl.formatMessage({ id: 'rules.rule-package-successfully-created' }),
        });
        this.hideAddingPackageFormHandler();
        history.replace(`/rules/${rulesPackageId}`);
      }
    } catch {
      notification.error({
        placement: 'top',
        duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        message: this.props.intl.formatMessage({ id: 'rules.rule-package-create-error' }),
      });
    }
  };

  render() {
    const {
      rulesPackagesListStore: {
        canSaveRulesPackage,
        isLoadingRulesPackages,
        isSavingRulesPackage,
        onSearchChangeHandler,
        rulesPackages,
      },
    } = this.context;

    return (
      <>
        <PageHeader
          content={
            <div className={styles.headerTop}>
              <Typography.Title level={2} className={styles.title}>
                <span>{this.title}</span>
              </Typography.Title>
            </div>
          }
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <div className={styles.headerActions}>
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={onSearchChangeHandler}
                className={styles.headerAction}
                disabled={isLoadingRulesPackages}
                allowClear
              />
            </div>
          }
        />
        <Table
          columns={this.columns}
          loading={isLoadingRulesPackages}
          dataSource={rulesPackages.slice()}
          className={styles.table}
          pagination={false}
          rowKey="id"
        />
        <RulesPackageForm
          visible={this.state.isFormVisible}
          isSaving={isSavingRulesPackage}
          onSubmit={this.savePackageHandler}
          onCancel={this.hideAddingPackageFormHandler}
          isDisabled={!canSaveRulesPackage}
        />
        {canSaveRulesPackage && !isLoadingRulesPackages && (
          <PlusFloatingButton
            onClick={this.showAddingPackageFormHandler}
            testId="rules-add-rules-package"
          />
        )}
      </>
    );
  }
}

export const RulesPackagesList = injectIntl(RulesPackagesListComponent);
