import axios from 'axios';

import { IDENTITIES } from 'constants/practitioner';
import { ROLES, PARTNER_ROLES } from 'constants/roles';

import { PractitionerCareUnit } from './practitionerApi';

export interface Origin {
  id: string;
  name: string;
  role: PARTNER_ROLES;
}

export interface User {
  id: string;
  givenName: string;
  middleAndSurname: string;
  externalIdType: IDENTITIES;
  externalId: string;
  careUnit: PractitionerCareUnit;
  roles: ROLES[];
  origins: Origin[];
}

export const USER_ENDPOINT = '/rest/admin/v1/me?includeCareUnit=true';
export const USER_CARE_UNITS_URI = '/rest/admin/v1/me/admin/careunits';

export const fetchUser = () => axios.get<User>(USER_ENDPOINT);

export const fetchUserCareUnits = () => axios.get<PractitionerCareUnit[]>(USER_CARE_UNITS_URI);

// gets users care unit based on role. This is the care unit to be displayed in the add role modal for both admin and practitioner roles
export const fetchUserCareUnitsV2 = (role: string) =>
  axios.get<PractitionerCareUnit[]>(`/rest/admin/v2/me/careunits?role=${role}`);
