import { Skeleton } from 'antd';
import React from 'react';

import styles from './CarePathways.module.css';
import { usePathwaysProps } from './carePathways.utils';
import withRootStoreProp from '../../components/HOC/withRootStoreProp';
import RootStore from '../../stores/RootStore';
import RemoteComponent from '../../utils/remoteModules/remoteComponent';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

const CreateCarePathwayTemplatePage = ({ rootStore }: { rootStore: RootStore }) => {
  const pathwayTemplateCreatorProps = usePathwaysProps(rootStore);

  return (
    <>
      <PartnerStatus />
      <div className={styles.container}>
        <React.Suspense fallback={<Skeleton />}>
          <RemoteComponent
            suspense
            module="pathwaysManage/PathwayTemplateCreator"
            props={pathwayTemplateCreatorProps}
          />
        </React.Suspense>
      </div>
    </>
  );
};

export default withRootStoreProp(CreateCarePathwayTemplatePage);
