import axios, { AxiosPromise } from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';
import { TranslatedText } from 'types/types';

export interface ToplistItem {
  order: number;
  conditionId: string;
  searchTermId: string;
  description: TranslatedText;
}

async function addOrderParam(promise: AxiosPromise<ToplistItem[]>) {
  const response = await promise;
  return { ...response, data: response.data.map((item, index) => ({ ...item, order: index })) };
}

export const fetchToplist = async (partnerId: string, originId: string) =>
  addOrderParam(
    axios.get<ToplistItem[]>(`${PARTNERS_URI}/${partnerId}/origins/${originId}/toplist`)
  );

export const updateToplistItems = (partnerId: string, originId: string, data: ToplistItem[]) =>
  addOrderParam(
    axios.put<ToplistItem[]>(`${PARTNERS_URI}/${partnerId}/origins/${originId}/toplist`, data)
  );
