import { Input } from 'antd';
import { ErrorMessage, FormikProps, FormikProvider } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { CreateAnnouncementFormFields } from './types';

const FieldWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledError = styled(ErrorMessage).attrs({ component: 'div' })`
  /* matches design in figma */
  color: #dc2e2e;
  margin-top: 4px;
`;

export const CreateAnnouncementForm = ({
  formik,
}: {
  formik: FormikProps<CreateAnnouncementFormFields>;
}) => (
  <FormikProvider value={formik}>
    <FieldWrapper>
      <label htmlFor="title">
        <FormattedMessage id="Title" />
      </label>
      <Input.TextArea
        autoSize={{
          minRows: 2,
        }}
        maxLength={100}
        name="title"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        showCount
        value={formik.values.title}
      />
      <StyledError name="title" />
    </FieldWrapper>
    <FieldWrapper>
      <label htmlFor="text">
        <FormattedMessage id="Text" />
      </label>
      <Input.TextArea
        autoSize={{
          minRows: 4,
        }}
        maxLength={400}
        name="text"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        showCount
        value={formik.values.text}
      />
      <StyledError name="text" />
    </FieldWrapper>
  </FormikProvider>
);
