import { Col, Divider, Row } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Checkbox, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import { ALPHANUMERIC_CHARS_HYPHENS_DOTS_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { ImportData } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
} from 'modules/Content24/Condition/utils/validationUtils';
import { sortWithLocale } from 'utils/textUtils';

/**
 * @notExported
 */
interface ImportDataFormProps {
  onCancel: () => void;
  onSubmit: (data: ImportData) => void;
  activeLanguage: LANGS;
  data?: ImportData;
  isDisabled?: boolean;
}

const ImportDataForm: FunctionComponent<ImportDataFormProps> = ({
  data,
  activeLanguage,
  onCancel,
  onSubmit,
  isDisabled,
}) => {
  const intl = useIntl();
  const { conditionStore, content24Store } = useContext(RootStoreContext);
  const defaultValues: ImportData = {
    id: '',
    type: CODE24_MODEL_TYPES.IMPORT_DATA,
    condition: '',
    importSource: 'healthdata',
    importSourceId: '',
    importVariable: '',
    required: false,
  };
  const initialValues = getInitialFormValues<ImportData, ImportData>(defaultValues, data);

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });

  const validationSchema = Yup.object().shape({
    importVariable: Yup.string()
      .matches(
        ALPHANUMERIC_CHARS_HYPHENS_DOTS_REGEX,
        intl.formatMessage({
          id: 'general.errors.alphanumeric-characters-hyphens-dots-validation',
        })
      )
      .required(requiredErrorMessage),
    importSourceId: Yup.string().required(requiredErrorMessage),
    importSource: Yup.string().required(requiredErrorMessage),
    condition: Yup.string().test(
      'isValidCondition',
      ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
        translationKey && characters && intl.formatMessage({ id: translationKey }, { characters }),
      validateStatementConditionWithDebounce
    ),
  });

  const handleSubmit = useCallback(
    (dataToSubmit: ImportData) => {
      onSubmit(getDataFromFinalFormValues<ImportData>(dataToSubmit));
    },
    [onSubmit]
  );

  useEffect(() => {
    content24Store.fetchHealthDataCodes();
  }, []);

  return (
    <Observer>
      {() => {
        const isLoadingHealthDataCodes = content24Store.isLoadingHealthDataCodes;
        const healthDataCodesOptions = content24Store.healthDataCodes
          .map(({ id, description }) => ({
            value: id,
            label: description[activeLanguage] || id,
          }))
          .sort((a, b) => sortWithLocale(a, b, 'label'));
        const isSaving = conditionStore.isLoading();
        const isFormDisabled = conditionStore.isLoading() || isDisabled;

        return (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="condition"
                      label={<FormattedMessage id="condition-edit.condition-label" />}
                    >
                      <Input.TextArea
                        name="condition"
                        disabled={isFormDisabled}
                        rows={1}
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="importSourceId"
                      required
                      label={<FormattedMessage id="condition-edit.destination-id-label" />}
                    >
                      <Select
                        name="importSourceId"
                        disabled={isFormDisabled}
                        loading={isLoadingHealthDataCodes}
                        options={healthDataCodesOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="importSource"
                      required
                      hasFeedback
                      label={<FormattedMessage id="condition-edit.source-label" />}
                    >
                      <Input name="importSource" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="importVariable"
                      required
                      hasFeedback
                      label={<FormattedMessage id="condition-edit.variable-label" />}
                    >
                      <Input name="importVariable" disabled={isFormDisabled} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="required"
                      label={<FormattedMessage id="condition-edit.mandatory-label" />}
                    >
                      <Checkbox name="required" disabled={isFormDisabled} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <FormActionButtons
                  isSaving={isSaving}
                  isDisabled={isDisabled}
                  isValid={isValid && dirty}
                  onCancel={onCancel}
                  showCancelConfirm={dirty}
                  cancelDeclineText={
                    <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                  }
                />
              </Form>
            )}
          </Formik>
        );
      }}
    </Observer>
  );
};

export default ImportDataForm;
