import { Typography, Spin, Checkbox } from 'antd';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { OriginCareUnit } from 'api/originsApi';

import styles from './OfflineFlowForm.module.css';

interface Props {
  onChange: (careUnitId: string) => void;
  isSaving: boolean;
  canEdit: boolean;
  enabledList: OriginCareUnit[];
  disabledList: OriginCareUnit[];
}

const OfflineFlowForm: FunctionComponent<Props> = ({
  onChange,
  canEdit,
  isSaving,
  enabledList,
  disabledList,
}) => {
  const renderList = (data: OriginCareUnit[]) =>
    !!data.length &&
    data.map(element => (
      <div
        key={element.careUnitId}
        className={classNames(styles.tab, {
          [styles.selected]: element.enabled,
          [styles.formTab]: canEdit,
        })}
        onClick={() => (canEdit ? onChange(element.careUnitId) : noop)}
        data-testid="care-unit"
      >
        {canEdit && (
          <div className={classNames('rules-form-group', styles.openCloseField)}>
            <Checkbox name={element.careUnitId} checked={element.enabled} />
          </div>
        )}
        <div className="rules-form-group">
          <p>{element.name}</p>
        </div>
      </div>
    ));

  return (
    <Spin spinning={isSaving}>
      <div data-testid="offline-flow-form">
        <Typography.Title level={3}>
          <FormattedMessage id="origin.offline-flow.enabled" />
        </Typography.Title>
        <div className={styles.listWrapper} data-testid="offline-flow-enabled-form">
          {renderList(enabledList)}
        </div>

        <Typography.Title level={3}>
          <FormattedMessage id="origin.offline-flow.disabled" />
        </Typography.Title>
        <div className={styles.listWrapper} data-testid="offline-flow-disabled-form">
          {renderList(disabledList)}
        </div>
      </div>
    </Spin>
  );
};

export default OfflineFlowForm;
