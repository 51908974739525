import { PageHeader as SharedPageHeader } from '@platform24/admin-ui-utils';
import { Typography } from 'antd';
import noop from 'lodash/noop';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { AppBreadcrumbItem } from 'components/Breadcrumbs/Breadcrumbs';

interface Props {
  content: ReactNode;
  canEdit?: boolean;
  onEditClick?: () => void;
  className?: string;
  headerActions?: ReactNode;
  breadcrumbs?: AppBreadcrumbItem[];
  children?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({
  content,
  canEdit = false,
  onEditClick = noop,
  className,
  headerActions,
  breadcrumbs,
  children,
}) => (
  <SharedPageHeader
    content={
      typeof content === 'string' ? (
        <Typography.Title level={2}>
          <FormattedMessage id={content} />
        </Typography.Title>
      ) : (
        content
      )
    }
    canEdit={canEdit}
    breadcrumbs={breadcrumbs}
    className={className}
    headerActions={headerActions}
    onEditClick={onEditClick}
  >
    {children}
  </SharedPageHeader>
);

export default PageHeader;
