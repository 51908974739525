import { observable, computed, action, IObservableArray, runInAction } from 'mobx';

import { fetchOriginCareUnits, updateOriginCareUnits, OriginCareUnit } from 'api/originsApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export default class OriginCareUnitsStore extends StateManager {
  @observable list: IObservableArray<OriginCareUnit> = observable.array([]);

  constructor(private rootStore: RootStore) {
    super();
  }

  fetch = async (originId: string) => {
    this.setLoading();

    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      const { data } = await fetchOriginCareUnits(originId, partnerId);

      runInAction(() => {
        this.list.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  update = async (data: OriginCareUnit[], originId: string) => {
    this.setSaving();

    try {
      const {
        partnersStore: { partnerId },
      } = this.rootStore;
      await updateOriginCareUnits(data, originId, partnerId);

      runInAction(() => {
        this.list.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @computed
  get enabledList() {
    return this.list.filter(c => c.enabled);
  }

  @computed
  get disabledList() {
    return this.list.filter(c => !c.enabled);
  }

  @action
  updateList(data: OriginCareUnit[]) {
    this.list.replace(data);
  }
}
