import { sendEventV1 } from '@platform24/analytics';
import Bowser, { Parser } from 'bowser';
import Detect from 'devtools-detect';
import { when } from 'mobx';

import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import RootStore from 'stores/RootStore';

const TRACKING_ENABLED_ENVIRONMENTS = [
  ENV.CONTENT24_DEMO,
  ENV.TESTING_DEMO,
  ENV.LOCAL,
  ENV.DEV,
  ENV.PROD,
  ENV.STAGING,
];

export default class EventTrackingService {
  private browserData?: Parser.ParsedResult;

  constructor(private rootStore: RootStore) {
    if (this.shouldTrack) {
      this.browserData = Bowser.parse(window.navigator.userAgent);
    }

    if (this.shouldTrackInDevMode) {
      when(
        () => !!this.rootStore.userDataStore.id && !!this.rootStore.partnersStore.currentPartner,
        () => {
          this.initializeDevModeTrackingEvent();
        }
      );
    }
  }

  private get shouldTrackInDevMode() {
    return [ENV.CONTENT24_DEMO, ENV.TESTING_DEMO].includes(CURRENT_ENV_TYPE);
  }

  private get shouldTrack() {
    return TRACKING_ENABLED_ENVIRONMENTS.includes(CURRENT_ENV_TYPE);
  }

  private initializeDevModeTrackingEvent() {
    this.trackDevMode(Detect.isOpen);

    window.addEventListener('devtoolschange', event => {
      this.trackDevMode(event.detail.isOpen);
    });
  }

  private trackDevMode(isOpen: boolean) {
    if (isOpen) {
      this.sendEvent('devModeOn');
    }
  }

  sendEvent(name: string, properties?: { [key: string]: number | boolean | string }) {
    const { partnersStore, userDataStore } = this.rootStore;
    if (
      !this.shouldTrack ||
      !this.browserData ||
      !partnersStore.currentPartner?.id ||
      !userDataStore.id
    ) {
      return;
    }

    const defaultEventProperties = {
      partnerId: partnersStore.currentPartner.id,
      app: 'Manage24',
      env: CURRENT_ENV_TYPE,
    };

    sendEventV1('admin-ui', name, {
      ...defaultEventProperties,
      ...properties,
    });
  }
}
