import { Divider, Modal, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { ACCESS_SCOPE_TYPES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import { ManageRoleScope, ManageViewScope } from 'modules/Roles/stores/ManageRolesStore';
import { filterSelectOptionV2, stringToSelectOption } from 'utils/formUtils';
import { sortWithLocale } from 'utils/textUtils';

import styles from './AddManageRoleModal.module.css';
import { sortRoles } from './utils';
import { useOptionsSelector } from '../hooks/useOptionsSelector';
import { isStarLabel } from '../utils';

interface FormValues {
  partnerId: string;
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  role?: string;
  careProviderId: string;
}

interface SelectableIT {
  selectable: boolean;
  id: string;
}

type FormikBag = FormikProps<{
  scopeType: ACCESS_SCOPE_TYPES;
  partnerId: string;
  scopeValue: string;
  careProviderId: string;
}>;

interface Props {
  data: ManageRoleScope;
  practitionerId: string;
  editedUserScopedRoles: ManageViewScope[];
  onSubmit: (values: ManageViewScope, practitionerId: string, careProviderId: string) => void;
  onClose: () => any;
}

const AddManageRoleModal: FunctionComponent<Props> = ({
  data,
  onSubmit,
  onClose,
  practitionerId,
}) => {
  const intl = useIntl();
  const { userPermissionsStore } = useContext(RootStoreContext);

  const {
    selectablePartners,
    selectableScopes,
    selectableOrigins,
    selectableCareProviders,
    selectableRole,
    selectableCareUnits,
    onScopeChange,
    onPartnerChange,
    onOriginChange,
    onCareProviderChange,
    onCareUnitChange,
  } = useOptionsSelector();

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });

  const validationSchema = Yup.object().shape({
    partnerId: Yup.string().required(requiredErrorMessage),
    scopeType: Yup.string().required(requiredErrorMessage),
    scopeValue: Yup.string().required(requiredErrorMessage),
    role: Yup.string().required(requiredErrorMessage),
    careProviderId: Yup.string().when('scopeType', {
      is: ACCESS_SCOPE_TYPES.CARE_UNIT,
      then: Yup.string().required(requiredErrorMessage),
      otherwise: Yup.string(),
    }),
  });

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const submitData = {
        ...data,
        scopeType: values.scopeType,
        scopeValue: values.scopeValue,
        partnerId: values.partnerId,
        role: values.role,
      };
      onSubmit(submitData, practitionerId, values.careProviderId);
    },
    [data, onSubmit]
  );

  const handleScopeTypeSelect = useCallback(
    (scopeType: ACCESS_SCOPE_TYPES, formikBag: FormikBag) => {
      onScopeChange(scopeType, formikBag.values.partnerId);
      if (scopeType !== formikBag.values.scopeType) {
        formikBag.setFieldValue('scopeValue', '');
        formikBag.setFieldValue('careProviderId', '');
        formikBag.setFieldValue('roles', []);
      }
    },
    []
  );

  const handleOriginSelect = useCallback((originId: string, formikBag: FormikBag) => {
    onOriginChange(originId, formikBag.values.partnerId, practitionerId);
    formikBag.setFieldValue('roles', []);
  }, []);

  const handleCareProviderSelect = useCallback((careProviderId: string, formikBag: FormikBag) => {
    onCareProviderChange(careProviderId, formikBag.values.partnerId);
    formikBag.setFieldValue('scopeValue', '');
    formikBag.setFieldValue('roles', []);
  }, []);

  const handleCareUnitSelect = useCallback((careUnitId: string, formikBag: FormikBag) => {
    onCareUnitChange(
      careUnitId,
      formikBag.values.partnerId,
      formikBag.values.careProviderId,
      practitionerId
    );
    formikBag.setFieldValue('roles', []);
  }, []);

  const handlePartnerSelect = useCallback((partner: string, formikBag: FormikBag) => {
    onPartnerChange(partner, formikBag.values.scopeType);
    formikBag.setFieldValue('roles', []);
    formikBag.setFieldValue('scopeValue', '');
    formikBag.setFieldValue('careProviderId', '');
  }, []);

  const initialValues = {
    scopeType: data.scopeType || ACCESS_SCOPE_TYPES.PARTNER,
    partnerId: data.partnerId || '',
    scopeValue: data.scopeValue,
    careProviderId: '',
  };

  const allText = intl.formatMessage({
    id: 'roles.roleid.all',
  });

  return (
    <Modal
      open
      destroyOnClose
      title={
        <span data-testid="add-manage-role-title">
          <FormattedMessage id="roles.add-manage-role" />
        </span>
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={validationSchema}
      >
        {formikBag => (
          <Form layout="vertical" data-testid="form">
            <Form.Item name="partnerId" label={<FormattedMessage id="general.partner" />} required>
              <Select
                name="partnerId"
                data-testid="field-partner"
                loading={userPermissionsStore.isLoading()}
                onChange={value => handlePartnerSelect(value, formikBag)}
                options={selectablePartners
                  .map(partner => stringToSelectOption(partner))
                  .sort((a, b) => sortWithLocale(a, b, 'label', intl.locale))}
                showSearch
              />
            </Form.Item>

            <Form.Item name="scopeType" label={<FormattedMessage id="roles.scope" />} required>
              <Select
                name="scopeType"
                data-testid="field-scope-type"
                onChange={(value: ACCESS_SCOPE_TYPES) => handleScopeTypeSelect(value, formikBag)}
                options={selectableScopes.map(scopeType => ({
                  value: scopeType,
                  label: intl.formatMessage({ id: `access-scope.${scopeType}` }),
                }))}
              />
            </Form.Item>

            {formikBag.values.scopeType === ACCESS_SCOPE_TYPES.ORIGIN && (
              <Form.Item
                name="scopeValue"
                label={<FormattedMessage id="general.origin" />}
                required
              >
                <Select
                  name="scopeValue"
                  showSearch
                  data-testid="field-origin"
                  optionFilterProp="children"
                  filterOption={filterSelectOptionV2}
                  onChange={(value: string) => handleOriginSelect(value, formikBag)}
                >
                  {selectableOrigins.map(({ id, name }) => (
                    <Select.Option data-testid={id} key={id} value={id}>
                      {isStarLabel(name) ? allText : name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {formikBag.values.scopeType === ACCESS_SCOPE_TYPES.CARE_UNIT && (
              <>
                <Form.Item
                  name="careProviderId"
                  label={<FormattedMessage id="general.care-provider" />}
                  required
                >
                  <Select
                    name="careProviderId"
                    data-testid="field-care-provider"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterSelectOptionV2}
                    onChange={(value: string) => handleCareProviderSelect(value, formikBag)}
                  >
                    {selectableCareProviders.map(({ id, name }) => (
                      <Select.Option data-testid={id} key={id} value={id}>
                        {isStarLabel(name) ? allText : name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="scopeValue"
                  label={<FormattedMessage id="general.care-unit" />}
                  required
                >
                  <Select
                    name="scopeValue"
                    data-testid="field-care-unit"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterSelectOptionV2}
                    onChange={(value: string) => handleCareUnitSelect(value, formikBag)}
                  >
                    {selectableCareUnits.map(({ id, name }) => (
                      <Select.Option data-testid={id} key={id} value={id}>
                        {isStarLabel(name) ? allText : name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item name="role" label={<FormattedMessage id="roles.role" />} required>
              <Select
                name="role"
                data-testid="field-roles"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOptionV2}
                loading={userPermissionsStore.isLoading()}
                disabled={!formikBag.values.scopeValue}
              >
                {sortRoles(selectableRole, intl)?.map(({ selectable, id }: SelectableIT) => (
                  <Select.Option disabled={!selectable} key={id} value={id}>
                    {selectable ? (
                      intl.formatMessage({ id: `roles.roleid.${id}` })
                    ) : (
                      <Tooltip
                        overlayClassName={styles.tooltipFix}
                        title={
                          id === 'config_admin' ? (
                            <FormattedMessage id="roles.cannot-use-admin-role" />
                          ) : (
                            <FormattedMessage id="roles.role-already-assigned" />
                          )
                        }
                        placement="top"
                      >
                        <div>{intl.formatMessage({ id: `roles.roleid.${id}` })}</div>
                      </Tooltip>
                    )}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Divider />

            <FormActionButtons
              isValid={formikBag.isValid}
              onCancel={onClose}
              showCancelConfirm={formikBag.dirty}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddManageRoleModal;
