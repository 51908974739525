import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';

export const PopoverTooltip = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <Popover content={<div style={{ maxWidth: 240 }}>{children}</div>}>
    <QuestionCircleOutlined className={className} />
  </Popover>
);
