import { notification } from 'antd';
import { observable, action, runInAction, IObservableArray, toJS } from 'mobx';
import { IntlShape } from 'react-intl';

import {
  Customization,
  fetchOriginCustomization,
  updateOriginCustomization,
} from 'api/customizationsApi';
import {
  fetchSearchTerms,
  ConditionSearchTerms,
} from 'modules/Content24/Condition/api/partnerCode24api';
import RootStore from 'stores/RootStore';

import { fetchToplist, ToplistItem, updateToplistItems } from '../api/toplistApi';

export default class ToplistStore {
  @observable activeItem?: ToplistItem;
  @observable isLoadingSearchTerms = false;
  @observable conditionSearchTerms: IObservableArray<ConditionSearchTerms> = observable.array([]);

  @observable isLoadingTopList = false;
  @observable isSwitchingCustomization = false;
  @observable isTopListEnabled = false;
  @observable toplist: IObservableArray<ToplistItem> = observable.array([]);
  @observable toplistCustomization?: Customization;

  constructor(
    private rootStore: RootStore,
    private intl: IntlShape
  ) {}

  @action
  async fetchSearchTerms() {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    try {
      this.isLoadingSearchTerms = true;

      const { data } = await fetchSearchTerms(partnerId);

      runInAction(() => {
        this.conditionSearchTerms.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoadingSearchTerms = false;
      });
    }
  }

  @action
  async fetchToplist() {
    const {
      partnersStore: { partnerId },
      originStore: { currentOrigin },
    } = this.rootStore;

    if (!currentOrigin) {
      return;
    }

    try {
      this.isLoadingTopList = true;

      const { data } = await fetchToplist(partnerId, currentOrigin.id);

      runInAction(() => {
        this.toplist.replace(data);
      });
    } finally {
      runInAction(() => {
        this.isLoadingTopList = false;
      });
    }
  }

  @action
  async fetchToplistCustomization() {
    const {
      partnersStore: { partnerId },
      originStore: { currentOrigin },
    } = this.rootStore;

    if (!currentOrigin?.id) {
      return;
    }

    this.isSwitchingCustomization = false;

    try {
      const { data } = await fetchOriginCustomization(
        partnerId,
        currentOrigin.id,
        'ENABLE_TOPLIST'
      );

      runInAction(() => {
        this.toplistCustomization = data;
        this.isTopListEnabled = !!data.value;
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isSwitchingCustomization = false;
      });
    }
  }

  @action
  saveData = async (toplistItems: ToplistItem[]) => {
    const {
      partnersStore: { partnerId },
      originStore: { currentOrigin },
    } = this.rootStore;

    if (!currentOrigin) {
      return;
    }

    this.isLoadingTopList = true;

    try {
      const { data } = await updateToplistItems(partnerId, currentOrigin.id, toplistItems);

      runInAction(() => {
        this.toplist.replace(data);
      });

      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'origin.toplist.toplist-updated' }),
      });
    } finally {
      runInAction(() => {
        this.isLoadingTopList = false;
      });
    }
  };

  @action
  handleEdit = (searchTermId: string) => {
    if (!this.conditionSearchTerms.length) {
      this.fetchSearchTerms();
    }
    this.activeItem = this.toplist.find(item => item.searchTermId === searchTermId);
  };

  @action
  handleAdd = () => {
    if (!this.conditionSearchTerms.length) {
      this.fetchSearchTerms();
    }

    this.activeItem = {
      conditionId: '',
      searchTermId: '',
      description: {
        sv: '',
        en: '',
        no: '',
        de: '',
      },
      order: this.toplist.length,
    };
  };

  @action
  handleCancel = () => {
    this.activeItem = undefined;
  };

  handleDelete = async (searchTermId: string) => {
    this.saveData(this.toplist.filter(item => item.searchTermId !== searchTermId));
  };

  @action
  handleSubmit = async (data: ToplistItem) => {
    try {
      this.isLoadingTopList = true;

      const newToplist = this.toplist.slice();
      const newToplistItemIndex = this.toplist.findIndex(item => item.order === data.order);

      if (newToplistItemIndex === -1) {
        newToplist.push(data);
      } else {
        newToplist[newToplistItemIndex] = data;
      }

      await this.saveData(newToplist);

      runInAction(() => {
        this.activeItem = undefined;
      });
    } finally {
      runInAction(() => {
        this.isLoadingTopList = false;
      });
    }
  };

  @action
  handleEnableToplistSwitchChange = async (value: boolean) => {
    const {
      originStore: { currentOrigin },
    } = this.rootStore;
    const { toplistCustomization } = this;

    if (currentOrigin?.id && toplistCustomization) {
      runInAction(() => {
        this.isTopListEnabled = value;
        this.isSwitchingCustomization = true;
      });

      try {
        const { data } = await updateOriginCustomization(
          {
            ...toJS(toplistCustomization),
            value,
          },
          this.rootStore.partnersStore.partnerId,
          currentOrigin?.id
        );

        runInAction(() => {
          this.toplistCustomization = data;
        });
      } catch {
        runInAction(() => {
          this.isTopListEnabled = !value;
        });
      } finally {
        runInAction(() => {
          this.isSwitchingCustomization = false;
        });
      }
    }
  };
}
