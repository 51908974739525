import ReactDOM from 'react-dom';

import { CreateRootShape } from './remoteComponent.types';

export const createRootIn16: CreateRootShape = (container: HTMLDivElement) => {
  return {
    render: (app: any) => {
      (ReactDOM as any).render(app, container);
    },
    unmount: () => {
      (ReactDOM as any).unmountComponentAtNode(container);
    },
  };
};
