import { EditOutlined } from '@ant-design/icons';
import { Button, Tabs, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LANGS } from 'constants/enums';
import { TranslatedText } from 'types/types';

import styles from './TabLayoutView.module.css';

const { TextArea } = Input;
const MAX_CHARACTER_LENGTH = 140;

interface Props {
  loading: boolean;
  editable: boolean;
  texts: TranslatedText;
  onTabChange: (value: string) => void;
  onTextChange: (lan: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  onEditClick: () => void;
  availableLanguages: LANGS[];
  disabled?: boolean;
}

const TabLayoutView = ({
  onTabChange,
  texts,
  editable,
  onEditClick,
  onTextChange,
  loading,
  onSubmit,
  availableLanguages = [],
  disabled = false,
}: Props) => {
  const intl = useIntl();
  const t = texts as Record<LANGS, string>;

  return (
    <>
      <Tabs
        onChange={onTabChange}
        data-testid="tab-layout-view"
        items={availableLanguages.map(lan => ({
          label: intl.formatMessage({ id: `general.language-original-${lan}` }),
          key: lan,
          children: editable ? (
            <TextArea
              data-testid={lan}
              value={t[lan]}
              onChange={onTextChange(lan)}
              maxLength={MAX_CHARACTER_LENGTH}
            />
          ) : (
            <Text>
              {t[lan]}
              <Button type="link" onClick={onEditClick} data-testid="editBtn">
                <EditOutlined />
              </Button>
            </Text>
          ),
        }))}
      />
      <Button
        loading={loading}
        className={styles.submit}
        data-testid="submit-id"
        type="primary"
        onClick={onSubmit}
        disabled={disabled}
      >
        <FormattedMessage id="general.save" />
      </Button>
    </>
  );
};
export default TabLayoutView;
