import { useCallback, useMemo, useState } from 'react';

export function useBoolean(initial: boolean) {
  const [value, setValue] = useState(initial);

  const toggle = useCallback(() => setValue(value => !value), []);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return useMemo(
    () => ({ value, setValue, toggle, setTrue, setFalse }),
    [value, setValue, toggle, setTrue, setFalse]
  );
}
