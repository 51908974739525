import { useSetAtom } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import RootStoreContext from 'context/RootStoreContext';

import { originAtoms } from './origin.atoms';

export const OriginAdapter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { originStore } = rootStore;

  const { currentOrigin, availableOriginLanguages, shutdownInfo } = originStore;

  const setOriginState = useSetAtom(originAtoms.root);

  useEffect(() => {
    setOriginState({
      currentOrigin,
      availableOriginLanguages: availableOriginLanguages.toJS(),
      shutdownInfo,
    });
  }, [availableOriginLanguages, currentOrigin, setOriginState, shutdownInfo]);

  return null;
});
