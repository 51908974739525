import axios from 'axios';

export interface Phrase {
  id: string;
  careUnitId: string;
  category: string | null;
  header: string;
  text: string;
}

const getURI = (partnerId: string, careProviderId: string, careUnitId: string) =>
  `/rest/admin/partners/${partnerId}/care_providers/${careProviderId}/care_units/${careUnitId}/phrases`;

export const getPhrases = async (partnerId: string, careProviderId: string, careUnitId: string) => {
  const response = await axios.get(getURI(partnerId, careProviderId, careUnitId));
  const data = response.data as Phrase[];

  return { ...response, data };
};

export const createPhrase = (
  phrase: Phrase,
  partnerId: string,
  careProviderId: string,
  careUnitId: string
) =>
  axios.post<Phrase>(getURI(partnerId, careProviderId, careUnitId), {
    ...phrase,
    id: undefined,
  });

export const updatePhrase = (
  phrase: Phrase,
  partnerId: string,
  careProviderId: string,
  careUnitId: string
) =>
  axios.put<Phrase>(
    `${getURI(partnerId, careProviderId, careUnitId)}/${encodeURIComponent(phrase.id)}`,
    phrase
  );

export const deletePhrase = (
  phraseId: string,
  partnerId: string,
  careProviderId: string,
  careUnitId: string
) =>
  axios.delete(`${getURI(partnerId, careProviderId, careUnitId)}/${encodeURIComponent(phraseId)}`);
