import { Button } from 'antd';
import { stringify } from 'query-string';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { LOGIN_ENDPOINTS } from 'constants/login';

const NorwegianBankIdLogin = () => {
  const handleClick = useCallback(() => {
    window.location.assign(
      `${LOGIN_ENDPOINTS.NORWEGIAN_BANK_ID_START}?${stringify({
        callbackUrl: `${window.location.origin}/login/collect/norwegian-bank-id`,
      })}`
    );
  }, []);

  return (
    <Button
      data-testid="norwegian-login-btn"
      onClick={handleClick}
      type="primary"
      size="large"
      block
    >
      <FormattedMessage id="login.with-bank-id" />
    </Button>
  );
};

export default NorwegianBankIdLogin;
