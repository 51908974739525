import { ManageViewScope } from './ManageRolesStore';

interface Roles {
  name: string;
  scopedRoleEntityId: number;
}

export const getIds = (data: ManageViewScope): number[] => {
  if (data && data?.roles && (!data.children || data.children.length < 1)) {
    return (data.roles as Roles[]).map(role => role?.scopedRoleEntityId) || [];
  }

  if (data && data.children) {
    let results: number[] = [];
    for (const child of data.children as ManageViewScope[]) {
      results = [...results, ...getIds(child)];
    }
    return results;
  }
  return [];
};
