export enum OrderStatusEnum {
  PENDING = 'pending',
  COMPLETE = 'complete',
  FAILED = 'failed',
  /**
   * Indicates that request has non-200 HTTP response
   * not part of bank-id statuses
   */
  CRASHED = 'crashed',
}

/**
 * Hint codes for pending orders.
 * Check https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/graenssnittsbeskrivning/collect
 */
export enum SwedishBankIdPendingHintCode {
  /**
   * Order is pending. The BankID app has not yet received the order. The hintCode will later change to noClient, started or userSign.
   */
  OUTSTANDING_TRANSACTION = 'outstandingTransaction',
  /**
  Order is pending. The client has not yet received the order.
  */
  NO_CLIENT = 'noClient',
  /**
   * Order is pending. A BankID client has launched with autostarttoken but a usable ID has not yet been found in the client.
   *
   * When the client launches there may be a short delay until all IDs are registered.

   * The user may not have any usable IDs, or is yet to insert their smart card.
   */
  STARTED = 'started',
  /**
   * 	Order is pending. A client has launched and received the order but additional steps for providing MRTD information is required to proceed with the order.
   */
  USER_MRTD = 'userMrtd',
  /**
   * Order is waiting for the user to confirm that they have received this order while in a call with the RP.
   */
  USER_CALL_CONFIRM = 'userCallConfirm',
  /**
   * Order is pending. The BankID client has received the order.
   */
  USER_SIGN = 'userSign',

  /**
   * Unknown hint code.
   *
   * **NOTE** This is not a valid hint code from the BankID API. It is used to represent an unknown hint code and set on FE
   */
  UNKNOWN = 'unknown',
}

export enum SwedishBankIdFailedHintCode {
  /**
   * The order has expired. The BankID security app/program did not launch, the user did not finalize the signing or the RP called collect too late.
   */
  EXPIRED_TRANSACTION = 'expiredTransaction',

  /**
   * This error is returned if:
   *
   * * The user has entered the wrong PIN code too many times. The BankID cannot be used.
   *
   * * The user’s BankID is blocked.
   *
   * * The user’s BankID is invalid.
   */
  CERTIFICATE_ERR = 'certificateErr',

  /**
   * The order was cancelled by the user. userCancel may also be returned in some rare cases related to other user interactions.
   */
  USER_CANCEL = 'userCancel',
  /**
     *
The order was cancelled. The system received a new order for the use
     */
  CANCELLED = 'cancelled',
  /**
   *
   * * The user did not provide their ID or the client did not launch within a certain time limit. Potential causes are:
   *
   * * RP did not use autoStartToken when launching the BankID security app. RP must correct this in their implementation.
   *
   * * Client software was not installed or other problem with the user’s device.
   */
  START_FAILED = 'startFailed',
  /**
   * The order was cancelled because the user indicated in the app that they are not in a call with the RP about the order.
   */
  USER_DECLINED_CALL = 'userDeclinedCall',

  /**
   * Unknown hint code.
   *
   * **NOTE** This is not a valid hint code from the BankID API. It is used to represent an unknown hint code and set on FE
   */
  UNKNOWN = 'unknown',
}

/**
 * Non-200 HTTP response
 * See https://www.bankid.com/en/utvecklare/guider/teknisk-integrationsguide/graenssnittsbeskrivning/felfall
 */
export enum OrderStatusErrorCodes {
  /**
     * An auth or sign request with a personal number was sent, but an order for the user is already in progress. The order is aborted. No order is created.

Details are found in details.
     */
  ALREADY_IN_PROGRESS = 'alreadyInProgress',
  /**
   * Invalid parameter. Invalid use of method. Details are found in details.
   *
   * **Potential causes:**
   *
   * * Using an orderRef that previously resulted in a completed order. The order cannot be collected twice.
   *
   * * Using an orderRef that previously resulted in a failed order. The order cannot be collected twice.
   *
   * * Using an orderRef that is too old.
   *
   * * Completed orders can only be collected up to 3 minutes and failed orders up to 5 minutes.
   *
   * * Timed out orders due to never being picked up by the client are only available for collect for 3 min and 10 seconds.
   *
   * * Using a different RP-certificate than the one used to create the order.
   *
   * * Using too big content in the request.
   *
   * * Using non-JSON in the request body.
   */

  INVALID_PARAMETERS = 'invalidParameters',
  /**
   * RP does not have access to the service.
   */
  UNAUTHORIZED = 'unauthorized',
  /**
   * An erroneous URL path was used.
   */
  NOT_FOUND = 'notFound',
  /**
   * Only http method POST is allowed.
   */
  METHOD_NOT_ALLOWED = 'methodNotAllowed',
  /**
   * It took too long time to transmit the request.
   */
  REQUEST_TIMEOUT = 'requestTimeout',
  /**
   * Adding a "charset" parameter after 'application/json' is not allowed since the MIME type "application/json" has neither optional nor required parameters.
   */
  UNSUPPORTED_MEDIA_TYPE = 'unsupportedMediaType',
  /**
   * Internal technical error in the BankID system.
   */
  INTERNAL_ERROR = 'internalError',
  /**
   * The service is temporarily unavailable
   */
  MAINTENANCE = 'maintenance',
  /**
   * Unknown error code.
   *
   * **NOTE** This is not a valid error code from the BankID API. It is used to represent an unknown error code and set on FE
   */
  UNKNOWN = 'unknown',
}

export type TextIds =
  | 'rfa1'
  | 'rfa2'
  | 'rfa3'
  | 'rfa4'
  | 'rfa5'
  | 'rfa6'
  | 'rfa8'
  | 'rfa9'
  | 'rfa13'
  | 'rfa14'
  | 'rfa15'
  | 'rfa16'
  | 'rfa17'
  | 'rfa18'
  | 'rfa19'
  | 'rfa20'
  | 'rfa21'
  | 'rfa22'
  | 'rfa23';

export type BankIdDictionary = Record<TextIds, string>;

export interface DisplayFriendlyErrorStatus {
  isError: true;
  text?: string;
}

export interface DisplayFriendlyPendingStatus {
  /**
   * when present, QR code must be generated
   */
  qrCodeString?: string;
  /**
   * when present, <img src={base64QrCode} /> must be displayed
   */
  base64QrCode?: string;

  /**
   * Text of the displayed message
   */
  text?: string | null;
}

export interface OrderStatusPending {
  status: OrderStatusEnum.PENDING;
  qrCodeText?: string;
  qrBase64?: string;
  hintCode?: string;
}

export interface OrderStatusComplete {
  status: OrderStatusEnum.COMPLETE;
  jwt: string;
}

export interface OrderStatusFailed {
  status: OrderStatusEnum.FAILED;
  hintCode: string;
}

/**
 * similar to "failed", but when HTTP request itself is crashed
 */
export interface OrderStatusCrashed {
  status: OrderStatusEnum.CRASHED;
  errorCode: string;
}

export interface OrderStatusFoo {
  qrCodeText?: string;
  status: string;
}

export type OrderStatus =
  | OrderStatusPending
  | OrderStatusComplete
  | OrderStatusFailed
  | OrderStatusCrashed;

export type DisplayFriendlyStatus = DisplayFriendlyPendingStatus | DisplayFriendlyErrorStatus;

export function isDisplayFriendlyError(
  maybeGuiFriendlyStatus: unknown
): maybeGuiFriendlyStatus is DisplayFriendlyErrorStatus {
  return (
    typeof maybeGuiFriendlyStatus === 'object' &&
    maybeGuiFriendlyStatus !== null &&
    'isError' in maybeGuiFriendlyStatus &&
    'text' in maybeGuiFriendlyStatus
  );
}

export interface BankIdStartAuthDTO {
  orderRef: string;
  autoStartToken: string;
  qrStartToken: string;
}

export interface SwedishBankIdAPI {
  getBankIdOrderRef(): Promise<BankIdStartAuthDTO>;

  collectOrderStatus(orderRef: string): Promise<OrderStatus>;
}
