// if there are brackets [], there should be one '/' sign inside
export const REGEX_PATIENT_FIELD_VALIDATION = /^[^[\]]*(\[[^/[\]]*\/[^/\][]*\][^[\]]*)*$/;
// there should be three '/' signs inside
export const REGEX_PATIENT_4_FIELD_VALIDATION =
  /^[^[\]]*(\[[^/\]]+\/[^/\]]+\/[^/\]]*\/[^/\]]*\][^[\]]*)+$/;
// there should be three '|' signs inside
export const REGEX_PATIENT_4_FIELD_VALIDATION_PIPE =
  /^[^[\]]*(\[[^/\]]*\|[^/\]]*\|[^/\]]*\|[^/\]]*\][^[\]]*)+$/;

const anyInSquareBrackets = `\\[([^\\[\\]]|(\\[[^\\[\\]]*\\]))*\\]`;
const anyInParentheses = `\\([^\\)]*\\)`;
const url = `(https?:\\/\\/)?(www\\.)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?([a-zA-Z0-9-._~:/?#[\\]@!$&'()*+,;=]*)`;

export const REGEX_BRACKETS_EXPRESSION_PATTERN = `${anyInSquareBrackets}(${anyInParentheses})?`;
export const REGEX_PIPE_ALTERNATIVES = /^[^()]*(\([^/()]*\/[^/)(]*\)[^()]*)*$/;

const REGEX_TEMPLATE_PATTERN = `^${anyInSquareBrackets}$`;
const REGEX_MARKDOWN_HYPERLINK_PATTERN = `^${anyInSquareBrackets}\\(${url}\\)$`;

export const isTemplate = (expr: string) => {
  return new RegExp(REGEX_TEMPLATE_PATTERN, 'g').test(expr);
};
export const isValidHyperlink = (expr: string) => {
  return new RegExp(REGEX_MARKDOWN_HYPERLINK_PATTERN, 'g').test(expr);
};
