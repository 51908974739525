import React from 'react';
import ReactMarkdown from 'react-markdown';

import { RICH_TEXT_CONTENT_TYPES } from 'constants/text';

import { sectionPlugin } from '../../utils/markdown';
import { MarkdownImage } from '../MarkdownImage';
import { MarkdownSection } from '../MarkdownSection';

interface RenderPreviewProps {
  text: string;
  type: RICH_TEXT_CONTENT_TYPES;
}

export const RenderPreview: React.FC<RenderPreviewProps> = ({ type, text }) => {
  if (type === RICH_TEXT_CONTENT_TYPES.HTML) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  return (
    <ReactMarkdown
      rehypePlugins={[sectionPlugin]}
      components={{ section: MarkdownSection, img: MarkdownImage }}
      linkTarget="_blank"
    >
      {text}
    </ReactMarkdown>
  );
};
