export enum BASIC_DETAILS_FORM_FIELDS {
  ID = 'id',
  NAME = 'name',
  CLINIC_NAME = 'clinicName',
  EMAIL = 'email',
  BANK_ID_DISPLAY_NAME = 'bankIdDisplayName',
  SMS_DISPLAY_NAME = 'smsDisplayName',
  PAYMENT_METHOD = 'paymentMethod',
  CARE_PROVIDER_NAME = 'careProviderName',
  CARE_PROVIDER_ID = 'careProviderId',
}

export const PRICE_RULES_FORM_FIELDS = {
  NAME: 'name',
  RESOURCE_TYPE: 'resourceType',
  CONDITION_ID: 'conditionId',
  CARE_UNIT_ID: 'careUnitId',
  PRICE: 'price',
};

export const ONLINE_FLOW_FORM_FIELDS = {
  OPEN: 'open',
  RESOURCE_TYPE: 'resourceType',
  CONDITION_ID: 'conditionId',
  CARE_UNIT_ID: 'careUnitId',
  CAPABILITY: 'capability',
};

export const OPENING_HOURS_FORM_FIELDS = {
  WEEKDAYS: 'weekdays',
  OPEN: 'open',
  FROM_HOURS: 'fromHours',
  FROM_MINUTES: 'fromMinutes',
  TO_HOURS: 'toHours',
  TO_MINUTES: 'toMinutes',
  NAME: 'name',
  FROM_TIME: 'fromTime',
  TO_TIME: 'toTime',
};

export const TEMPORARY_PAYMENT_OPTIONS = [
  { label: 'Klarna', value: 'KLARNA' },
  { label: 'Offline', value: 'OFFLINE' },
];

export enum ORIGIN_RULES_CATEGORIES {
  'OPENING_HOURS' = 'openingHoursRule',
  'WORKFLOW' = 'workflowRule',
  'TRIAGE_OUTCOME' = 'triageOutcomeRule',
  'ACTION_PROPERTY' = 'actionPropertyRule',
  'RESOURCE' = 'resourceRule',
  'CARE_UNIT' = 'careUnitRule',
  'APPOINTMENT_PRIORITY' = 'appointmentPriorityRule',
  'ACTION_TEXT_PREFIX' = 'actionTextPrefixRule',
  'OPTIONS' = 'optionsRule',
  'ACTION_TEXT_GENERATION' = 'actionTextGenerationRule',
  'PRICE' = 'priceRule',
  'OTHER' = 'otherRule',
}

export const ORIGIN_RULES_CATEGORY_PRIORITY = {
  [ORIGIN_RULES_CATEGORIES.OPENING_HOURS]: 1000,
  [ORIGIN_RULES_CATEGORIES.WORKFLOW]: 2000,
  [ORIGIN_RULES_CATEGORIES.TRIAGE_OUTCOME]: 3000,
  [ORIGIN_RULES_CATEGORIES.ACTION_PROPERTY]: 4000,
  [ORIGIN_RULES_CATEGORIES.RESOURCE]: 5000,
  [ORIGIN_RULES_CATEGORIES.CARE_UNIT]: 6000,
  [ORIGIN_RULES_CATEGORIES.APPOINTMENT_PRIORITY]: 7000,
  [ORIGIN_RULES_CATEGORIES.ACTION_TEXT_PREFIX]: 8000,
  [ORIGIN_RULES_CATEGORIES.OPTIONS]: 9000,
  [ORIGIN_RULES_CATEGORIES.ACTION_TEXT_GENERATION]: 10000,
  [ORIGIN_RULES_CATEGORIES.PRICE]: 11000,
  [ORIGIN_RULES_CATEGORIES.OTHER]: 12000,
};

export const VIEWABLE_ORIGIN_RULES_CATEGORIES = [
  ORIGIN_RULES_CATEGORIES.OPENING_HOURS,
  ORIGIN_RULES_CATEGORIES.CARE_UNIT,
  ORIGIN_RULES_CATEGORIES.PRICE,
  ORIGIN_RULES_CATEGORIES.RESOURCE,
  ORIGIN_RULES_CATEGORIES.OTHER,
];
