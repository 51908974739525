import axios from 'axios';

import { LANGS } from 'constants/enums';
import { IDENTITIES } from 'constants/practitioner';

import { PARTNERS_URI } from './partnersApi';

export interface CareUnitBase {
  id: string;
  name: string;
  externalOrgId: string;
  externalOrgIdType: string;
}

export interface Partner {
  id: string;
  name: string;
}

export interface CareProvider {
  countryCode: string;
  externalOrgId: string;
  externalOrgIdType: IDENTITIES;
  id: string;
  languageCode: LANGS;
  name: string;
  partner?: Partner;
}

export interface CareUnit extends CareUnitBase {
  visitingAddress: string;
  latitude: number;
  longitude: number;
  bookingSystem: string;
  location: string;
  phoneNumber: string;
  defaultOriginId: string;
  careProvider?: Omit<CareProvider, 'languageCode' | 'countryCode'>;
}

export const CARE_UNITS_URI = '/rest/admin/v1/me/careunits';

export const getAllCareUnits = () => axios.get<CareUnit[]>(CARE_UNITS_URI);

export const fetchCareUnit = (partnerId: string, careProviderId: string, id: string) =>
  axios.get<CareUnit>(
    `${PARTNERS_URI}/${encodeURIComponent(partnerId)}/careproviders/${encodeURIComponent(
      careProviderId
    )}/careunits/${encodeURIComponent(id)}`
  );

export const updateCareUnit = (partnerId: string, careProviderId: string, careUnit: CareUnit) =>
  axios.put(
    `${PARTNERS_URI}/${encodeURIComponent(partnerId)}/careproviders/${encodeURIComponent(
      careProviderId
    )}/careunits/${encodeURIComponent(careUnit.id)}`,
    careUnit
  );

export const createCareUnit = (partnerId: string, careProviderId: string, data: CareUnit) =>
  axios.post<CareUnit>(
    `${PARTNERS_URI}/${encodeURIComponent(partnerId)}/careproviders/${encodeURIComponent(
      careProviderId
    )}/careunits`,
    data
  );

export const fetchCareUnits = (partnerId: string, careProviderId: string) =>
  axios.get<CareUnit[]>(
    `${PARTNERS_URI}/${encodeURIComponent(partnerId)}/careproviders/${encodeURIComponent(
      careProviderId
    )}/careunits`
  );

export const fetchCareProvider = (partnerId: string, careProviderId: string) =>
  axios.get<CareProvider>(
    `${PARTNERS_URI}/${encodeURIComponent(partnerId)}/careproviders/${encodeURIComponent(
      careProviderId
    )}`
  );
