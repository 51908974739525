import { Button, Popconfirm } from 'antd';
import { Observer } from 'mobx-react';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';

import styles from './EditSelfcare.module.css';

interface EditSelfCareControlsProps {
  onDelete?: () => void;
  isValid?: boolean;
}

export const EditSelfCareControls: React.FC<EditSelfCareControlsProps> = ({
  isValid,
  onDelete,
}) => {
  const { selfcareStore } = useContext(RootStoreContext);
  const isLoading = selfcareStore.isLoading;
  const isSaving = selfcareStore.isSaving;
  const isDisabled = isLoading || isSaving;
  const showPreview = selfcareStore.isPreviewMode;

  return (
    <Observer>
      {() => (
        <div className={styles.actionBar}>
          {isValid !== undefined && (
            <FormActionButtons
              isSaving={isSaving}
              isValid={isValid}
              isDisabled={isLoading}
              disabledContent={null}
            />
          )}
          <Button
            shape="round"
            onClick={selfcareStore.togglePreviewMode}
            disabled={isDisabled}
            className={styles.previewButton}
          >
            {showPreview ? (
              <FormattedMessage id="general.edit" />
            ) : (
              <FormattedMessage id="general.preview" />
            )}
          </Button>
          {onDelete && (
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={onDelete}
            >
              <Button shape="round" disabled={isDisabled} className={styles.previewButton}>
                <FormattedMessage id="general.delete" />
              </Button>
            </Popconfirm>
          )}
        </div>
      )}
    </Observer>
  );
};
