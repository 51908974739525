export function getPrecisionForMaxMinValues(stepValue: string | number | undefined) {
  if (!stepValue) {
    return 0;
  }

  let precision = 0;
  let step = stepValue;

  if (typeof step === 'string') {
    step = parseFloat(step);
  }

  if (step < 1) {
    do {
      step *= 10;
      precision += 1;
    } while (step < 1);
  }

  return precision;
}

export function getStepForMaxMinValues(stepValue: string | number | undefined) {
  if (!stepValue) {
    return 1;
  }

  let step = stepValue;

  if (typeof step === 'string') {
    step = parseFloat(step);
  }
  return step < 1 ? step : 1;
}
