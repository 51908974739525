import { jotaiStore as store } from 'config/jotaiStore';
import { RESOURCE_TYPES } from 'constants/permissions';
import { userPermissionsAtoms, userPermissionsSelectors } from 'state/userPermissions';
import { getPartnerId } from 'utils/tokenUtils';

const getCanEditContent24Resource = (
  resourceType: RESOURCE_TYPES.CODE24 | RESOURCE_TYPES.HOOKS | RESOURCE_TYPES.SELFCARE
) => {
  const partnerId = getPartnerId();
  if (!partnerId) {
    return false;
  }

  return partnerId
    ? userPermissionsSelectors.getPermission(
        {
          // Currently there's no way to set partner scope permission in Manage24
          // Details: https://platform24.atlassian.net/browse/AX-30158
          originId: partnerId,
          resourceType,
        },
        store.get(userPermissionsAtoms.canEditCurrentPartner)
      )
    : false;
};

export const content24Selectors = {
  getCanEditContent24Resource,
};
