import { FileWithPath, UseFilePickerConfig } from 'use-file-picker/types';
import { Validator } from 'use-file-picker/validators';

// modified from https://github.com/Jaaneek/useFilePicker/issues/76#issuecomment-1722588180
// for using with MIME types instead of file extensions
export class MimeTypeValidator extends Validator {
  constructor(private readonly acceptedMimeTypes: string[]) {
    super();
  }

  validateBeforeParsing(_config: UseFilePickerConfig<any>, plainFiles: File[]): Promise<void> {
    const mimeTypeErrors = plainFiles.reduce<
      { name: string; reason: string; causedByFile: File }[]
    >((errors, currentFile) => {
      const fileType = currentFile.type;
      if (!fileType) {
        return [
          ...errors,
          {
            name: 'FileTypeError',
            reason: 'FILE_TYPE_NOT_FOUND',
            causedByFile: currentFile,
          },
        ];
      }
      if (!this.acceptedMimeTypes.includes(fileType)) {
        return [
          ...errors,
          {
            name: 'FileTypeError',
            reason: 'FILE_TYPE_NOT_ACCEPTED',
            causedByFile: currentFile,
          },
        ];
      }

      return errors;
    }, []);

    return mimeTypeErrors.length > 0 ? Promise.reject(mimeTypeErrors) : Promise.resolve();
  }

  validateAfterParsing(
    _config: UseFilePickerConfig<any>,
    _file: FileWithPath,
    _reader: FileReader
  ): Promise<void> {
    return Promise.resolve();
  }
}
