import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

// We must exclude id, originalStatement properties from PUT payload recursively
// if they co-exist in the same object, since those fields have actual data and our BE code
// would parse it and consider that data as a part of update payload.
// Which leads to errors when user tries to remove some field value in condition form for example.
// See more in https://platform24.atlassian.net/browse/AX-38011

/**
 * Takes condition update payload and removes id and originalStatment properties recursively,
 * if they co-exist in the same object.
 *
 * @param data unknown
 */

export function stripIdAndOriginalStatement(data: unknown): unknown {
  // Convert array recursively
  if (Array.isArray(data)) {
    return data.map(value => stripIdAndOriginalStatement(value));
  }

  // Convert objects recursively, strip id and originalStatement if they co-exist on the same level
  if (typeof data === 'object' && isPlainObject(data)) {
    const result = mapValues(data, (value: unknown) =>
      stripIdAndOriginalStatement(value)
    ) as Record<string, unknown>;

    if (result.id && result.originalStatement) {
      delete result.id;
      delete result.originalStatement;
    }

    return result;
  }

  // return unchanged
  return data;
}
