import { Select, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { LabelledField } from '../fields/LabelledField';
import { UnlocalizedFieldOutsideDefaultLangContainer } from '../fields/UnlocalizedFieldOutsideDefaultLangContainer';
import { useIsOutsideDefaultLanguage } from '../LanguageTabs/LanguageTabs.state';

const Styled = {
  Container: styled.div`
    margin-bottom: 1rem;
    margin-top: 1px;
  `,
  LabelledField: styled(LabelledField)`
    margin-bottom: 0;
  `,
};

interface Props {
  selected?: string[];
  name?: string;
  className?: string;
  onChange?: (selectedTags: string[]) => void;
}
export const TagsSelect = ({ name, selected, className, onChange }: Props) => {
  const [isOutsideDefaultLanguage] = useIsOutsideDefaultLanguage();

  return (
    <Styled.Container className={className}>
      <UnlocalizedFieldOutsideDefaultLangContainer active={isOutsideDefaultLanguage}>
        <Styled.LabelledField label="Tags" name={name} tooltip="Tags">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            disabled={isOutsideDefaultLanguage}
            value={selected}
            onChange={onChange}
          />
        </Styled.LabelledField>
      </UnlocalizedFieldOutsideDefaultLangContainer>

      <Typography.Text type="secondary">
        <FormattedMessage id="articles.tags.tooltip" />
      </Typography.Text>
    </Styled.Container>
  );
};
