import { Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { EditorState } from 'draft-js';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PARAGRAPH_TYPES = [
  { label: 'H1', value: 'header-one' },
  { label: 'H2', value: 'header-two' },
  { label: 'H3', value: 'header-three' },
  { label: 'H4', value: 'header-four' },
  { label: 'H5', value: 'header-five' },
  { label: 'H6', value: 'header-six' },
  { label: <FormattedMessage id="general.text" />, value: 'unstyled' },
];

interface Props {
  editorState: EditorState;
  onToggle: (style: string) => void;
  isDisabled?: boolean;
}

const ParagraphStyleControls = (props: Props) => {
  const { editorState, isDisabled } = props;
  const selection = editorState.getSelection();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const activeParagraphItem = PARAGRAPH_TYPES.find(({ value }) => value === blockType);

  return (
    <Select
      style={{ width: '80px' }}
      labelInValue
      value={
        activeParagraphItem || { label: <FormattedMessage id="general.text" />, value: 'unstyled' }
      }
      onChange={({ value }: LabeledValue) => props.onToggle(value.toString())}
      disabled={isDisabled}
      options={PARAGRAPH_TYPES}
    />
  );
};

export default ParagraphStyleControls;
