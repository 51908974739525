import { omit } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LOGIN_METHODS } from 'constants/login';
import { useLang } from 'context/IntlContext';
import RootStoreContext from 'context/RootStoreContext';

import { parseSwedishBankIdStatusObject } from './ParseSwedishBankIdStatusObject';
import { SwedishBankIdAPIImpl } from './SwedishBankId.api';
import {
  BankIdStartAuthDTO,
  DisplayFriendlyStatus,
  OrderStatusEnum,
  SwedishBankIdAPI,
} from './SwedishBankId.types';

export interface UseSwedishSecureLoginHook {
  retry?: () => void;
  status: DisplayFriendlyStatus;
  autoStartToken?: string | undefined;
  startOnThisDevice: () => void;
}

const EMPTY_STATUS = {};

/**
 * Contains the logic for the Swedish BankID login
 */
const createUseSwedishSecureLogin = (api: SwedishBankIdAPI) => (): UseSwedishSecureLoginHook => {
  const lang = useLang().lang;
  const history = useHistory();
  const { authStore } = useContext(RootStoreContext);
  const [localBankIdFlow, setLocalBankIdFlow] = useState(false);

  const [startObj, setStartObj] = useState<BankIdStartAuthDTO | null>(null);
  const [tryCount, setTry] = useState(0);

  const retry = useCallback(() => {
    // whenever we click "retry", we increment the try count
    // that causes the effect to run again
    // getting new orderRef
    setTry(prev => prev + 1);
    setLocalBankIdFlow(false); // reset, so we show qr code and button again
  }, []);

  useEffect(() => {
    setStartObj(null);
    api.getBankIdOrderRef().then(setStartObj);
  }, [tryCount]);

  const [status, setStatus] = useState<DisplayFriendlyStatus>(EMPTY_STATUS);

  useEffect(() => {
    if (startObj) {
      let isMounted = true;
      let timeout: ReturnType<typeof setTimeout>;
      const poll = async () => {
        const status = await api.collectOrderStatus(startObj.orderRef);
        if (isMounted) {
          setStatus(parseSwedishBankIdStatusObject(status, lang));

          if (status.status === OrderStatusEnum.PENDING) {
            timeout = setTimeout(poll, 1000);
          }

          if (status.status === OrderStatusEnum.COMPLETE) {
            await authStore.doLogin(LOGIN_METHODS.SWEDISH_BANK_ID, status.jwt);
            history.push('/login/select-partner');
          }
        }
      };

      poll();

      return () => {
        isMounted = false;
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    } else {
      setStatus(EMPTY_STATUS);
      return;
    }
  }, [authStore, history, lang, startObj]);

  const startOnThisDevice = useCallback(() => {
    if (startObj) {
      setLocalBankIdFlow(true);
      const url = `bankid:///?autostarttoken=${startObj.autoStartToken}&redirect=null`;
      try {
        window.location.href = url;
      } catch (e) {
        console.error('Failed to start BankID on this device', e);
      }
    }
  }, [startObj]);

  const cleanedUpStatus = localBankIdFlow ? omit(status, ['qrCodeString']) : status;

  return {
    status: startObj ? cleanedUpStatus : EMPTY_STATUS,
    retry,
    autoStartToken: startObj?.autoStartToken,
    startOnThisDevice,
  };
};

/**
 * Contains the logic for the Swedish BankID login
 */
export const useSwedishSecureLogin = createUseSwedishSecureLogin(new SwedishBankIdAPIImpl());
