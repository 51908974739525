export const getVersionFromHostname = (hostname: string, scope: string) => {
  if (!hostname.endsWith('.manage.dev.platform24.se')) {
    return null;
  }

  // cannot practically be longer than 63 characters
  const [rawOverrides] = hostname.split('.');

  for (const match of rawOverrides.matchAll(
    /mfe(?<scopes>(-[a-z][_a-z\d]*)+)-(?<version>\d+-\d+-\d+)-(?<hash>[a-f\d]{8})/g
  )) {
    if (match.groups?.scopes.split('-').includes(scope.toLowerCase())) {
      return `${match.groups.version.replaceAll('-', '.')}/${match.groups.hash}`;
    }
  }

  return null;
};

export const getOverridenLatestVersion = (scope: string) =>
  getVersionFromHostname(window.location.hostname, scope);
