import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Spin, notification } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

import OfflineFlowForm from './components/OfflineFlowForm';
import styles from './OfflineFlow.module.css';

interface Props extends RouteComponentProps<{ originId: string }>, WrappedComponentProps {}

@observer
export class OfflineFlow extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  componentDidMount() {
    this.context.originCareUnitsStore.fetch(this.props.match.params.originId);
  }

  handleChange = async (id: string) => {
    const { originCareUnitsStore } = this.context;
    const updatedList = originCareUnitsStore.list.map(el => {
      if (el.careUnitId === id) {
        return {
          ...el,
          enabled: !el.enabled,
        };
      }
      return el;
    });

    try {
      const { match, intl } = this.props;
      const { originCareUnitsStore } = this.context;

      await originCareUnitsStore.update(updatedList, match.params.originId);

      notification.success({
        placement: 'top',
        message: intl.formatMessage({ id: 'origin.offline-flow.updated' }),
      });
      /* eslint-disable no-empty */
    } catch (e: any) {}
  };

  render() {
    const { originCareUnitsStore, userPermissionsStore } = this.context;

    return (
      <div>
        <div className={styles.header}>
          <Typography.Title level={2}>
            <FormattedMessage id="origin.offline-flow.header" />
          </Typography.Title>
          <p className={styles.subheader}>
            <FormattedMessage id="origin.offline-flow.subheader" />
          </p>
        </div>
        <Spin
          size="large"
          indicator={<LoadingOutlined spin />}
          spinning={originCareUnitsStore.isLoading()}
        >
          <OfflineFlowForm
            canEdit={
              userPermissionsStore.hasSuperAdminRole ||
              userPermissionsStore.canEditOrigin(this.props.match.params.originId)
            }
            onChange={this.handleChange}
            isSaving={originCareUnitsStore.isSaving()}
            enabledList={originCareUnitsStore.enabledList}
            disabledList={originCareUnitsStore.disabledList}
          />
        </Spin>
      </div>
    );
  }
}

export default injectIntl(OfflineFlow);
