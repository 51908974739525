import React from 'react';

import { ReactComponent as Logo } from 'assets/svg/platform24-logo.svg';
import Copyright from 'modules/Login/components/Copyright';

import styles from './LoginPage.module.css';
import { LoginRoutes } from './LoginRoutes';
import { useLoginRedirects } from '../../hooks/useLoginRedirects';

export const NewLoginPage = () => {
  useLoginRedirects();
  return (
    <div className={styles.container}>
      <div className={styles.loginSection} data-testid="login-section">
        <div className={styles.logoBar}>
          <Logo className={styles.logo} />
          <div className={styles.headerText}>Manage</div>
        </div>
        <div className={styles.content}>
          <LoginRoutes />
        </div>
      </div>
      <div className={styles.copyright}>
        <Copyright />
      </div>
    </div>
  );
};
