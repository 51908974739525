import React, { MutableRefObject, useCallback } from 'react';

import { isSelfMountedConstructor, SelfMountedInterface } from './remoteComponent.types';
import { toSelfRendered } from './toSelfRendered';

export const useOnModuleLoaded = <T>(
  ref: MutableRefObject<HTMLDivElement | null>,
  createRoot: any
): [SelfMountedInterface<T> | null, (module: unknown) => void] => {
  const [Component, setComponent] = React.useState<SelfMountedInterface<T> | null>(() => null);

  const onLoaded = useCallback(
    (module: unknown) => {
      // if ref.current is null -- we already unmounted component
      if (ref.current) {
        const mod = isSelfMountedConstructor(module)
          ? module
          : toSelfRendered((module as { component: any }).component ?? module, createRoot);
        const component = mod.create(ref.current);
        setComponent(() => component);
      }
    },
    [createRoot, ref]
  );

  return [Component, onLoaded];
};
