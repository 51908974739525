import { Typography } from 'antd';
import { useField } from 'formik';
import React from 'react';

import style from './OpeningHours.module.css';
import { TimePicker } from './TimePicker';
import { Toggle } from './Toggle';
import { getWeekDayName } from '../../../../utils/dateUtils';

interface Props {
  name: string;
  disabled?: boolean;
}

export const RowRegularDay = ({ name, disabled }: Props) => {
  const [{ value: dayName }] = useField(`${name}.day`);

  return (
    <tr className={style.row} data-testid={`row-${dayName}`}>
      <td className={style.day}>
        <Typography.Text>{getWeekDayName(dayName)}</Typography.Text>
      </td>
      <td>
        <Toggle name={name} disabled={disabled} />
      </td>
      <td>{<TimePicker name={name} disabled={disabled} />}</td>
    </tr>
  );
};
