import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './PageWithSectionsContainer.module.css';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const PageWithSectionsContainer: FunctionComponent<Props> = ({
  children,
  className,
  ...restProps
}) => (
  <div className={classNames(styles.settingsContainer, className)} {...restProps}>
    {children}
  </div>
);

export default PageWithSectionsContainer;
