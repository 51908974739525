import {
  MedicineBoxOutlined,
  MobileOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  TeamOutlined,
  UserOutlined,
  PicLeftOutlined,
  InfoCircleOutlined,
  FolderViewOutlined,
  BranchesOutlined,
  BugOutlined,
} from '@ant-design/icons';
import { routes } from '@platform24/admin-ui-utils';
import type { MenuProps } from 'antd';
import { Layout, Select, Button, Tooltip, Menu } from 'antd';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import logo from 'assets/svg/manage24.svg';
import logoCollapsed from 'assets/svg/manage24_small.svg';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { LANGS } from 'constants/enums';
import { IntlConsumer } from 'context/IntlContext';
import RootStoreContext from 'context/RootStoreContext';
import {
  canActivateContent24,
  canActivateCareUnits,
  canActivatePractitioners,
  canActivateOrigins,
  canViewRules,
  canViewCarePathways,
} from 'routes/routeGuards';
import { withNavigationCollapsedState } from 'stores/NavigationCollapse';
import { InputOption } from 'types/types';
import { getFirstLetters, getValueOfFeatureFlag, isEducationEnv } from 'utils/appUtils';

import Submenu, { SublinkItem } from './components/Submenu';
import styles from './MainNavigation.module.css';

const QUESTIONNAIRE_LINK_GROUPS = ['triage', 'diagnosis', 'assessment', 'libraries'];

type MenuItem = MenuProps['items'];

interface Props extends RouteComponentProps {
  intl: IntlShape;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

@observer
class MainNavigation extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  handleLogout = () => {
    this.context.authStore.logout();
    this.props.history.push('/login');
  };

  handleChangePartner = async (partnerId: string) => {
    await this.context.authStore.partnerSwitch(partnerId);
    // Reset the app with new partner context
    this.props.history.push('/');
    window.location.reload();
  };

  @computed get careUnitsLinks(): SublinkItem[] {
    return this.sortNavList(
      toJS(
        this.context.userDataStore.userCareUnits.map(elem => ({
          ...elem,
          slug: `/${elem.id}/basic-details`,
        }))
      )
    );
  }

  get content24Links(): SublinkItem[] {
    const conditionLinks = QUESTIONNAIRE_LINK_GROUPS.map(itemName => ({
      id: `/questionnaires?category=${itemName}`,
      slug: `/questionnaires?category=${itemName}`,
      name: this.props.intl.formatMessage({ id: `condition-list.category-${itemName}` }),
      testId: `main-navigation-content24-${itemName}`,
    }));
    const selfcareLink = {
      id: routes.content24.selfCare.list.path,
      slug: routes.content24.selfCare.list.path,
      name: this.props.intl.formatMessage({ id: 'selfcare.menu-item' }),
      testId: 'main-navigation-content24-selfcare',
    };
    const informationArticlesLink = {
      id: routes.content24.articles.list.path,
      slug: routes.content24.articles.list.path,
      name: this.props.intl.formatMessage({ id: 'articles.title' }),
      testId: 'main-navigation-content24-articles',
    };
    const statisticsLink = {
      id: '/statistics',
      slug: '/statistics',
      name: this.props.intl.formatMessage({ id: 'statistics.header' }),
      testId: 'main-navigation-content24-statistics',
    };
    const testLink = {
      id: '/test',
      slug: '/test',
      name: this.props.intl.formatMessage({ id: 'test.menu-item' }),
      testId: 'main-navigation-content24-test',
    };
    const list = this.sortNavList(conditionLinks.concat(selfcareLink));

    if (canViewCarePathways(this.context.userPermissionsStore) && !isEducationEnv()) {
      list.push(informationArticlesLink);
    }

    if (this.context.partnersStore.partnerCustomizations.get('CODE24_STATISTICS_ENABLED')) {
      list.push(statisticsLink);
    }

    list.push(testLink);

    return list;
  }

  sortNavList = (list: SublinkItem[]) =>
    sortBy(list, elem => (elem.name ? elem.name.toLowerCase() : (elem.id || '').toLowerCase()));

  renderPartner = () => {
    const { isCollapsed } = this.props;
    const { partnersStore } = this.context;

    return partnersStore.partners.length > 1 ? (
      <Select
        className={styles.select}
        defaultValue={partnersStore.partnerId}
        onSelect={this.handleChangePartner}
        showSearch
      >
        {partnersStore.partnersAsSelectOptions.map((opt: InputOption) => (
          <Select.Option key={opt.value} value={opt.value}>
            {isCollapsed ? (
              <Tooltip title={opt.label} placement="right">
                <span>{opt.label}</span>
              </Tooltip>
            ) : (
              opt.label
            )}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <p className={styles.soloPartner}>{partnersStore.currentPartner?.id}</p>
    );
  };

  render() {
    const { userDataStore: userStore, partnersStore, userPermissionsStore } = this.context;
    const { location, intl, isCollapsed, toggleCollapse } = this.props;
    const activeMenuKey = location.pathname.split('/')[1];
    const isDanishEnabled = getValueOfFeatureFlag('FF_ENABLE_DANISH_TRANSLATION');
    const languageOptions = Object.values(LANGS)
      .filter(language => (!isDanishEnabled && language === LANGS.DA ? false : true))
      .map(value => ({
        value,
        label: intl.formatMessage({ id: `general.language-original-${value}` }),
      }));

    const IS_EDUCATION_ENV = isEducationEnv();

    // TODO: refactor
    const menuItems: MenuItem = [];
    if (canActivateOrigins(userPermissionsStore) && !IS_EDUCATION_ENV) {
      menuItems.push({
        key: 'origins',
        icon: <MobileOutlined className={styles.linkIcon} data-testid="main-navigation-origins" />,
        label: (
          <NavLink to="/origins" data-testid="origins-menu-item">
            <FormattedMessage id="main-navigation.patient-apps" />
          </NavLink>
        ),
        className: styles.linkContainer,
      });
    }
    if (canActivateCareUnits(userPermissionsStore) && !IS_EDUCATION_ENV) {
      menuItems.push({
        key: 'care-providers',
        icon: (
          <MedicineBoxOutlined
            className={styles.linkIcon}
            data-testid="main-navigation-care-providers"
          />
        ),
        label: (
          <NavLink to="/care-providers" data-testid="care-providers-menu-item">
            <FormattedMessage id="care-providers-and-care-units" />
          </NavLink>
        ),
        className: styles.linkContainer,
      });
    }
    if (canActivatePractitioners(userPermissionsStore)) {
      menuItems.push({
        className: styles.linkContainer,
        key: 'roles',
        icon: <TeamOutlined data-testid="main-navigation-practitioners" />,
        label: (
          <NavLink data-testid="users-menu-item" to="/roles">
            <FormattedMessage id="main-navigation.practitioners" />
          </NavLink>
        ),
      });
    }
    if (canActivateContent24(userPermissionsStore) && !IS_EDUCATION_ENV) {
      menuItems.push({
        ...Submenu({
          mainLink: 'content24',
          isMainLinkActive: false,
          testId: 'main-navigation-content24',
          subLinks: this.content24Links,
          text: <FormattedMessage id="main-navigation.content24" />,
          icon: <PicLeftOutlined />,
          activeMenuKey: activeMenuKey,
          isCollapsed,
        }),
      });
    }
    if (canViewRules(userPermissionsStore) && !IS_EDUCATION_ENV) {
      menuItems.push({
        key: 'rules',
        label: (
          <NavLink data-testid="rules-menu-item" to="/rules">
            <FormattedMessage id="main-navigation.rules" />
          </NavLink>
        ),
        icon: <FolderViewOutlined data-testid="main-navigation-rules" />,
        className: styles.linkContainer,
      });
    }

    if (canViewCarePathways(userPermissionsStore) && !IS_EDUCATION_ENV) {
      menuItems.push({
        key: 'carePathways',
        label: (
          <NavLink data-testid="pathways-menu-item" to="/carePathways">
            <FormattedMessage id="main-navigation.carePathways" />
          </NavLink>
        ),
        icon: <BranchesOutlined data-testid="main-navigation-carePathways" />,
        className: styles.linkContainer,
      });
    }

    return (
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        theme="light"
        className={classNames(styles.container, { [styles.isCollapsed]: isCollapsed })}
      >
        <div className={styles.sliderChildrenWrapper}>
          <div className={classNames(styles.menuItem, styles.logoContainer)}>
            <NavLink to="/">
              <img
                src={isCollapsed ? logoCollapsed : logo}
                alt={intl.formatMessage({ id: 'general.logo' })}
                className={styles.logo}
              />
            </NavLink>
          </div>
          <div data-testid="user-name">
            {isCollapsed ? (
              <Tooltip title={userStore.fullName} placement="right">
                <div
                  data-testid="main-navigation-logout"
                  className={classNames(styles.menuItem, styles.userName)}
                >
                  {getFirstLetters(userStore.fullName)}
                </div>
              </Tooltip>
            ) : (
              <div className={styles.menuItem}>
                <div className={styles.userIcon}>
                  <UserOutlined />
                </div>
                {userStore.fullName}
              </div>
            )}
            <div className={styles.partnerContainer}>
              <p className={styles.partnerLabel}>
                <FormattedMessage id="general.partner" />:
              </p>
              {isCollapsed ? (
                <Tooltip title={partnersStore.currentPartner?.id} placement="right">
                  {this.renderPartner()}
                </Tooltip>
              ) : (
                this.renderPartner()
              )}
            </div>
          </div>

          {userPermissionsStore.isLoaded() && (
            <Menu
              mode="vertical"
              selectable={true}
              className={styles.menuContainer}
              selectedKeys={[activeMenuKey]}
              items={menuItems}
            />
          )}

          <div className={styles.bottomContainer}>
            <IntlConsumer>
              {({ switchLang, lang }) => (
                <LanguageSwitcher
                  activeLanguage={lang as LANGS}
                  languages={languageOptions}
                  onChange={switchLang}
                  className={classNames(styles.languageSwitcher, {
                    [styles.isCollapsed]: isCollapsed,
                  })}
                  isCollapsed={isCollapsed}
                />
              )}
            </IntlConsumer>
            {isCollapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.about" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={() => this.props.history.push('/about')}
                >
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={() => this.props.history.push('/about')}
              >
                <InfoCircleOutlined /> <FormattedMessage id="main-navigation.about" />
              </Button>
            )}
            {isCollapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.expand" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={toggleCollapse}
                >
                  <RightOutlined className={styles.collapseIcon} />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={toggleCollapse}
              >
                <LeftOutlined className={styles.collapseIcon} />
                <FormattedMessage id="main-navigation.collapse" />
              </Button>
            )}
            {isCollapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.logout" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={this.handleLogout}
                  data-testid="main-navigation-logout"
                >
                  <LogoutOutlined className={styles.logoutIcon} />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={this.handleLogout}
                data-testid="main-navigation-logout"
              >
                <LogoutOutlined className={styles.logoutIcon} />
                <FormattedMessage id="main-navigation.logout" />
              </Button>
            )}
            {localStorage.getItem('enable_crash_button') === 'true' && (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={() => {
                  throw new Error('Crash button clicked');
                }}
                data-testid="main-navigation-crash"
              >
                <BugOutlined className={styles.logoutIcon} />
                Crash app
              </Button>
            )}
          </div>
        </div>
      </Layout.Sider>
    );
  }
}

export default withNavigationCollapsedState(injectIntl(withRouter(MainNavigation)));
