import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { stringify } from 'query-string';

import { ACCEPT_LANGUAGE_HEADERS, LANGS } from 'constants/enums';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { LOGIN_ENDPOINTS } from 'constants/login';
import { JWTUpdateFailError } from 'errors/JWTUpdateFailError';
import RootStore from 'stores/RootStore';
import { getToken } from 'utils/tokenUtils';

export default class HttpInterceptor {
  requestsAwaitingTokeRefresh: Map<AxiosRequestConfig, boolean> = new Map();
  pendingTokenRefreshRequest: Promise<void> | null = null;

  constructor(private rootStore: RootStore) {}

  public registerInterceptors(axios: AxiosInstance) {
    this.registerRequestHeaderTokenInterceptor(axios);
    this.registerRequestHeaderLanguageInterceptor(axios);
    this.registerResponseRefreshTokenInterceptor(axios);
    this.registerResponseErrorHandlerInterceptor(axios);
    this.registerResponsePartnerStatusRefreshInterceptor(axios);
  }

  private registerRequestHeaderTokenInterceptor(axios: AxiosInstance) {
    axios.interceptors.request.use(
      config => {
        const authToken = getToken();
        if (authToken !== undefined) {
          config.headers = config.headers || {};
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  private registerRequestHeaderLanguageInterceptor(axios: AxiosInstance) {
    axios.interceptors.request.use(
      config => {
        config.headers = config.headers || {};
        config.headers['Accept-Language'] =
          ACCEPT_LANGUAGE_HEADERS[
            (localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) as LANGS) || LANGS.SV
          ];
        return config;
      },
      error => Promise.reject(error)
    );
  }

  private registerResponseRefreshTokenInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(undefined, async error => {
      if (
        // check if response status is 401
        error.response &&
        error.response.status === 401 &&
        // check request
        error.config &&
        // check if it's not login request
        ![
          LOGIN_ENDPOINTS.SITHS,
          LOGIN_ENDPOINTS.HSAID_DEV,
          LOGIN_ENDPOINTS.NORWEGIAN_BANK_ID_DEV,
        ].includes(error.config.url) &&
        // check if the request is not already repeated
        !this.requestsAwaitingTokeRefresh.has(error.config) &&
        // check if there's a token key set
        getToken()
      ) {
        try {
          this.requestsAwaitingTokeRefresh.set(error.config, true);

          if (!this.pendingTokenRefreshRequest) {
            this.pendingTokenRefreshRequest = this.rootStore.authStore.refreshToken();
          }

          await this.pendingTokenRefreshRequest;

          return axios(error.config);
        } catch (e) {
          this.rootStore.authStore.logout({
            errorMessage: stringify({ messageKey: 'general.errors.jwt-token-update-failed' }),
          });
          return Promise.reject(new JWTUpdateFailError());
        } finally {
          this.pendingTokenRefreshRequest = null;
          this.requestsAwaitingTokeRefresh.delete(error.config);
        }
      }

      if (error.config && this.requestsAwaitingTokeRefresh.has(error.config)) {
        this.requestsAwaitingTokeRefresh.delete(error.config);
      }

      return Promise.reject(error);
    });
  }

  private registerResponseErrorHandlerInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(undefined, async error => {
      const responseStatusesToIgnore = get(error, 'config.ignoreErrorStatuses');

      if (
        responseStatusesToIgnore &&
        error.response &&
        responseStatusesToIgnore.includes(error.response.status)
      ) {
        return Promise.reject(error);
      }

      const errorData: Record<string, string> = {
        stack: error.stack,
        message: error.message,
      };

      if (error.config) {
        errorData.url = error.config.url;
        errorData.method = error.config.method;
        errorData.requestData = JSON.stringify(error.config.data);
        errorData.requestHeaders = JSON.stringify({
          ...error.config.headers,
          Authorization: '["Bearer Token"]',
        });
      }

      if (error.response) {
        errorData.status = error.response.status;
        errorData.statusText = error.response.statusText;
        errorData.responseData = JSON.stringify(error.response.data);
        errorData.responseHeaders = JSON.stringify({
          ...error.response.headers,
          'set-cookie': error.response.headers['set-cookie'] || '["set-cookie"]',
        });
      }

      return Promise.reject(error);
    });
  }

  // We need to refresh the Partner status every time a change is made to the Partner Configuration ednpoints
  // making this action in the interceptor is the easy solution, however it can lead to strange errors.
  // If a better way of handling this use case is found, then it is encouraged to swich to it.
  // Just bear in mind that the partner endpoints might be added/changed/removed quite often, so the solution should
  // should be agnostic to those changes.
  private registerResponsePartnerStatusRefreshInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(
      async response => {
        if (
          response.config.method &&
          ['put', 'post', 'delete'].includes(response.config.method) &&
          response.config.url?.match('rest/admin/partners')
        ) {
          this.rootStore.partnerStatusStore.fetchPartnerGitStatus();
        }
        return Promise.resolve(response);
      },
      (error: AxiosResponse) => {
        return Promise.reject(error);
      }
    );
  }
}
