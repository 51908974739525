import { getOverridenLatestVersion } from './overrides';

const loadRemoteEntry = async (scope: string, versionedScope: string, path: string) => {
  const scripts = Array.from(
    document.querySelectorAll<HTMLScriptElement>(`[data-scope="${scope}"]`)
  );
  const settledScripts = await Promise.allSettled(
    scripts.map(
      script =>
        new Promise((resolve, reject) => {
          switch (script.dataset.state) {
            case 'pending': {
              script.addEventListener('load', () => {
                resolve(undefined);
              });

              script.addEventListener('error', reject);
              return;
            }

            case 'resolved': {
              resolve(undefined);
              return;
            }

            case 'rejected':
            default: {
              reject();
            }
          }
        })
    )
  );

  const scriptIndex = scripts.findIndex(script => script.dataset.path === path);
  if (scriptIndex >= 0) {
    return settledScripts[scriptIndex].status === 'fulfilled'
      ? Promise.resolve()
      : Promise.reject();
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = `${path}?t=${Date.now()}`;
    script.async = true;
    script.dataset.path = path;
    script.dataset.scope = scope;
    script.dataset.state = 'pending';
    script.addEventListener('load', () => {
      window[versionedScope] = window[scope];

      script.dataset.state = 'resolved';
      resolve(undefined);
    });

    script.addEventListener('error', error => {
      script.dataset.state = 'rejected';
      reject(error);
    });

    document.head.appendChild(script);
  });
};

type Module = {
  [prop: string]: unknown;
  default?: Module;
};

type Container = {
  init: (scope: unknown) => Promise<unknown>;
  get: (module: string) => Promise<() => Module>;
};

declare global {
  const __webpack_init_sharing__: (scope: string) => Promise<unknown>;
  const __webpack_share_scopes__: Record<string, unknown>;

  interface Window {
    [scope: string]: Container;
  }
}

// need to find a way to have bundler-independent remote entries
const getModuleFromWebpackFederation = async (scope: string, module: string) => {
  await __webpack_init_sharing__('default');
  await window[scope].init(__webpack_share_scopes__.default);

  const moduleFactory = await window[scope].get(module);
  const wrappedModule = moduleFactory();

  return wrappedModule.default ?? wrappedModule;
};

export const resolveModuleV2 = async (moduleId: string): Promise<Module> => {
  const [bareModuleId, version = undefined] = moduleId.split('@');
  const [scope, name] = bareModuleId.split('/');

  if (!version) {
    const overridenVersion = getOverridenLatestVersion(scope);

    if (overridenVersion) {
      return resolveModuleV2(`${bareModuleId}@${overridenVersion}`);
    }
  }

  if (version && !version.includes('/')) {
    const tag = version;
    const versionTags = await resolveModuleV2(`${scope}/versionTags`);

    return resolveModuleV2(versionTags[tag] ? `${bareModuleId}@${versionTags[tag]}` : bareModuleId);
  }

  const versionedScope = `${scope}_${version ? version.replace(/\W/g, '_') : 'latest'}`;

  // eslint-disable-next-line no-useless-catch
  try {
    const path = `/fe/${version ? `${scope}/${version}` : scope}/remoteEntry.js`;

    await loadRemoteEntry(scope, versionedScope, path);

    return getModuleFromWebpackFederation(versionedScope, name);
  } catch (error: unknown) {
    // This block was required to support the MFE v1 with having `importmap` support.
    // Since we're not going to import old modules into Manage at the moment, I'm commenting this out.
    // We'll need to set this up if we import some old `/js/{scope}` modules.
    //
    // const path = parseImportMap().get(scope);
    //
    // if (!path) {
    throw error;
    // }
    //
    // if (version) {
    //   throw new Error(`version pinning ("${version}") is not supported by the MFE "${scope}"`);
    // }
    //
    // await loadRemoteEntry(scope, versionedScope, path);
    //
    // return getModuleFromWebpackFederation(scope, name);
  }
};

const withReactSuspense = <A extends unknown[], R>(fn: (...args: A) => Promise<R>) => {
  type Entry = {
    promise: Promise<R>;
    value?: R;
    error?: unknown;
  };

  const cache: Partial<Record<string, Entry>> = {};

  return (...args: A) => {
    const key = JSON.stringify(args);
    const entry = cache[key];

    if (entry) {
      if (entry.value) {
        return entry.value;
      }

      throw entry.error ?? entry.promise;
    }

    const promise = fn(...args);

    cache[key] = { promise };

    promise.then(
      value => {
        cache[key] = { promise, value };
      },
      error => {
        cache[key] = { promise, error };
      }
    );

    throw promise;
  };
};

export const resolveModuleSuspenseV2 = withReactSuspense(resolveModuleV2);
