import {
  DeleteOutlined,
  PlusCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Table, Button, Popconfirm, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import Highlighter from 'react-highlight-words';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LANGS } from 'constants/enums';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';

import styles from './SearchTerms.module.css';
import { CODE24_MODEL_TYPES } from '../../constants/code24types';
import { Statement } from '../../models/Code24Model';
import ConditionViewStore, {
  ConditionListItem,
  ConditionListSection,
} from '../../stores/ConditionViewStore';
import BlockIcon from '../BlockIcon';
import ErrorTooltip from '../ErrorTooltip';

/**
 * @notExported
 */
interface SearchTermsProps extends RouteComponentProps<{ id: string }>, WrappedComponentProps {
  conditionViewStore: ConditionViewStore;
}

@observer
class SearchTerms extends Component<SearchTermsProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  columns = [
    {
      title: <FormattedMessage id="condition-edit.question-type-label" />,
      dataIndex: 'type',
      width: 300,
      render: (_: string, record: ConditionListItem) => {
        const errorMessages = ('errorMessages' in record && record.errorMessages) || [];
        const hasError = !!errorMessages.length;
        const hasChildren = 'children' in record;

        return (
          <Fragment>
            {hasError && <ErrorTooltip errors={errorMessages} />}
            {!hasError && !hasChildren && <span className={styles.spacer}></span>}
            <BlockIcon block={record} />
            {'children' in record ? (
              this.renderText(
                this.props.intl.formatMessage({ id: `condition.type-${record.type}` })
              )
            ) : (
              <span className={styles.blockType}>
                {this.renderText(
                  this.props.intl.formatMessage({ id: `condition.type-${record.type}` })
                )}
              </span>
            )}
          </Fragment>
        );
      },
    },
    {
      title: <FormattedMessage id="condition-edit.search-term-label" />,
      render: (_: string, term: ConditionListItem) =>
        'term' in term && term.term
          ? (term.term as Record<LANGS, string>)[this.props.intl.locale as LANGS]
          : null,
    },
    {
      render: (_: string, term: ConditionListItem) =>
        'shouldDisplay' in term &&
        term.shouldDisplay && (
          <Tag>
            <FormattedMessage id="condition.display" />
          </Tag>
        ),
    },
    {
      title: <FormattedMessage id="condition-edit.properties-label" />,
      render: (_: string, term: ConditionListItem) =>
        'properties' in term && term.properties ? term.properties.join(' | ') : null,
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: ConditionListItem) => this.renderActionButtons(record),
    },
  ];

  renderText = (text: string) =>
    this.props.conditionViewStore.searchTerm ? (
      <Highlighter
        highlightClassName={styles.highlightedText}
        searchWords={[this.props.conditionViewStore.searchTerm]}
        autoEscape
        textToHighlight={text}
      />
    ) : (
      text
    );

  renderActionButtons = (listItem: ConditionListItem) => {
    const { conditionViewStore } = this.props;
    const { partnersStore, content24Store } = this.context;
    const availableStatements =
      partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_STATEMENTS') || [];
    const isSectionItem = 'children' in listItem;
    const isStatementItem = !isSectionItem;
    const isAddNewStatementButtonVisible =
      isSectionItem &&
      !!availableStatements.some(
        (value: CODE24_MODEL_TYPES) => value === CODE24_MODEL_TYPES.SEARCH_TERM
      );

    if (!content24Store.canEditContent24 || conditionViewStore.isDisabledCondition) {
      return isSectionItem ? null : (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => conditionViewStore.handleActiveStatement(listItem as Statement)}
          className={styles.actionBtn}
        />
      );
    }

    return (
      <Fragment>
        {isStatementItem && (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => conditionViewStore.handleActiveStatement(listItem as Statement)}
          />
        )}
        {isStatementItem && (
          <Popconfirm
            title={<FormattedMessage id="general.sure-to-delete" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onConfirm={() =>
              conditionViewStore.handleDeleteStatement(listItem as Statement, {
                successMessage: this.props.intl.formatMessage({
                  id: 'condition-edit.statement-deleted',
                }),
                errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
              })
            }
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        )}
        {isAddNewStatementButtonVisible && (
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() =>
              conditionViewStore.handleNewStatementSection(listItem as ConditionListSection)
            }
          />
        )}
      </Fragment>
    );
  };

  render() {
    const { conditionStore } = this.context;
    const { searchTermsList, expandedRowKeys, handleExpand } = this.props.conditionViewStore;
    const isLoading = conditionStore.isLoading();

    return (
      <Table<ConditionListItem>
        columns={this.columns}
        dataSource={searchTermsList}
        pagination={false}
        rowKey="id"
        className={styles.table}
        loading={isLoading}
        onExpand={handleExpand}
        expandIcon={({ expanded, onExpand, record }) =>
          'children' in record && record.children.length ? (
            <Button
              type="link"
              icon={expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
              onClick={e => onExpand(record, e)}
            />
          ) : null
        }
        expandedRowKeys={expandedRowKeys.slice()}
      />
    );
  }
}

export default injectIntl(withRouter(SearchTerms));
