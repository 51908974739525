import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { LANGS } from 'constants/enums';
import { InputOption } from 'types/types';

import styles from './LanguageSwitcher.module.css';

interface Props {
  activeLanguage: LANGS;
  languages: InputOption<string>[];
  onChange: (language: LANGS) => any;
  className?: string;
  isCollapsed?: boolean;
  isDisabled?: boolean;
}

const LanguageSwitcher = ({
  activeLanguage,
  languages,
  onChange,
  className,
  isCollapsed,
  isDisabled,
}: Props) => {
  return (
    <div className={classNames(styles.container, className, { [styles.isCollapsed]: isCollapsed })}>
      <GlobalOutlined className={styles.icon} />
      <Select
        className={styles.select}
        defaultValue={activeLanguage}
        onSelect={onChange}
        disabled={isDisabled}
        options={languages.map(({ value, label }) => ({
          value,
          label: isCollapsed ? value.toUpperCase() : label,
        }))}
      />
    </div>
  );
};

export default LanguageSwitcher;
