import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useAtom } from 'jotai';
import { Observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from 'styled-components';
import * as Yup from 'yup';

import { Image, isBlobImageSrc } from 'components/Image';
import { PopoverTooltip } from 'components/PopoverTooltip';
import { getImageIdFromFileName } from 'components/RichTextEditor/utils';
import RootStoreContext from 'context/RootStoreContext';
import { UploadImageButton } from 'modules/Articles/ArticlePage/components/UploadImage/UploadImageButton';

import { imageModalStateAtom } from './ImageModal.state';
import { PartnerImage } from '../../../api/partnersApi';
import { isArticleThumbnailImage } from '../../../modules/Articles/ArticlePage/utils/images';
import {
  ARTICLE_CONTENT_IMAGE_SIZE,
  ARTICLE_THUMBNAIL_SIZE,
} from '../../../modules/Articles/constants';

export type ImageData = {
  src: string; // can be imageId or blob URL
  imageName: string;
  alt?: string;
};

const Styled = {
  FormItem: styled(Form.Item)`
    margin-bottom: 16px;
  `,
  ImageWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
  Image: styled(Image)`
    margin-bottom: 1em;
  `,
  AltInputLabel: styled.div`
    display: flex;
    gap: 4px;
    margin-top: 0.5em;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
  `,
  Button: styled(Button)`
    margin-right: 7px;
  `,
};

export function ImageModal() {
  const intl = useIntl();
  const {
    partnersStore: { imageStore },
  } = useContext(RootStoreContext);

  const [{ mode, imageType, onSelect, onDelete, data }, setModalStateAtom] =
    useAtom(imageModalStateAtom);

  const initialFormData = useMemo(
    () => data || ({ src: '', alt: '', imageName: '' } as ImageData),
    [data]
  );

  const closeModal = useCallback(() => {
    setModalStateAtom({});
  }, [setModalStateAtom]);

  const submitForm = useCallback(
    (values: ImageData) => {
      onSelect?.(values);
      closeModal();
    },
    [onSelect, closeModal]
  );

  const deleteImage = useCallback(() => {
    if (data && onDelete) {
      onDelete(data.src);
    }
    closeModal();
  }, [onDelete, data, closeModal]);

  const filterImagesCallback = useCallback(
    (image: PartnerImage) => {
      return imageType === 'thumbnail'
        ? isArticleThumbnailImage(image)
        : !isArticleThumbnailImage(image);
    },
    [imageType]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        alt:
          imageType === 'content'
            ? Yup.string()
                .trim()
                .required(
                  intl.formatMessage({
                    id: 'image-modal.alt.required-error',
                  })
                )
            : Yup.string(),
        src: Yup.string().required(
          intl.formatMessage({
            id: 'image-modal.src.required-error',
          })
        ),
      }),
    [imageType, intl]
  );

  useEffect(() => {
    if (mode !== 'select') {
      return;
    }
    imageStore.fetchImages();
  }, [imageStore, mode]);

  const getUploadImageCallback = useCallback(
    ({ values, setValues }: { values: ImageData; setValues: (values: ImageData) => void }) =>
      ({ src, imageName }: ImageData) => {
        setValues({ src, alt: values.alt, imageName });
      },
    []
  );

  const getUploadImageButtonLabel = useCallback((src: string) => {
    return isBlobImageSrc(src) ? (
      <FormattedMessage id="general.upload-new" />
    ) : (
      <FormattedMessage id="general.upload" />
    );
  }, []);

  if (!mode) {
    return null;
  }

  return (
    <Modal
      open={true}
      destroyOnClose
      title={
        <FormattedMessage
          id={mode === 'select' ? 'image-modal.insert-title' : 'image-modal.edit-title'}
        />
      }
      footer={null}
      onCancel={closeModal}
    >
      <Observer>
        {() => {
          const imageOptions = imageStore.images.filter(filterImagesCallback).map(image => {
            const imageId = getImageIdFromFileName(image.fileName);
            return {
              value: imageId,
              label: image.metadata.title[intl.locale] || image.fileName,
            };
          });

          return (
            <Formik
              initialValues={initialFormData}
              onSubmit={submitForm}
              validationSchema={validationSchema}
            >
              {({ isValid, dirty, values, submitForm, setValues }) => {
                let actionButton;
                if (mode === 'edit') {
                  actionButton = (
                    <Popconfirm
                      title={
                        <div style={{ maxWidth: 240 }}>
                          <FormattedMessage id="image-modal.sure-to-remove" />
                        </div>
                      }
                      okText={<FormattedMessage id="image-modal.confirm-remove" />}
                      cancelText={<FormattedMessage id="image-modal.cancel-remove" />}
                      onConfirm={deleteImage}
                    >
                      <Button icon={<DeleteOutlined />}>
                        <FormattedMessage id="image-modal.remove" />
                      </Button>
                    </Popconfirm>
                  );
                } else {
                  if (imageType === 'thumbnail') {
                    actionButton = (
                      <UploadImageButton
                        label={getUploadImageButtonLabel(values.src)}
                        onUpload={getUploadImageCallback({ values, setValues })}
                        allowCropAndResize
                        targetImageSize={ARTICLE_THUMBNAIL_SIZE}
                      />
                    );
                  } else {
                    actionButton = (
                      <UploadImageButton
                        label={getUploadImageButtonLabel(values.src)}
                        onUpload={getUploadImageCallback({ values, setValues })}
                        targetImageWidth={ARTICLE_CONTENT_IMAGE_SIZE.width}
                      />
                    );
                  }
                }

                return (
                  <Form layout="vertical">
                    <Styled.FormItem
                      label={<FormattedMessage id="images.select" />}
                      name="src"
                      required
                      style={{ display: mode === 'select' ? 'block' : 'none' }}
                    >
                      <Select
                        name="src"
                        showSearch
                        optionFilterProp="label"
                        options={imageOptions.concat(
                          isBlobImageSrc(values.src)
                            ? [{ value: values.src, label: values.imageName }]
                            : []
                        )}
                      />
                    </Styled.FormItem>

                    {values.src && (
                      <Styled.ImageWrapper>
                        <Styled.Image src={values.src} alt={values.alt} />
                      </Styled.ImageWrapper>
                    )}

                    {imageType === 'content' && (
                      <Styled.FormItem
                        label={
                          <Styled.AltInputLabel>
                            <div>
                              <FormattedMessage id="images.alt-text" />
                            </div>
                            <PopoverTooltip>
                              <FormattedMessage id="image-modal.alt.info" />
                            </PopoverTooltip>
                          </Styled.AltInputLabel>
                        }
                        name="alt"
                        required={imageType === 'content'}
                      >
                        <Input name="alt" />
                      </Styled.FormItem>
                    )}

                    <Styled.ButtonsContainer>
                      <div>{actionButton}</div>

                      <div>
                        {dirty ? (
                          <Popconfirm
                            title={<FormattedMessage id="general.sure-to-cancel" />}
                            cancelText={<FormattedMessage id="general.cancel" />}
                            onConfirm={closeModal}
                          >
                            <Styled.Button>
                              <FormattedMessage id="general.cancel" />
                            </Styled.Button>
                          </Popconfirm>
                        ) : (
                          <Styled.Button onClick={closeModal}>
                            <FormattedMessage id="general.cancel" />
                          </Styled.Button>
                        )}
                        <Button
                          type="primary"
                          disabled={!isValid}
                          onClick={submitForm}
                          icon={mode === 'select' ? <PlusOutlined /> : <SaveOutlined />}
                        >
                          <FormattedMessage
                            id={mode === 'select' ? 'image-modal.insert' : 'general.save'}
                          />
                        </Button>
                      </div>
                    </Styled.ButtonsContainer>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Observer>
    </Modal>
  );
}
