import React, { FunctionComponent } from 'react';

import styles from './Minimap.module.css';

/**
 * @notExported
 */
interface MinimapProps {
  content: React.ReactNode;
  svgWidth: number;
  svgHeight: number;
  minimapTrasform?: string;
  visibleAreaWidth: number;
  visibleAreaHeight: number;
  visibleAreaTransform: string;
  minimapScale?: number;
  minimapTranslate?: { x: number; y: number };
}

const Minimap: FunctionComponent<MinimapProps> = ({
  content,
  svgWidth,
  svgHeight,
  visibleAreaHeight,
  visibleAreaWidth,
  visibleAreaTransform,
  minimapTrasform = 'translate(0,0)',
  minimapScale = 0.03,
  minimapTranslate = { x: 0, y: 0 },
}) => {
  const isSvgHigherThanVisibleArea =
    svgHeight * minimapScale > visibleAreaHeight - minimapTranslate.y;
  const horizontalScrollbarMargin = isSvgHigherThanVisibleArea ? 20 : 0;

  return (
    <div
      className={styles.container}
      style={{
        width: svgWidth * minimapScale + horizontalScrollbarMargin,
        height: isSvgHigherThanVisibleArea ? visibleAreaHeight : svgHeight * minimapScale,
        overflowY: isSvgHigherThanVisibleArea ? 'auto' : 'hidden',
      }}
    >
      <svg width={svgWidth * minimapScale} height={svgHeight * minimapScale} fill="white">
        <g
          transform={`
            scale(${minimapScale})
            translate(${minimapTranslate.x},${minimapTranslate.y})
          `}
        >
          <g transform={minimapTrasform}>{content}</g>
          <rect
            width={visibleAreaWidth}
            height={visibleAreaHeight}
            fill="black"
            fillOpacity={0.2}
            stroke="black"
            strokeWidth={4}
            transform={visibleAreaTransform}
          />
        </g>
      </svg>
    </div>
  );
};

export default Minimap;
