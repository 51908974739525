import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import React, { useContext, FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';
import { CurrentPartnerStatus } from 'modules/PartnerStatus/stores/PartnerStatusViewStore';

import styles from './MergeRequestButton.module.css';

interface Props {
  currentPartnerStatus: CurrentPartnerStatus | null;
  onCreateMergeRequest: () => void;
}

const MergeRequestButton: FunctionComponent<Props> = ({
  currentPartnerStatus,
  onCreateMergeRequest,
}) => {
  const { formatMessage } = useIntl();
  const { userPermissionsStore, mergeRequestStore, partnerStatusStore } =
    useContext(RootStoreContext);

  return (
    <Observer>
      {() => {
        const canMakeMergeRequests =
          (userPermissionsStore.hasSuperAdminRole ||
            userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CONFIG_ADMIN)) &&
          CURRENT_ENV_TYPE !== ENV.DEMO;
        const isDisabled = mergeRequestStore.isLoading || partnerStatusStore.isMergeRequestPending;
        if (!canMakeMergeRequests || !currentPartnerStatus?.changes.length) {
          return null;
        }

        return (
          <div className={styles.wrapper} data-testid="merge-request-button">
            {!isDisabled && (
              <Tooltip
                title={formatMessage({ id: 'partner-status.send-for-approval.description' })}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            )}
            <Button
              shape="round"
              type="primary"
              onClick={onCreateMergeRequest}
              disabled={isDisabled}
              className={styles.button}
            >
              <FormattedMessage id="partner-status.send-for-approval" />
            </Button>
          </div>
        );
      }}
    </Observer>
  );
};

export default MergeRequestButton;
