import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { IDENTITIES } from 'constants/practitioner';
import { useRootStore } from 'context/RootStoreContext';

export const useActualValidation = (pathname: string) => {
  // note that checks for "current value" and "initial value" and "empty value"
  // are more performant inside usePractitionerValidateExternalId
  // but keeping them here makes that function much easier to read

  const { practitionerFindStore } = useRootStore();

  const getExistingPractitioner = practitionerFindStore.searchPractitioner;

  const intl = useIntl();

  return useCallback(
    async (
      value: unknown,
      idType: IDENTITIES,
      id?: string,
      initialExternalId?: string,
      initialExternalIdType?: IDENTITIES
    ) => {
      // shall never happen, but it is typed as "any" in formik
      if (!(typeof value === 'string')) {
        return undefined;
      }

      if (!value?.trim()) {
        return;
      }

      const isEdit = !!id;
      if (isEdit && initialExternalIdType === IDENTITIES.INTERNAL_IDP) {
        // field disabled in edit mode. ie. no need validating.
        return;
      }

      if (initialExternalId === value && initialExternalIdType === idType) {
        // again questionable, but keep from previous implementation
        return undefined;
      }

      const practitioner = await getExistingPractitioner({
        externalId: value as string,
        externalIdType: idType,
      });

      if (practitioner && (!id || id !== practitioner.id)) {
        return intl.formatMessage(
          { id: 'general.errors.practitioner-exists' },
          {
            editLink: (
              <Link to={`/roles/${practitioner.id}/edit`}>
                <FormattedMessage id="practitioner-data-form.practitioner-exists-error-edit-link" />
              </Link>
            ),
          }
        );
      }

      return undefined;
    },
    [getExistingPractitioner, intl, pathname]
  );
};

export type ValidateFn = (value: unknown) => Promise<unknown>;
export type CreateValidateFn = (idType: IDENTITIES) => ValidateFn;

export const usePractitionerValidateExternalId = (
  pathname: string,
  id?: string,
  initialExternalId?: string,
  initialExternalIdType?: IDENTITIES
): [CreateValidateFn, IDENTITIES[]] => {
  // huge triple-memoized validation function
  // with built-in debounce

  const DEBOUNCE_TIMEOUT = 500;

  const fnMemoForValue = useRef<Record<string, Promise<unknown> | undefined>>({});

  const actualValidation = useActualValidation(pathname);

  const [nowValidating, setNowValidating] = useState<IDENTITIES[]>([]);

  const declinePreviousByType = useRef<Record<string, () => void>>({});

  const validate = useCallback(
    (idType: IDENTITIES) => {
      const key = `validateExternalId-${id}-${initialExternalId}-${pathname}-${idType}`;

      return (value: unknown) => {
        const fnFn = (): Promise<unknown> => {
          const fnMemoKey = `${key}-${value}`;

          const declinePrevious = declinePreviousByType.current[idType];
          if (declinePrevious) {
            declinePrevious();
          }

          if (fnMemoForValue.current[fnMemoKey]) {
            // strange TS

            return fnMemoForValue.current[fnMemoKey]!;
          }

          const debounceTimeout = Promise.race([
            new Promise(resolveWith => {
              setTimeout(() => {
                resolveWith('PROCESS');
              }, DEBOUNCE_TIMEOUT);
            }),
            new Promise(resolveWith => {
              declinePreviousByType.current[idType] = () => resolveWith('DECLINE');
            }),
          ]);

          return debounceTimeout.then(status => {
            if (status === 'DECLINE') {
              // if previous promise declined, it means
              // that we are inside "debounce" timeout and do not to process
              // this specific "value"
              return undefined;
            }

            // if current promise is not declined (aka, not debounced)
            // we start actual validation request and memo whole promise
            const happyPromise = actualValidation(
              value,
              idType,
              id,
              initialExternalId,
              initialExternalIdType
            );

            fnMemoForValue.current[fnMemoKey] = happyPromise;

            return happyPromise;
          });
        };

        setNowValidating(prev => [...prev, idType]);
        return fnFn().finally(() => {
          setNowValidating(prev => {
            const idx = prev.indexOf(idType);
            if (idx === -1) {
              return prev;
            }
            return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
          });
        });
      };
    },
    [actualValidation, id, initialExternalId, initialExternalIdType, pathname]
  );

  return [validate, nowValidating];
};
