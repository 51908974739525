export const DATA_ERROR_MESSAGE = 'Data error';

export class DataError extends Error {
  constructor() {
    super(DATA_ERROR_MESSAGE);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(DATA_ERROR_MESSAGE).stack;
    }
  }
}
