import React from 'react';
import { useIntl } from 'react-intl';

import iPhoneX2x from 'assets/png/toplist_preview_iPhoneX@2x.png';

import styles from './PhonePreview.module.css';
import { SystemShutDownError } from './ShutdownError';

const PhonePreview = ({ previewText }: { previewText: string }) => {
  const intl = useIntl();
  return (
    <div className={styles.previewContainer}>
      <img
        src={iPhoneX2x}
        alt={intl.formatMessage({ id: 'origin.toplist.live-preview' })}
        width={300}
      />
      <div className={styles.previewShutdown}>
        <SystemShutDownError
          error={previewText}
          imageAlt={intl.formatMessage({ id: 'origin.shutdown.technical-error' })}
        />
      </div>
    </div>
  );
};
export default PhonePreview;
