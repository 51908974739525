import sortBy from 'lodash/sortBy';
import { Observer } from 'mobx-react';
import React, { ComponentType, useCallback, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ROLES_DESCRIPTION_KEY, ROLES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import { InputOption, Subtract } from 'types/types';

export interface PractitionerRolesOptionsProps {
  practitionerRolesOptions: InputOption<ROLES>[];
  administrativeRolesOptions: InputOption<ROLES>[];
  resourceTypesOptions: InputOption<ROLES>[];
  rolesAreLoading: boolean;
}

const withPractitionerRolesOptions = <P extends PractitionerRolesOptionsProps>(
  WrappedComponent: ComponentType<P>
) => {
  const WrappedComponentToReturn = (props: Subtract<P, PractitionerRolesOptionsProps>) => {
    const { rolesStore } = useContext(RootStoreContext);
    const { formatMessage } = useIntl();
    const buildRolesSelectOptions = useCallback(
      (roles: ROLES[]) => {
        const rolesUnsorted = roles.map(roleName => ({
          value: roleName,
          label: ROLES_DESCRIPTION_KEY[roleName]
            ? formatMessage({
                id: ROLES_DESCRIPTION_KEY[roleName],
              })
            : roleName,
        }));
        return sortBy(rolesUnsorted, ['label']);
      },
      [formatMessage]
    );

    useEffect(() => {
      rolesStore.fetchAvailableRoles();
      rolesStore.fetchAdministrativeRoles();
      rolesStore.fetchResourceTypes();
    }, [rolesStore]);

    return (
      <Observer>
        {() => (
          <WrappedComponent
            {...(props as P)}
            practitionerRolesOptions={buildRolesSelectOptions(rolesStore.availableRoles)}
            administrativeRolesOptions={buildRolesSelectOptions(rolesStore.administrativeRoles)}
            resourceTypesOptions={buildRolesSelectOptions(rolesStore.resourceTypes)}
            rolesAreLoading={rolesStore.isLoading()}
          />
        )}
      </Observer>
    );
  };
  WrappedComponentToReturn.displayName = 'withPractitionerRolesOptions';
  return WrappedComponentToReturn;
};

export default withPractitionerRolesOptions;
