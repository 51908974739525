import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { fetchPartnerImageData, fetchPartnerImages } from './partnersApi';

export const partnersQueryKeys = {
  images: (partnerId: string) => ['partners', 'images', partnerId],
  imageData: (partnerId: string, imageId: string) => ['partners', 'imageData', partnerId, imageId],
};

export const getPartnerImagesQueryConfig = (partnerId: string) => ({
  queryKey: partnersQueryKeys.images(partnerId),
  queryFn: () => fetchPartnerImages(partnerId).then(res => res.data),
});
export const usePartnerImages = (partnerId: string) =>
  useQuery({
    ...getPartnerImagesQueryConfig(partnerId),
    refetchOnMount: false,
  });

export const usePartnerImageData = ({
  partnerId,
  imageId,
}: {
  partnerId: string;
  imageId?: string | null;
}) =>
  useQuery({
    queryKey: partnersQueryKeys.imageData(partnerId, imageId ?? ''),
    queryFn: () => fetchPartnerImageData(partnerId, imageId!).then(res => res.data),
    enabled: !!imageId,
    refetchOnMount: false,
    // We don't want to retry too many times if the image is not found
    retry: (failureCount, error?: AxiosError) =>
      failureCount < 1 || error?.response?.status !== 404,
  });
