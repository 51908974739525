import { LinkOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { EditorState, RichUtils, EntityInstance } from 'draft-js';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LinkModal from './LinkModal';

interface Props {
  editorState: EditorState;
  onEditLink: (newEditorState: EditorState) => void;
  isDisabled?: boolean;
}

const LinkControls: React.FC<Props> = ({ editorState, onEditLink, isDisabled }) => {
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
  const [linkInstance, setLinkInstance] = useState<EntityInstance | null>(null);
  const editorSelection = editorState.getSelection();
  const isControlButtonEnabled = !editorSelection.isCollapsed() && editorSelection.getHasFocus();

  React.useEffect(() => {
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
      const selectionStart = selection.getStartOffset();
      const entityKey = block.getEntityAt(selectionStart);
      if (entityKey) {
        return setLinkInstance(editorState.getCurrentContent().getEntity(entityKey));
      }
    }
    setLinkInstance(null);
  }, [editorState, setLinkInstance]);

  const handleLinkModalSubmit = (urlValue: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    const selection = newEditorState.getSelection();

    if (urlValue) {
      onEditLink(RichUtils.toggleLink(newEditorState, selection, entityKey));
    } else {
      onEditLink(RichUtils.toggleLink(newEditorState, selection, null));
    }

    toggleModal();
  };

  const toggleModal = () => setModalIsOpen(!isModalOpen);

  return (
    <Fragment>
      <LinkModal
        isVisible={isModalOpen}
        url={linkInstance ? linkInstance.getData().url : ''}
        onCancel={toggleModal}
        onSubmit={handleLinkModalSubmit}
      />
      <Tooltip
        title={!isControlButtonEnabled && <FormattedMessage id="general.editor-add-link-tooltip" />}
      >
        <Button
          type={linkInstance && linkInstance.getType() === 'LINK' ? 'primary' : undefined}
          onMouseDown={toggleModal}
          icon={<LinkOutlined />}
          disabled={isDisabled || !isControlButtonEnabled}
        />
      </Tooltip>
    </Fragment>
  );
};

export default LinkControls;
