import { observable, IObservableArray, runInAction, action } from 'mobx';

import { fetchPractitioners, Practitioner } from 'api/practitionerApi';
import StateManager from 'stores/abstractStores/StateManager';

export default class UserListStore extends StateManager {
  @observable
  list: IObservableArray<Practitioner> = observable.array([]);

  getByExternalId(id: string) {
    return this.list.find(user => user.externalId === id);
  }

  fetchUsers = async () => {
    if (this.isLoaded()) {
      return;
    }

    this.setLoading();

    try {
      const { data: users } = await fetchPractitioners();

      this.setLoaded();

      runInAction(() => {
        this.list.replace(users);
      });
    } catch (e: any) {
      this.setError();
      throw e;
    }
  };

  @action
  upsert = (user: Practitioner) => {
    if (!this.isLoaded()) {
      // Don't update the list if not loaded
      return;
    }

    const userIndex = this.list.findIndex(it => it.id === user.id);

    if (userIndex === -1) {
      this.list.push(user);
    } else {
      this.list[userIndex] = user;
    }
  };
}
