import axios from 'axios';

import { defaultInterceptorsBuilder } from './appConfig';

export const createAxiosInstance = () => {
  const axiosInstance = axios.create();
  defaultInterceptorsBuilder.registerInterceptors(axiosInstance);

  return axiosInstance;
};
