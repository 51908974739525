import { LOGIN_BANK_ID_AUTHENTICATE, LOGIN_BANK_ID_COLLECT } from 'constants/login';

import {
  BankIdStartAuthDTO,
  OrderStatus,
  OrderStatusEnum,
  SwedishBankIdAPI,
  SwedishBankIdFailedHintCode,
  SwedishBankIdPendingHintCode,
} from './SwedishBankId.types';

export class SwedishBankIdAPIImpl implements SwedishBankIdAPI {
  async getBankIdOrderRef() {
    const response = await fetch(LOGIN_BANK_ID_AUTHENTICATE);
    const data: BankIdStartAuthDTO = await response.json();
    return data;
  }

  private fixBEResponse(response: { status: string; hintCode: string; jwt?: string }): OrderStatus {
    // theoretically BE returns very close API to actual BankID
    // but at the moment we might have some differences
    // so we are trying our best to format status to what we expect in the rest of system
    const status = response.status.toLowerCase();
    const { hintCode } = response;
    if (status === 'pending') {
      return {
        status: OrderStatusEnum.PENDING,
        hintCode,
      };
    }

    if (status === 'failed') {
      return {
        status: OrderStatusEnum.FAILED,
        hintCode,
      };
    }

    if (status === 'completed') {
      if (!response.jwt) {
        throw new Error('jwt is missing');
      }

      return {
        status: OrderStatusEnum.COMPLETE,
        jwt: response.jwt,
      };
    }

    if (response.jwt) {
      return {
        status: OrderStatusEnum.COMPLETE,
        jwt: response.jwt,
      };
    }

    const pendingCodes = Object.values(SwedishBankIdPendingHintCode);

    if (pendingCodes.includes(hintCode as SwedishBankIdPendingHintCode)) {
      return {
        status: OrderStatusEnum.PENDING,
        hintCode,
      };
    }

    const failedCodes = Object.values(SwedishBankIdFailedHintCode);
    if (failedCodes.includes(hintCode as SwedishBankIdFailedHintCode)) {
      return {
        status: OrderStatusEnum.FAILED,
        hintCode,
      };
    }

    return {
      status: OrderStatusEnum.CRASHED,
      errorCode: 'UNKNOWN',
    };
  }

  async collectOrderStatus(orderRef: string): Promise<OrderStatus> {
    try {
      const response = await fetch(`${LOGIN_BANK_ID_COLLECT}/${orderRef}`);

      if (response.status !== 200) {
        return {
          status: OrderStatusEnum.CRASHED,
          errorCode: 'UNKNOWN',
        };
      }

      const data = await response.json();

      return {
        ...data,
        ...this.fixBEResponse(data),
      };
    } catch {
      return {
        status: OrderStatusEnum.CRASHED,
        errorCode: 'UNKNOWN',
      };
    }
  }
}
