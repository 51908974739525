import axios from 'axios';

import { TranslatedText } from 'types/types';

import {
  CODE24_CATEGORIES,
  ConditionSectionModelType,
  ConditionStatementModelType,
  QUESTION_TYPES,
} from '../constants/code24types';

export interface ConditionGroup {
  id: string;
  category: CODE24_CATEGORIES;
  label: TranslatedText;
}

export interface SymptomOption {
  id: string;
  translations: {
    staff: TranslatedText;
    term: TranslatedText;
    patient: TranslatedText;
  };
}

export interface ExpressionValidation {
  valid: boolean;
  errorMsg: string;
  errorsAt: number[];
}

export type AvailableSectionStatements = {
  [key in ConditionSectionModelType]: ConditionStatementModelType[];
};

export interface QuestionCategory {
  questionCategory: string;
  questionTypes: QUESTION_TYPES[];
  singleResponse: boolean;
}

export const fetchConditionGroups = () => axios.get<ConditionGroup[]>('/rest/admin/code24/group');

export const fetchQuestionCategories = () =>
  axios.get<QuestionCategory[]>('/rest/admin/code24/question-categories');

export const validateCondition = (expression: string) =>
  axios.put<ExpressionValidation>('/rest/admin/code24/condition/validate', { expression });

export const validateBuildTimeIf = (expression: string) =>
  axios.put<ExpressionValidation>('/rest/admin/code24/buildTimeIf/validate', { expression });

export const fetchAvailableSectionStatements = () =>
  axios.get<AvailableSectionStatements>('/rest/admin/code24/section-types');
