import { Row, Col } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { RulesPackageMeta } from 'modules/Rules/types';

import styles from './RulesPackageMetaForm.module.css';

/**
 * @notExported
 */
interface RulesPackegeMetaFormProps {
  data: RulesPackageMeta;
  onSubmit: (data: RulesPackageMeta) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const RulesPackageMetaForm = ({
  data,
  onSubmit,
  isLoading,
  isDisabled,
}: RulesPackegeMetaFormProps) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
  });

  const isFormDisabled = isDisabled || isLoading;

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formikProps => {
        return (
          <Form>
            <div className="ant-form-vertical">
              <Row gutter={16} align="middle">
                <Col span="7">
                  <Form.Item name="rulesPackageId" label={<FormattedMessage id="general.id" />}>
                    <Input name="id" disabled />
                  </Form.Item>
                </Col>
                <Col span="7">
                  <Form.Item
                    name={`title`}
                    required
                    label={<FormattedMessage id="general.title" />}
                  >
                    <Input name={`title`} disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="7">
                  <Form.Item
                    name={`description`}
                    label={<FormattedMessage id="general.description" />}
                  >
                    <Input name={`description`} disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="3">
                  <SubmitButton
                    className={styles.submit}
                    shape="round"
                    key="submit"
                    loading={isLoading}
                    disabled={isFormDisabled || !formikProps.isValid}
                  >
                    <FormattedMessage id="general.save" />
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
