import { LANGS } from './enums';
import { DevCredentials } from '../types/types';

export const DEV_LOGIN_CREDENTIALS: DevCredentials[] =
  JSON.parse(process.env.REACT_APP_DEV_LOGIN_CREDENTIALS || 'null') ||
  window.DEV_LOGIN_CREDENTIALS ||
  [];

export const ONLY_ALLOW_USER_HANDLING: boolean | undefined =
  JSON.parse(process.env.REACT_APP_ONLY_ALLOW_USER_HANDLING || 'null') ||
  window.ONLY_ALLOW_USER_HANDLING;

export enum ENV {
  PROD = 'production',
  DEV = 'development',
  LOCAL = 'local',
  STAGING = 'staging',
  DEMO = 'demo',
  CONTENT24_DEMO = 'content24Demo',
  TESTING_DEMO = 'testingDemo',
}

export const CURRENT_ENV_TYPE = getEnv();

export const CURRENT_ENV_LANGUAGE = getEnvLang() || LANGS.SV;

export function getEnv(): ENV {
  const url = window.location.hostname;

  if (url.endsWith('dev.platform24.se')) {
    return ENV.DEV;
  }

  if (url.endsWith('staging.platform24.se')) {
    return ENV.STAGING;
  }

  if (url.endsWith('content24.demo.platform24.se')) {
    return ENV.CONTENT24_DEMO;
  }

  if (url.endsWith('testing.demo.platform24.se')) {
    return ENV.TESTING_DEMO;
  }

  if (url.endsWith('demo.platform24.se')) {
    return ENV.DEMO;
  }

  if (url.endsWith('manage.platform24.se')) {
    return ENV.PROD;
  }

  return ENV.LOCAL;
}

export function getEnvLang(): LANGS {
  const url = window.location.hostname;

  if (url.startsWith('alerisnorge') || url.startsWith('if')) {
    return LANGS.NO;
  }

  return LANGS.SV;
}
