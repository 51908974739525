export function wrapWithHtmlDocumentMeta(html: string) {
  const header = `
    <html>
    <head>
        <title>Patientinformation</title>
        <link rel="stylesheet" href="styles.css">
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    <body>
    <div class="content">
  `;
  const footer = `
    </div>
    </body>
    </html>
  `;

  if (!html.startsWith('<html')) {
    return `${header}${html}${footer}`;
  }

  return html;
}
