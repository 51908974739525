import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Switch, FormItem, Checkbox, Select } from 'formik-antd';
import omit from 'lodash/omit';
import { Observer } from 'mobx-react';
import { parse } from 'query-string';
import React, { FunctionComponent, Fragment, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { LANGS } from 'constants/enums';
import { ALPHANUMERIC_CHARS_REGEX, NOT_ONLY_NUMBERS_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import {
  filterConditionsWithCategoryGroup,
  getConditionGroupsFilteredByCategoryAsSelectOptions,
} from 'modules/Content24/utils';
import { InputOption, TranslatedText } from 'types/types';
import { sortWithLocale } from 'utils/textUtils';

import { NewPartnerCondition } from '../../api/partnerCode24api';
import { TEMPLATED_CONDITION_CATEGORIES } from '../../constants/code24types';

/**
 * @notExported
 */
interface AddPartnerConditionProps extends RouteComponentProps {
  onSubmit: (condition: NewPartnerCondition) => Promise<string | undefined>;
  onCancel: () => void;
  isSaving: boolean;
  initialValues?: NewPartnerCondition;
  isDisabled?: boolean;
}

/**
 * @notExported
 */
enum NEW_CONDITION_MODE {
  NEW = 'new',
  REPLACE = 'replace',
}

/**
 * @notExported
 */
const newConditionModesOptions = [
  { label: 'general.new', value: NEW_CONDITION_MODE.NEW },
  { label: 'general.replace', value: NEW_CONDITION_MODE.REPLACE },
];

/**
 * @notExported
 */
interface FormValues extends NewPartnerCondition {
  mode: NEW_CONDITION_MODE;
}

const AddPartnerCondition: FunctionComponent<AddPartnerConditionProps> = ({
  onSubmit,
  onCancel,
  isSaving,
  initialValues,
  isDisabled,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { conditionsListStore, content24Store } = useContext(RootStoreContext);

  const category = parse(location.search).category;

  const isFormDisabled = isDisabled || isSaving;

  const handleSubmit = async (data: FormValues) => {
    const submitData: NewPartnerCondition = omit(data, 'mode');

    if (data.mode === NEW_CONDITION_MODE.REPLACE) {
      submitData.copyOf = data.conditionId;
    }

    // ConditionId can be modified by BE (it adds prefix for no medical content version partners)
    const conditionId = await onSubmit(submitData);

    if (conditionId) {
      history.replace(`/content24/questionnaires/${conditionId}`);
    }
  };

  if (!initialValues) {
    return null;
  }

  return (
    <Observer>
      {() => {
        const newConditionGroupsSelectOptions = getConditionGroupsFilteredByCategoryAsSelectOptions(
          content24Store.groups,
          category,
          intl.locale as LANGS
        );
        const categoryGroups = content24Store.categoryGroups.get(category) || [];
        const replaceConditionSelectOptions = filterConditionsWithCategoryGroup(
          conditionsListStore.defaultConditions,
          categoryGroups
        )
          .map(condition => ({
            label:
              condition.description && condition.description[intl.locale]
                ? condition.description[intl.locale]
                : condition.conditionId,
            value: condition.conditionId,
          }))
          .sort((a, b) => sortWithLocale(a, b, 'label', intl.locale));
        const medicalContentVersion = content24Store.code24MedconVersion;
        const enhancedInitialValues = {
          ...initialValues,
          group: newConditionGroupsSelectOptions[0]?.value,
          mode: NEW_CONDITION_MODE.NEW,
        };
        const validationSchema = Yup.object().shape({
          conditionId: Yup.string()
            .when('mode', {
              is: val => val !== NEW_CONDITION_MODE.REPLACE,
              then: Yup.string().test(
                'isUsed',
                intl.formatMessage({ id: 'general.errors.already-in-use' }),
                value =>
                  value && !conditionsListStore.lowerCasedConditionIds.includes(value.toLowerCase())
              ),
              otherwise: Yup.string(),
            })
            .required(
              intl.formatMessage({
                id: 'condition-add.constraints-conditionId.required',
              })
            )
            .matches(
              ALPHANUMERIC_CHARS_REGEX,
              intl.formatMessage({
                id: 'general.errors.alphanumeric-characters-validation',
              })
            )
            .matches(
              NOT_ONLY_NUMBERS_REGEX,
              intl.formatMessage({
                id: 'general.errors.not_only_numbers_validation',
              })
            ),
          group: Yup.string().required(
            intl.formatMessage({ id: 'condition-add.constraints-group.required' })
          ),
        });

        return (
          <Modal
            open
            destroyOnClose
            title={<FormattedMessage id="condition-add.header" />}
            footer={null}
            closable={false}
          >
            <Formik
              initialValues={enhancedInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              render={({ isValid, values, dirty, setFieldValue }) => {
                const activeGroupsSelectOptions = newConditionGroupsSelectOptions.filter(
                  (option: InputOption<string, TranslatedText>) => !option.disabled
                );

                return (
                  <Form layout="vertical" labelAlign="left">
                    {medicalContentVersion && (
                      <Form.Item
                        name="mode"
                        required
                        label={<FormattedMessage id="condition-add.mode-label" />}
                      >
                        <Select
                          name="mode"
                          disabled={isFormDisabled}
                          options={newConditionModesOptions.map(({ value, label }) => ({
                            value,
                            label: intl.formatMessage({ id: label }),
                          }))}
                          onChange={() => {
                            setFieldValue('conditionId', '');
                          }}
                        />
                      </Form.Item>
                    )}
                    {values.mode === NEW_CONDITION_MODE.NEW && (
                      <Form.Item
                        name="conditionId"
                        required
                        hasFeedback
                        label={<FormattedMessage id="general.title" />}
                      >
                        <Input name="conditionId" disabled={isFormDisabled} />
                      </Form.Item>
                    )}
                    {values.mode === NEW_CONDITION_MODE.REPLACE && (
                      <Form.Item
                        name="conditionId"
                        required
                        label={<FormattedMessage id="general.title" />}
                      >
                        <Select
                          name="conditionId"
                          disabled={isFormDisabled}
                          showSearch
                          optionFilterProp="label"
                          options={replaceConditionSelectOptions}
                        />
                      </Form.Item>
                    )}
                    {activeGroupsSelectOptions.length > 1 && (
                      <Form.Item
                        name="group"
                        required
                        label={<FormattedMessage id="condition-add.group-label" />}
                      >
                        <Select
                          name="group"
                          disabled={isFormDisabled}
                          showSearch
                          optionFilterProp="label"
                          options={activeGroupsSelectOptions.map(({ value, label, disabled }) => ({
                            value,
                            label: label[intl.locale],
                            disabled,
                          }))}
                        />
                      </Form.Item>
                    )}
                    <Form.Item name="isHidden" label={<FormattedMessage id="general.hidden" />}>
                      <Switch name="isHidden" disabled={isFormDisabled} />
                    </Form.Item>
                    {values.mode === NEW_CONDITION_MODE.NEW &&
                      !TEMPLATED_CONDITION_CATEGORIES.includes(category) && (
                        <Fragment>
                          <FormItem name="createPreTriage">
                            <Checkbox name="createPreTriage" disabled={isFormDisabled}>
                              <FormattedMessage id="condition-add.createPreTriage-label" />
                            </Checkbox>
                          </FormItem>
                          <FormItem name="createPostTriage">
                            <Checkbox name="createPostTriage" disabled={isFormDisabled}>
                              <FormattedMessage id="condition-add.createPostTriage-label" />
                            </Checkbox>
                          </FormItem>
                        </Fragment>
                      )}
                    <Divider />
                    <FormActionButtons
                      isSaving={isSaving}
                      isValid={isValid && dirty}
                      onCancel={onCancel}
                      showCancelConfirm={dirty}
                      cancelDeclineText={
                        <FormattedMessage id="condition-edit.statement-cancel-confirm" />
                      }
                    />
                  </Form>
                );
              }}
            />
          </Modal>
        );
      }}
    </Observer>
  );
};

export default withRouter(AddPartnerCondition);
