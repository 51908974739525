export enum REPORT_STATUSES {
  QUEUED = 'QUEUED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  RUNNING = 'RUNNING',
  CRASHED = 'CRASHED',
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
}

export const TRANSLATION_KEYS = {
  [REPORT_STATUSES.QUEUED]: 'condition.report-status.queued',
  [REPORT_STATUSES.REJECTED]: 'condition.report-status.rejected',
  [REPORT_STATUSES.CANCELED]: 'condition.report-status.canceled',
  [REPORT_STATUSES.RUNNING]: 'condition.report-status.running',
  [REPORT_STATUSES.CRASHED]: 'condition.report-status.crashed',
  [REPORT_STATUSES.INCOMPLETE]: 'condition.report-status.incomplete',
  [REPORT_STATUSES.COMPLETE]: 'condition.report-status.complete',
};
