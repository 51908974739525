import { Col } from 'antd';
import { Input, Form, InputNumber } from 'formik-antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * @notExported
 */
interface YesNoQuestionTypeProps {
  activeLanguage: string;
  isFormDisabled: boolean;
}

const YesNoQuestionType = ({ activeLanguage, isFormDisabled }: YesNoQuestionTypeProps) => {
  return (
    <Fragment>
      <Col span={8}>
        <Form.Item
          name={`staffNegative.${activeLanguage}`}
          label={<FormattedMessage id="condition-edit.staff-negative-label" />}
        >
          <Input.TextArea
            name={`staffNegative.${activeLanguage}`}
            disabled={isFormDisabled}
            rows={2}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="points" label={<FormattedMessage id="condition-edit.points-label" />}>
          <InputNumber name="points" min={0} precision={1} disabled={isFormDisabled} step={0.1} />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export default YesNoQuestionType;
