import { Row, Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, Input, Select, SubmitButton, Switch } from 'formik-antd';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { InputOption, Optional, TranslatedText } from 'types/types';

import styles from './MetadataForm.module.css';
import { CODE24_CATEGORIES } from '../../constants/code24types';
import { Metadata } from '../../models/Code24Model';

/**
 * @notExported
 */
interface MetadataFormProps {
  data: Metadata;
  onSubmit: (data: Metadata) => void;
  groupsSelectOptions: InputOption<string, TranslatedText>[];
  activeLanguage: string;
  isLoading?: boolean;
  conditionCategory: Optional<CODE24_CATEGORIES>;
  isDisabled?: boolean;
}

const MetadataForm = ({
  data,
  onSubmit,
  isLoading,
  groupsSelectOptions,
  activeLanguage,
  conditionCategory,
  isDisabled,
}: MetadataFormProps) => {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    title: Yup.object().shape({
      [activeLanguage]: Yup.string().required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      ),
    }),
    level1id: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
  });

  const switchLayout = {
    labelCol: { md: 18, lg: 19, xl: 20 },
    wrapperCol: { md: 6, lg: 5, xl: 4 },
  };

  const isCategoryAllowedForEditing = conditionCategory !== CODE24_CATEGORIES.LIBRARIES;
  const isFormDisabled = isDisabled || isLoading || !isCategoryAllowedForEditing;

  const handleGroupSelect = useCallback((value: string, formikProps: FormikProps<Metadata>) => {
    // level1id and level1group should have the same value
    formikProps.setFieldValue('level1group', value);
  }, []);

  if (!data.title || !data.title[activeLanguage]) {
    data.title = {
      ...data.title,
      [activeLanguage]: data.conditionId,
    };
  }

  // Title is searchable by default in the BE, so "no value" means "true"
  if (data.titleSearchable !== false) {
    data.titleSearchable = true;
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {formikProps => {
        const activeGroupsSelectOptions = groupsSelectOptions.filter(
          (option: InputOption<string, TranslatedText>) =>
            !option.disabled || option.value === formikProps.values.level1id
        );

        return (
          <Form>
            <div className="ant-form-vertical">
              <Row gutter={16}>
                <Col span="8">
                  <Form.Item name="conditionId" label={<FormattedMessage id="general.id" />}>
                    <Input name="conditionId" disabled />
                  </Form.Item>
                </Col>
                <Col span="8">
                  <Form.Item
                    name={`title.${activeLanguage}`}
                    required
                    label={<FormattedMessage id="general.title" />}
                  >
                    <Input name={`title.${activeLanguage}`} disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="8">
                  <Form.Item
                    name="level1id"
                    required
                    label={<FormattedMessage id="condition-add.group-label" />}
                  >
                    <Select
                      name="level1id"
                      disabled={isFormDisabled || groupsSelectOptions.length === 1}
                      onSelect={(value: string) => handleGroupSelect(value, formikProps)}
                      showSearch
                      optionFilterProp="label"
                      options={activeGroupsSelectOptions.map(({ value, label, disabled }) => ({
                        value,
                        label: label[intl.locale],
                        disabled,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span="8">
                  <Form.Item
                    name={`descriptionPractitioner.${activeLanguage}`}
                    label={
                      <FormattedMessage id="condition-edit.metadata.practitioner-description" />
                    }
                  >
                    <Input
                      name={`descriptionPractitioner.${activeLanguage}`}
                      disabled={isFormDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span="4">
                  <Form.Item
                    name="skipInterviewIntro"
                    label={<FormattedMessage id="condition-edit.metadata.skip-interview-intro" />}
                    labelAlign="left"
                    className={styles.switch}
                    {...switchLayout}
                  >
                    <Switch name="skipInterviewIntro" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="4">
                  <Form.Item
                    name="skipInterviewFinal"
                    label={<FormattedMessage id="condition-edit.metadata.skip-interview-final" />}
                    labelAlign="left"
                    className={styles.switch}
                    {...switchLayout}
                  >
                    <Switch name="skipInterviewFinal" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="4">
                  <Form.Item
                    name="hiddenForPatient"
                    label={<FormattedMessage id="condition-edit.metadata.hidden-for-patient" />}
                    labelAlign="left"
                    className={styles.switch}
                    {...switchLayout}
                  >
                    <Switch name="hiddenForPatient" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
                <Col span="4">
                  <Form.Item
                    name="titleSearchable"
                    label={<FormattedMessage id="condition-edit.metadata.title-searchable" />}
                    labelAlign="left"
                    className={styles.switch}
                    {...switchLayout}
                  >
                    <Switch name="titleSearchable" disabled={isFormDisabled} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.submit}>
              <SubmitButton
                shape="round"
                key="submit"
                loading={isLoading}
                disabled={isFormDisabled || !formikProps.isValid}
              >
                <FormattedMessage id="general.save" />
              </SubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MetadataForm;
