import { Modal, Divider, Select, Form } from 'antd';
import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
  Fragment,
  useMemo,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import { InputOption } from 'types/types';
import { sortWithLocale } from 'utils/textUtils';

import { CODE24_MODEL_TYPES } from '../../constants/code24types';
import { Statement } from '../../models/Code24Model';
import CallForm from '../BlockForms/CallForm';
import ExitForm from '../BlockForms/ExitForm';
import ExportDataForm from '../BlockForms/ExportDataForm';
import FormulaForm from '../BlockForms/FormulaForm';
import GotoForm from '../BlockForms/GotoForm';
import GuidanceForm from '../BlockForms/GuidanceForm';
import ImportDataForm from '../BlockForms/ImportDataForm';
import IncludeForm from '../BlockForms/IncludeForm';
import QuestionForm from '../BlockForms/QuestionForm';
import SearchTermForm from '../BlockForms/SearchTermForm';

/**
 * @notExported
 */
interface AddStatementProps {
  isVisible: boolean;
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (data: Statement) => void;
  activeLanguage: LANGS;
  availableLanguages: LANGS[];
  onLanguageChange: (language: LANGS) => any;
  statementTypeOptions?: InputOption<CODE24_MODEL_TYPES>[];
  isDisabled?: boolean;
}

const AddStatement: FunctionComponent<AddStatementProps> = ({
  isVisible,
  isSaving,
  isDisabled,
  statementTypeOptions = [],
  activeLanguage,
  availableLanguages,
  onCancel,
  onSubmit,
  onLanguageChange,
}) => {
  const { formatMessage, locale } = useIntl();
  const sortedStatementTypeOptions = useMemo(
    () =>
      statementTypeOptions
        .map(option => ({ ...option, label: formatMessage({ id: option.label }) }))
        .sort((a, b) => sortWithLocale(a, b, 'label', locale)),
    [statementTypeOptions, formatMessage, locale]
  );
  const [type, setType] = useState<CODE24_MODEL_TYPES>();
  const isFormDisabled = isSaving || isDisabled;

  useEffect(() => {
    setType(sortedStatementTypeOptions[0]?.value);
  }, [sortedStatementTypeOptions]);

  const renderForm = useCallback(() => {
    switch (type) {
      case CODE24_MODEL_TYPES.INCLUDE:
        return (
          <IncludeForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.FORMULA:
        return <FormulaForm isDisabled={isDisabled} onCancel={onCancel} onSubmit={onSubmit} />;
      case CODE24_MODEL_TYPES.EXIT:
        return (
          <ExitForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.SEARCH_TERM:
        return (
          <SearchTermForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.GOTO:
        return (
          <GotoForm
            activeLanguage={activeLanguage}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      case CODE24_MODEL_TYPES.QUESTION:
        return (
          <QuestionForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.CALL:
        return (
          <CallForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.GUIDANCE:
        return (
          <GuidanceForm
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.EXPORT_DATA:
        return (
          <ExportDataForm
            isDisabled={isDisabled}
            activeLanguage={activeLanguage}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      case CODE24_MODEL_TYPES.IMPORT_DATA:
        return (
          <ImportDataForm
            isDisabled={isDisabled}
            activeLanguage={activeLanguage}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      default:
        return null;
    }
  }, [type, isDisabled, onCancel, onSubmit, activeLanguage]);

  return (
    <Modal
      open={isVisible}
      destroyOnClose
      title={
        <ModalTitleWithLanguageSwitcher
          title={<FormattedMessage id="condition-edit.add-block-header" />}
          activeLanguage={activeLanguage}
          onLanguageChange={onLanguageChange}
          availableLanguages={availableLanguages}
        />
      }
      footer={null}
      closable={false}
      width={1200}
    >
      {statementTypeOptions.length > 1 && (
        <Fragment>
          <Form layout="vertical">
            <Form.Item label={<FormattedMessage id="condition-edit.add-block-type" />}>
              <Select
                disabled={isFormDisabled}
                onChange={(value: CODE24_MODEL_TYPES) => setType(value)}
                value={type}
                options={sortedStatementTypeOptions}
              />
            </Form.Item>
          </Form>
          <Divider />
        </Fragment>
      )}
      {renderForm()}
    </Modal>
  );
};

export default AddStatement;
