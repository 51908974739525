export const JWTUpdateFailErrorMsg = 'Renewal authorization failed [53423]';

export class JWTUpdateFailError extends Error {
  constructor() {
    super(JWTUpdateFailErrorMsg);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(JWTUpdateFailErrorMsg).stack;
    }
  }
}
