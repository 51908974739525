import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import styles from './Spinner.module.css';

export const Spinner = () => {
  return (
    <div className={styles.spinnerContainer} data-testid="content24-spinner">
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </div>
  );
};
