import { Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './LoginPage.module.css';

export const LoginPageHeader = ({ subtitle }: { subtitle?: JSX.Element | boolean }) => {
  return (
    <div data-testid="login-page-header" className={styles.header}>
      <Typography.Title level={3} data-testid="login-header" className={styles.title}>
        <FormattedMessage id="general.welcome-to-platform24" />
      </Typography.Title>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};
