import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { ROLES } from 'constants/roles';

import { sortOptionsAlphabetically } from './utils';

interface InitialValues {
  practitionerRole: string[];
  administrativeRole: string[];
}

export const useRoleFilter = (initialValues: InitialValues) => {
  const intl = useIntl();
  const [selectedAdministrativeRoles, setSelectedAdministrativeRoles] = useState<string[]>(
    initialValues.administrativeRole || []
  );
  const [selectedPractitionerRoles, setSelectedPractitionerRoles] = useState<string[]>(
    initialValues.practitionerRole || []
  );
  const { error, data, isLoading, isSuccess } = useQuery({
    queryKey: ['rolesFilter'],
    queryFn: () =>
      axios.get('/rest/admin/v2/clinic/roles').then(({ data }) => {
        return data;
      }),
  });

  const clearRoles = () => {
    setSelectedAdministrativeRoles([]);
    setSelectedPractitionerRoles([]);
  };

  const mapRoles = React.useCallback(
    (roles: string[] = []) =>
      roles.map(role => ({
        value: role,
        label: intl.formatMessage({ id: `roles.${role}` }),
      })),
    [intl]
  );

  const administrativeRoles =
    React.useMemo(
      () =>
        sortOptionsAlphabetically(
          mapRoles(data?.administrative).filter(role => role.value !== ROLES.ADMIN)
        ),
      [data, mapRoles, sortOptionsAlphabetically]
    ) || [];
  const practitionerRoles =
    React.useMemo(
      () => sortOptionsAlphabetically(mapRoles(data?.practitioner)),
      [data, mapRoles, sortOptionsAlphabetically]
    ) || [];

  return {
    roleError: error,
    administrativeRoles,
    practitionerRoles,
    isRoleLoading: isLoading,
    isRolesSuccess: isSuccess,
    selectedAdministrativeRoles,
    selectedPractitionerRoles,
    clearRoles,
    onAdministrativeRoleChange: setSelectedAdministrativeRoles,
    onPractitionerRoleChange: setSelectedPractitionerRoles,
  };
};
