import axios from 'axios';

import { IDENTITIES } from '../../constants/practitioner';
import { ExternalId } from '../Roles/api/rolesApi';

export interface UserType {
  id: string;
  externalId: string;
  externalIdType: IDENTITIES;
  givenName: string;
  middleAndSurname: string;
  title: string;
  externalIds: ExternalId[];
  email?: string;
  mobileNumber?: string;
}

export const fetchUser = async (practitionerId: string) => {
  return axios.get<UserType>(`/rest/admin/v1/practitioners/${encodeURIComponent(practitionerId)}`);
};

export const updateUser = (practitioner: UserType) => {
  // PUT endpoint is incompatible with the result of GET method in the sense that
  // primary external id has to be excluded from externalIds array. If it's not, it
  // might have unexpected results. This should be fixed on the backend: https://platform24.atlassian.net/browse/AX-9421
  return axios.put<UserType>(
    `/rest/admin/v1/practitioners/${encodeURIComponent(practitioner.id)}`,
    {
      ...practitioner,
      externalIds: practitioner.externalIds.slice(1),
    }
  );
};

export const createUser = (practitioner: UserType) => {
  // POST endpoint is incompatible with the result of GET method in the sense that
  // primary external id has to be excluded from externalIds array. If it's not, it will be
  // duplicated. This should be fixed on the backend: https://platform24.atlassian.net/browse/AX-9421
  return axios.post<UserType>('/rest/admin/v1/admin/practitioners', {
    ...practitioner,
    externalIds: practitioner.externalIds.slice(1),
  });
};
