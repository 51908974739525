import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Submenu.module.css';

export interface SublinkItem {
  id?: string;
  name?: string;
  slug?: string;
  testId?: string;
}

interface Props {
  mainLink: string;
  subLinks: SublinkItem[];
  text: JSX.Element;
  icon: JSX.Element;
  testId?: string;
  isEmptyVisible?: boolean;
  activeMenuKey?: string;
  isMainLinkActive?: boolean;
  isCollapsed?: boolean;
}

const Submenu = ({
  mainLink,
  subLinks,
  text,
  icon,
  testId,
  isEmptyVisible,
  activeMenuKey,
  isMainLinkActive = true,
  isCollapsed,
}: Props) => {
  const mainLinkContent = (
    <div data-testid={testId}>
      <icon.type {...icon.props} />
      <span>{text}</span>
    </div>
  );

  return {
    className: classNames({
      // this is for Ant Design theme customization file, because library does not set this class on
      // active submenu item by itself
      'ant-menu-item-selected': activeMenuKey === mainLink,
    }),
    popupClassName: classNames(styles.wrapper, {
      collapsed: isCollapsed,
    }),
    key: mainLink,
    label: isMainLinkActive ? (
      <NavLink data-testid={testId} to={`/${mainLink}`}>
        {mainLinkContent}
      </NavLink>
    ) : (
      mainLinkContent
    ),
    children: subLinks
      .filter(({ id }) => !!id || !!isEmptyVisible)
      .map((link, index) => ({
        key: link.id || index,
        label: (
          <NavLink
            to={`/${mainLink}${link.slug}`}
            data-testid={link.testId}
            // NavLink compares only pathname by default
            // todo: refactor fix later
            // isActive={(_: any, { pathname, search }: any) =>
            //   `/${mainLink}${link.slug}` === `${pathname}${search}`
            // }
            // exact
          >
            {link.name}
          </NavLink>
        ),
      })),
  };
};

export default Submenu;
