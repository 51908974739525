import { HistoryOutlined } from '@ant-design/icons';
import { Alert, Space, Typography } from 'antd';
import { Formik } from 'formik';
import noop from 'lodash/noop';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormRegularContent } from './FormRegularContent';
import style from './OpeningHours.module.css';
import { RowSpecialDay } from './RowSpecialDay';
import BoxContent from '../../../../components/BoxContent';
import { OpeningHoursContext } from '../openingHours.context';
import { useOpeningHoursQuery } from '../openingHours.hooks';
import { OpeningHoursRegular, OpeningHoursSpecial } from '../openingHours.types';
import { groupSpecialHours } from '../openingHours.utils';

export const PendingInfo = () => {
  const { id, mode } = useContext(OpeningHoursContext);
  const { data } = useOpeningHoursQuery(mode, id);

  if (!data?.candidate) {
    return null;
  }

  const { candidate } = data;
  const { futureDays } = groupSpecialHours(candidate.detailedSpecialHourPeriodsWrapper);
  return (
    <>
      <BoxContent
        isPending
        isCollapsible
        title={
          <Space>
            <HistoryOutlined />
            <Typography.Text strong>
              <FormattedMessage id="opening-hours.pending.title" />
            </Typography.Text>
          </Space>
        }
        topContent={
          <Alert
            className={style.boxContentAlert}
            showIcon
            type="info"
            message={<FormattedMessage id="opening-hours.pending.description" />}
          />
        }
      >
        {candidate.detailedRegularHoursWrapper && (
          <>
            <Typography.Text strong>
              <FormattedMessage id="opening-hours.regular.title" />
            </Typography.Text>
            <Formik<OpeningHoursRegular>
              initialValues={candidate.detailedRegularHoursWrapper}
              enableReinitialize
              onSubmit={noop}
            >
              <FormRegularContent disabled />
            </Formik>
          </>
        )}
        {futureDays && (
          <>
            <Typography.Text strong>
              <FormattedMessage id="opening-hours.special.upcoming" />
            </Typography.Text>
            <Formik<OpeningHoursSpecial>
              initialValues={candidate.detailedSpecialHourPeriodsWrapper}
              enableReinitialize
              onSubmit={noop}
            >
              <table>
                <tbody>
                  {futureDays.map((day, index) => (
                    <RowSpecialDay
                      key={`pending-${index}-${day.startDate}-${day.endDate}`}
                      name={`detailedSpecialHourPeriods[${candidate.detailedSpecialHourPeriodsWrapper.detailedSpecialHourPeriods.indexOf(
                        day
                      )}]`}
                      disabled
                    />
                  ))}
                </tbody>
              </table>
            </Formik>
          </>
        )}
      </BoxContent>
    </>
  );
};
