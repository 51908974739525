import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { ChildOrigin, Partner, PartnerCareProvider, PartnerUserRole } from 'api/partnersApi';
import { CURRENT_ENV_TYPE, ENV } from 'constants/env';

import { LANGS } from '../../constants/enums';
import { partnerStatusAtoms } from '../partnerStatus';

interface PartnersState {
  partners: Partner[];
  careProviders: PartnerCareProvider[];
  rootOrigins: ChildOrigin[];
  currentPartner?: Partner;
  partnerRoles: PartnerUserRole[];
  partnerCustomizations: Map<string, any>;
  partnerConfig: Map<string, any>;
  countryCallingCodes: string[];
  defaultLanguage: string;
}

const root = atom<PartnersState>({
  partners: [],
  careProviders: [],
  rootOrigins: [],
  partnerRoles: [],
  partnerCustomizations: new Map(),
  partnerConfig: new Map(),
  countryCallingCodes: [],
  defaultLanguage: 'sv',
});

const partners = focusAtom(root, o => o.prop('partners'));
const careProviders = focusAtom(root, o => o.prop('careProviders'));
const rootOrigins = focusAtom(root, o => o.prop('rootOrigins'));
const currentPartner = focusAtom(root, o => o.prop('currentPartner'));
const partnerRoles = focusAtom(root, o => o.prop('partnerRoles'));
const partnerCustomizations = focusAtom(root, o => o.prop('partnerCustomizations'));
const partnerConfig = focusAtom(root, o => o.prop('partnerConfig'));
const countryCallingCodes = focusAtom(root, o => o.prop('countryCallingCodes'));
const defaultLanguage = focusAtom(root, o => o.prop('defaultLanguage'));
const availableLanguages = atom(
  get => get(partnerCustomizations).get('AVAILABLE_LANGUAGES') as LANGS[]
);

// --- computed
const isReadOnlyModeEnabled = atom(
  get => CURRENT_ENV_TYPE === ENV.DEMO || get(partnerStatusAtoms.isMergeRequestPending)
);

export const partnersAtoms = {
  root,
  partners,
  careProviders,
  rootOrigins,
  currentPartner,
  partnerRoles,
  partnerCustomizations,
  partnerConfig,
  countryCallingCodes,
  defaultLanguage,
  availableLanguages,
  isReadOnlyModeEnabled,
};
