import { Divider, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { AccessScopeRole } from 'api/permissionsApi';
import FormActionButtons from 'components/FormActionButtons';
import { ACCESS_SCOPE_OPTIONS, ACCESS_SCOPE_TYPES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import { ManageViewScope } from 'modules/Roles/stores/ManageRolesStore';
import { filterSelectOption, stringToSelectOption } from 'utils/formUtils';

interface FormValues {
  partnerId: string;
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  roles: string[];
}

interface Props {
  data: ManageViewScope;
  onSubmit: (values: ManageViewScope) => void;
  onClose: () => any;
}

const EditManageRoleModal: FunctionComponent<Props> = ({ data, onSubmit, onClose }) => {
  const intl = useIntl();
  const { userPermissionsStore } = useContext(RootStoreContext);

  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    roles: Yup.string().required(requiredErrorMessage),
  });

  const rolesOptions = useMemo(() => {
    let roles: AccessScopeRole[] = [];

    switch (data.scopeType) {
      case ACCESS_SCOPE_TYPES.ORIGIN: {
        roles = userPermissionsStore.getOriginScopedRoles(data.partnerId, data.scopeValue);
        break;
      }

      case ACCESS_SCOPE_TYPES.CARE_UNIT: {
        const careUnitId = data.scopeValue;
        const careProviderForThisCareUnit = userPermissionsStore
          .getManagedCareProviders(data.partnerId)
          .find(careProvider =>
            careProvider.careUnits.find(careUnit => careUnit.scopeValue === careUnitId)
          );

        if (careProviderForThisCareUnit) {
          const careProviderId = careProviderForThisCareUnit.scopeValue;
          roles = userPermissionsStore.getCareUnitScopedRoles(
            data.partnerId,
            careProviderId,
            careUnitId
          );
        }
        break;
      }
    }

    return roles.map(role => stringToSelectOption(role.name));
  }, [data, userPermissionsStore]);

  const getRoles = useCallback(() => {
    const result = data.roles?.map(({ name }) => name);
    return result || [];
  }, [data]);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit({
        ...data,
        scopeValue: values.scopeValue,
        scopeType: values.scopeType,
        roles: values.roles!.map(role => ({ name: role })),
      });
    },
    [data, onSubmit]
  );

  const intlIds = {
    [ACCESS_SCOPE_TYPES.ORIGIN]: 'general.origin',
    [ACCESS_SCOPE_TYPES.CARE_UNIT]: 'general.care-unit',
  } as Record<ACCESS_SCOPE_TYPES, string>;

  const initialValues = {
    scopeType: data.scopeType || ACCESS_SCOPE_TYPES.PARTNER,
    partnerId: data.partnerId || '',
    scopeValue: data.scopeValue,
    roles: getRoles(),
  };

  const scopeType = ACCESS_SCOPE_OPTIONS.find(({ id }) => id === initialValues.scopeType);
  const scopeTypeText = scopeType
    ? intl.formatMessage({ id: scopeType.translationId })
    : initialValues.scopeType;

  return (
    <Modal
      open
      destroyOnClose
      title={
        <span data-testid="edit-manage-role-form-title">
          <FormattedMessage id="roles.edit-manage-role" />
        </span>
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange
        validationSchema={validationSchema}
      >
        {formikBag => (
          <Form layout="vertical" data-testid="form">
            <Form.Item name="partnerId" label={<FormattedMessage id="general.partner" />}>
              <Input type="text" name="partnerId" disabled />
            </Form.Item>
            <Form.Item name="scopeType" label={<FormattedMessage id="roles.scope" />}>
              <Input type="text" name="scopeType" disabled value={scopeTypeText} />
            </Form.Item>
            <Form.Item
              name="scopeValue"
              label={<FormattedMessage id={intlIds[formikBag.values.scopeType]} />}
            >
              <Input type="text" name="scopeValue" disabled />
            </Form.Item>
            <Form.Item name="roles" label={<FormattedMessage id="roles.role" />} required>
              <Select
                name="roles"
                data-testid="field-roles"
                mode="multiple"
                allowClear
                showSearch
                filterOption={filterSelectOption}
                loading={userPermissionsStore.isLoading()}
                options={rolesOptions}
              />
            </Form.Item>
            <Divider />
            <FormActionButtons
              isValid={formikBag.isValid}
              onCancel={onClose}
              showCancelConfirm={formikBag.dirty}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditManageRoleModal;
