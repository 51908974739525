import { createContext } from 'react';

import { MODE } from './openingHours.types';

export const OpeningHoursContext = createContext<{
  id: string;
  mode: MODE;
}>({
  id: '',
  mode: MODE.CARE_UNIT,
});
