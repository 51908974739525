import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

import { ShutdownDTO } from 'modules/Origins/Shutdown/api/shutdownApi';
import { CurrentOrigin } from 'stores/OriginStore';

interface OriginState {
  currentOrigin?: CurrentOrigin;
  availableOriginLanguages: string[];
  shutdownInfo?: ShutdownDTO;
}

const root = atom<OriginState>({
  currentOrigin: undefined,
  availableOriginLanguages: [],
  shutdownInfo: undefined,
});

const currentOrigin = focusAtom(root, o => o.prop('currentOrigin'));
const availableOriginLanguages = focusAtom(root, o => o.prop('availableOriginLanguages'));
const shutdownInfo = focusAtom(root, o => o.prop('shutdownInfo'));

export const originAtoms = {
  root,
  currentOrigin,
  availableOriginLanguages,
  shutdownInfo,
};
