import { ChildOrigin } from 'api/partnersApi';
import UserPermissionsStore from 'stores/UserPermissionsStore';

export interface OriginListItem extends Omit<ChildOrigin, 'origins'> {
  name: string;
  id: string;
  canView?: boolean;
  canEdit?: boolean;
  children: OriginListItem[];
}

export const passUserPermissionDownOriginTreeGivenUserPermissionDataAndOrigin = (
  origins: OriginListItem[],
  userPermissionStore: UserPermissionsStore
): OriginListItem[] => {
  const originsList = origins;

  function addCanView(node: OriginListItem) {
    // Add canView property to the current node
    node.canView = true;

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => addCanView(child));
    }
  }

  function addCanEdit(node: OriginListItem) {
    // Add canEdit property to the current node
    node.canEdit = true;
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => addCanEdit(child));
    }
  }

  for (const origin of originsList) {
    origin.canView = userPermissionStore.canViewOrigin(origin.id);
    origin.canEdit = userPermissionStore.canEditOrigin(origin.id);

    if (origin.children && origin.children.length > 0) {
      origin.children = passUserPermissionDownOriginTreeGivenUserPermissionDataAndOrigin(
        origin.children,
        userPermissionStore
      );
    }

    // if canEdit is true recursive add canEdit to children
    if (origin.canView) {
      addCanView(origin);
    }

    if (origin.canEdit) {
      addCanEdit(origin);
    }
  }

  return originsList;
};

export const traverseOriginsTree = (
  origins: OriginListItem[],
  predicateFunction: (originId: string) => boolean
): boolean => {
  const checkPermissions = (node: OriginListItem): boolean => {
    if (node.children && node.children.length > 0) {
      if (predicateFunction(node.id)) {
        return true;
      }
      return node.children.some((child: OriginListItem) =>
        checkPermissions(child as OriginListItem)
      );
    }
    return false;
  };

  return origins.some(origin => checkPermissions(origin));
};
