import {
  DisplayFriendlyErrorStatus,
  DisplayFriendlyStatus,
  OrderStatus,
  OrderStatusEnum,
  OrderStatusErrorCodes,
  SwedishBankIdFailedHintCode,
  SwedishBankIdPendingHintCode,
  TextIds,
} from './SwedishBankId.types';
import { SwedishSecureLoginTexts } from './SwedishBankIdTexts';
import { LANGS } from '../../../../constants/enums';

export const pendingStatusToCode = (hintCode: SwedishBankIdPendingHintCode): TextIds | null => {
  switch (hintCode) {
    case SwedishBankIdPendingHintCode.OUTSTANDING_TRANSACTION:
    case SwedishBankIdPendingHintCode.NO_CLIENT:
      return 'rfa1';

    case SwedishBankIdPendingHintCode.USER_SIGN:
      return 'rfa9';

    case SwedishBankIdPendingHintCode.STARTED:
      return 'rfa14';

    case SwedishBankIdPendingHintCode.UNKNOWN:
      return 'rfa21';

    // in case of "Pending" status we rather show default text than "unknown error"
    default:
      return null;
  }
};

export const failedStatusToCode = (hintCode: SwedishBankIdFailedHintCode): TextIds => {
  switch (hintCode) {
    case SwedishBankIdFailedHintCode.USER_CANCEL:
      return 'rfa6';
    case SwedishBankIdFailedHintCode.EXPIRED_TRANSACTION:
      return 'rfa8';
    case SwedishBankIdFailedHintCode.CERTIFICATE_ERR:
      return 'rfa16';
    case SwedishBankIdFailedHintCode.START_FAILED:
      return 'rfa17';
    default:
      // unknown
      return 'rfa22';
  }
};

export const errorCodeToCode = (errorCode: OrderStatusErrorCodes): TextIds => {
  switch (errorCode) {
    case OrderStatusErrorCodes.ALREADY_IN_PROGRESS:
      return 'rfa3';
    case OrderStatusErrorCodes.REQUEST_TIMEOUT:
    case OrderStatusErrorCodes.MAINTENANCE:
    case OrderStatusErrorCodes.INTERNAL_ERROR:
      return 'rfa5';
    default:
      // unknown error
      return 'rfa22';
  }
};

/**
 * Parses status object and returns ready to use object for visualisation
 */
export const parseSwedishBankIdStatusObject = (
  status: OrderStatus | null,
  lang: LANGS,
  throwOnError = false
): DisplayFriendlyStatus => {
  if (!status) {
    return {};
  }

  const dictionary =
    lang in SwedishSecureLoginTexts
      ? SwedishSecureLoginTexts[lang as 'sv' | 'en']
      : SwedishSecureLoginTexts.en;

  if (status.status === OrderStatusEnum.PENDING) {
    if (
      !status.hintCode ||
      status.hintCode === SwedishBankIdPendingHintCode.OUTSTANDING_TRANSACTION
    ) {
      // outstanding transaction means that we are waiting for a client. Show normal QR code
      return {
        qrCodeString: status.qrCodeText,
        base64QrCode: status.qrBase64,
      };
    }

    // user started app and scanned QR code, we cannot scan it again, show only text
    const pendingStatusCode = pendingStatusToCode(status.hintCode as SwedishBankIdPendingHintCode);

    if (!pendingStatusCode) {
      return {};
    }

    return {
      text: dictionary[pendingStatusCode], //PendingStatusToCode(status.hintCode as SwedishBankIdPendingHintCode),
    };
  }

  if (status.status === OrderStatusEnum.CRASHED || status.status === OrderStatusEnum.FAILED) {
    const errorStatus: DisplayFriendlyErrorStatus = { isError: true };
    if (status.status === OrderStatusEnum.CRASHED) {
      const code =
        status.errorCode in OrderStatusErrorCodes
          ? OrderStatusErrorCodes[status.errorCode]
          : OrderStatusErrorCodes.UNKNOWN;
      const textCode = errorCodeToCode(code);
      errorStatus.text = dictionary[textCode];
    }

    if (status.status === OrderStatusEnum.FAILED) {
      const code = failedStatusToCode(status.hintCode as SwedishBankIdFailedHintCode);
      errorStatus.text = dictionary[code];
    }

    // When used in the component, it can throw error so error boundary component can easily retry login
    if (throwOnError) {
      throw errorStatus;
    }

    return errorStatus;
  }

  return {};
};
