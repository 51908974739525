import {
  StatementUpdated,
  SectionUpdated,
  Condition,
} from 'modules/Content24/Condition/api/partnerCode24api';
import { Optional } from 'types/types';

import { CODE24_MODEL_TYPES } from '../Condition/constants/code24types';
import { Metadata, SearchTerm, Section, Statement } from '../Condition/models/Code24Model';
import { UPDATE_ACTIONS } from '../constants/conditions';

function isSectionUpdate(
  statementUpdate?: SectionUpdated | StatementUpdated
): statementUpdate is SectionUpdated {
  return (
    !!statementUpdate?.model &&
    (statementUpdate.model.type === CODE24_MODEL_TYPES.PRE_TRIAGE ||
      statementUpdate.model.type === CODE24_MODEL_TYPES.POST_TRIAGE)
  );
}

function isMetadata(model?: Section | Statement): model is Metadata {
  // No model is provided when deleting the default statement in modified condition
  return !!model && model.type === CODE24_MODEL_TYPES.METADATA;
}

function isSearchTerm(model?: Section | Statement, disable?: string): model is SearchTerm {
  // No model is provided when deleting the default statement in modified condition
  return (
    (!!model && model.type === CODE24_MODEL_TYPES.SEARCH_TERM) ||
    (!!disable && disable.includes(CODE24_MODEL_TYPES.SEARCH_TERM))
  );
}

function getSectionOrStatementLocationIndexes(sections: Section[], statementId: string) {
  const result = {
    sectionIndex: -1,
    statementIndex: -1,
  };

  for (let i = 0; i < sections.length; i += 1) {
    if (sections[i].id === statementId) {
      result.sectionIndex = i;
      break;
    }

    const contentIndex = sections[i].content.findIndex(({ id }) => id === statementId);
    if (contentIndex !== -1) {
      result.sectionIndex = i;
      result.statementIndex = contentIndex;
      break;
    }
  }

  return result;
}

export function updateStatementInOriginCondition(
  statementUpdate: StatementUpdated | SectionUpdated,
  actionType: UPDATE_ACTIONS,
  condition: Optional<Condition>,
  // search term changes id with each update, we have to pass the original id to make an update
  updatePayloadId?: string
) {
  // We're inserting section
  if (condition && isSectionUpdate(statementUpdate)) {
    if (!statementUpdate.model) {
      throw new Error('No section to add');
    }

    if (!condition?.sections) {
      condition.sections = [statementUpdate.model];
    } else if (statementUpdate.after) {
      const { sectionIndex } = getSectionOrStatementLocationIndexes(
        condition.sections,
        statementUpdate.after
      );

      if (sectionIndex === -1) {
        throw new Error('No section to put section after');
      }

      condition.sections.splice(sectionIndex + 1, 0, statementUpdate.model);
    } else {
      condition.sections.unshift(statementUpdate.model);
    }

    return condition;
  }

  // We're inserting metadata
  if (condition && isMetadata(statementUpdate.model)) {
    condition.metadata = statementUpdate.model;

    return condition;
  }

  // We're inserting statement that is not search term
  if (
    !isSectionUpdate(statementUpdate) &&
    !isSearchTerm(statementUpdate.model, statementUpdate.disable)
  ) {
    if (!condition?.sections) {
      return;
    }

    switch (actionType) {
      case UPDATE_ACTIONS.ADD: {
        if (!statementUpdate.model) {
          throw new Error('No statement to add');
        }

        if (!statementUpdate.after) {
          throw new Error('No statement to place after');
        }

        const { sectionIndex, statementIndex } = getSectionOrStatementLocationIndexes(
          condition.sections,
          statementUpdate.after
        );

        if (sectionIndex === -1) {
          throw new Error('No section to put statement into');
        }

        condition.sections[sectionIndex].content.splice(
          statementIndex + 1,
          0,
          statementUpdate.model
        );

        break;
      }

      case UPDATE_ACTIONS.EDIT: {
        // some statements change id with each update, we have to pass the original id to make an update
        if (!updatePayloadId) {
          throw new Error('No statement to be edited');
        }

        if (!statementUpdate.model) {
          throw new Error('No statement to edit');
        }

        const { sectionIndex, statementIndex } = getSectionOrStatementLocationIndexes(
          condition.sections,
          updatePayloadId
        );

        if (sectionIndex === -1) {
          throw new Error('No section to replace statement in');
        }

        if (statementIndex === -1) {
          throw new Error('No statement to replace');
        }

        condition.sections[sectionIndex].content.splice(statementIndex, 1, statementUpdate.model);
        break;
      }

      case UPDATE_ACTIONS.MOVE: {
        if (!statementUpdate.model) {
          throw new Error('No statement to move');
        }

        if (!statementUpdate.after) {
          throw new Error('No statement to place after');
        }

        const { sectionIndex, statementIndex: statementStartIndex } =
          getSectionOrStatementLocationIndexes(condition.sections, statementUpdate.model.id);

        if (sectionIndex === -1) {
          throw new Error('No section to move statement in');
        }

        if (statementStartIndex === -1) {
          throw new Error('No statement to move');
        }

        condition.sections[sectionIndex].content.splice(statementStartIndex, 1);

        const statementEndIndex = condition.sections[sectionIndex].content.findIndex(
          item => item.id === statementUpdate.after
        );

        condition.sections[sectionIndex].content.splice(
          statementEndIndex + 1,
          0,
          statementUpdate.model
        );
        break;
      }

      case UPDATE_ACTIONS.DELETE: {
        if (!statementUpdate.model) {
          throw new Error('No statement to delete');
        }

        const { sectionIndex, statementIndex } = getSectionOrStatementLocationIndexes(
          condition.sections,
          statementUpdate.model.id
        );

        if (sectionIndex === -1) {
          throw new Error('No section to delete statement from');
        }

        if (statementIndex === -1) {
          throw new Error('No statement to delete');
        }

        condition.sections[sectionIndex].content.splice(statementIndex, 1);
        break;
      }
    }

    return condition;
  }

  // We're inserting statement that is a search term
  if (
    !isSectionUpdate(statementUpdate) &&
    isSearchTerm(statementUpdate.model, statementUpdate.disable)
  ) {
    if (!condition) {
      return;
    }

    if (!condition.searchTerms) {
      condition.searchTerms = [];
    }

    switch (actionType) {
      case UPDATE_ACTIONS.ADD: {
        if (!statementUpdate.model) {
          throw new Error('No statement to add');
        }

        // First search term in row
        if (!statementUpdate.after) {
          condition.searchTerms.push(statementUpdate.model);
        } else {
          const statementIndex = condition.searchTerms.findIndex(
            item => item.id === statementUpdate.after
          );

          if (statementIndex === -1) {
            throw new Error('No statement to put statement after');
          }

          condition.searchTerms.splice(statementIndex + 1, 0, statementUpdate.model);
        }

        break;
      }

      case UPDATE_ACTIONS.EDIT: {
        // search term changes id with each update, we have to pass the original id to make an update
        if (!updatePayloadId) {
          throw new Error('No statement to be edited');
        }

        if (!statementUpdate.model) {
          throw new Error('No statement to edit');
        }

        const statementToRemoveIndex = condition.searchTerms.findIndex(
          item => item.id === updatePayloadId
        );

        if (statementToRemoveIndex === -1) {
          throw new Error('No statement to be edited');
        }

        condition.searchTerms.splice(statementToRemoveIndex, 1);
        condition.searchTerms.push(statementUpdate.model);
        break;
      }

      case UPDATE_ACTIONS.DELETE: {
        const model = statementUpdate.model;

        if (!model) {
          throw new Error('No statement to delete');
        }

        const statementIndex = condition.searchTerms.findIndex(item => item.id === model.id);

        if (statementIndex === -1) {
          throw new Error('No statement to delete');
        }

        condition.searchTerms.splice(statementIndex, 1);
        break;
      }
    }

    return condition;
  }

  return;
}
