import { useSetAtom } from 'jotai';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import RootStoreContext from 'context/RootStoreContext';
import { partnersAtoms } from 'state/partners/partners.atoms';

export const PartnersStoreAdapter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { partnersStore } = rootStore;
  const {
    partners,
    careProviders,
    rootOrigins,
    currentPartner,
    partnerRoles,
    partnerCustomizations,
    partnerConfig,
    countryCallingCodes,
    defaultLanguage,
  } = partnersStore;

  const setPartnersRootAtomValue = useSetAtom(partnersAtoms.root);

  useEffect(() => {
    setPartnersRootAtomValue({
      partners: partners.toJS(),
      careProviders: careProviders.toJS(),
      rootOrigins: rootOrigins.toJS(),
      currentPartner: currentPartner,
      partnerRoles: partnerRoles.toJS(),
      partnerCustomizations: partnerCustomizations.toJS(),
      partnerConfig: partnerConfig.toJS(),
      countryCallingCodes: countryCallingCodes.toJS(),
      defaultLanguage: defaultLanguage,
    });
  }, [
    partners,
    careProviders,
    rootOrigins,
    currentPartner,
    partnerRoles,
    partnerCustomizations,
    partnerConfig,
    countryCallingCodes,
    defaultLanguage,
    setPartnersRootAtomValue,
  ]);

  return null;
});
