import { action, computed, observable, runInAction, when } from 'mobx';
import uuid from 'uuid/v4';

import { DEFAULT_LOCALE } from 'constants/dateFormat';
import { LANGS } from 'constants/enums';
import {
  ConditionVisualization,
  ExitCategory,
  fetchConditionVisualization,
  GotoVisualizationModel,
  VariableDependency,
} from 'modules/Content24/Condition/api/partnerCode24api';
import {
  CODE24_MODEL_TYPES,
  EXIT_ATTRIBUTES_KEYS,
  QUESTION_SHORT_RESPONSES,
  QUESTION_TYPES,
} from 'modules/Content24/Condition/constants/code24types';
import { Exit, Question, QuestionResponse } from 'modules/Content24/Condition/models/Code24Model';
import RootStore from 'stores/RootStore';
import { InputOption } from 'types/types';
import { capitalizeFirst } from 'utils/textUtils';

import { getResponseNodeId, parseExitDependencies } from '../utils';

export interface NodeDependency {
  questionId: string;
  responseId: string;
  not?: boolean;
}

export interface NodeData {
  id: string;
  isDimmed?: boolean;
  text: string | InputOption | InputOption[];
  source?: string;
  children: NodeData[];
  gotos?: NodeData[];
  dependencies?: NodeDependency[];
  type?: unknown;
  questionId?: string;
  questionType?: QUESTION_TYPES;
  [key: string]: unknown;
}

export interface ExitNodeData extends NodeData {
  type: CODE24_MODEL_TYPES.EXIT;
  breaking?: boolean;
  children: [];
  exitModel: Exit;
}

export interface ExitDependencies {
  dependencies: { questionId: string }[];
}

export default class ConditionVisualizationStore {
  @observable
  conditionVisualization: ConditionVisualization = {
    conditionId: '',
    questions: [],
    gotos: [],
    exits: [],
    properties: [],
    couldNotBuildNonFormulaCount: 0,
  };

  @observable
  isLoading = false;

  @observable activeLanguage: LANGS = DEFAULT_LOCALE;
  @observable selectedNodeId: string | undefined;
  @observable focusedElementId: string | undefined;
  @observable focusedExit: NodeData | undefined;
  @observable nodeNavigation = false;

  constructor(private rootStore: RootStore) {
    when(
      () => !!this.rootStore.content24Store.availableLanguages.length,
      () => {
        this.activeLanguage = this.rootStore.content24Store.availableLanguages[0];
      }
    );
  }

  @action
  initalize = async (conditionId: string) => {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    this.isLoading = true;

    try {
      const { data } = await fetchConditionVisualization(partnerId, conditionId);

      runInAction(() => {
        this.conditionVisualization = data;
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  clearVisualization = () => {
    this.conditionVisualization = {
      conditionId: '',
      questions: [],
      gotos: [],
      exits: [],
      properties: [],
      couldNotBuildNonFormulaCount: 0,
    };
    this.activeLanguage = DEFAULT_LOCALE;
    this.selectedNodeId = undefined;
    this.focusedElementId = undefined;
    this.focusedExit = undefined;
    this.nodeNavigation = false;
  };

  @computed
  get questionsAndGotos(): NodeData[] {
    let returnValue: NodeData[] = [];

    if (!this.conditionVisualization) {
      return [];
    }

    returnValue = this.conditionVisualization.questions.map(
      ({ questionModel, source, variableDependencies }) => {
        const questionGotos: GotoVisualizationModel[] | undefined =
          this.conditionVisualization.gotos.length > 0
            ? this.conditionVisualization.gotos.filter(
                goto => goto.afterQuestionId === questionModel.questionId
              )
            : undefined;

        const hasChildren =
          !!questionModel.content?.length ||
          [QUESTION_TYPES.YESNOMAYBE, QUESTION_TYPES.YESNO].includes(questionModel.questionType);

        const question: NodeData = {
          id: questionModel.id,
          questionId: questionModel.questionId,
          source,
          type: CODE24_MODEL_TYPES.QUESTION,
          questionType: questionModel.questionType,
          text: questionModel.patient
            ? questionModel.patient[this.activeLanguage]
            : (questionModel.patient4 && questionModel.patient4[this.activeLanguage]) || '',
          min: questionModel.min,
          max: questionModel.max,
          children: hasChildren ? this.getQuestionChildren(questionModel, source) : [],
          dependencies: variableDependencies
            .filter(dep => dep.type === CODE24_MODEL_TYPES.QUESTION)
            .map(this.getNodeDependency),
          gotos:
            questionGotos && questionGotos.length > 0
              ? this.getQuestionGotoNodes(questionGotos)
              : [],
        };

        return question;
      }
    );

    return returnValue;
  }

  @computed
  get exits(): ExitNodeData[] {
    if (!this.conditionVisualization) {
      return [];
    }

    return this.conditionVisualization.exits.map(({ exitModel, source, variableDependencies }) => ({
      id: exitModel.id,
      source,
      type: CODE24_MODEL_TYPES.EXIT,
      breaking: exitModel.breaking,
      text: this.getExitText(exitModel),
      dependencies: variableDependencies
        .filter(dep => dep.type === CODE24_MODEL_TYPES.QUESTION)
        .map(this.getNodeDependency),
      children: [],
      exitModel,
    }));
  }

  private getQuestionGotoNodes = (data: GotoVisualizationModel[]) => {
    return data.map(({ gotoConditionId, afterQuestionId, variableDependencies }) => ({
      id: gotoConditionId,
      type: CODE24_MODEL_TYPES.GOTO,
      text: '',
      afterQuestionId,
      source: CODE24_MODEL_TYPES.GOTO,
      dependencies: variableDependencies
        .filter(dep => dep.type === CODE24_MODEL_TYPES.QUESTION)
        .map(this.getNodeDependency),
      children: [],
    }));
  };

  getQuestionChildKey(data: QuestionResponse) {
    if ('responseId' in data) {
      return data.responseId;
    }

    return uuid();
  }

  private getQuestionChildren = (questionModel: Question, source: string): NodeData[] => {
    if ([QUESTION_TYPES.YESNOMAYBE, QUESTION_TYPES.YESNO].includes(questionModel.questionType)) {
      return this.getYesNoMaybeChildren(questionModel, source);
    }

    return (questionModel.content || []).map(elem => ({
      id: getResponseNodeId(questionModel.questionId, this.getQuestionChildKey(elem)),
      type: elem.type,
      text:
        elem.patient && elem.patient[this.activeLanguage] ? elem.patient[this.activeLanguage] : '',
      source,
      children: [],
    }));
  };

  private getYesNoMaybeChildren = (questionModel: Question, source: string): NodeData[] => {
    const children = [QUESTION_SHORT_RESPONSES.YES, QUESTION_SHORT_RESPONSES.NO];

    if (questionModel.questionType === QUESTION_TYPES.YESNOMAYBE) {
      children.push(QUESTION_SHORT_RESPONSES.MAYBE);
    }

    return children.map(elem => ({
      id: getResponseNodeId(questionModel.questionId, elem),
      type: elem,
      text: elem,
      source,
      children: [],
    }));
  };

  private getExitText = (exitModel: Exit) =>
    EXIT_ATTRIBUTES_KEYS.reduce((accumulator: InputOption[], attr: string) => {
      if (attr in exitModel && exitModel[attr]) {
        const text = Array.isArray(exitModel[attr])
          ? this.getExitAttributeText(attr, exitModel[attr].join(', '))
          : this.getExitAttributeText(attr, exitModel[attr]);
        if (text) {
          return [...accumulator, text];
        }
      }
      return accumulator;
    }, []);

  private getExitAttributeText = (key: string, value: string) => {
    const {
      content24Store: { exitAttributesTranslations },
    } = this.rootStore;

    const exitGroupCategories: ExitCategory[] =
      exitAttributesTranslations && exitAttributesTranslations[key];
    const exitCategory = exitGroupCategories && exitGroupCategories[value];
    const valueTranslation = (exitCategory && exitCategory[this.activeLanguage]) || value;

    return { label: key, value: valueTranslation };
  };

  private getNodeDependency = (dep: VariableDependency) => ({
    questionId: dep.id,
    responseId: dep.value,
    not: dep.not,
  });

  getTitle = (locale: string) => {
    const { condition } = this.rootStore.conditionStore;

    if (!condition.metadata.conditionId) {
      return '';
    }

    return capitalizeFirst(
      (condition.metadata.title && condition.metadata.title[locale]) ||
        condition.metadata.conditionId
    );
  };

  @action
  setSelectedNodeId = (id: string) => {
    this.selectedNodeId = id;
  };

  @action
  clearSelectedNodeId = () => {
    this.selectedNodeId = undefined;
  };

  @action
  setFocusedElementId = (id: string) => {
    this.focusedElementId = id;
  };

  @action
  setNodeNavigation = (nodeNavigation: boolean) => {
    this.nodeNavigation = nodeNavigation;
  };

  @action
  clearFocusedElementId = () => {
    this.focusedElementId = undefined;
  };

  @action
  handleLanguageChange = (language: LANGS) => {
    this.activeLanguage = language;
  };

  @action
  setFocusedExit = (exit: NodeData) => {
    this.focusedExit = { ...exit, dependencies: parseExitDependencies(exit.dependencies) };
  };
}
