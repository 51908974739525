import { Radio, Form } from 'formik-antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  getBaseValueType,
  getBooleanControlValues,
  getConstraints,
} from 'modules/Rules/utils/utils';
import { createSelectOptions } from 'utils/selectUtils';

import styles from './RuleForm.module.css';
import { ListValueInput, MapValueInput, OtherValueInput, SetValueInput } from './valueInputs';
import {
  isBoolean,
  isList,
  isMap,
  isSet,
  Outcome,
  RulesAttribute,
  RULES_ATTRIBUTE_TYPE,
  RULES_OUTCOME_OPERATOR,
} from '../../types';

interface OutcomeValueProps {
  outcome: Outcome;
  idx: number;
  isDisabled: boolean;
  outcomeMeta: RulesAttribute | null;
}

export const OutcomeValue = ({ outcome, outcomeMeta, idx, isDisabled }: OutcomeValueProps) => {
  const isMapValue = isMap(outcomeMeta);
  const isSetValue = isSet(outcomeMeta);
  const isBooleanValue = isBoolean(outcomeMeta);
  const isListValue = isList(outcomeMeta);
  const { formatMessage } = useIntl();

  const booleanValues = getBooleanControlValues(formatMessage);

  const allowedValues = useMemo(() => {
    const { allowedValues: allowedValuesFromConstraints } = getConstraints(outcomeMeta);

    // boolean type of data is kinda special and we have to treat them
    // in a special way if outcome field has not primitive data type like SET and LIST.
    // we set allowed values to [true, false]
    // in order to be able to display correct form controls for boolean values
    // with not promitive outcome data types like LIST and SET
    const baseValueType = getBaseValueType(outcomeMeta);
    const allowedValuesToUse =
      (isSetValue || isListValue) && baseValueType === RULES_ATTRIBUTE_TYPE.BOOL
        ? booleanValues
        : createSelectOptions(allowedValuesFromConstraints ?? []);

    // outcomeMeta is null when user creates brand new value field and value type is not yet specified
    return outcomeMeta === null ? [] : allowedValuesToUse;
  }, [outcomeMeta, isSetValue, isListValue, booleanValues]);

  // render nothing if outcome clears the value
  // TODO: if user had some values for MAP field
  // then has chosen clear as a n operator ==> outcome value fileds dissapeared
  // then has chosen any other operator ==> outcome value fields appeared,
  // but as empty. doesnt happen with SET type of value
  if (outcome.operator === RULES_OUTCOME_OPERATOR.CLEAR) {
    return null;
  }

  const path = `outcomes[${idx}].value`;

  return (
    <>
      {isSetValue ? (
        <SetValueInput
          isDisabled={isDisabled}
          outcomeMeta={outcomeMeta}
          allowedValues={allowedValues}
          name={path}
        />
      ) : isListValue ? (
        <ListValueInput
          isDisabled={isDisabled}
          outcomeMeta={outcomeMeta}
          allowedValues={allowedValues}
          name={path}
        />
      ) : isMapValue ? (
        <MapValueInput
          isDisabled={isDisabled}
          outcomeMeta={outcomeMeta}
          allowedValues={allowedValues}
          name={path}
          outcome={outcome}
        />
      ) : isBooleanValue ? (
        <Form.Item
          name={path}
          required
          className={styles.formItem}
          label={formatMessage({ id: 'rules.value' })}
        >
          <Radio.Group name={path} options={booleanValues} disabled={isDisabled} />
        </Form.Item>
      ) : (
        <Form.Item
          name={path}
          required
          className={styles.formItem}
          label={formatMessage({ id: 'rules.value' })}
        >
          <OtherValueInput
            isDisabled={isDisabled}
            outcomeMeta={outcomeMeta}
            allowedValues={allowedValues}
            name={path}
          />
        </Form.Item>
      )}
    </>
  );
};
