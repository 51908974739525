export const ARTICLE_THUMBNAIL_FILE_PREFIX = 'artcl-thumb_';

export const ARTICLE_THUMBNAIL_SIZE = {
  width: 84,
  height: 166,
};

export const ARTICLE_CONTENT_IMAGE_SIZE = {
  width: 360,
};

export const MAX_UPLOAD_IMAGE_SIZE_IN_MB = 15; // 15 MB is a lot, but we resize the image before uploading anyway so it becomes ~10 times smaller
