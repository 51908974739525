import { SorterResult } from 'antd/es/table/interface';

import { CareProviderFilter, FilterProps, UserFilter, UserTableDataType, CareUnit } from '../types';

interface Label {
  value: string;
  label: string;
}

export const getSelectedProvidersAndCareUnits = (
  careProviders: CareProviderFilter[],
  initialSelectedProviders: string[] = [],
  initialSelectedCareUnits: string[] = []
) => {
  const hasAllCareProviderOptionSelected = initialSelectedProviders.includes('ALL');
  const shouldPickAllCareUnits =
    !initialSelectedProviders ||
    initialSelectedProviders.length === 0 ||
    !careProviders.some(cp => cp.accessible);
  const selectedCareProviders: string[] = [];
  const selectedCareUnits: string[] = [];

  const addCareUnits = (careUnits: CareUnit[]) => {
    for (const careUnit of careUnits) {
      if (careUnit.accessible || initialSelectedCareUnits.includes(careUnit.id)) {
        selectedCareUnits.push(careUnit.id);
      }
    }
  };

  careProviders?.forEach((careProvider: CareProviderFilter) => {
    if (
      (initialSelectedProviders && initialSelectedProviders.length > 0) ||
      careProvider.accessible
    ) {
      if (
        initialSelectedProviders?.includes(careProvider.id) ||
        careProvider.accessible ||
        hasAllCareProviderOptionSelected
      ) {
        selectedCareProviders.push(careProvider.id);
        addCareUnits(careProvider.careUnits);
      }
    }
    // only do this if there's no careProvider with accessible and there's no selectedProviders
    else if (shouldPickAllCareUnits) {
      addCareUnits(careProvider.careUnits);
    }
  });

  return { selectedCareProviders, selectedCareUnits };
};

export const getProvidersAndCareUnitsOptions = (
  careProviders: CareProviderFilter[],
  initialSelectedProviders: string[] = []
) => {
  const hasAllCareProviderOptionSelected = initialSelectedProviders.includes('ALL');
  const shouldPickAllCareUnits =
    !initialSelectedProviders ||
    initialSelectedProviders.length === 0 ||
    hasAllCareProviderOptionSelected;
  const careProviderOptions: Label[] = [];
  const careUnitOptions: Label[] = [];
  careProviders?.forEach((careProvider: CareProviderFilter) => {
    careProviderOptions.push({ value: careProvider.id, label: careProvider.name });

    if (initialSelectedProviders?.includes(careProvider.id) || shouldPickAllCareUnits) {
      for (const careUnit of careProvider.careUnits) {
        // populates only care units in a particular provider. care units from either selected provider or care units from accessible care providers
        careUnitOptions.push({ value: careUnit.id, label: careUnit.name });
      }
    }
  });
  const all = careProviderOptions.find(cp => cp.value === 'ALL') as Label;
  const sortedCareProviderOptionsWithoutAll = sortOptionsAlphabetically(
    careProviderOptions.filter(cp => cp.value !== 'ALL')
  );

  return {
    careProviderOptions: all?.value
      ? // // NB. In order to always put ALL on top of the list, sort without ALL first, then add ALL on top
        [all, ...sortedCareProviderOptionsWithoutAll]
      : sortedCareProviderOptionsWithoutAll,
    careUnitOptions: sortOptionsAlphabetically(careUnitOptions),
  };
};

export const getSelectedCareUnitForProvider = (
  careProviders: CareProviderFilter[],
  selectedCareUnitIds: string[],
  selectedCareProviders: string[]
) => {
  const selectedCareUnits: string[] = [];
  careProviders?.forEach((careProvider: CareProviderFilter) => {
    if (selectedCareProviders.includes(careProvider.id)) {
      for (const careUnit of careProvider.careUnits) {
        if (selectedCareUnitIds.includes(careUnit.id)) {
          selectedCareUnits.push(careUnit.id);
        }
      }
    }
  });
  return selectedCareUnits;
};

export const mapFilterToQuery = (filterProps: FilterProps) => {
  return {
    searchString: filterProps.searchString || '',
    careProviderIds: filterProps.selectedCareProviders || [],
    careUnitIds: filterProps.selectedCareUnits || [],
    practitionerRoles: filterProps.selectedPractitionerRoles || [],
    administrativeRoles: filterProps.selectedAdministrativeRoles || [],
  };
};

const getSortField = (sorter: SorterResult<UserTableDataType>) => {
  if (sorter.field === 'name') {
    return sorter.order === 'ascend' ? { sortOrder: 'NAME' } : { sortOrder: 'NAME_DESC' };
  } else if (sorter.field === 'latestLogin') {
    return sorter.order === 'ascend'
      ? { sortOrder: 'LATEST_LOGIN' }
      : { sortOrder: 'LATEST_LOGIN_DESC' };
  }
  return { sortOrder: 'NAME' };
};

export const getPayload = (
  filters: UserFilter,
  sorter: SorterResult<UserTableDataType>,
  limit = 20,
  offset = 0
) => {
  const roles = Array.from(new Set([...filters.practitionerRoles, ...filters.administrativeRoles]));
  // NB. backend default is undefined... so we don't send empty arrays nor empty string
  return {
    ...filters,
    ...getSortField(sorter),
    practitionerRoles: undefined,
    administrativeRoles: undefined,
    searchString: filters.searchString ? filters.searchString : undefined,
    careProviderIds:
      !filters.careProviderIds.length || filters.careProviderIds?.includes('ALL')
        ? undefined
        : filters.careProviderIds,
    careUnitIds: filters.careUnitIds.length ? filters.careUnitIds : undefined,
    roles: roles.length ? roles : undefined,
    pagination: {
      limit,
      offset,
    },
  };
};

export const sortOptionsAlphabetically = (option: Label[]) => {
  return option.sort((a, b) => {
    return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
  });
};

export const isEmptyFilters = (filters: UserFilter) => {
  if (!filters) {
    return true;
  }
  return (
    filters?.searchString?.trim()?.length === 0 &&
    filters?.practitionerRoles?.length === 0 &&
    filters?.administrativeRoles?.length === 0 &&
    filters?.careProviderIds?.length === 0 &&
    filters?.careUnitIds?.length === 0
  );
};
