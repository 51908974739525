import { QUESTION_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { Question } from 'modules/Content24/Condition/models/Code24Model';
import { validatePatient4Regex } from 'modules/Content24/Condition/utils/validationUtils';
import { removeUUID } from 'utils/uuidUtils';

export const deleteUnnecessaryProperties = (dataToSubmit: Question) => {
  const isSingleOrMultipleQuestionType = [QUESTION_TYPES.MULTIPLE, QUESTION_TYPES.SINGLE].includes(
    dataToSubmit.questionType
  );
  const isFreetextOrFileQuestionType = [QUESTION_TYPES.FREETEXT, QUESTION_TYPES.FILE].includes(
    dataToSubmit.questionType
  );
  const isNumberQuestionType = dataToSubmit.questionType === QUESTION_TYPES.NUMBER;
  const isRangeQuestionType = dataToSubmit.questionType === QUESTION_TYPES.RANGE;
  const isYesNoQuestionType = dataToSubmit.questionType === QUESTION_TYPES.YESNO;
  const isPatient4 = Object.values(dataToSubmit.patient!).some(validatePatient4Regex);
  const isUnitFieldDisabled =
    dataToSubmit.min === undefined ||
    !Number.isInteger(dataToSubmit.min) ||
    dataToSubmit.max === undefined ||
    !Number.isInteger(dataToSubmit.max);

  // if one language is in patient4, send all as patient4
  if (isPatient4) {
    dataToSubmit.patient4 = dataToSubmit.patient;
    delete dataToSubmit.patient;
  } else {
    if (dataToSubmit.patient4) delete dataToSubmit.patient4;
  }

  if (!isSingleOrMultipleQuestionType) {
    delete dataToSubmit.content;
  }

  // question content condition cannot be empty string
  if (dataToSubmit.content) {
    dataToSubmit.content = dataToSubmit.content.map(item => {
      const itemToSubmit = { ...removeUUID(item) };

      // question condition cannot be empty string
      if (itemToSubmit.condition === '') {
        delete itemToSubmit.condition;
      }

      // buildTimeIf cannot be empty string
      if (itemToSubmit.buildTimeIf === '') {
        itemToSubmit.buildTimeIf = null;
      }

      return itemToSubmit;
    });
  }

  if (!isFreetextOrFileQuestionType) {
    delete dataToSubmit.skippable;
  }

  // there's a possibility for unit field being filled even though there's no min/max value
  // and API will return 400 in that case
  if (isUnitFieldDisabled) {
    delete dataToSubmit.unit;
  }

  if (!isYesNoQuestionType) {
    delete dataToSubmit.staffNegative;
    delete dataToSubmit.points;
  }

  if (!isNumberQuestionType && !isRangeQuestionType) {
    delete dataToSubmit.min;
    delete dataToSubmit.max;
    delete dataToSubmit.unit;
    delete dataToSubmit.step;
  }

  if (!isRangeQuestionType) {
    delete dataToSubmit.minLabel;
    delete dataToSubmit.maxLabel;
  }

  if (!isSingleOrMultipleQuestionType) {
    delete dataToSubmit.showNegative;
  }
};
