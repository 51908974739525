import { notification } from 'antd';
import { parse } from 'query-string';
import { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';

import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';

export const useLoginRedirects = () => {
  const { authStore, partnersStore } = useContext(RootStoreContext);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      if (!partnersStore.partnerId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        location.pathname !== '/login/select-partner' && history.push('/login/select-partner');
      } else {
        history.push('/');
      }
    }

    if (location.search) {
      const query = parse(location.search);

      if (query.messageKey) {
        notification.error({
          message: intl.formatMessage({ id: query.messageKey }),
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
          placement: 'top',
        });
      }
    }
  });
};
