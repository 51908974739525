import React, { Fragment } from 'react';

import NorwegianBankIdLogin from '../NorwegianBankIdLogin';

const NorwegianLoginMethods = () => (
  <Fragment>
    <NorwegianBankIdLogin />
  </Fragment>
);

export default NorwegianLoginMethods;
