import { createContext, useContext } from 'react';

import { rootStore } from 'config/appConfig';
import RootStore from 'stores/RootStore';

const RootStoreContext = createContext<RootStore>(rootStore);

export default RootStoreContext;

export const useRootStore = () => useContext(RootStoreContext);
