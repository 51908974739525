import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Button, Select, InputNumber, Switch, Table, Form } from 'antd';
import React, { FunctionComponent, useState, useEffect, useCallback, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { CustomizationRoleValue, Customization, CUSTOMIZATION_TYPES } from 'api/customizationsApi';
import withPractitionerRolesOptions, {
  PractitionerRolesOptionsProps,
} from 'components/HOC/withPractitionerRolesOptions';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { ROLES } from 'constants/roles';
import { InputOption } from 'types/types';
import { createSelectOptions } from 'utils/selectUtils';

import styles from './RolesCustomizationsList.module.css';
import TextlistForm from '../TextlistForm';

interface Props extends PractitionerRolesOptionsProps {
  customization: Customization;
  onChange: (values: CustomizationRoleValue[]) => void;
  isSaving: boolean;
  isDisabled?: boolean;
}

const RolesCustomizationsList: FunctionComponent<Props> = ({
  customization,
  onChange,
  isSaving,
  isDisabled,
  resourceTypesOptions,
  rolesAreLoading,
}) => {
  const isFormDisabled = isSaving || isDisabled;
  const [formValues, setFormValues] = useState<CustomizationRoleValue[]>(customization.roles || []);
  const [availableRoles, setAvailableRoles] = useState<InputOption<ROLES>[]>([]);

  useEffect(() => {
    setAvailableRoles(
      resourceTypesOptions.filter(role => !formValues.find(val => val.role === role.value))
    );
  }, [formValues, resourceTypesOptions]);

  useEffect(() => {
    onChange(formValues);
  }, [formValues, onChange]);

  const handleRemove = useCallback(
    (index: number) => {
      if (isFormDisabled) {
        return;
      }

      setFormValues(formValues.filter((_, i) => i !== index));
    },
    [formValues, isFormDisabled]
  );

  const handleAdd = useCallback(() => {
    if (!availableRoles.length) {
      return;
    }

    setFormValues(
      formValues.concat({
        role: availableRoles[0].value, // use the first available role as the default
        value: customization.value,
      })
    );
  }, [availableRoles, customization.value, formValues]);

  const handleRoleChange = useCallback(
    (option: ROLES, index: number) => {
      const newValues = formValues.map((elem, i) => {
        if (i === index) {
          return {
            ...elem,
            role: option,
          };
        }
        return elem;
      });

      setFormValues(newValues);
    },
    [formValues]
  );

  const handleChange = useCallback(
    (value: any | undefined, index: number) => {
      const newValues = formValues.map((elem, i) => {
        if (i === index) {
          return { ...elem, value };
        }
        return elem;
      });

      setFormValues(newValues);
    },
    [formValues]
  );

  const renderField = useCallback(
    (_: string, field: CustomizationRoleValue, index: number) => {
      switch (customization.type) {
        case CUSTOMIZATION_TYPES.INTEGER:
          return (
            <InputNumber
              value={field.value}
              onChange={data => handleChange(data, index)}
              min={0}
              disabled={isFormDisabled}
              defaultValue={customization.value}
            />
          );
        case CUSTOMIZATION_TYPES.BOOLEAN:
          return (
            <Switch
              checked={field.value}
              onChange={checked => handleChange(checked, index)}
              defaultChecked={!!customization.value}
              disabled={isFormDisabled}
            />
          );
        case CUSTOMIZATION_TYPES.TEXT:
          return (
            <Select
              value={field.value}
              onChange={(option: string) => handleChange(option, index)}
              defaultValue={customization.value}
              disabled={isFormDisabled}
              options={createSelectOptions(customization.allowedValues || ['test'])}
            />
          );
        case CUSTOMIZATION_TYPES.TEXTLIST:
          return (
            <TextlistForm
              value={field.value}
              onChange={data => handleChange(data, index)}
              isSaving={isSaving}
              isDisabled={isFormDisabled}
            />
          );
        default:
          return (
            <Input
              defaultValue={customization.value}
              value={field.value}
              onChange={event => handleChange(event.target.value, index)}
              disabled={isFormDisabled}
            />
          );
      }
    },
    [
      customization.type,
      customization.value,
      customization.allowedValues,
      isSaving,
      isFormDisabled,
      handleChange,
    ]
  );

  const columns = [
    {
      title: <FormattedMessage id="customizations.practitioner-role" />,
      dataIndex: 'role',
      render: (_: string, __: CustomizationRoleValue, index: number) => (
        <Select
          value={formValues[index].role}
          onChange={(option: ROLES) => handleRoleChange(option, index)}
          disabled={isFormDisabled}
          options={availableRoles}
        />
      ),
    },
    {
      title: <FormattedMessage id="general.value" />,
      dataIndex: 'value',
      render: renderField,
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      render: (_: string, __: CustomizationRoleValue, index: number) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleRemove(index)}
          disabled={isFormDisabled}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Table<CustomizationRoleValue>
        dataSource={formValues}
        columns={columns}
        pagination={false}
        rowKey="role"
        className={styles.table}
        tableLayout="auto"
        locale={{ emptyText: <FormattedMessage id="customizations.no-roles-placeholder" /> }}
        loading={
          rolesAreLoading && {
            spinning: true,
            indicator: <LoadingOutlined spin />,
          }
        }
      />
      {!!availableRoles.length && (
        <Form.Item>
          <Button type="link" onClick={handleAdd} disabled={isFormDisabled}>
            <PlusOutlined /> <FormattedMessage id="general.add-more" />
          </Button>
        </Form.Item>
      )}
    </Fragment>
  );
};

export default withPractitionerRolesOptions(RolesCustomizationsList);
