import { SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { Fragment, ReactNode, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './SideMenu.module.css';

export interface SideMenuLink {
  url?: string;
  id: string | number;
  // ReactNode for MenuItem, string (title) for SubMenu
  content?: ReactNode | string;
  testid?: string;
  submenus?: SideMenuLink[];
}

interface Props extends RouteComponentProps {
  links: SideMenuLink[];
  testid?: string;
  title?: ReactNode;
}

interface RenderLink {
  key: string | number;
  className?: string;
  label: ReactNode;
  children?: RenderLink[];
}

const renderLink = (link: SideMenuLink): RenderLink => {
  if (link.submenus && link.content) {
    return {
      key: link.id,
      className: styles.link,
      label: <span data-testid={link.testid}>{link.content}</span>,
      children: link.submenus.map(renderLink),
    };
  }

  return {
    key: link.url || link.id,
    label: (
      <NavLink
        to={link.url || '#'}
        className={styles.link}
        activeClassName={styles.active}
        key={link.id}
        data-testid={link.testid}
      >
        {link.content}
      </NavLink>
    ),
  };
};

const renderLinks = (links: SideMenuLink[], title: ReactNode, activeMenuKey: string) => {
  return (
    <Fragment>
      {title || (
        <div className={styles.title}>
          <SettingOutlined className={styles.titleIcon} />
          &nbsp;
          <span className={styles.titleText}>
            <FormattedMessage id="general.settings" />
          </span>
        </div>
      )}
      <Menu
        className={styles.navigation}
        selectedKeys={[activeMenuKey]}
        mode="inline"
        items={links.length ? links.map(renderLink) : []}
      />
    </Fragment>
  );
};

const SideMenu: FunctionComponent<Props> = ({ links = [], testid, title, location }) => (
  <div className={styles.container} data-testid={testid}>
    {renderLinks(links, title, location.pathname)}
  </div>
);

export default withRouter(SideMenu);
