import { Observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import LanguageSwitcher from 'components/LanguageSwitcher';
import { LANGS } from 'constants/enums';

import styles from './ModalTitleWithLanguageSwitcher.module.css';

interface Props {
  title: ReactNode;
  activeLanguage: LANGS;
  availableLanguages: LANGS[];
  onLanguageChange: (language: LANGS) => any;
}

const ModalTitleWithLanguageSwitcher = ({
  title,
  activeLanguage,
  availableLanguages,
  onLanguageChange,
}: Props) => {
  const { messages } = useIntl();
  return (
    <Observer>
      {() => {
        const originLanguagesOptions = availableLanguages.map(lang => ({
          value: lang,
          label: (messages[`general.language-${lang}`] || lang).toString(),
        }));

        return (
          <div className={styles.header}>
            {title}
            <LanguageSwitcher
              activeLanguage={activeLanguage}
              languages={originLanguagesOptions}
              onChange={onLanguageChange}
            />
          </div>
        );
      }}
    </Observer>
  );
};

export default ModalTitleWithLanguageSwitcher;
