import omit from 'lodash/omit';
import { observable, computed, action, runInAction, IObservableArray } from 'mobx';
import { ChangeEvent } from 'react';

import { ACCESS_LEVEL } from 'api/permissionsApi';
import { RESOURCE_TYPES } from 'constants/permissions';
import RootStore from 'stores/RootStore';

import { createRulesPackage, fetchRulesPackagesList } from '../api/rules';
import {
  RulesPackage,
  RulesPackageMeta,
  RulesPackageMetaDraft,
  RULES_PACKAGE_SOURCES,
} from '../types';

export default class RulesPackagesListStore {
  rulesPackagesList: IObservableArray<RulesPackageMeta> = observable([]);
  @observable searchTerm = '';
  @observable
  isLoadingRulesPackages = false;
  @observable
  isSavingRulesPackage = false;

  constructor(private rootStore: RootStore) {}

  initialize = async () => this.fetchRulesPackages();

  @action
  private async fetchRulesPackages() {
    const {
      partnersStore: { partnerId },
    } = this.rootStore;

    try {
      this.isLoadingRulesPackages = true;

      const { data: rulesPackagesList } = await fetchRulesPackagesList(partnerId);

      runInAction(() => {
        this.rulesPackagesList.replace(rulesPackagesList);
      });
    } finally {
      runInAction(() => {
        this.isLoadingRulesPackages = false;
      });
    }
  }

  @computed
  get rulesPackages(): RulesPackageMeta[] {
    const notSystemPackages = this.rulesPackagesList.filter(
      // for now system packages are coming from BE, but we should show partner specific RulesPackages
      pkg => pkg.source !== RULES_PACKAGE_SOURCES.SYSTEM
    );

    if (!this.searchTerm) {
      return notSystemPackages;
    }

    return notSystemPackages.filter(
      ({ id, title, description }) =>
        id.toLowerCase().includes(this.searchTerm) ||
        title.toLowerCase().includes(this.searchTerm) ||
        description?.toLowerCase().includes(this.searchTerm)
    );
  }

  @computed
  get lowerCasedRulePackageIds(): string[] {
    return this.rulesPackagesList.map(({ id }) => id.toLowerCase());
  }

  @computed
  get lowerCasedRulePackageTitles(): string[] {
    return this.rulesPackagesList.map(({ title }) => title.toLowerCase());
  }

  @action
  onSearchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = e.target.value.toLowerCase();
  };

  /*--------------------------
  ADDING
  --------------------------*/
  @computed
  get canSaveRulesPackage() {
    const {
      partnersStore: { isReadOnlyModeEnabled },
      userPermissionsStore: { getPermission },
    } = this.rootStore;

    return (
      getPermission({
        resourceType: RESOURCE_TYPES.RULES,
        accessLevel: ACCESS_LEVEL.WRITE,
      }) && !isReadOnlyModeEnabled
    );
  }

  @action
  handleCreateRulesPackage = async (payload: RulesPackageMetaDraft, copyOf?: string) => {
    try {
      runInAction(() => {
        this.isSavingRulesPackage = true;
      });

      const {
        partnersStore: { partnerId },
      } = this.rootStore;

      const { id } = payload;
      // BE doesn't allow to create rules packages without partnerId as a prefix for fileName,
      // so we make sure we have it set for user
      const payloadToUse = { ...payload, id: `${partnerId}-${id}` };

      const { data } = await createRulesPackage(partnerId, payloadToUse, copyOf);
      const newRulePackage: RulesPackage = { ...data, rules: observable.array(data.rules) };
      const newRulePackageMeta: RulesPackageMeta = omit(data, 'rules');

      runInAction(() => {
        this.rulesPackagesList.push(newRulePackageMeta);
      });

      this.rootStore.rulesPackageStore.setNewlyCreatedRulesPackage(newRulePackage);

      return data.id;
    } finally {
      runInAction(() => {
        this.isSavingRulesPackage = false;
      });
    }
  };
}
