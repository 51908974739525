import axios from 'axios';

import { PARTNERS_URI } from 'api/partnersApi';

import { EXIT_ATTRIBUTES } from '../Condition/constants/code24types';
import { REPORT_STATUSES } from '../constants/reports';

export interface Report {
  reportId: string;
  createdAt: string;
  status: REPORT_STATUSES;
}

export interface ConditionValidationError {
  modelId: string;
  message: string;
}

export interface ConditionValidationReport {
  conditionId: string;
  errors: ConditionValidationError[];
}

export interface Statistic {
  source?: string;
  exitGroup?: string;
  count?: number;
  condition?: string;
  id?: string;
  [EXIT_ATTRIBUTES.TYPE_OF_CONSULTATION]?: string[];
  [EXIT_ATTRIBUTES.CAPABILITY]?: string[];
  [EXIT_ATTRIBUTES.URGENCY]?: string;
  [EXIT_ATTRIBUTES.RESOURCE]?: string;
  [EXIT_ATTRIBUTES.APPOINTMENT_PRIORITY]?: number;
  [EXIT_ATTRIBUTES.LEVEL_OF_CARE]?: string;
  [EXIT_ATTRIBUTES.URL]?: string;
}

// Please note: API still requires originId, but Content24 is now used in Partner scope. Using partnerId for both params is
// a "hacky" way to handle that.

export const fetchStatisticsReports = (partnerId: string) =>
  axios.get<Report[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/exits/queue`
  );

export const fetchStatistics = (partnerId: string, reportId: string) =>
  axios.get<Statistic[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/exits/${reportId}`
  );

export const createStatisticsReport = (partnerId: string) =>
  axios.post<Report>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/exits/queue`
  );

export const fetchLastCompletedValidationReport = (partnerId: string, reportId: string) =>
  axios.get<ConditionValidationReport[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/validation/${reportId}`
  );

export const fetchValidationReports = (partnerId: string) =>
  axios.get<Report[]>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/validation/queue`
  );

export const createValidationReport = (partnerId: string) =>
  axios.post<Report>(
    `${PARTNERS_URI}/${partnerId}/origins/${partnerId}/code24/reports/validation/queue`
  );
