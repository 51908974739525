import { eachDayOfInterval, endOfWeek, format as formatFns, parse, startOfWeek } from 'date-fns';
import { zipObject } from 'lodash';

const now = new Date();

export const TIME_FORMAT_LONG = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const DATE_DISPLAY_FORMAT = 'dd/MM/yy';
export const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

const weekDayNames = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const;

export function getWeekDays() {
  return eachDayOfInterval({
    start: startOfWeek(now),
    end: endOfWeek(now),
  }).map(d => formatFns(d, 'EEEE'));
}

export function getWeekDayName(day: string) {
  const weekDaysMap = zipObject(weekDayNames, getWeekDays());
  return weekDaysMap[day];
}

export function hourToDate(hour: string | null, format = TIME_FORMAT_LONG): Date | null {
  return hour ? parse(hour, format, new Date()) : null;
}

export function dateToHour(date: Date | null, format = TIME_FORMAT_SHORT): string | null {
  return date ? formatFns(date, format) : date;
}

export function longHourToShort(hour: string | null): string | null {
  return dateToHour(hourToDate(hour));
}

export function longDateToShort(date: string | null): string {
  return date ? formatFns(parse(date, DATE_INPUT_FORMAT, new Date()), DATE_DISPLAY_FORMAT) : '';
}
