import { action, observable, runInAction, IObservableArray } from 'mobx';

import { intlNotification } from 'state/notification';
import RootStore from 'stores/RootStore';

import { TextContent, fetchTextContent, updateTextContent } from '../api/textContentApi';

class TextContentStore {
  textContent: IObservableArray<TextContent> = observable.array([]);
  @observable isLoading = false;
  @observable private searchPhrase = '';
  @observable activeTextContent?: TextContent;

  constructor(
    private rootStore: RootStore,
    private originId: string
  ) {}

  @action
  fetchTextContent = async () => {
    if (this.searchPhrase === '') {
      return;
    }

    try {
      this.isLoading = true;
      const { data } = await fetchTextContent(
        this.rootStore.partnersStore.partnerId,
        this.originId,
        this.searchPhrase
      );

      runInAction(() => {
        this.textContent.replace(data);
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleSearch = (searchPhrase: string) => {
    this.searchPhrase = searchPhrase;
    return this.fetchTextContent();
  };

  @action
  handleEdit = (key: string) => {
    this.activeTextContent = this.textContent.find(textContent => textContent.key === key);
  };

  @action
  handleCancel = () => {
    this.activeTextContent = undefined;
  };

  @action
  handleSubmit = async (editedTextContent: TextContent) => {
    try {
      this.isLoading = true;
      const { data } = await updateTextContent(
        this.rootStore.partnersStore.partnerId,
        this.originId,
        {
          ...editedTextContent,
          translations: { ...this.filterAllowedTranslations(editedTextContent) },
        }
      );
      runInAction(() => {
        this.activeTextContent = undefined;
        this.textContent.replace(
          this.textContent.map(item => (item.key === data.key ? data : item))
        );
      });

      intlNotification.success(
        {
          frmMessage: {
            id: 'origin.text-content.translation-updated',
          },
        },
        {
          placement: 'top',
        }
      );
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  filterAllowedTranslations = (editedTextContent: TextContent) => {
    if (!this.activeTextContent) {
      return {};
    }

    const updatedTranslations = {};

    Object.keys(this.activeTextContent.translations).forEach(lang => {
      const originalTranslation = this.activeTextContent?.translations[lang];
      const editedTranslation = editedTextContent.translations[lang];

      // include only updated translations...
      if (originalTranslation !== editedTranslation) {
        updatedTranslations[lang] = editedTranslation;
      }
      // ...and those previously modified in the same origin
      else if (
        this.activeTextContent?.source[lang] === this.rootStore.originStore.currentOrigin?.id
      ) {
        updatedTranslations[lang] = originalTranslation;
      }
    });

    return updatedTranslations;
  };
}

export default TextContentStore;
