import Axios from 'axios';

export type PatientData = {
  givenName: string;
  surname: string;
  username: string;
};

export const fetchPatientData = (partnerId: string, userId: string) =>
  Axios.get<PatientData>(`/rest/admin/partners/${partnerId}/users/${userId}`);
