import { Typography } from 'antd';
import React, { ReactNode, FunctionComponent } from 'react';

import Breadcrumbs, { AppBreadcrumbItem } from 'components/Breadcrumbs';

import styles from './PageWithSectionsHeader.module.css';

interface Props {
  breadcrumbs?: AppBreadcrumbItem[];
  title: ReactNode;
}

const PageWithSectionsHeader: FunctionComponent<Props> = ({ breadcrumbs, title }) => {
  return (
    <div className={styles.wrapper}>
      <Typography.Title level={2}>{title}</Typography.Title>
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
    </div>
  );
};

export default PageWithSectionsHeader;
