export const TEST_IDS = {
  SUBMIT_BTN: 'submit-btn',
  CANCEL_BTN: 'cancel-btn',
  ADD_BTN: 'add-btn',
  EXPAND_BTN: 'expand-btn',
  EDIT_BTN: 'edit-btn',
  COPY_BTN: 'copy-btn',
  DELETE_BTN: 'delete-btn',
  TIME_PICKER_INPUT: 'time-picker-input',
  INPUT_ERROR: 'input-error',
};

export const SELECT_IDS = {
  WRAPPER: 'select-wrapper',
  SEARCH: 'select-search-input',
  CLEAR_BTN: 'clear-btn',
};

export const ORIGIN_IDS = {
  SETTINGS_MENU: 'settings-menu',
};
