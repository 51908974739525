import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { CreateAnnouncementFormFields } from './types';

export const useCreateAnnouncementForm = () => {
  const intl = useIntl();
  const requiredFieldMsg = intl.formatMessage({ id: 'Required field' });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required(requiredFieldMsg),
        text: Yup.string().required(requiredFieldMsg),
      }),
    [requiredFieldMsg]
  );

  return useFormik<CreateAnnouncementFormFields>({
    initialValues: {
      title: '',
      text: '',
    },
    onSubmit: async () => null,
    validateOnBlur: true,
    validationSchema,
  });
};
