import { Modal, Row, Col, Divider, Typography, Form as AntdForm } from 'antd';
import { Formik } from 'formik';
import { Select, Form, InputNumber, Input } from 'formik-antd';
import invert from 'lodash/invert';
import transform from 'lodash/transform';
import React, { FunctionComponent, useCallback, Fragment, useContext, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import { ORIGIN_RULES_CATEGORIES, ORIGIN_RULES_CATEGORY_PRIORITY } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import { InputOption } from 'types/types';

import styles from './EditRule.module.css';
import {
  OriginRule,
  OriginRuleConditionOperators,
  OriginRuleOutcomeOperators,
} from '../../api/rulesApi';
import RuleConditions from '../RuleConditions';
import RuleOutcome from '../RuleOutcome';

const { Item: FormItem } = AntdForm;

enum NegatedOriginRuleConditionOperators {
  NotContainsAny = 'NotContainsAny',
  NotContains = 'NotContains',
  NotEquals = 'NotEquals',
  NotGreaterThanOrEqual = 'NotGreaterThanOrEqual',
  NotIn = 'NotIn',
  NotIsEmpty = 'NotIsEmpty',
  NotIsNull = 'NotIsNull',
  NotLessThanOrEqual = 'NotLessThanOrEqual',
}

const NegatedOriginRuleConditionOperatorsMap = {
  [OriginRuleConditionOperators.ContainsAny]: NegatedOriginRuleConditionOperators.NotContainsAny,
  [OriginRuleConditionOperators.Contains]: NegatedOriginRuleConditionOperators.NotContains,
  [OriginRuleConditionOperators.Equals]: NegatedOriginRuleConditionOperators.NotEquals,
  [OriginRuleConditionOperators.GreaterThanOrEqual]:
    NegatedOriginRuleConditionOperators.NotGreaterThanOrEqual,
  [OriginRuleConditionOperators.In]: NegatedOriginRuleConditionOperators.NotIn,
  [OriginRuleConditionOperators.IsEmpty]: NegatedOriginRuleConditionOperators.NotIsEmpty,
  [OriginRuleConditionOperators.IsNull]: NegatedOriginRuleConditionOperators.NotIsNull,
  [OriginRuleConditionOperators.LessThanOrEqual]:
    NegatedOriginRuleConditionOperators.NotLessThanOrEqual,
};

type ExtendedOriginRuleConditionOperators =
  | OriginRuleConditionOperators
  | NegatedOriginRuleConditionOperators;

interface Props extends WrappedComponentProps {
  initialValues?: OriginRule;
  isSaving: boolean;
  isDisabled: boolean;
  onCancel: () => void;
  onSubmit: (data: OriginRule) => void;
}

interface FormValue extends Omit<OriginRule, 'conditional' | 'outcome'> {
  conditional: {
    field: string;
    operator: ExtendedOriginRuleConditionOperators;
    value: any;
  }[];
  outcome: {
    field: string;
    operator: OriginRuleOutcomeOperators;
    value: any;
    method?: string;
    methodProperties?: string;
  }[];
}

const EditRule: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  initialValues,
  onSubmit,
  isDisabled,
  intl,
}) => {
  const { originStore } = useContext(RootStoreContext);

  const [activeLanguage, setLanguage] = useState<LANGS>(
    originStore.currentOrigin?.defaultLanguage || (intl.locale as LANGS)
  );
  const validationSchema = Yup.object().shape({
    id: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
    category: Yup.string().nullable(),
    priority: Yup.number().required(intl.formatMessage({ id: 'general.errors.required' })),
    description: Yup.string().nullable(),
    conditional: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
        operator: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
        value: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
      })
    ),
    outcome: Yup.array()
      .of(
        Yup.object().shape({
          field: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
          operator: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
          value: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
          method: Yup.string().nullable(),
          methodProperties: Yup.string().nullable(),
        })
      )
      .required(intl.formatMessage({ id: 'general.errors.required' })),
  });

  const handleSubmit = useCallback(
    (data: FormValue) => {
      onSubmit({
        ...data,
        // We need to convert back conditions from our ExtendedOriginRuleConditionOperators to
        // OriginRuleConditionOperators API format with boolean negation...
        conditional: data.conditional.map(condition => ({
          ...condition,
          operator:
            condition.operator in NegatedOriginRuleConditionOperators
              ? (
                  invert(NegatedOriginRuleConditionOperatorsMap) as {
                    [key in NegatedOriginRuleConditionOperators]: OriginRuleConditionOperators;
                  }
                )[condition.operator]
              : condition.operator,
          not: condition.operator in NegatedOriginRuleConditionOperators,
        })),
        // ...and convert methodProperties back to object
        outcome: data.outcome.map(outcome => ({
          ...outcome,
          methodProperties: outcome.methodProperties
            ? JSON.parse(outcome.methodProperties)
            : undefined,
        })),
      });
    },
    [onSubmit]
  );

  if (!initialValues) {
    return null;
  }

  const isEditMode = !!initialValues.id;
  const formattedInitialValues: FormValue = {
    ...initialValues,
    // We need to convert conditions from OriginRuleConditionOperators API format with boolean negation
    // to our ExtendedOriginRuleConditionOperators values...
    conditional: initialValues.conditional.map(condition => ({
      ...condition,
      operator: condition.not
        ? NegatedOriginRuleConditionOperatorsMap[condition.operator]
        : condition.operator,
    })),
    // ...and convert methodProperties to string
    outcome: initialValues.outcome.map(outcome => ({
      ...outcome,
      methodProperties: outcome.methodProperties
        ? JSON.stringify(outcome.methodProperties)
        : undefined,
    })),
  };

  return (
    <Modal
      open={!!initialValues}
      destroyOnClose
      title={
        <ModalTitleWithLanguageSwitcher
          title={
            <Fragment>
              <FormattedMessage id="origin.rules.rule" /> {formattedInitialValues.category}
            </Fragment>
          }
          activeLanguage={activeLanguage}
          onLanguageChange={setLanguage}
          availableLanguages={originStore.availableOriginLanguages}
        />
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={formattedInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ isValid, values, setFieldValue, dirty }) => (
          <Form layout="vertical" labelAlign="left">
            <Row gutter={16}>
              <Col span={8}>
                <FormItem label={<FormattedMessage id="origin.rules.id" />} required={isEditMode}>
                  <Input name="id" disabled={isSaving || isDisabled || isEditMode} />
                </FormItem>
              </Col>
              <Col span={8}>
                <Form.Item name="category" label={<FormattedMessage id="origin.rules.category" />}>
                  <Select
                    name="category"
                    onChange={value => {
                      setFieldValue(
                        'priority',
                        // Unfortunately formik-antd's version of Select does not allow us to pass
                        // SelectValue type
                        ORIGIN_RULES_CATEGORY_PRIORITY[ORIGIN_RULES_CATEGORIES[value as string]]
                      );
                    }}
                    disabled={isSaving || isDisabled}
                    options={transform<ORIGIN_RULES_CATEGORIES, InputOption[]>(
                      ORIGIN_RULES_CATEGORIES,
                      (accumulator, value, key) => accumulator.push({ label: value, value: key }),
                      []
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="priority"
                  label={<FormattedMessage id="origin.rules.priority" />}
                  required
                  hasFeedback
                >
                  <InputNumber
                    name="priority"
                    className={styles.inputNumber}
                    disabled={isSaving || isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="description"
              label={<FormattedMessage id="origin.rules.description" />}
            >
              <Input.TextArea name="description" rows={3} disabled={isSaving || isDisabled} />
            </Form.Item>
            <Typography.Title level={4}>
              <FormattedMessage id="origin.rules.condition" />
            </Typography.Title>
            <RuleConditions values={values} isDisabled={isSaving || isDisabled} />
            <Divider />
            <Typography.Title level={4}>
              *<FormattedMessage id="origin.rules.outcome" />
            </Typography.Title>
            <RuleOutcome
              activeLanguage={activeLanguage}
              values={values}
              isDisabled={isSaving || isDisabled}
            />
            <FormActionButtons
              isSaving={isSaving}
              isValid={dirty && isValid}
              isDisabled={isDisabled}
              onCancel={onCancel}
              showCancelConfirm={dirty}
            />
          </Form>
        )}
      />
    </Modal>
  );
};

export default injectIntl(EditRule);
