import { observable, action } from 'mobx';

import { State } from 'constants/enums';

/**
 * @deprecated This should not be used anymore, it's an old and very limited way of handling API interaction state
 */

export default class StateManager {
  @observable state = State.UNLOADED;

  isLoading() {
    return this.state === State.LOADING;
  }

  isLoaded() {
    return this.state === State.LOADED;
  }

  isError() {
    return this.state === State.ERROR;
  }

  isSaving() {
    return this.state === State.SAVING;
  }

  @action
  setLoading() {
    this.state = State.LOADING;
  }

  @action
  setLoaded() {
    this.state = State.LOADED;
  }

  @action
  setError() {
    this.state = State.ERROR;
  }

  @action
  setSaving() {
    this.state = State.SAVING;
  }

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };
}
