import sortBy from 'lodash/sortBy';
import { observable, computed, IObservableArray, runInAction } from 'mobx';

import {
  getAllCareUnits,
  CareUnit,
  fetchCareUnits,
  fetchCareProvider,
  CareProvider,
} from 'api/careUnitsApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export default class CareUnitsStore extends StateManager {
  @observable
  allCareUnits: IObservableArray<CareUnit> = observable.array([]);

  @observable
  currentCareUnits: IObservableArray<CareUnit> = observable.array([]);

  @observable
  currentCareProvider?: CareProvider;

  constructor(private rootStore: RootStore) {
    super();
  }

  fetchAllCareUnits = async () => {
    this.setLoading();

    try {
      const { data } = await getAllCareUnits();

      runInAction(() => {
        this.allCareUnits.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchCareUnits = async (careProviderId: string) => {
    this.setLoading();

    try {
      const { data } = await fetchCareUnits(this.rootStore.partnersStore.partnerId, careProviderId);

      runInAction(() => {
        this.currentCareUnits.replace(data);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchCareProvider = async (careProviderId: string) => {
    this.setLoading();

    try {
      const { data } = await fetchCareProvider(
        this.rootStore.partnersStore.partnerId,
        careProviderId
      );

      runInAction(() => {
        this.currentCareProvider = data;
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @computed
  get allCareUnitsAsSelectOptions() {
    return sortBy(
      this.allCareUnits.map(careUnit => ({
        value: careUnit.id,
        label: careUnit.name || '',
      })),
      ['label']
    );
  }

  getCareUnitByDefaultOriginId = (id: string) =>
    this.allCareUnits.find(unit => unit.defaultOriginId === id);

  getCareUnitLabel = (id: string) => {
    const foundCareUnit = this.rootStore.userDataStore.userCareUnits.find(
      careUnit => careUnit.id === id
    );
    return foundCareUnit ? foundCareUnit.name : '';
  };

  getCareUnitLabelFromAllCareUnits = (id: string) => {
    const foundCareUnit = this.allCareUnits.find(careUnit => careUnit.id === id);
    return foundCareUnit ? foundCareUnit.name : '';
  };
}
