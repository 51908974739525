import { Button, List } from 'antd';
import { FunctionComponent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';
import { MergeRequest } from 'modules/PartnerStatus/api/partnerStatusApi';
import { useLongDateTimeFormat } from 'utils/useDateFormat';

import styles from './LogHistory.module.css';
import PartnerStatusTag from '../PartnerStatusTag';

interface Props {
  onLogItemDetailsClick: (mergeRequest: MergeRequest) => void;
}

const LogHistory: FunctionComponent<Props> = ({ onLogItemDetailsClick }) => {
  const { mergeRequestStore } = useContext(RootStoreContext);

  const format = useLongDateTimeFormat();

  return (
    <List
      loading={mergeRequestStore.isLoading}
      dataSource={mergeRequestStore.logHistory}
      renderItem={mergeRequest => (
        <List.Item
          className={styles.item}
          actions={[
            <Button
              type="link"
              onClick={() => onLogItemDetailsClick(mergeRequest)}
              key={mergeRequest.id}
            >
              <FormattedMessage id="partner-status.show-details" />
            </Button>,
          ]}
        >
          <div>
            <p>
              <span>{format.format(new Date(mergeRequest.when))}</span>
              <PartnerStatusTag status={mergeRequest.status} />
            </p>
            <p>{mergeRequest.description}</p>
          </div>
        </List.Item>
      )}
    />
  );
};

export default LogHistory;
