import { InputProps } from 'antd/lib/input';
import { FormikFieldProps } from 'formik-antd/lib/FieldProps';
import { IObservableArray } from 'mobx';
import React from 'react';

import { InputOption, Primitive } from 'types/types';

export enum RULES_OUTCOME_OPERATOR {
  SET = 'SET',
  CLEAR = 'CLEAR',
  APPEND = 'APPEND',
  REMOVE = 'REMOVE',
}

export type Outcome<T = any> = {
  field: string | null;
  // operator === null when user adds brand new outcome in form
  // or form field is 'reseted'
  operator: RULES_OUTCOME_OPERATOR | null;
  value: T | { key: string; value: T }[] | null;
};

export type RuleDraft = {
  id: string;
  description?: string;
  when: string | null;
  outcomes: Outcome[];
};

export type Rule = RuleDraft & {
  index: number;
};

export interface RulesPackageMetaDraft {
  id: string;
  title: string;
  description?: string;
}

export interface RulesPackageMeta extends RulesPackageMetaDraft {
  source: RULES_PACKAGE_SOURCES.SYSTEM | string;
}

export interface RulesPackage extends RulesPackageMeta {
  rules: IObservableArray<Rule>;
}

export enum RULES_PACKAGE_SOURCES {
  SYSTEM = 'system',
}

export enum RULES_ATTRIBUTE_TYPE {
  BOOL = 'BOOL',
  DATE = 'DATE',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  STRING = 'STRING',
  TIME = 'TIME',
  SET = 'SET',
  LIST = 'LIST',
  MAP = 'MAP',
}
export const INPUTS_TYPES_MAP = {
  DATE: { type: 'date' },
  DOUBLE: { type: 'number', step: '0.1' },
  LONG: {
    type: 'number',
    step: '1',
    onKeyDown: (e: React.KeyboardEvent) => {
      if (e.key === '.' || e.key === ',') {
        e.preventDefault();
      }
    },
  },
  STRING: { type: 'string' },
  TIME: { type: 'time' },
};

export enum RULES_ATTRIBUTE_PRIMITIVE_TYPE {
  BOOL = 'BOOL',
  DATE = 'DATE',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  STRING = 'STRING',
  TIME = 'TIME',
}

type RuleAttributeConstraints = {
  allowedValues?: string[];
  min?: number;
  max?: number;
};

type BaseRulesAttributeType<T> = {
  allowedOperators: RULES_OUTCOME_OPERATOR[];
  baseType: T;
  constraints: RuleAttributeConstraints;
  name: string;
};

export type AttributeMapValueType = Record<string, Primitive>[];
export type AttributeListValueType = Primitive[];
export type AttributeSimpleValueType = Primitive;
export type AttributeDefaultValue =
  | AttributeMapValueType
  | AttributeListValueType
  | AttributeSimpleValueType;

export interface RulesAttribute<T = RULES_ATTRIBUTE_TYPE> {
  id: string;
  defaultValue?: AttributeDefaultValue;
  type: BaseRulesAttributeType<T> & {
    // means this is custom attribute type
    valueType?: BaseRulesAttributeType<T>;
  };
  description?: string;
  constraints?: RuleAttributeConstraints;
  immutable: boolean;
  mandatory: boolean;
}
export function isSet(
  outcomeMeta: RulesAttribute | null
): outcomeMeta is RulesAttribute<RULES_ATTRIBUTE_TYPE.SET> {
  if (!outcomeMeta) {
    return false;
  }
  return outcomeMeta.type.baseType === RULES_ATTRIBUTE_TYPE.SET;
}

export function isMap(
  outcomeMeta: RulesAttribute | null
): outcomeMeta is RulesAttribute<RULES_ATTRIBUTE_TYPE.MAP> {
  if (!outcomeMeta) {
    return false;
  }
  return outcomeMeta.type.baseType === RULES_ATTRIBUTE_TYPE.MAP;
}
export function isBoolean(
  outcomeMeta: RulesAttribute | null
): outcomeMeta is RulesAttribute<RULES_ATTRIBUTE_TYPE.BOOL> {
  if (!outcomeMeta) {
    return false;
  }
  return outcomeMeta.type.baseType === RULES_ATTRIBUTE_TYPE.BOOL;
}

export function isList(
  outcomeMeta: RulesAttribute | null
): outcomeMeta is RulesAttribute<RULES_ATTRIBUTE_TYPE.LIST> {
  if (!outcomeMeta) {
    return false;
  }
  return outcomeMeta.type.baseType === RULES_ATTRIBUTE_TYPE.LIST;
}

export function isCustomValueType(outcomeMeta: RulesAttribute | null): boolean {
  if (!outcomeMeta) {
    return false;
  }
  return Boolean(outcomeMeta.type.valueType);
}

export function isPrimitive(
  outcomeMeta: RulesAttribute | null
): outcomeMeta is RulesAttribute<
  | RULES_ATTRIBUTE_TYPE.BOOL
  | RULES_ATTRIBUTE_TYPE.DATE
  | RULES_ATTRIBUTE_TYPE.DOUBLE
  | RULES_ATTRIBUTE_TYPE.LONG
  | RULES_ATTRIBUTE_TYPE.STRING
  | RULES_ATTRIBUTE_TYPE.TIME
> {
  if (!outcomeMeta) {
    return false;
  }
  return Boolean(outcomeMeta.type.baseType in RULES_ATTRIBUTE_PRIMITIVE_TYPE);
}

export const isObject = (input: any): input is Record<string, any> => {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
};

export const isKeyValueLikeData = (input: unknown): input is { key: string; value: unknown } => {
  return isObject(input) && 'key' in input && 'value' in input;
};

export interface ExpressionValidationResult {
  valid: boolean;
  message?: string;
}

export type CustomTagInputValueType = string[] | null;
export type CustomTagInputOnBlur = (event: React.FocusEvent<InputProps>) => void;
export type CustomTagInputOnAdd = (item: string) => void;
export type CustomTagInputOnRemove = (idx: number) => void;
export type CustomTagInputOnChange = (value: CustomTagInputValueType) => void;

export type CustomTagInputProps = {
  onBlur: CustomTagInputOnBlur;
  onAdd: CustomTagInputOnAdd;
  onRemove: CustomTagInputOnRemove;
  value?: CustomTagInputValueType;
  allowedValues?: InputOption<any, string>[];
  isDisabled: boolean;
  outcomeMeta: RulesAttribute | null;
};

export type ConnectedCustomTagInputProps = FormikFieldProps & {
  onBlur?: CustomTagInputOnBlur;
  onChange?: CustomTagInputOnChange;
  isDisabled: boolean;
  allowedValues: InputOption<any, string>[];
  outcomeMeta: RulesAttribute | null;
};

export enum SEND_RULES_DATA_MODE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
