import sortBy from 'lodash/sortBy';

import { LANGS } from 'constants/enums';
import { InputOption } from 'types/types';
import { sortWithLocale } from 'utils/textUtils';

import { ConditionGroup } from '../Condition/api/code24api';
import { Condition, ConditionInfo } from '../Condition/api/partnerCode24api';
import {
  CODE24_CATEGORIES,
  CODE24_MODEL_TYPES,
  DISABLED_CONDITIONS,
  DISABLED_STATEMENT_GROUPS,
} from '../Condition/constants/code24types';
import { Section } from '../Condition/models/Code24Model';

export function filterConditionsWithCategoryGroup<T extends ConditionInfo>(
  conditions: T[],
  categoryGroups: string[]
): typeof conditions {
  return conditions.filter(
    ({ group }) =>
      // Conditions that have no group fall under all categories
      group === undefined || categoryGroups.includes(group)
  );
}

export function filterNotDisabledConditionsWithoutCategoryGroup<T extends ConditionInfo>(
  conditions: T[],
  categoryGroups: string[]
) {
  return conditions.filter(
    ({ conditionId, group }) =>
      // Conditions that have no group fall under all categories
      !DISABLED_CONDITIONS.includes(conditionId) &&
      (group === undefined || !categoryGroups.includes(group))
  );
}

export function getConditionGroupsFilteredByCategoryAsSelectOptions(
  conditionGroups: ConditionGroup[],
  category: CODE24_CATEGORIES,
  locale: LANGS
) {
  return conditionGroups
    .filter(({ category: groupCategory }) => groupCategory === category)
    .map(({ label, id }) => ({
      label,
      value: id,
      disabled: DISABLED_STATEMENT_GROUPS.includes(id),
    }))
    .sort((a, b) => sortWithLocale(a.label, b.label, locale, locale));
}

export function getQuestionsFromConditionAsSelectOptions(condition: Condition) {
  return sortBy(
    (condition?.sections || []).reduce((questions: InputOption[], current: Section) => {
      return questions.concat(
        current.content.reduce((statements: InputOption[], statement) => {
          if (statement.type === CODE24_MODEL_TYPES.QUESTION) {
            statements.push({
              label: statement.questionId,
              value: statement.questionId,
            });
          }

          return statements;
        }, [])
      );
    }, []),
    'label'
  );
}

export function getConditionsFilteredByGroupAsSelectOptions(
  conditions: ConditionInfo[],
  groupToFilterBy: string
) {
  return sortBy(
    conditions
      .filter(
        ({ group, conditionId }) =>
          group === groupToFilterBy && !DISABLED_CONDITIONS.includes(conditionId)
      )
      .map(condition => ({
        label: condition.description,
        value: condition.conditionId,
      })),
    'label'
  );
}
