import DOMPurify from 'dompurify';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

import { marked } from './marked';

export function htmlToDraft(html: string) {
  return convertToRaw(
    convertFromHTML({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      htmlToEntity: (nodeName: string, node: HTMLElement, createEntity: Function) => {
        if (nodeName === 'a') {
          return createEntity('LINK', 'MUTABLE', { url: (node as HTMLAnchorElement).href });
        }

        if (nodeName === 'img') {
          return createEntity('IMAGE', 'IMMUTABLE', {
            src: (node as HTMLImageElement).attributes.getNamedItem('src')?.value || '',
            alt: (node as HTMLImageElement).attributes.getNamedItem('alt')?.value || '',
            title: (node as HTMLImageElement).attributes.getNamedItem('title')?.value || '',
          });
        }

        return null;
      },
      htmlToBlock: (nodeName: string) => {
        if (nodeName === 'img') {
          return 'atomic';
        }

        return '';
      },
    })(html)
  );
}

export function draftToHtml(raw: RawDraftContentState) {
  return raw
    ? convertToHTML({
        blockToHTML: block => {
          if (block.type === 'atomic') {
            return {
              start: '',
              end: '',
            };
          }

          return null;
        },

        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return <a href={entity.data.url}>{originalText}</a>;
          }

          if (entity.type === 'IMAGE') {
            return <img src={entity.data.src} alt={entity.data.alt} />;
          }

          return originalText;
        },
      })(convertFromRaw(raw))
    : '';
}

export const convertMdToDraft = (md: string) => {
  const html = DOMPurify.sanitize(marked.parse(md, { async: false }));

  return htmlToDraft(html);
};

export const convertDraftToMd = (raw: RawDraftContentState) =>
  markdownImageEntityContentReplacer(
    draftToMarkdown(raw, {
      entityItems: {
        IMAGE: {
          // Should be Entity type, but for some reason it lacks data property definition.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          open: (block?: any) => {
            const alt = block?.data?.alt || '';
            const title = block?.text ? ` "${block.text}"` : '';

            return `![${alt}](${block.data.src}${title})`;
          },
          close: () => '',
        },
      },
    })
  );

/**
 * Removes additional content (unicode camera emoji) added by
 * customRawContentStateConverterImageEntityFix
 *
 * @param markdown Markdown formatted text
 * @returns Markdown formatted text
 */
const markdownImageEntityContentReplacer = (markdown: string) => {
  return markdown.replace(/(!\[[^\]]*\]\(.*?\s*(?:"(?:.*[^"])")?\s*\))(📷)/g, '$1');
};

export interface EntityPlacementData {
  blockKey: string;
  anchor: number;
  focus: number;
}

/**
 * Returns set of Entity "coordinates" inside a block.
 *
 * @param entityKey entity key
 * @param editorState EditorState
 * @returns EntityPlacementData
 */
export function getEntityPlacementDataByKey(
  entityKey: string,
  editorState: EditorState
): EntityPlacementData | undefined {
  const content = editorState.getCurrentContent();
  const blocks = content.getBlocksAsArray();
  let entityPlacementData: EntityPlacementData | undefined = undefined;

  for (let i = 0; i < blocks.length; i += 1) {
    const block = blocks[i];
    block.findEntityRanges(
      character => {
        if (character.getEntity() === entityKey) {
          entityPlacementData = {
            blockKey: block.getKey(),
            anchor: 0,
            focus: 0,
          };
          return true;
        }
        return false;
      },
      (anchor, focus) => {
        if (entityPlacementData) {
          entityPlacementData = { ...entityPlacementData, anchor, focus };
        }
      }
    );
    if (entityPlacementData) {
      break;
    }
  }

  return entityPlacementData;
}

/**
 * Strips file name from extension.
 * Unfortunately API sends file name with extension,
 * while what we need most is image id that allows us
 * to fetch the image data. This id is simply file name
 * without extension.
 *
 * @param fileName string
 * @returns string
 */
export function getImageIdFromFileName(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export const BLOB_URL_REGEXP = /!\[.*?]\((blob:[^\s)]+)\)/m;

/**
 * Checks if any of text contents contains blob urls.
 *
 * @param texts Texts
 * @returns boolean
 */
export function textsHaveBlobUrls(texts: { [key: string]: string }) {
  return Object.values(texts).some(textContent => BLOB_URL_REGEXP.test(textContent));
}
