import axios from 'axios';

import { ACCESS_SCOPE_TYPES } from 'constants/roles';

export enum ACCESS_LEVEL {
  READ = 'R',
  WRITE = 'CDRU',
}

export interface AccessScopeRole {
  name: string;
}

export const USER_PERMISSIONS_ENDPOINT = '/rest/admin/permissions';

export interface Scope {
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  scopeName?: string;
  roles?: AccessScopeRole[];
  'data-testid'?: string;
}

export interface AccessModes {
  path: string;
  permissionString: ACCESS_LEVEL;
  extendable?: boolean;
}

export interface CareProvidersScope {
  scopeType: ACCESS_SCOPE_TYPES;
  scopeValue: string;
  scopeName: string;
  careUnits: Scope[];
}

export interface ScopedRoles {
  partnerScope: Scope;
  scopes: {
    origins?: Scope[];
    careProviders?: CareProvidersScope[];
  };
}

export interface ScopesGroupedByPartner {
  [ACCESS_SCOPE_TYPES.PARTNER]: ScopedRoles[];
  [ACCESS_SCOPE_TYPES.ORIGIN]: ScopedRoles[];
  [ACCESS_SCOPE_TYPES.CARE_UNIT]: ScopedRoles[];
}

export const fetchMyUserPermissions = () =>
  axios.get<AccessModes[]>(`${USER_PERMISSIONS_ENDPOINT}/me/access`, {
    ignoreErrorStatuses: [404],
  });

export const fetchMyAccessScopedRoles = () =>
  axios.get<ScopedRoles[]>(`${USER_PERMISSIONS_ENDPOINT}/me/access/scoped-roles`);

export const fetchUserPermissions = (practitionerId: string) =>
  axios.get<AccessModes[]>(`${USER_PERMISSIONS_ENDPOINT}/${practitionerId}/access`);

export const fetchUserScopedRoles = (practitionerId: string) =>
  axios.get<ScopedRoles[]>(`${USER_PERMISSIONS_ENDPOINT}/${practitionerId}/access/scoped-roles`);

export const saveUserScopedRoles = (practitionerId: string, data: ScopedRoles[]) =>
  axios.post<ScopedRoles[]>(
    `${USER_PERMISSIONS_ENDPOINT}/${practitionerId}/access/scoped-roles`,
    data
  );
