import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { styled } from 'styled-components';

import { Image } from 'components/Image';

export const Styled = {
  ThumbnailImageFrame: styled.div`
    height: 166px;
    width: 84px;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
  `,

  ThumbnailImageFrameOutlined: styled.div`
    border: 1px dashed #bdbdbd;
    background-color: #f6f6f6;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  `,

  ThumbnailImage: styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
};

export const ThumbnailImageFrame = ({
  src,
  loading,
}: {
  src?: string | null;
  loading?: boolean;
}) => (
  <Styled.ThumbnailImageFrame>
    {src ? (
      <Styled.ThumbnailImage alt="Thumbnail" src={src} />
    ) : (
      <Styled.ThumbnailImageFrameOutlined>
        {loading ? <Spin indicator={<LoadingOutlined spin />} /> : null}
      </Styled.ThumbnailImageFrameOutlined>
    )}
  </Styled.ThumbnailImageFrame>
);
