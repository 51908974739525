import { Col } from 'antd';
import { Form, Input, Switch, InputNumber } from 'formik-antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ResponseFormFields.module.css';

/**
 * @notExported
 */
interface ResponseFormFieldsProps {
  isSaving: boolean;
  isDisabled?: boolean;
  index: number;
  activeLanguage: string;
}

const ResponseFormFields = ({
  isSaving,
  isDisabled,
  index,
  activeLanguage,
}: ResponseFormFieldsProps) => {
  const isFormDisabled = isSaving || isDisabled;

  return (
    <Fragment>
      <Col span={8}>
        <Form.Item
          label={<FormattedMessage id="condition-edit.id-label" />}
          name={`content.${index}.responseId`}
          required
          hasFeedback
        >
          <Input disabled={isFormDisabled} required name={`content.${index}.responseId`} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={`content.${index}.condition`}
          label={<FormattedMessage id="condition-edit.condition-label" />}
        >
          <Input name={`content.${index}.condition`} disabled={isFormDisabled} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={`content.${index}.buildTimeIf`}
          label={<FormattedMessage id="condition-edit.build-time-if-label" />}
        >
          <Input name={`content.${index}.buildTimeIf`} disabled={isFormDisabled} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={<FormattedMessage id="condition-edit.response-patient-label" />}
          name={`content.${index}.patient[${activeLanguage}]`}
          required
          hasFeedback
        >
          <Input
            disabled={isFormDisabled}
            required
            name={`content.${index}.patient[${activeLanguage}]`}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={<FormattedMessage id="condition-edit.response-staff-label" />}
          name={`content.${index}.staff[${activeLanguage}]`}
        >
          <Input disabled={isFormDisabled} name={`content.${index}.staff[${activeLanguage}]`} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={`content.${index}.textCondition`}
          label={<FormattedMessage id="condition-edit.textCondition-label" />}
        >
          <Input disabled={isFormDisabled} name={`content.${index}.textCondition`} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={`content.${index}.negate`}
          label={<FormattedMessage id="condition-edit.negate-response-label" />}
        >
          <Switch name={`content.${index}.negate`} disabled={isFormDisabled} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item
          label={<FormattedMessage id="condition-edit.response-points-label" />}
          name={`content.${index}.points`}
        >
          <InputNumber
            disabled={isFormDisabled}
            name={`content.${index}.points`}
            className={styles.inputNumber}
            step={0.1}
            precision={1}
            min={0}
          />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export default ResponseFormFields;
