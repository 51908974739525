import { Input, Select } from 'formik-antd';
import React from 'react';

import { RulesAttribute } from 'modules/Rules/types';
import { getInputMetaProps } from 'modules/Rules/utils/utils';
import { InputOption } from 'types/types';

type OtherValueInputProps = {
  name: string;
  allowedValues: InputOption<any, string>[];
  isDisabled: boolean;
  outcomeMeta: RulesAttribute | null;
};

export const OtherValueInput = ({
  allowedValues,
  outcomeMeta,
  name,
  isDisabled,
}: OtherValueInputProps) => {
  return allowedValues.length > 0 ? (
    <Select options={allowedValues} name={name} disabled={isDisabled} allowClear />
  ) : (
    <Input {...getInputMetaProps(outcomeMeta)} placeholder="" name={name} disabled={isDisabled} />
  );
};
