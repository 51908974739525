import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { FilterPayload } from '../types';

export const useUserFilter = () => {
  return useMutation(async (payload: FilterPayload) => {
    return axios.post('/rest/admin/v1/practitioners/search', payload).then(({ data }) => data);
  });
};
