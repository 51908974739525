import { FlagFilled } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import {
  Include,
  Formula,
  SearchTerm,
  Goto,
  Call,
  Exit,
  Question,
  Statement,
  Guidance,
  ExportData,
  ImportData,
} from 'modules/Content24/Condition/models/Code24Model';

import styles from './EditStatement.module.css';
import { CODE24_MODEL_TYPES } from '../../constants/code24types';
import CallForm from '../BlockForms/CallForm';
import ExitForm from '../BlockForms/ExitForm';
import ExportDataForm from '../BlockForms/ExportDataForm';
import FormulaForm from '../BlockForms/FormulaForm';
import GotoForm from '../BlockForms/GotoForm';
import GuidanceForm from '../BlockForms/GuidanceForm';
import ImportDataForm from '../BlockForms/ImportDataForm';
import IncludeForm from '../BlockForms/IncludeForm';
import QuestionForm from '../BlockForms/QuestionForm';
import SearchTermForm from '../BlockForms/SearchTermForm';

/**
 * @notExported
 */
interface EditStatementProps {
  onCancel: () => void;
  onSubmit: (data: Statement) => void;
  activeLanguage: LANGS;
  availableLanguages: LANGS[];
  onLanguageChange: (language: LANGS) => void;
  statement?: Statement;
  isDisabled?: boolean;
  diagnosisCodes?: {
    [key: string]: string[];
  };
}

const EditStatement: FunctionComponent<EditStatementProps> = ({
  onCancel,
  onSubmit,
  onLanguageChange,
  isDisabled,
  statement,
  activeLanguage,
  availableLanguages,
  diagnosisCodes,
}) => {
  const renderForm = useCallback(() => {
    if (!statement) {
      return null;
    }

    switch (statement.type) {
      case CODE24_MODEL_TYPES.INCLUDE:
        return (
          <IncludeForm
            data={statement as Include}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.FORMULA:
        return (
          <FormulaForm
            data={statement as Formula}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      case CODE24_MODEL_TYPES.EXIT:
        return (
          <ExitForm
            data={statement as Exit}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
            isDisabled={isDisabled}
            diagnosisCodes={diagnosisCodes}
          />
        );
      case CODE24_MODEL_TYPES.SEARCH_TERM:
        return (
          <SearchTermForm
            data={statement as SearchTerm}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.GOTO:
        return (
          <GotoForm
            data={statement as Goto}
            activeLanguage={activeLanguage}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      case CODE24_MODEL_TYPES.QUESTION:
        return (
          <QuestionForm
            data={statement as Question}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.CALL:
        return (
          <CallForm
            data={statement as Call}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.GUIDANCE:
        return (
          <GuidanceForm
            data={statement as Guidance}
            isDisabled={isDisabled}
            onCancel={onCancel}
            onSubmit={onSubmit}
            activeLanguage={activeLanguage}
          />
        );
      case CODE24_MODEL_TYPES.EXPORT_DATA:
        return (
          <ExportDataForm
            data={statement as ExportData}
            isDisabled={isDisabled}
            activeLanguage={activeLanguage}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      case CODE24_MODEL_TYPES.IMPORT_DATA:
        return (
          <ImportDataForm
            data={statement as ImportData}
            isDisabled={isDisabled}
            activeLanguage={activeLanguage}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        );
      default:
        return null;
    }
  }, [statement, isDisabled, onCancel, onSubmit, activeLanguage, diagnosisCodes]);

  return (
    <Modal
      open={!!statement}
      destroyOnClose
      width={1200}
      title={
        <ModalTitleWithLanguageSwitcher
          title={
            <div className={styles.title}>
              {statement && <FormattedMessage id={`condition.type-${statement.type}`} />}
              {statement && 'breaking' in statement && statement.breaking && (
                <Tooltip title={<FormattedMessage id="condition-edit.breaking-exit-label" />}>
                  <FlagFilled className={styles.exitFlagIcon} />
                </Tooltip>
              )}
            </div>
          }
          activeLanguage={activeLanguage}
          availableLanguages={availableLanguages}
          onLanguageChange={onLanguageChange}
        />
      }
      footer={null}
      closable={false}
    >
      {renderForm()}
    </Modal>
  );
};

export default EditStatement;
