import React from 'react';
import ReactMarkdown from 'react-markdown';

import { sectionPlugin } from '../../utils/markdown';
import { MarkdownImage } from '../EditArticleCard/MarkdownImage';
import { MarkdownSection } from '../EditArticleCard/MarkdownSection';

interface RenderPreviewProps {
  text: string;
}

export const RenderPreview = ({ text }: RenderPreviewProps) => (
  <ReactMarkdown
    rehypePlugins={[sectionPlugin]}
    components={{ section: MarkdownSection, img: MarkdownImage }}
    linkTarget="_blank"
  >
    {text}
  </ReactMarkdown>
);
